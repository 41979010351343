export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';

export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAILURE = 'ADMIN_LOGIN_FAILURE';

export const GET_ADMIN_RIGHTS = 'GET_ADMIN_RIGHTS';
export const GET_ADMIN_RIGHTS_SUCCESS = 'GET_ADMIN_RIGHTS_SUCCESS';
export const GET_ADMIN_RIGHTS_FAILURE = 'GET_ADMIN_RIGHTS_FAILURE';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_FAILURE = 'UPDATE_STATUS_FAILURE';

export const UPDATE_RIGHTS = 'UPDATE_RIGHTS';
export const UPDATE_RIGHTS_SUCCESS = 'UPDATE_RIGHTS_SUCCESS';
export const UPDATE_RIGHTS_FAILURE = 'UPDATE_RIGHTS_FAILURE';

export const UPDATE_BIT_ALLOWED = 'UPDATE_BIT_ALLOWED';
export const UPDATE_BIT_ALLOWED_SUCCESS = 'UPDATE_BIT_ALLOWED_SUCCESS';
export const UPDATE_BIT_ALLOWED_FAILURE = 'UPDATE_BIT_ALLOWED_FAILURE';

export const UPDATE_LOT_COUNT_LIMIT = 'UPDATE_LOT_COUNT_LIMIT';
export const UPDATE_LOT_COUNT_LIMIT_SUCCESS = 'UPDATE_LOT_COUNT_LIMIT_SUCCESS';
export const UPDATE_LOT_COUNT_LIMIT_FAILURE = 'UPDATE_LOT_COUNT_LIMIT_FAILURE';

export const UPDATE_LOT_PRICE_LIMIT = 'UPDATE_LOT_PRICE_LIMIT';
export const UPDATE_LOT_PRICE_LIMIT_SUCCESS = 'UPDATE_LOT_PRICE_LIMIT_SUCCESS';
export const UPDATE_LOT_PRICE_LIMIT_FAILURE = 'UPDATE_LOT_PRICE_LIMIT_FAILURE';

export const UPDATE_LOT_PRICE_RESERVE = 'UPDATE_LOT_PRICE_RESERVE';
export const UPDATE_LOT_PRICE_RESERVE_SUCCESS =
  'UPDATE_LOT_PRICE_RESERVE_SUCCESS';
export const UPDATE_LOT_PRICE_RESERVE_FAILURE =
  'UPDATE_LOT_PRICE_RESERVE_FAILURE';

export const GET_OWN_FEATURES = 'GET_OWN_FEATURES';
export const GET_OWN_FEATURES_SUCCESS = 'GET_OWN_FEATURES_SUCCESS';
export const GET_OWN_FEATURES_FAILURE = 'GET_OWN_FEATURES_FAILURE';

export const UPDATE_OWN_FEATURES = 'UPDATE_OWN_FEATURES';
export const UPDATE_OWN_FEATURES_SUCCESS = 'UPDATE_OWN_FEATURES_SUCCESS';
export const UPDATE_OWN_FEATURES_FAILURE = 'UPDATE_OWN_FEATURES_FAILURE';

export const GET_EXPORTED_FEATURES = 'GET_EXPORTED_FEATURES';
export const GET_EXPORTED_FEATURES_SUCCESS = 'GET_EXPORTED_FEATURES_SUCCESS';

export const UPDATE_EXPORTED_FEATURES = 'UPDATE_EXPORTED_FEATURES';
export const UPDATE_EXPORTED_FEATURES_SUCCESS =
  'UPDATE_EXPORTED_FEATURES_SUCCESS';
export const UPDATE_EXPORTED_FEATURES_FAILURE =
  'UPDATE_EXPORTED_FEATURES_FAILURE';

export const GET_ADMIN_EVENTS = 'GET_ADMIN_EVENTS';
export const GET_ADMIN_EVENTS_SUCCESS = 'GET_ADMIN_EVENTS_SUCCESS';
export const GET_ADMIN_EVENTS_FAILURE = 'GET_ADMIN_EVENTS_FAILURE';


export const UPDATE_VS_OWN_FEATURES = 'UPDATE_VS_OWN_FEATURES';
export const AUTO_SORT_INDEX = 'AUTO_SORT_INDEX';
export const UPDATE_VS_EXPORTED_FEATURES = 'UPDATE_VS_EXPORTED_FEATURES';
export const CREATE_VS_OWN_FEATURES = 'CREATE_VS_OWN_FEATURES';
export const CREATE_VS_EXPORTED_FEATURES = 'CREATE_VS_EXPORTED_FEATURES';
export const DELETE_VS_OWN_FEATURES = 'DELETE_VS_OWN_FEATURES';
export const DELETE_VS_EXPORTED_FEATURES = 'DELETE_VS_EXPORTED_FEATURES';

export const CREATE_OWN_FEATURE = 'CREATE_OWN_FEATURE';
export const CREATE_EXPORTED_FEATURE = 'CREATE_EXPORTED_FEATURE';

export const SYNCHRONIZE_FEATURE = 'SYNCHRONIZE_FEATURE';
export const SYNCHRONIZE_VALUE_STRUCTURE = 'SYNCHRONIZE_VALUE_STRUCTURE';

export const GET_LOT_PROCESSING_DATA = 'GET_LOT_PROCESSING_DATA';
export const GET_LOT_PROCESSING_DATA_SUCCESS = 'GET_LOT_PROCESSING_DATA_SUCCESS';
export const GET_LOT_PROCESSING_DATA_FAILURE = 'GET_LOT_PROCESSING_DATA_FAILURE';

export const UPDATE_LOT_PROCESSING_PRICES = 'UPDATE_LOT_PROCESSING_PRICES';
export const UPDATE_LOT_PROCESSING_PRICES_SUCCESS = 'UPDATE_LOT_PROCESSING_PRICES_SUCCESS';
export const UPDATE_LOT_PROCESSING_PRICES_FAILURE = 'UPDATE_LOT_PROCESSING_PRICES_FAILURE';

export const UPDATE_LOT_PROCESSING_STATUS = 'UPDATE_LOT_PROCESSING_STATUS';
export const UPDATE_LOT_PROCESSING_STATUS_SUCCESS = 'UPDATE_LOT_PROCESSING_STATUS_SUCCESS';
export const UPDATE_LOT_PROCESSING_STATUS_FAILURE = 'UPDATE_LOT_PROCESSING_STATUS_FAILURE';

export const UPDATE_LOT_PROCESSING_INVOICE = 'UPDATE_LOT_PROCESSING_INVOICE';
export const UPDATE_LOT_PROCESSING_INVOICE_SUCCESS = 'UPDATE_LOT_PROCESSING_INVOICE_SUCCESS';
export const UPDATE_LOT_PROCESSING_INVOICE_FAILURE = 'UPDATE_LOT_PROCESSING_INVOICE_FAILURE';


export const UPDATE_SURCHARGES_VALUE = 'UPDATE_SURCHARGES_VALUE';
export const UPDATE_SURCHARGES_VALUE_SUCCESS = 'UPDATE_SURCHARGES_VALUE_SUCCESS';
export const UPDATE_SURCHARGES_VALUE_FAILURE = 'UPDATE_SURCHARGES_VALUE_FAILURE';

export const ADD_SURCHARGES = 'ADD_SURCHARGES';
export const ADD_SURCHARGES_SUCCESS = 'ADD_SURCHARGES_SUCCESS';
export const ADD_SURCHARGES_FAILURE = 'ADD_SURCHARGES_FAILURE';

export const CHECK_NEW_ADMIN_EVENTS = 'CHECK_NEW_ADMIN_EVENTS';
export const CHECK_NEW_ADMIN_EVENTS_SUCCESS = 'CHECK_NEW_ADMIN_EVENTS_SUCCESS';
export const CHECK_NEW_ADMIN_EVENTS_FAILURE = 'CHECK_NEW_ADMIN_EVENTS_FAILURE';

export const DELETE_SURCHARGES = 'DELETE_SURCHARGES';
export const DELETE_SURCHARGES_SUCCESS = 'DELETE_SURCHARGES_SUCCESS';
export const DELETE_SURCHARGES_FAILURE = 'DELETE_SURCHARGES_FAILURE';

export const GENERATE_LOT_INVOICE = 'GENERATE_LOT_INVOICE';
export const GENERATE_LOT_INVOICE_SUCCESS = 'GENERATE_LOT_INVOICE_SUCCESS';
export const GENERATE_LOT_INVOICE_FAILURE = 'GENERATE_LOT_INVOICE_FAILURE';

export const SET_EVENT_STATUS_AS_PROCESSED = 'SET_EVENT_STATUS_AS_PROCESSED';
export const SET_EVENT_STATUS_AS_PROCESSED_SUCCESS = 'SET_EVENT_STATUS_AS_PROCESSED_SUCCESS';
export const SET_EVENT_STATUS_AS_PROCESSED_FAILURE = 'SET_EVENT_STATUS_AS_PROCESSED_FAILURE';

export const GET_PAYMENTS = 'GET_PAYMENTS';
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_FAILURE = 'GET_PAYMENTS_FAILURE';

export const UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS';
export const UPDATE_PAYMENT_STATUS_SUCCESS = 'UPDATE_PAYMENT_STATUS_SUCCESS';
export const UPDATE_PAYMENT_STATUS_FAILURE = 'UPDATE_PAYMENT_STATUS_FAILURE';

export const UPDATE_PAYMENT_PROVIDER = 'UPDATE_PAYMENT_PROVIDER';
export const UPDATE_PAYMENT_PROVIDER_SUCCESS = 'UPDATE_PAYMENT_PROVIDER_SUCCESS';
export const UPDATE_PAYMENT_PROVIDER_FAILURE = 'UPDATE_PAYMENT_PROVIDER_FAILURE';

export const SCRAPE_AUTOBID3_DATA = 'SCRAPE_AUTOBID3_DATA';
export const SCRAPE_AUTOBID3_DATA_SUCCESS = 'SCRAPE_AUTOBID3_DATA_SUCCESS';
export const SCRAPE_AUTOBID3_DATA_FAILURE = 'SCRAPE_AUTOBID3_DATA_FAILURE';

export const TEST_DEBUG = 'TEST_DEBUG';
export const TEST_DEBUG_SUCCESS = 'TEST_DEBUG_SUCCESS';
export const TEST_DEBUG_FAILURE = 'TEST_DEBUG_FAILURE';

export const SCRAPE_CAR_AUCTIONS_DATA = 'SCRAPE_CAR_AUCTIONS_DATA';
export const SCRAPE_CAR_AUCTIONS_DATA_SUCCESS = 'SCRAPE_CAR_AUCTIONS_DATA_SUCCESS';
export const SCRAPE_CAR_AUCTIONS_DATA_FAILURE = 'SCRAPE_CAR_AUCTIONS_DATA_FAILURE';

export const GET_FILTERS = 'GET_FILTERS';
export const GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS';
export const GET_FILTERS_FAILURE = 'GET_FILTERS_FAILURE';

export const CRATE_FILTER = 'CRATE_FILTER';
export const CRATE_FILTER_SUCCESS = 'CRATE_FILTER_SUCCESS';
export const CRATE_FILTER_FAILURE = 'CRATE_FILTER_FAILURE';

export const CRATE_FILTER_VALUE = 'CRATE_FILTER_VALUE';
export const CRATE_FILTER_VALUE_SUCCESS = 'CRATE_FILTER_VALUE_SUCCESS';
export const CRATE_FILTER_VALUE_FAILURE = 'CRATE_FILTER_VALUE_FAILURE';

export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_FILTER_SUCCESS = 'UPDATE_FILTER_SUCCESS';
export const UPDATE_FILTER_FAILURE = 'UPDATE_FILTER_FAILURE';

export const UPDATE_VALUE_STRUCTURE = 'UPDATE_VALUE_STRUCTURE';
export const UPDATE_VALUE_STRUCTURE_SUCCESS = 'UPDATE_VALUE_STRUCTURE_SUCCESS';
export const UPDATE_VALUE_STRUCTURE_FAILURE = 'UPDATE_VALUE_STRUCTURE_FAILURE';

export const UPDATE_SYNC_WITH_FEATURES = 'UPDATE_SYNC_WITH_FEATURES';
export const UPDATE_SYNC_WITH_FEATURES_SUCCESS = 'UPDATE_SYNC_WITH_FEATURES_SUCCESS';
export const UPDATE_SYNC_WITH_FEATURES_FAILURE = 'UPDATE_SYNC_WITH_FEATURES_FAILURE';

export const DELETE_FILTER = 'DELETE_FILTER';
export const DELETE_FILTER_SUCCESS = 'DELETE_FILTER_SUCCESS';
export const DELETE_FILTER_FAILURE = 'DELETE_FILTER_FAILURE';

export const DELETE_VALUE_STRUCTURE = 'DELETE_VALUE_STRUCTURE';
export const DELETE_VALUE_STRUCTURE_SUCCESS = 'DELETE_VALUE_STRUCTURE_SUCCESS';
export const DELETE_VALUE_STRUCTURE_FAILURE = 'DELETE_VALUE_STRUCTURE_FAILURE';

export const DELETE_SYNC_WITH_FEATURE = 'DELETE_SYNC_WITH_FEATURE';
export const DELETE_SYNC_WITH_FEATURE_SUCCESS = 'DELETE_SYNC_WITH_FEATURE_SUCCESS';
export const DELETE_SYNC_WITH_FEATURE_FAILURE = 'DELETE_SYNC_WITH_FEATURE_FAILURE';
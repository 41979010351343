import {GET_AUCTIONS_INFO_SUCCESS, GET_AUCTION_DATA_SUCCESS} from './types';

const initialState = {};

const auctionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUCTIONS_INFO_SUCCESS:
      return {...action.payload};
    case GET_AUCTION_DATA_SUCCESS:
      return {...state, auction: action.payload};
    default:
      //Do nothing
      break;
  }
  return state;
};

export default auctionsReducer;

import {
  GET_IS_CRONS_RUNNING_SUCCESS,
  GET_IS_TECHNICAL_WORKS_SUCCESS,
  LOAD_DATA_FOR_HOME_SUCCESS,
} from './types';

const initialState = {
  isLoaded: false,
  count: 0,
  expensiveCar: null,
  topManufacs: null,
  topViews: null,
};

const homeReducer = (state = initialState, action) => {
  let new_state = {};
  switch (action.type) {
    case LOAD_DATA_FOR_HOME_SUCCESS:
      new_state = {
        expensiveCar: action.payload.expensiveCar,
        topManufacs: action.payload.topManufacs,
        topViews: action.payload.topViews,
      };
      break;
    case GET_IS_TECHNICAL_WORKS_SUCCESS:
      new_state = {
        isTechnicalWorks: action.payload.isTechnicalWorks,
      };
      break;
    case GET_IS_CRONS_RUNNING_SUCCESS:
      new_state = {
        isCronsRunning: action.payload.isCronsRunning,
      };
      break;
    default:
      //Do nothing
      break;
  }
  return {...state, ...new_state};
};

export default homeReducer;

import {ReactComponent as FuelIcon} from '@assets/tags/fuel.svg';
import {ReactComponent as MilesIcon} from '@assets/tags/miles.svg';
import {ReactComponent as TransmissionIcon} from '@assets/tags/transmission.svg';
import {ReactComponent as CalendarIcon} from '@assets/tags/calendar.svg';
import {ReactComponent as PowerIcon} from '@assets/tags/power.svg';

import './Tags.scss';

function Tags({tagsInfo = []}) {
  if (!tagsInfo) {
    return <></>;
  }
  const tags = {};
  for (const tag of tagsInfo) {
    tags[tag.code] = {
      ...tag,
      docs: tag['docs'].join(', '),
    };
  }
  return (
    <div className='tags'>
      {tags['millage'] && tags['millage_type'] ? (
        <div className='tag'>
          <p>
            <MilesIcon className='icon' />
          </p>
          <p>{tags['millage'].docs}</p>
          <p> {tags['millage_type'].value === '1' ? 'km' : 'm'}</p>
        </div>
      ) : null}

      {tags['power_kw'] && tags['power_hp'] ? (
        <div className='tag'>
          <PowerIcon className='icon' />
          <p>{tags['power_kw']?.docs + ' KW'}</p>
          {' / '}
          <p>{tags['power_hp']?.docs + ' PS'}</p>
        </div>
      ) : null}

      {tags['year'] ? (
        <div className='tag'>
          <p>
            <CalendarIcon className='icon' />
          </p>
          <p>{tags['year']?.docs}</p>
        </div>
      ) : null}

      {tags['fuel'] && tags['engine_size'] ? (
        <div className='tag'>
          <p>
            <FuelIcon className='icon' />
          </p>
          <p>{tags['fuel']?.docs}</p>
          <p>{tags['engine_size'].docs + ' L'}</p>
        </div>
      ) : null}

      {tags['transmission'] ? (
        <div className='tag'>
          <p>
            <TransmissionIcon className='icon' />
          </p>
          <p> {tags['transmission']?.docs}</p>
        </div>
      ) : null}
    </div>
  );
}

export default Tags;

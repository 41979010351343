import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {DropDown, Input} from '@ui';
import {Select, Interval, Radio} from '@components';
import {Logger} from '@helpers';

import './Filters.scss';

function Filters({filtersConfig, setFiltersConfig}) {
  const [t, i18n] = useTranslation();
  const [params, setSearchParams] = useSearchParams();
  const [filtersConfigLocal, setFiltersConfigLocal] = useState(filtersConfig);

  function loadDataFromSearchParams(filtersConfig) {
    for (const filter of filtersConfig) {
      switch (filter.type) {
        case 'select':
          {
            const filterParams = params
              .get(filter.id)
              ?.split(',')
              .filter(el => el !== '' && el !== null && el !== undefined)
              .map(i => +i);
            for (const item of filter.data) {
              item.checked =
                filterParams?.find(i => i === item.id) !== undefined;
            }
          }
          break;
        case 'input':
          filter.data.value = params.get(filter.id) || '';
          break;
        case 'interval':
          filter.from = parseFloat(params.get(filter.id + 'From'));
          filter.to = parseFloat(params.get(filter.id + 'To'));
          break;
        case 'radio':
          {
            let filterParams = params.get(filter.id);
            if (filterParams !== null && filterParams !== undefined) {
              filterParams = parseFloat(filterParams);
              for (const item of filter.data) {
                item.checked = item.id === filterParams;
              }
            }
          }
          break;

        default:
          Logger.error('Unknown type for filter: ' + filter.type);
      }
    }
    return filtersConfig;
  }

  useEffect(() => {
    if (filtersConfigLocal === filtersConfig) return;
    if (filtersConfig.length === 0) return;
    const newFiltersConfigLocal = [...loadDataFromSearchParams(filtersConfig)];
    setFiltersConfigLocal(newFiltersConfigLocal);
    setFiltersConfig(newFiltersConfigLocal);
  }, [filtersConfig]);

  useEffect(() => {
    if (filtersConfig.length === 0) return;
    const newFiltersConfigLocal = [...loadDataFromSearchParams(filtersConfig)];
    setFiltersConfigLocal(newFiltersConfigLocal);
    setFiltersConfig(newFiltersConfigLocal);
  }, [params]);

  function onChange(type, title, value) {
    const filter = filtersConfig.find(i => i.title === title);
    let paramKey = filter.id;
    let paramsValue = value;
    switch (type) {
      case 'Select':
        const res = filter.data.find(i => i.id === value);
        res.checked = !res.checked;
        paramsValue = filter.data.filter(i => i.checked).map(i => i.id);
        break;
      case 'Input':
        filter.data.value = value;
        break;
      case 'UnitMeasurement':
        filter.unit = value;
        filter.from = null;
        filter.to = null;
        paramKey = 'ptype';
        break;
      case 'FieldFrom':
        filter.from = value;
        paramKey += 'From';
        paramsValue = parseFloat(('' + value).replace(' ', ''));
        break;
      case 'FieldTo':
        filter.to = value;
        paramKey += 'To';
        paramsValue = parseFloat(('' + value).replace(' ', ''));
        break;
      case 'Radio': {
        filter.data.forEach(i => {
          if (i.id === value) {
            i.checked = !i.checked;
          } else if (i.checked) {
            i.checked = false;
          }
        });
        paramsValue = filter.data.filter(i => i.checked).map(i => i.id);
        break;
      }
      default:
        Logger.error('Unknown handlers for filter: ' + type);
    }
    params.set([paramKey], paramsValue);
    if (params.has('page')) {
      params.delete('page');
    }
    for (const [key, value] of params.entries()) {
      if (value === '') params.delete(key);
    }
    setSearchParams(params, {replace: true});
    setFiltersConfig([...filtersConfig]);
  }

  const Handlers = {
    Select: onChange.bind(null, 'Select'),
    Input: onChange.bind(null, 'Input'),
    UnitMeasurement: onChange.bind(null, 'UnitMeasurement'),
    FieldFrom: onChange.bind(null, 'FieldFrom'),
    FieldTo: onChange.bind(null, 'FieldTo'),
    Radio: onChange.bind(null, 'Radio'),
  };

  function determineFilters(filter) {
    switch (filter.type) {
      case 'select':
        return <Select filter={filter} onChange={Handlers.Select} />;
      case 'input':
        return (
          <Input
            value={filter.data.value}
            placeholder={filter.placeHolder}
            onChange={e => Handlers.Input(filter.title, e.target.value)}
            underTitle={filter.footer}
          />
        );
      case 'interval':
        return (
          <Interval
            filter={filter}
            onChangeUnitMeasurement={Handlers.UnitMeasurement}
            onChangeFieldFrom={Handlers.FieldFrom}
            onChangeFieldTo={Handlers.FieldTo}
          />
        );
      case 'radio':
        return <Radio filter={filter} onChange={Handlers.Radio} />;
      default:
        Logger.error('Unknown type for filter: ' + filter.type);
    }
  }

  return (
    <div className='LotsFilters'>
      {filtersConfig &&
        filtersConfig
          .filter(f => f.data.length !== 0)
          .map((filter, index) => (
            <div className='filter' key={index}>
              <DropDown title={filter.title}>
                {determineFilters(filter)}
              </DropDown>
            </div>
          ))}
    </div>
  );
}
export default Filters;

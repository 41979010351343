import {formatPriceWithCent} from '@utils';

import {getVFF} from '../../../modules/getValueFomField';

function Price(field) {
  return rowData => {
    const value = getVFF(rowData, field);
    if (value === null) return 0;
    return (
      <p style={{textAlign: 'end', marginRight: '10px'}}>
        {formatPriceWithCent(value)}
      </p>
    );
  };
}
export default Price;

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import {Loader, SelectButton} from '@ui';
import {getExportedFeatures, getOwnFeatures} from '@actions/admin';
import {useDispatchAsync} from '@hooks';

import './Features.scss';


function Features() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatchAsync();
  const pagesTitles = ['Власні', 'Експортовані', 'Синхронізувати'];
  const pagesLinks = ['own', 'export', 'synchronize'];
  const ownFeatures = useSelector(state => state.admin.ownFeatures);
  const exportedFeatures = useSelector(state => state.admin.exportedFeatures);

  const [selectPage, setSelectPage] = useState();

  useEffect(() => {
    if (location.pathname === '/org/admin/features') {
      return navigate('/org/admin/features/own', {replace: true});
    }

    let page = location.pathname.split('/').at(-1);
    pagesLinks.find(i => i === page) || (page = pagesLinks[2]);

    setSelectPage(pagesTitles[pagesLinks.findIndex(i => i === page)]);
  }, [location]);

  function synchronize(_synchronizeItem) {
    navigate('/org/admin/features/synchronize/' + _synchronizeItem.id);
  }

  useEffect(() => {
    dispatch(getOwnFeatures());
    dispatch(getExportedFeatures());
  }, []);

  return (
    <div className='Features'>
      <div className='card flex justify-content-center m-3'>
        <SelectButton
          value={selectPage}
          onChange={e =>
            e.value !== null &&
            navigate(
              '/org/admin/features/' +
                pagesLinks[pagesTitles.findIndex(i => i === e.value)]
            )
          }
          options={pagesTitles}
        />
      </div>
      <div className='Features_container'>
        {ownFeatures && exportedFeatures ? (
          <Outlet context={{synchronize: synchronize}} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}
export default Features;

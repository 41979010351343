import ReactHtmlParser from 'html-react-parser';
import {Link} from 'react-router-dom';

import {getVFF} from '../../../modules/getValueFomField';

function LotLink(field) {
  return rowData => {
    const value = getVFF(rowData, field);
    const lotId = rowData.lotId;
    return (
      <Link
        to={'/lot/' + lotId}
        style={{lineHeight: 1.4, color: 'rgb(49 153 118)', textDecoration: 'underline'}}
        target={'_blank'}>
        {ReactHtmlParser(value)}
      </Link>
    );
  };
}
export default LotLink;

import {Logger} from '@helpers';

const fetchingQuery = {};

async function FetchToRedux(
  dispatch,
  type,
  link,
  params,
  _data,
  isLogErrors,
  excludeAbort
) {
  const controller = new AbortController();

  let data = {};

  if (fetchingQuery[type] && excludeAbort === false) {
    fetchingQuery[type].forEach(query => {
      query.abort();
    });
    fetchingQuery[type] = [];
  }

  if (!fetchingQuery[type]) {
    fetchingQuery[type] = [];
  }
  fetchingQuery[type].push(controller);
  dispatch({type: type, payload: _data});

  let response = null;
  try {
    response = await fetch(link, {signal: controller.signal, ...params});
  } catch (error) {
    data.ok = null;
    return callFailure(
      dispatch,
      type,
      data,
      !error.toString().includes('aborted')
    );
  }

  const contentType = response.headers.get('content-type');
  switch (contentType) {
    case 'application/json; charset=utf-8':
      data = await response.json();
      break;
    case 'application/octet-stream; charset=utf-8':
    case 'application/octet-stream':
    case 'application/pdf; charset=utf-8':
    case 'application/pdf':
      dispatch({type: type + '_SUCCESS', payload: data});
      return response.blob();
    case 'text/plain; charset=utf-8':
    case 'text/html; charset=utf-8':
      Logger.error('Unknown type response: ' + contentType, {response});
      break;
    default:
      Logger.error('Unknown type response: ' + contentType, {response});
      break;
  }

  //Add custom field (ok)  currentPrice-  means whether the request was successful
  if (response.ok) {
    data.ok = data.status !== 'error';
  } else {
    data.ok = false;
  }

  if (data.ok) {
    dispatch({type: type + '_SUCCESS', payload: data});
  } else {
    return callFailure(dispatch, type, data);
  }
  return data;
}

function callFailure(dispatch, type, data, isLog = true) {
  if (isLog) {
    dispatch({type: type + '_FAILURE', payload: data});
    Logger.error(type + '_FAILURE', data);
  }
  return data;
}

function COMMONQUERY(method, data, headers = {}) {
  const params = {
    method: method,
    mode: 'cors',
  };
  if (method === 'POST') {
    if (data?.__proto__?.constructor?.name === 'FormData') {
      params.body = data;
      params.headers = {
        ...headers,
      };
      return params;
    }
  }
  params.body = JSON.stringify(data);
  params.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers,
  };
  return params;
}

export {FetchToRedux, COMMONQUERY};

import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {CheckBox} from '@ui';
import {ACCOUNT_TYPE_PERSONAL} from '@models';

import './TypeAccount.scss';

function TypeAccount({electType, setElectType, exclude}) {
  const typeAccount = useSelector(state => state.user.type);

  const [t] = useTranslation('pages/registration', {
    keyPrefix: 'step_3',
  });
  useEffect(() => {
    if (electType === undefined) {
      setElectType(
        typeAccount === ACCOUNT_TYPE_PERSONAL ? 'personal' : 'company'
      );
    }
  }, []);

  return (
    <div className='TypeAccount'>
      <section>
        <CheckBox
          state={electType === 'personal'}
          setState={() => !exclude && setElectType('personal')}
        />
        <div className='text'>
          <div className='head'>{t('typeAccount.personal.head')}</div>
          <div className='bottom'>{t('typeAccount.personal.head')}</div>
        </div>
      </section>
      <section>
        <CheckBox
          state={electType === 'company'}
          setState={() => !exclude && setElectType('company')}
        />
        <div className='text'>
          <div className='head'>{t('typeAccount.company.head')}</div>
          <div className='bottom'>{t('typeAccount.company.bottom')}</div>
        </div>
      </section>
    </div>
  );
}

export default TypeAccount;

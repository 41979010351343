import {
  GET_LOT_INFO,
  GET_LOT_PRICES,
  GET_NEXT_LOT,
  GET_PREV_LOT,
  SET_LOT_NOTE,
} from './types';

export const getLotInfo = id => ({
  event: GET_LOT_INFO,
  link: `/api/info/lot/${id}`,
});

export const getNextLotId = id => ({
  event: GET_NEXT_LOT,
  link: `/api/info/lot/${id}/next`,
});

export const getPrevLotId = id => ({
  event: GET_PREV_LOT,
  link: `/api/info/lot/${id}/prev`,
});

export const getLotPrices = id => ({
  event: GET_LOT_PRICES,
  link: `/api/info/lot/${id}/prices`,
});

export const setLotNote = (lotId, userId, note) => ({
  event: SET_LOT_NOTE,
  link: `/api/info/set_lot_note`,
  data: {lotId, userId, note},
});

import {
  LOT_CATEGORY_CAR,
  LOT_CATEGORY_STUFF,
  LOT_FEATURE_CATEGORY_GROUP_CAR_BODY_DEFECTS,
  LOT_FEATURE_CATEGORY_GROUP_CAR_BODY_SALON,
  LOT_FEATURE_CATEGORY_GROUP_CAR_BODY_SELLER_INFO,
  LOT_FEATURE_CATEGORY_GROUP_CAR_DOCUMENTATION,
  LOT_FEATURE_CATEGORY_GROUP_CAR_MAIN_DATA,
  LOT_FEATURE_CATEGORY_GROUP_CAR_OPTIONS,
  LOT_FEATURE_CATEGORY_GROUP_CAR_OTHER,
  LOT_FEATURE_CATEGORY_GROUP_CAR_SEATS,
  LOT_FEATURE_CATEGORY_GROUP_CAR_TIRES,
  LOT_FEATURE_VALUE_TYPE_NUMBER,
  LOT_FEATURE_VALUE_TYPE_STRING,
  LOT_FEATURE_VALUE_TYPE_BOOLEAN,
  LOT_FEATURE_VALUE_TYPE_DATE,
  LOT_FEATURE_VALUE_TYPE_ENUM,
  LOT_FEATURE_VALUE_TYPE_SET,
  FILTER_OPTION_MANUFACTURER,
  FILTER_OPTION_YEAR,
  FILTER_OPTION_FUEL,
  FILTER_OPTION_TRANSMISSION,
  FILTER_OPTION_DAMAGE,
  FILTER_OPTION_MILES,
  FILTER_OPTION_POWER_KW,
  FILTER_OPTION_POWER_HP,
  FILTER_OPTION_ENGINE_SIZE,
  FILTER_OPTION_COLOR,
  FILTER_OPTION_DOORS,
} from '@helpers/models';

export const config = {
  header: 'OwnFeatures',
  type: 'table',
  fields: {
    id: {
      header: 'Id',
      type: 'number',
    },
    name: {
      header: "Ім'я",
      modifier: 'public',
      type: 'text',
      required: true,
    },
    code: {
      header: 'Код',
      type: 'text',
      modifier: 'public',
      required: true,
    },
    valueStructure: {
      header: 'Таблиця значень',
      type: 'table',
      fields: {
        id: {
          header: 'Id',
          type: 'text',
        },
        code: {
          header: 'Код',
          modifier: 'public',
          type: 'number',
          required: true,
        },
        sortIndex: {
          header: 'Сорт індекс',
          modifier: 'public',
          type: 'number',
          required: true,
        },
        'title.en': {
          header: 'Заголовок en',
          modifier: 'public',
          type: 'text',
          required: true,
        },
        'title.uk': {
          header: 'Заголовок uk',
          modifier: 'public',
          type: 'text',
          required: true,
        },
        'title.ru': {
          header: 'Заголовок ru',
          modifier: 'public',
          type: 'text',
          required: true,
        },
        'title.pl': {
          header: 'Заголовок pl',
          modifier: 'public',
          type: 'text',
          required: true,
        },
      },
    },
    valueType: {
      required: true,
      header: 'Тип значення',
      type: 'list',
      modifier: 'public',
      editorData: [
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_NUMBER,
          title: 'Число',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_STRING,
          title: 'Рядок',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_BOOLEAN,
          title: 'Булеве',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_DATE,
          title: 'Дата',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_ENUM,
          title: 'Масив',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_SET,
          title: 'Набір',
          severity: 'info',
        },
      ],
    },
    filterOption: {
      type: 'list',
      header: 'Фільтер опція',
      modifier: 'public',
      editorData: [
        {
          indexInDB: FILTER_OPTION_MANUFACTURER,
          title: 'Марка',
          severity: 'info',
        },
        {indexInDB: FILTER_OPTION_YEAR, title: 'Рік', severity: 'info'},
        {indexInDB: FILTER_OPTION_FUEL, title: 'Паливо', severity: 'info'},
        {
          indexInDB: FILTER_OPTION_TRANSMISSION,
          title: 'Трансмісія',
          severity: 'info',
        },
        {
          indexInDB: FILTER_OPTION_DAMAGE,
          title: 'Пошкодження',
          severity: 'info',
        },
        {indexInDB: FILTER_OPTION_MILES, title: 'Пробіг', severity: 'info'},
        {
          indexInDB: FILTER_OPTION_POWER_KW,
          title: 'Потужність KW',
          severity: 'info',
        },
        {
          indexInDB: FILTER_OPTION_POWER_HP,
          title: 'Кінські сили HP',
          severity: 'info',
        },
        {
          indexInDB: FILTER_OPTION_ENGINE_SIZE,
          title: 'Обʼєм двигуна',
          severity: 'info',
        },
        {indexInDB: FILTER_OPTION_COLOR, title: 'Колір', severity: 'info'},
        {indexInDB: FILTER_OPTION_DOORS, title: 'Двері', severity: 'info'},
      ],
    },

    category: {
      required: true,
      type: 'list',
      header: 'Категорія',
      modifier: 'public',
      editorData: [
        {indexInDB: LOT_CATEGORY_CAR, title: 'Авто', severity: 'info'},
        {indexInDB: LOT_CATEGORY_STUFF, title: 'Речі', severity: 'info'},
      ],
    },
    category_group: {
      required: true,
      type: 'list',
      header: 'Група категорії',
      modifier: 'public',
      editorData: [
        {
          indexInDB: LOT_FEATURE_CATEGORY_GROUP_CAR_OTHER,
          title: 'Інше',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_CATEGORY_GROUP_CAR_MAIN_DATA,
          title: 'Головні дані',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_CATEGORY_GROUP_CAR_DOCUMENTATION,
          title: 'Документація',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_CATEGORY_GROUP_CAR_OPTIONS,
          title: 'Опції',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_CATEGORY_GROUP_CAR_SEATS,
          title: 'Сидіння',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_CATEGORY_GROUP_CAR_TIRES,
          title: 'Шини',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_CATEGORY_GROUP_CAR_BODY_DEFECTS,
          title: 'Пошкодження кузова',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_CATEGORY_GROUP_CAR_BODY_SALON,
          title: 'Кузов-Салон',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_CATEGORY_GROUP_CAR_BODY_SELLER_INFO,
          title: 'Інформація від власника',
          severity: 'info',
        },
      ],
    },

    title: {
      type: 'table',
      header: 'Заголовки',
      fields: {
        en: {
          header: 'Заголовок en',
          modifier: 'public',
          type: 'text',
          required: true,
        },
        uk: {
          header: 'Заголовок uk',
          modifier: 'public',
          type: 'text',
          required: true,
        },
        ru: {
          header: 'Заголовок ru',
          modifier: 'public',
          type: 'text',
          required: true,
        },
        pl: {
          header: 'Заголовок pl',
          modifier: 'public',
          type: 'text',
          required: true,
        },
      },
    },
    srcCode: {
      header: 'Код джерела',
      modifier: 'public',
      type: 'text',
    },
    sortIndex: {
      header: 'Індекс сортування',
      type: 'number',
      modifier: 'public',
      required: true,
    },
    isTag: {
      header: 'isTag',
      modifier: 'public',
      type: 'list',
      editorData: [
        {indexInDB: true, title: 'Так', severity: 'info'},
        {indexInDB: false, title: 'Ні', severity: 'danger'},
      ],
    },
  },
};

import {Button} from '@ui';
import {useEffect, useState} from 'react';

function PayBtn({
  orderType = null,
  tariffType = null,
  lotId = null,
  children,
  ...props
}) {
  const [link, setLink] = useState();

  useEffect(() => {
    const params = new URLSearchParams();
    orderType !== null && params.set('orderType', orderType);
    tariffType !== null && params.set('tariffType', tariffType);
    lotId !== null && params.set('lotId', lotId);
    setLink('/payment?' + params);
  }, [orderType, tariffType, lotId]);

  return (
    <Button className='PayBtn' to={link} target={'_blank'} {...props}>
      {children}
    </Button>
  );
}

export default PayBtn;

import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import './PaginationComponent.scss';
import {useSearchParams} from 'react-router-dom';

function PaginationComponent({data}) {
  const [searchParams, setSearchParams] = useSearchParams();

  function pageChange(page) {
    searchParams.set('page', page);
    setSearchParams(searchParams, {replace: true});
  }

  if (!data) return <></>;
  const {currentPage, totalPages} = data;
  return (
    <div className='PaginationComponent'>
      {totalPages <= 1 ? null : (
        <Pagination
          page={Number(currentPage)}
          count={totalPages}
          onChange={(_, page) => pageChange(page)}
          shape='rounded'
          size='small'
          className='my_pagination_theme'
          renderItem={item => <PaginationItem {...item} />}
        />
      )}
    </div>
  );
}

export default PaginationComponent;

import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {Toast} from '@helpers';
import {useDispatchAsync} from '@hooks';

function useUpdateData() {
  const dispatch = useDispatchAsync();
  const [errors] = useTranslation('common', {keyPrefix: 'errors'});

  return useCallback(async queryFunction => {
    const res = await dispatch(queryFunction, false);
    if (res.ok) {
      Toast.success('Дані успішно збережені');
    } else {
      if (res.code) {
        Toast.error('Сталася помилка: ' + errors([res.code, 'unknown']));
      } else {
        Toast.error('Сталася помилка');
      }
    }
    return res;
  });
}
export default useUpdateData;

function useValidation(name, color) {
  const inputs = document.querySelectorAll('.' + name);
  let countValid = 0;
  inputs.forEach(i => {
    if (i.nextSibling) {
      let isValid = i.validity.valid;
      if (i.type === 'date' && i._is_valid !== undefined) {
        isValid = i._is_valid;
      }
      countValid = isValid && i.value !== '' ? countValid + 1 : countValid - 1;
      if (color === 'color') {
        i.nextSibling.style.color = +!isValid ? 'red' : '#999999';
      } else {
        i.nextSibling.style.opacity = +!isValid;
      }
    }
  });

  return countValid === inputs.length;
}
export default useValidation;

import React, {useEffect, useState} from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Toast} from '@helpers';

import {timeToString} from '@utils';
import {Button as CustomButton, Input} from '@ui';
import {useDownloadOrderDocs, useDispatchAsync} from '@hooks';
import {
  generateLotInvoice,
  getCurrentRate,
  updateLotProcessingInvoice,
} from '@actions/admin';

import {
  LOT_PROCESSING_STATUS_ADMIN_CHECKED,
  LOT_PROCESSING_STATUS_NEW,
} from '@models';

import {config_invoice_form} from '../../../pages/admin/lots/config-invoice-form';

function GenerateLotInvoice(config) {
  const obj = {};
  function GenerateLotInvoice(rowData) {
    if ('rowData' in rowData) {
      rowData = rowData.rowData;
    }
    const data = rowData.invoice || obj;
    const [formInputs, setFormInputs] = useState(
      JSON.parse(JSON.stringify(config_invoice_form))
    );
    const dispatch = useDispatchAsync();
    const invoice = useDownloadOrderDocs();

    const [isCanSave, setCanSave] = useState(true);

    const [visible, setVisible] = useState(false);
    const [invoiceData, setInvoiceData] = useState(obj);

    useEffect(async () => {
      const res = await dispatch(getCurrentRate());

      const {
        value = 0,
        table_number = '',
        date_publication = new Date(),
      } = res && res.ok ? res.rate : {};

      setInvoiceData({
        no: data.no || `FV ${timeToString().split('.').join('/')}`,
        lot_name:
          data.lot_name || rowData.VIN
            ? `${rowData.lotName} ${rowData.VIN}`
            : rowData.lotName,
        rate: value,
        table_number,
        date_publication: new Date(date_publication),

        issued_at: data.issued_at || Date.now(),
      });
    }, []);

    async function onGenerate() {
      if (
        rowData.status !== LOT_PROCESSING_STATUS_NEW &&
        rowData.status !== LOT_PROCESSING_STATUS_ADMIN_CHECKED
      ) {
        Toast.error('При даному статусі не можливо змінювати invoice!!!');
        setVisible(false);
        return;
      }
      setCanSave(() => false);
      const resUpdate = await dispatch(
        updateLotProcessingInvoice(rowData.lotId, invoiceData)
      );
      if (resUpdate.ok) {
        setFormInputs(JSON.parse(JSON.stringify(config_invoice_form)));
        const res = await dispatch(generateLotInvoice(rowData.lotId));
        if (res.ok) {
          Toast.success('Invoice згенеровано успішно!');
          setVisible(() => false);
        } else {
          Toast.error('Помилка при генеруванні invoice: ' + res.code);
        }
      } else if (
        resUpdate.code === 'format' &&
        resUpdate.fields &&
        resUpdate.fields.length > 0
      ) {
        for (const input of formInputs) {
          input.error = resUpdate.fields.find(i => i === input.key);
        }
      }
      setCanSave(() => true);

      await config.onLoad();
    }

    return (
      <div className='card flex justify-content-center'>
        <div
          className='linkDownloadInvoice-container'
          style={{minHeight: '70px'}}>
          {data.no ? (
            <CustomButton
              onClick={() =>
                invoice({lotId: rowData.lotId, userId: rowData.userId})
              }
              className={`white linkDownloadInvoice ${
                rowData.status === LOT_PROCESSING_STATUS_NEW ? 'absolute' : ''
              }`}>
              {data.no}
            </CustomButton>
          ) : null}
          {rowData.status === LOT_PROCESSING_STATUS_NEW ? (
            <Button
              label={'Згенерувати'}
              icon='pi pi-external-link'
              onClick={() => setVisible(true)}
            />
          ) : null}
        </div>

        <Dialog
          header={'Додаткові дані для генерування invoice'}
          visible={visible}
          style={{width: '500px'}}
          onHide={() => {
            setVisible(false);
          }}>
          <div className='DialogDownloadInvoice'>
            <div className='inputs'>
              {formInputs.map(i => (
                <Input
                  {...i}
                  className={'invoice_field_input'}
                  classNameUnderTitle={`${i.error ? 'error' : ''}`}
                  value={invoiceData[i.key]}
                  onChange={e =>
                    setInvoiceData(p => {
                      p[i.key] = e.target.value;
                      return {...p};
                    })
                  }
                />
              ))}
            </div>
            <div className='btns'>
              <CustomButton
                text={'Згенерувати'}
                className={isCanSave ? '' : 'inactive'}
                onClick={onGenerate}
              />
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
  return GenerateLotInvoice;
}
export default GenerateLotInvoice;

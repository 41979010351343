import {SEND_EMAIL_LIST, SET_NEW_PASSWORD} from './types';

export const sendEmailList = email => ({
  event: SEND_EMAIL_LIST,
  link: '/api/auth/recover_password',
  data: {
    email: email,
  },
});

export const setNewPassword = (password, token) => ({
  event: SET_NEW_PASSWORD,
  link: '/api/auth/set_new_password',
  data: {
    password: password,
    token: token,
  },
});

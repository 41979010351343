import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {Toast} from '@helpers';
import {timeToString} from '@utils';
import {useDispatchAsync} from '@hooks';

import {
  ACCOUNT_DOCUMENT_STATUS_DECLINED,
  ACCOUNT_DOCUMENT_STATUS_NEW,
} from '@models';

import {
  deleteDocument,
  downloadDocument,
  loadDocuments,
} from '@actions/user';

import './TableDocuments.scss';

function TableDocuments() {
  const dispatch = useDispatchAsync();
  const documents = useSelector(state => state.user.documents) || [];
  const userId = useSelector(state => state.user.id) || null;
  const [t] = useTranslation('pages/profile', {
    keyPrefix: 'documents',
  });
  const [errors] = useTranslation('common', {keyPrefix: 'errors'});

  useEffect(async () => {
    const res = await dispatch(loadDocuments(), false);
    if (res.status === 'error' && res.code !== 'document_not_found') {
      Toast.error(errors(res.code));
    }
  }, []);

  async function onDownloadDocument(doc) {
    const blob = await dispatch(downloadDocument(userId, doc.id));
    if (blob.ok !== undefined) return;
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = doc.title;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  async function onDeleteDocument(doc) {
    if (doc.status !== ACCOUNT_DOCUMENT_STATUS_NEW) {
      Toast.error(t('forbidden_delete_document'));
      return;
    }
    if (!window.confirm(t('confirm_delete_file') + doc.title)) {
      return;
    }

    const res = await dispatch(deleteDocument(userId, doc.id));
    if (res.ok) {
      Toast.success(t('delete_success'));
    }
    dispatch(loadDocuments());
  }

  if (documents.length === 0) return <></>;
  return (
    <table className='TableDocuments'>
      <thead>
        <tr>
          <th>{t('thead.name')}</th>
          <th>{t('thead.date')}</th>
          <th>{t('thead.status')}</th>
          <th>{t('thead.download')}</th>
          <th>{t('thead.delete')}</th>
        </tr>
      </thead>
      <tbody>
        {documents.map((item, key) => (
          <tr key={key}>
            <td>{item.title}</td>
            <td>{timeToString(item.createdAt)}</td>
            <td>
              {item.status === ACCOUNT_DOCUMENT_STATUS_DECLINED
                ? `${t(`status.${item.status}`)} : ${t([
                  `reason_declined-${item.reason_declined}`,
                  'unknown',
                ])}`
                : t(`status.${item.status}`)}
            </td>
            <td>
              <a name={item.title} onClick={() => onDownloadDocument(item)}>
                <span className='icon-for-downloading'></span>
              </a>
            </td>
            <td>
              <a
                name={item.title}
                onClick={() =>
                  item.status === ACCOUNT_DOCUMENT_STATUS_NEW &&
                  onDeleteDocument(item)
                }
                className={`${
                  item.status === ACCOUNT_DOCUMENT_STATUS_NEW ? '' : 'inactive'
                }`}>
                <span className='icon-for-delete_file'></span>
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default TableDocuments;

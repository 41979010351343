import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {Loader} from '@ui';
import {Logger, Toast} from '@helpers';
import {useDispatchAsync} from '@hooks';

import {
  createSurcharges,
  deleteSurcharges,
  getLotProcessingData,
  updateLotProcessingPrices,
  updateLotProcessingStatus,
  updateSurchargesValue,
} from '@actions/admin';

import {
  LOT_PRICE_TYPE_NET,
  LOT_PROCESSING_STATUS_ADMIN_CHECKED,
  LOT_PROCESSING_STATUS_DELIVERED,
  LOT_PROCESSING_STATUS_DELIVERY,
  LOT_PROCESSING_STATUS_DONE,
  LOT_PROCESSING_STATUS_NEW,
  LOT_PROCESSING_STATUS_PAYED,
  LOT_PROCESSING_STATUS_REJECTED,
  getSURCHARGES_GROSS,
  getSURCHARGES_NET,
} from '@models';

import checkRequiredFields from '../../../modules/checkRequiredFields';
import useUpdateData from '../../../hooks/update-data.hook';
import Table from '../../../2-components/table/Table';

import {config} from './config';

import './Lots.scss';

function Lots() {
  const pages = [
    {title: 'Нові', status: LOT_PROCESSING_STATUS_NEW, url: 'new'},

    {
      title: 'Перевірені',
      status: LOT_PROCESSING_STATUS_ADMIN_CHECKED,
      url: 'admin_checked',
    },
    {title: 'Оплачені', status: LOT_PROCESSING_STATUS_PAYED, url: 'payed'},
    {
      title: 'В доставці',
      status: LOT_PROCESSING_STATUS_DELIVERY,
      url: 'delivery',
    },
    {
      title: 'Доставлені',
      status: LOT_PROCESSING_STATUS_DELIVERED,
      url: 'delivered',
    },
    {title: 'Завершені', status: LOT_PROCESSING_STATUS_DONE, url: 'done'},
    {title: 'Відхилені', status: LOT_PROCESSING_STATUS_REJECTED, url: 'danger'},
  ];

  const dispatch = useDispatchAsync();
  const api = useUpdateData();
  const data = useSelector(state => state.admin.lots_processing);
  useEffect(() => {
    dispatch(getLotProcessingData());
  }, []);

  useEffect(() => {
    config.onSave = async ({next, fieldMustToUpdated}) => {
      for (const {field, value} of fieldMustToUpdated) {
        switch (field) {
          case 'prices.priceType':
          case 'prices.currentPrice':
            await api(updateLotProcessingPrices(next.lotId, field, value));
            break;
          case 'status':
            await api(updateLotProcessingStatus(next.lotId, value));
            break;
          default:
            Logger.warn('NEW field :' + field + ' in admin Lots');
        }
      }
    };
    config.fields['prices.surcharges'].onCreate = async (idRow, newProduct) => {
      const {taxType, value} = newProduct;

      const priceType = data.find(i => i.id === idRow)?.prices.priceType;
      const deleteSurcharges =
        priceType === LOT_PRICE_TYPE_NET
          ? getSURCHARGES_GROSS()
          : getSURCHARGES_NET();
      if (deleteSurcharges.includes(taxType)) {
        Toast.error(
          `Даний тип Доплати не може бути застосований до ціни типу: ${
            priceType === LOT_PRICE_TYPE_NET ? 'Нетто' : 'Брутто'
          }`
        );
        return;
      }

      if (
        data
          .find(i => i.id === idRow)
          ?.prices.surcharges.find(i => i.taxType === taxType)
      ) {
        Toast.error('Вибраний тип податку вже використовується!!!');
        return;
      }

      const isFieldsFilled = checkRequiredFields(
        config.fields['prices.surcharges'].fields,
        newProduct
      );
      if (!isFieldsFilled) {
        return await api(createSurcharges(idRow, taxType, value));
      } else {
        Toast.warning(
          'Заповніть обов`язкове поле для введення - ' + isFieldsFilled
        );
        return false;
      }
    };
    config.onLoad = async () => {
      await dispatch(getLotProcessingData());
    };
    config.fields['prices.surcharges'].onSave = async ({
      idRow,
      prev,
      next,
      fieldMustToUpdated,
    }) => {
      for (const {field, value} of fieldMustToUpdated) {
        switch (field) {
          case 'value':
            await api(updateSurchargesValue(idRow, next.taxType, field, value));
            break;
          case 'taxType':
            if (
              data
                .find(i => i.id === idRow)
                ?.prices.surcharges.find(i => i.taxType === next.taxType)
            ) {
              Toast.error(
                'Вибраний тип податку вже використовується!!! ОНОВІТЬ СТОРІНКУ'
              );
              break;
            }

            await api(
              updateSurchargesValue(idRow, prev.taxType, field, next.taxType)
            );
            break;
          default:
            Logger.warn('NEW field :' + field + ' in admin Lots surcharges');
        }
      }
    };
    config.fields['prices.surcharges'].onDelete = async (prod, lotId) => {
      const res = await dispatch(deleteSurcharges(lotId, prod.taxType));
      if (res.ok) {
        Toast.success('Дані успішно збережені');
      } else {
        Toast.error('Сталася помилка');
      }
    };
    config.fields['prices.surcharges'].onLoad = async () => {
      await dispatch(getLotProcessingData());
    };
  });

  if (!data) return <Loader />;
  return (
    <div className='LotsProcessing'>
      <Table
        config={config}
        PATH={'/org/admin/lots'}
        pages={pages}
        data={data}
      />
    </div>
  );
}
export default Lots;

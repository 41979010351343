import {useEffect} from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './InputNumber.scss';

function InputNumber({
  onChangeHandler,
  phone,
  pattern,
  placeholder,
  title,
  under_input_text,
  default_value = '380',
}) {
  useEffect(async () => {
    if (!process.env.REACT_APP_GEOAPIFY || !!phone) return;
    const response = await fetch(process.env.REACT_APP_GEOAPIFY, {
      method: 'GET',
    });

    const data = await response.json();
    if (data.country && data.country.phone_code && onChangeHandler) {
      if (!phone || phone === default_value)
        onChangeHandler(data.country.phone_code);
    } else {
      onChangeHandler(default_value);
    }
  }, []);

  return (
    <div className='InputNumber'>
      <label htmlFor='title'>{title}</label>
      <ReactPhoneInput
        placeholder={placeholder}
        id='title'
        country={'ua'}
        value={phone}
        pattern={pattern}
        onChange={onChangeHandler}
      />
      <span className='under_input_text'>{under_input_text}</span>
    </div>
  );
}
export default InputNumber;

import React, {useState, useEffect, useRef} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
//hooks
import {useUpdatedState} from '@hooks';

//components
import {Loader, SelectButton} from '@ui';
//primereact
import {FilterMatchMode} from 'primereact/api';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';

//modules
import {getVFF, setVFF} from '../../modules/getValueFomField';

import DeleteProductsDialog from './dialogs/DeleteProductsDialog';
import CreateProductsDialog from './dialogs/CreateProductsDialog';
import ConfirmAutoSortIndexDialog from './dialogs/ConfirmAutoSortIndexDialog';
import SyncWithFeature from './dialogs/SyncWithFeature';

//body
import Synchronize from './body/Synchronize';
//build func
import buildBody from './body/_index';
import buildEditor from './editor/_index';

//scss
import 'primereact/resources/themes/lara-light-indigo/theme.css'; // theme
import 'primereact/resources/primereact.css'; // core css
import 'primeicons/primeicons.css'; // icons
import 'primeflex/primeflex.css';
import './Table.scss';

function Table({
  config,
  name,
  data,
  idRow = null,
  height = null,
  autoHeight = true,
  synchronize,
  synchronizeValueStructure,
  selectedProduct: _selectedProduct,
  middlewareSetColumns = null,
  PATH,
  pages,
}) {
  const COUNT_ROWS_FOR_PAGINATIONS = 25;
  const tableRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [scrollHeight, setScrollHeight] = useState();
  const [params, setSearchParams] = useSearchParams();
  const [products, setProducts] = useState(data);
  const [columns, setColumns] = useState([]);
  const searchKey = (idRow || name || '') + 'search';
  const [searchValue, setSearchValue] = useState(params.get(searchKey));

  useEffect(() => {
    if (searchValue) {
      params.set(searchKey, searchValue);
    } else {
      params.delete(searchKey);
    }
    setSearchParams(params);
  }, [searchValue]);

  useEffect(() => {
    if (_selectedProduct) {
      setSearchValue(_selectedProduct.id);
    }
  }, [_selectedProduct]);

  const [selectPage, setSelectPage] = useState();
  const [selectedProduct, setSelectedProduct] =
    useUpdatedState(_selectedProduct);

  useEffect(() => {
    if (!pages) {
      setSelectPage(null);
      setProducts(data);
      return;
    }
    if (location.pathname === PATH) {
      if (searchValue) {
        setSelectPage(null);
        setProducts(data);
      } else {
        return navigate(PATH + '/' + pages[0].url, {replace: true});
      }
    } else {
      const currentPageStatus = location.pathname.split('/').at(-1);
      const page = pages.find(i => i.url === currentPageStatus) || pages[0];
      setSelectPage(page);
      setProducts([...data.filter(i => i.status === page.status)]);
    }
  }, [location, data]);

  useEffect(() => {
    const localColumns = [];
    for (const field in config.fields) {
      const col = config.fields[field];
      localColumns.push({
        field: field,
        header: col.header,
        sortable: true,
        body: buildBody({
          config,
          field,
          synchronizeValueStructure,
          selectedProduct,
          middlewareSetColumns,
        }),
        editor: buildEditor({config, field}),
      });
    }
    if (middlewareSetColumns) {
      setColumns(middlewareSetColumns(localColumns, {config, products}));
    } else {
      setColumns(localColumns);
    }
  }, [products]);

  async function onRowEditComplete({data, newData}) {
    products[products.findIndex(i => i.id === newData.id)] = newData;
    setProducts(products);

    for (const key in newData) {
      if (key.includes('.')) {
        const value = newData[key];
        delete newData[key];
        setVFF(newData, key, value);
      }
    }
    const fieldMustToUpdated = [];
    for (const field in config.fields) {
      const newValue = getVFF(newData, field);
      if (typeof newValue !== 'object' && getVFF(data, field) !== newValue) {
        if (newValue === '') {
          fieldMustToUpdated.push({field: field, value: null});
        } else {
          fieldMustToUpdated.push({field: field, value: newValue});
        }
      }
    }
    await config.onSave({
      idRow,
      prev: data,
      next: newData,
      nameTable: name,
      fieldMustToUpdated,
    });
    config.onLoad();
  }

  function onResize() {
    if (!tableRef.current || !autoHeight) return;
    const el = tableRef.current.getTable().querySelector('.p-datatable-thead');
    if (!el) return;
    const newScrollHeight =
      window.innerHeight - el.getBoundingClientRect().bottom - 50;
    const difference = scrollHeight - newScrollHeight;
    if (!scrollHeight || difference > 10 || difference < -10) {
      setScrollHeight(newScrollHeight);
    }
  }

  useEffect(() => {
    if (!autoHeight) return;
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  });

  if (!products) return <Loader />;

  return (
    <div className='table_container'>
      {pages ? (
        <div className='card flex justify-content-center m-3'>
          <SelectButton
            value={selectPage?.title}
            options={pages.map(i => i.title)}
            onChange={e => {
              if (!e.value) return;
              navigate(PATH + '/' + pages.find(i => i.title === e.value).url);
            }}
          />
        </div>
      ) : null}
      <div className='Table'>
        <DataTable
          ref={tableRef}
          scrollable
          scrollHeight={(() => {
            if (autoHeight && scrollHeight) {
              return scrollHeight + 'px';
            } else if (height) {
              return height;
            } else {
              return '100vh';
            }
          })()}
          showGridlines
          resizableColumns
          size='small'
          editMode='row'
          emptyMessage='Немає записів!'
          selectionMode='single'
          dataKey='id'
          className={
            products && products.length === 1 ? 'type_table_vertical' : ''
          }
          value={products}
          filters={{
            global: {value: searchValue, matchMode: FilterMatchMode.CONTAINS},
          }}
          selection={selectedProduct}
          onSelectionChange={e => {
            setSelectedProduct(e.value);
            e.originalEvent.stopPropagation();
          }}
          globalFilterFields={Object.keys(config.fields)}
          onRowEditComplete={onRowEditComplete}
          paginator={products && products.length > COUNT_ROWS_FOR_PAGINATIONS}
          rows={COUNT_ROWS_FOR_PAGINATIONS}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 40, 50, 100, 200]}
          header={(() => {
            if (
              (!products || products.length === 0) &&
              !config.onCreate &&
              !config.onSynchronize
            ) {
              return <></>;
            }
            return (
              <div className='flex flex-row justify-content-between gap-3'>
                <div className='flex flex-wrap gap-2'>
                  {config.onCreate ? (
                    <CreateProductsDialog
                      idRow={idRow || null}
                      config={config}
                    />
                  ) : null}
                  {config.onEdit ? (
                    <Button
                      label='Редагувати'
                      icon='pi pi-file-edit'
                      severity='warning'
                      disabled={!selectedProduct}
                      onClick={() => {
                        config.onEdit(selectedProduct);
                      }}
                    />
                  ) : null}
                  {config.onDelete && products && products.length > 0 ? (
                    <DeleteProductsDialog
                      idRow={idRow}
                      config={config}
                      selectedProduct={selectedProduct}
                    />
                  ) : null}
                  {config.onAutoSortIndex ? (
                    <ConfirmAutoSortIndexDialog
                      idRow={idRow || null}
                      config={config}
                    />
                  ) : null}
                  {config.onSynchronize ? (
                    <SyncWithFeature config={config} idRow={idRow} />
                  ) : null}
                </div>
                {products && products.length > 0 ? (
                  <div className='search_bloc flex-wrap gap-2 justify-content-between align-items-center'>
                    <span className='p-input-icon-left p-input-icon-right'>
                      <i className='pi pi-search' />
                      <InputText
                        style={{width: '300px'}}
                        value={searchValue || ''}
                        onChange={e => {
                          setSearchValue(
                            e.target.value
                              ? e.target.value.trim()
                              : e.target.value
                          );
                        }}
                        placeholder='Keyword Search'
                      />
                      <i
                        className='pi pi-trash'
                        onClick={() => setSearchValue('')}
                      />
                    </span>
                  </div>
                ) : null}
              </div>
            );
          })()}>
          {config.onEdit || config.onDelete ? (
            <Column selectionMode='single' exportable={false} />
          ) : null}

          {config.onSave ? (
            <Column
              rowEditor
              headerStyle={{width: '8%', minWidth: '1rem'}}
              bodyStyle={{textAlign: 'center'}}
            />
          ) : null}

          {!!synchronize ? (
            <Column
              headerStyle={{width: '8%', minWidth: '1rem'}}
              bodyStyle={{textAlign: 'center'}}
              body={Synchronize(synchronize, setSelectedProduct)}
            />
          ) : null}
          {synchronizeValueStructure && config.synchronize === true ? (
            <Column
              headerStyle={{width: '8%', minWidth: '1rem'}}
              bodyStyle={{textAlign: 'center'}}
              body={Synchronize(synchronizeValueStructure, setSelectedProduct)}
            />
          ) : null}

          {columns.map(col => (
            <Column key={col.field} {...col} />
          ))}
        </DataTable>
      </div>
    </div>
  );
}
export default Table;

import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {formatPrice} from '@utils';
import VAT from '@assets/icons/VAT.png';

import './StartPrice.scss';


function StartPrice() {
  const [t] = useTranslation('pages/lot');
  const lot = useSelector(state => state.lot);

  return (
    <div className='StartPrice'>
      <div className='title_tax'>
        <div className='title'>{t('start_price')}</div>
      </div>

      <div className='value'>
        <div className='price'>{formatPrice(lot.prices.ownerPrice)}</div>
        {lot.prices.priceType === 2 ? (
          <div className='vat'>
            <img src={VAT} title={t('VAT')} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default StartPrice;

import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

import {Loader} from '@ui';
import {Logger} from '@helpers'; //eslint-disable-line
//Do not delete
import i18n from '@helpers/i18n'; //eslint-disable-line
// Do not delete

import {store} from './store/store';
import App from './App';
import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Suspense fallback={<Loader />}>
          <App />
        </Suspense>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const USER_LOGOUT = 'USER_LOGOUT';

export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';

export const REQUEST_CHANGE_EMAIL = 'REQUEST_CHANGE_EMAIL';
export const REQUEST_CHANGE_EMAIL_SUCCESS = 'REQUEST_CHANGE_EMAIL_SUCCESS';
export const REQUEST_CHANGE_EMAIL_FAILURE = 'REQUEST_CHANGE_EMAIL_FAILURE';

export const SET_NEW_EMAIL = 'SET_NEW_EMAIL';
export const SET_NEW_EMAIL_SUCCESS = 'SET_NEW_EMAIL_SUCCESS';
export const SET_NEW_EMAIL_FAILURE = 'SET_NEW_EMAIL_FAILURE';

export const SEND_CODE_TO_PHONE = 'SEND_CODE_TO_PHONE';
export const SEND_CODE_TO_PHONE_SUCCESS = 'SEND_CODE_TO_PHONE_SUCCESS';
export const SEND_CODE_TO_PHONE_FAILURE = 'SEND_CODE_TO_PHONE_FAILURE';

export const SET_NEW_PHONE = 'SET_NEW_PHONE';
export const SET_NEW_PHONE_SUCCESS = 'SET_NEW_PHONE_SUCCESS';
export const SET_NEW_PHONE_FAILURE = 'SET_NEW_PHONEE_FAILURE';

export const PASSWORD_CHANGE = 'PASSWORD_CHANGE';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_FAILURE = 'PASSWORD_CHANGE_FAILURE';

export const GET_USER_EVENTS = 'GET_USER_EVENTS';
export const GET_USER_EVENTS_SUCCESS = 'GET_USER_EVENTS_SUCCESS';
export const GET_USER_EVENTS_FAILURE = 'GET_USER_EVENTS_FAILURE';

export const SET_EVENT_AS_READ = 'SET_EVENT_AS_READ';
export const SET_EVENT_AS_READ_SUCCESS = 'SET_EVENT_AS_READ_SUCCESS';
export const SET_EVENT_AS_READ_FAILURE = 'SET_EVENT_AS_READ_FAILURE';

export const CHECK_NEW_EVENTS = 'CHECK_NEW_EVENTS';
export const CHECK_NEW_EVENTS_SUCCESS = 'CHECK_NEW_EVENTS_SUCCESS';
export const CHECK_NEW_EVENTS_FAILURE = 'CHECK_NEW_EVENTS_FAILURE';

export const MARK_ALL_EVENTS_AS_READ = 'MARK_ALL_EVENTS_AS_READ';

export const CLEAN_EVENTS = 'CLEAN_EVENTS';

export const GENERATE_TARIFF_INVOICE = 'GENERATE_TARIFF_INVOICE';
export const GENERATE_TARIFF_INVOICE_SUCCESS = 'GENERATE_TARIFF_INVOICE_SUCCESS';
export const GENERATE_TARIFF_INVOICE_FAILURE = 'GENERATE_TARIFF_INVOICE_FAILURE';

export const INIT_ORDER = 'INIT_ORDER';
export const INIT_ORDER_SUCCESS = 'INIT_ORDER_SUCCESS';
export const INIT_ORDER_FAILURE = 'INIT_ORDER_FAILURE';

export const ORDER_CHECK = 'ORDER_CHECK';
export const ORDER_CHECK_SUCCESS = 'ORDER_CHECK_SUCCESS';
export const ORDER_CHECK_FAILURE = 'ORDER_CHECK_FAILURE';

export const GET_USER_PAYMENTS = 'GET_USER_PAYMENTS';
export const GET_USER_PAYMENTS_SUCCESS = 'GET_USER_PAYMENTS_SUCCESS';
export const GET_USER_PAYMENTS_FAILURE = 'GET_USER_PAYMENTS_FAILURE';

export const SEND_VERIFICATION_DATA = 'SEND_VERIFICATION_DATA';
export const SEND_VERIFICATION_DATA_SUCCESS = 'SEND_VERIFICATION_DATA_SUCCESS';
export const SEND_VERIFICATION_DATA_FAILURE = 'SEND_VERIFICATION_DATA_FAILURE';

export const SIGN_SALE_CONTRACT = 'SIGN_SALE_CONTRACT';
export const SIGN_SALE_CONTRACT_SUCCESS = 'SIGN_SALE_CONTRACT_SUCCESS';
export const SIGN_SALE_CONTRACT_FAILURE = 'SIGN_SALE_CONTRACT_FAILURE';

export const LOAD_DOCUMENTS = 'LOAD_DOCUMENTS';
export const LOAD_DOCUMENTS_SUCCESS = 'LOAD_DOCUMENTS_SUCCESS';
export const LOAD_DOCUMENTS_FAILURE = 'LOAD_DOCUMENTS_FAILURE';

export const ADD_DOCUMENTS = 'ADD_DOCUMENTS';
export const ADD_DOCUMENTS_SUCCESS = 'ADD_DOCUMENTS_SUCCESS';
export const ADD_DOCUMENTS_FAILURE = 'ADD_DOCUMENTS_FAILURE';

export const DOWNLOAD_DOCUMENTS = 'DOWNLOAD_DOCUMENTS';
export const DOWNLOAD_DOCUMENTS_SUCCESS = 'DOWNLOAD_DOCUMENTS_SUCCESS';
export const DOWNLOAD_DOCUMENTS_FAILURE = 'DOWNLOAD_DOCUMENTS_FAILURE';

export const DELETE_DOCUMENTS = 'DELETE_DOCUMENTS';
export const DELETE_DOCUMENTS_SUCCESS = 'DELETE_DOCUMENTS_SUCCESS';
export const DELETE_DOCUMENTS_FAILURE = 'DELETE_DOCUMENTS_FAILURE';

export const APPROVE_DOCUMENT = 'APPROVE_DOCUMENT';
export const APPROVE_DOCUMENT_SUCCESS = 'APPROVE_DOCUMENT_SUCCESS';
export const APPROVE_DOCUMENT_FAILURE = 'APPROVE_DOCUMENT_FAILURE';

export const DECLINED_DOCUMENT = 'DECLINED_DOCUMENT';
export const DECLINED_DOCUMENT_SUCCESS = 'DECLINED_DOCUMENT_SUCCESS';
export const DECLINED_DOCUMENT_FAILURE = 'DECLINED_DOCUMENT_FAILURE';

export const DOWNLOAD_SALE_CONTRACT = 'DOWNLOAD_SALE_CONTRACT';
export const DOWNLOAD_SALE_CONTRACT_SUCCESS = 'DOWNLOAD_SALE_CONTRACT_SUCCESS';
export const DOWNLOAD_SALE_CONTRACT_FAILURE = 'DOWNLOAD_SALE_CONTRACT_FAILURE';

export const DOWNLOAD_ORDER_DOC = 'DOWNLOAD_ORDER_DOC';
export const DOWNLOAD_ORDER_DOC_SUCCESS = 'DOWNLOAD_ORDER_DOC';
export const DOWNLOAD_ORDER_DOC_FAILURE = 'DOWNLOAD_ORDER_DOC';

export const SEND_COMPLAINTS = 'SEND_COMPLAINTS';
export const SEND_COMPLAINTS_SUCCESS = 'SEND_COMPLAINTS';
export const SEND_COMPLAINTS_FAILURE = 'SEND_COMPLAINTS';

export const BID_ALLOWED_HELP_REQUEST = 'BID_ALLOWED_HELP_REQUEST';
export const BID_ALLOWED_HELP_REQUEST_SUCCESS = 'BID_ALLOWED_HELP_REQUEST_SUCCESS';
export const BID_ALLOWED_HELP_REQUEST_FAILURE = 'BID_ALLOWED_HELP_REQUEST_FAILURE';

export const FEEDBACK_REQUEST = 'FEEDBACK_REQUEST';
export const FEEDBACK_REQUEST_SUCCESS = 'FEEDBACK_REQUEST_SUCCESS';
export const FEEDBACK_REQUEST_FAILURE = 'FEEDBACK_REQUEST_FAILURE';

export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const SET_USER_LANGUAGE_SUCCESS = 'SET_USER_LANGUAGE_SUCCESS';
export const SET_USER_LANGUAGE_FAILURE = 'SET_USER_LANGUAGE_FAILURE';

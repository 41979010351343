import {Button} from 'primereact/button';

function Synchronize(synchronize, setSelectedProduct) {
  return rowData => {
    if (rowData.syncWith !== undefined) {
      // Its Own features
      return (
        <Button link severity='info' className='p-0'>
          <a
            href={`/org/admin/features/synchronize/${rowData.id}?search=${
              rowData.syncWith || ''
            }`}
            target='_blank'
            rel='noopener noreferrer'
            className='p-button font-bold'>
            Синхронізувати
          </a>
        </Button>
      );
    }

    return (
      <Button
        label='Синхронізувати'
        severity='info'
        onClick={() => {
          synchronize(rowData);
          setSelectedProduct(rowData);
        }}
      />
    );
  };
}
export default Synchronize;

import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Filters} from '@components';
import {
  LOT_STATUS_READY,
  LOT_STATUS_PREPARATION,
  LOT_STATUS_ACTIVE,
  LOT_STATUS_FINISHED_SOLD,
  LOT_STATUS_FINISHED_NOT_SOLD,
  LOT_STATUS_FINISHED_RESERVED,
} from '@models';

function AuctionFilters() {
  const [t] = useTranslation('pages/auctions');
  const [filtersConfig, setFiltersConfig] = useState([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const config = {
      id: 'lot_statuses',
      type: 'select',
      title: t('lot_statuses'),
      data: [
        {
          checked: false,
          id: LOT_STATUS_READY,
          name: t('wait'),
        },
        {
          checked: false,
          id: LOT_STATUS_PREPARATION,
          name: t('preparation'),
        },
        {
          checked: false,
          id: LOT_STATUS_ACTIVE,
          name: t('active_lot'),
        },
        {
          checked: false,
          id: LOT_STATUS_FINISHED_SOLD,
          name: t('sold'),
        },
        {
          checked: false,
          id: LOT_STATUS_FINISHED_NOT_SOLD,
          name: t('no_sold'),
        },
        {
          checked: false,
          id: LOT_STATUS_FINISHED_RESERVED,
          name: t('reserved'),
        },
      ],
    };

    const params = searchParams.get(config.id);
    if (params) {
      params
        .split(',')
        .map(i => +i)
        .forEach(_id => {
          const res = config.data.find(i => i.id === _id);
          res.checked = true;
        });
    }

    setFiltersConfig([config]);
  }, [t]);

  return (
    <div
      className='AuctionFilters'
      style={{marginTop: '20px', marginBottom: '20px'}}>
      {filtersConfig && (
        <Filters
          filtersConfig={filtersConfig}
          setFiltersConfig={setFiltersConfig}
        />
      )}
    </div>
  );
}
export default AuctionFilters;

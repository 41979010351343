import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Dialog} from 'primereact/dialog';

import {ShadowContainer} from '@containers';
import {timeToString} from '@utils';
import {getUsers} from '@actions/admin';
import {Button as CustomButton, Loader, SelectButton} from '@ui';
import {useDispatchAsync} from '@hooks';

import {
  approveDocument,
  declinedDocument,
  downloadDocument,
} from '@actions/user';

import {
  ACCOUNT_DOCUMENT_REASON_DECLINED_BAD_QUALITY,
  ACCOUNT_DOCUMENT_REASON_DECLINED_FULL_DATA_DO_NOT_MATCH,
  ACCOUNT_DOCUMENT_REASON_DECLINED_HAS_EXPIRED,
  ACCOUNT_DOCUMENT_REASON_DECLINED_INAPPROPRIATE_DOCUMENT,
  ACCOUNT_DOCUMENT_REASON_DECLINED_NOT_FULLY_SCANNED,
  ACCOUNT_DOCUMENT_REASON_DECLINED_PART_DATA_DO_NOT_MATCH,
  ACCOUNT_DOCUMENT_STATUS_APPROVED,
  ACCOUNT_DOCUMENT_STATUS_DECLINED,
  ACCOUNT_DOCUMENT_STATUS_NEW,
  ACCOUNT_TYPE_COMPANY_EU,
  ACCOUNT_TYPE_COMPANY_UA,
  ACCOUNT_TYPE_PERSONAL,
} from '@models';

import useUpdateData from '../../../hooks/update-data.hook';
import TagField from '../../../2-components/table/body/TagField';
import {getVFF} from '../../../modules/getValueFomField';
import {config} from '../moderation/config';

import './ModerationDocuments.scss';

function ModerationDocuments() {
  const api = useUpdateData();
  const dispatch = useDispatchAsync();
  const users = useSelector(state => state.admin.users);
  const options = ['Документи на перевірку', 'Всі документи'];
  const [page, setPage] = useState(options[0]);
  const [searchValue, setSearchValue] = useState('');
  const [infoForDialog, setInfoForDeclinedDialog] = useState(null);
  const [reason, setReason] = useState(null);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  async function clickDownloadDocument(userId, id, name) {
    const blob = await dispatch(downloadDocument(userId, id));
    if (blob.ok !== undefined) return;
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = name;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  function Tag(user, type) {
    return TagField(
      {editorData: config.fields.accountData.fields[type].editorData},
      type
    )({[type]: user[type]});
  }

  async function onApprove(userId, docId) {
    await api(approveDocument(userId, docId));
    dispatch(getUsers());
  }

  async function onDeclined(userId, docId) {
    if (!reason) return;
    await api(declinedDocument(userId, docId, reason));
    dispatch(getUsers());
  }

  const REASON_DECLINED = [
    {
      id: ACCOUNT_DOCUMENT_REASON_DECLINED_INAPPROPRIATE_DOCUMENT,
      title: 'Невідповідний документ',
    },
    {
      id: ACCOUNT_DOCUMENT_REASON_DECLINED_BAD_QUALITY,
      title: 'Документ в поганій якості',
    },
    {
      id: ACCOUNT_DOCUMENT_REASON_DECLINED_NOT_FULLY_SCANNED,
      title: 'Документ відскановано не повноцінно',
    },
    {
      id: ACCOUNT_DOCUMENT_REASON_DECLINED_PART_DATA_DO_NOT_MATCH,
      title: 'Деякі дані з форми не відповідають даним в документі',
    },
    {
      id: ACCOUNT_DOCUMENT_REASON_DECLINED_FULL_DATA_DO_NOT_MATCH,
      title: 'Документ не відповідає переданим даним у формі',
    },
    {
      id: ACCOUNT_DOCUMENT_REASON_DECLINED_HAS_EXPIRED,
      title: 'В документа завершився термін дії',
    },
  ];
  if (!users) return <Loader />;
  return (
    <div className='ModerationDocuments'>
      <Dialog
        className='reason_declined_dialog'
        header='Виберіть причину відхилення документа ...'
        visible={infoForDialog !== null}
        onHide={() => setInfoForDeclinedDialog(null)}
        style={{width: '50vw'}}>
        <div className='dialog'>
          <div className='reasons'>
            {REASON_DECLINED.map(i => (
              <div
                key={i.id}
                onClick={() => setReason(i.id)}
                className={`reason ${reason === i.id ? 'active' : ''}`}>
                <p>{i.title}</p>
              </div>
            ))}
          </div>
          <CustomButton
            onClick={async () => {
              await onDeclined(infoForDialog.userId, infoForDialog.docId);
              setInfoForDeclinedDialog(null);
              setReason(null);
            }}>
            Підтвердити
          </CustomButton>
        </div>
      </Dialog>

      <div className='card flex justify-content-center header'>
        <SelectButton
          value={page}
          onChange={e => e.value !== null && setPage(e.value)}
          options={options}
        />
        <div className='search-input p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            placeholder='Пошук по користувачах'
            onInput={({target}) => setSearchValue(target.value.toLowerCase())}
          />
        </div>
      </div>

      {users
        .filter(user => {
          return [
            'id',
            'email',
            'phone',
            'nickname',
            'addressInfo.country',
            'addressInfo.address',
            'addressInfo.ZIPCode',
            'userInfo.last_name',
            'userInfo.first_name',
            'userInfo.identification_number',
            'userInfo.passport_no',
            'companyInfoUA.companyName',
            'companyInfoUA.IPNCompany',
            'companyInfoUA.CodeEDRPOYCompany',
            'companyInfoEU.companyName',
            'companyInfoEU.NIPCompany',
            'companyInfoEU.VATCompany',
            '',
          ].find(field => {
            const value = getVFF(user, field);
            if (value) {
              return value.toString().toLowerCase().includes(searchValue);
            } else {
              return false;
            }
          });
        })
        .filter(i => !!i.documents?.length)
        .filter(i =>
          i.documents.find(
            i => !i.deleted && (page === options[0] ? i.status === 0 : true)
          )
        )
        .map(user => (
          <ShadowContainer key={user.id}>
            <ShadowContainer className='infoBloc'>
              <table>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Телефон</th>
                    <th>Статус аккаунта</th>
                    <th>Тип аккаунта</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      <Link
                        to={'/org/admin/moderation?search=' + user.id}
                        style={{
                          textDecoration: 'underline',
                        }}
                        target={'_blank'}>
                        {user.email}
                      </Link>
                    </th>
                    <td>{user.phone}</td>
                    <td>{Tag(user, 'status')}</td>
                    <td>{Tag(user, 'type')}</td>
                  </tr>
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th>Країна</th>
                    <th>Адреса</th>
                    <th>ZIP Code</th>
                    {user.type === ACCOUNT_TYPE_PERSONAL &&
                    user.userInfo?.last_name ? (
                        <>
                          <th>Прізвище</th>
                          <th>Імʼя</th>
                          <th>Ідентифікаціний номер</th>
                          <th>Номер паспорта</th>
                        </>
                      ) : null}
                    {user.type === ACCOUNT_TYPE_COMPANY_UA ? (
                      <>
                        <th>Назва компанії</th>
                        <th>IPN</th>
                        <th>Code EDRPOY</th>
                      </>
                    ) : null}
                    {user.type === ACCOUNT_TYPE_COMPANY_EU ? (
                      <>
                        <th>Назва компанії</th>
                        <th>NIP</th>
                        <th>VAT</th>
                      </>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{user.addressInfo.country}</td>
                    <td>{user.addressInfo.address}</td>
                    <td>{user.addressInfo.ZIPCode}</td>
                    {user.type === ACCOUNT_TYPE_PERSONAL &&
                    user.userInfo?.last_name ? (
                        <>
                          <td>{user.userInfo.last_name}</td>
                          <td>{user.userInfo.first_name}</td>
                          <td>{user.userInfo.identification_number}</td>
                          <td>{user.userInfo.passport_no}</td>
                        </>
                      ) : null}
                    {user.type === ACCOUNT_TYPE_COMPANY_UA ? (
                      <>
                        <td>{user.companyInfoUA.companyName}</td>
                        <td>{user.companyInfoUA.IPNCompany}</td>
                        <td>{user.companyInfoUA.CodeEDRPOYCompany}</td>
                      </>
                    ) : null}
                    {user.type === ACCOUNT_TYPE_COMPANY_EU ? (
                      <>
                        <td>{user.companyInfoEU.companyName}</td>
                        <td>{user.companyInfoEU.NIPCompany}</td>
                        <td>{user.companyInfoEU.VATCompany}</td>
                      </>
                    ) : null}
                  </tr>
                </tbody>
              </table>
            </ShadowContainer>
            <table key={user.id}>
              <thead>
                <tr>
                  <th>Назва документа</th>
                  <th>Дата завантаження</th>
                  <th>Статус</th>
                  <th>Завантажити</th>
                  <th>Змінити статус</th>
                </tr>
              </thead>
              <tbody>
                {user.documents
                  .filter(
                    i =>
                      !i.deleted &&
                      (page === options[0] ? i.status === 0 : true)
                  )
                  .map(item => (
                    <tr key={item.id}>
                      <td>{item.title}</td>
                      <td>{timeToString(item.createdAt)}</td>
                      <td className='docStatus'>
                        {TagField(
                          {
                            editorData: [
                              {
                                indexInDB: ACCOUNT_DOCUMENT_STATUS_NEW,
                                title: 'Новий',
                                severity: 'info',
                              },
                              {
                                indexInDB: ACCOUNT_DOCUMENT_STATUS_APPROVED,
                                title: 'Схвалений',
                                severity: 'success',
                              },
                              {
                                indexInDB: ACCOUNT_DOCUMENT_STATUS_DECLINED,
                                title:
                                  REASON_DECLINED.find(
                                    i => i.id === item.reason_declined
                                  )?.title || 'Відхилено',
                                severity: 'danger',
                              },
                            ],
                          },
                          'status'
                        )({status: item.status})}
                      </td>
                      <td>
                        <a
                          onClick={() =>
                            clickDownloadDocument(user.id, item.id, item.title)
                          }>
                          <span className='icon-for-downloading'></span>
                        </a>
                      </td>
                      <td>
                        <div className='card  flex-wrap flex justify-content-center gap-2'>
                          <Button
                            label='Схвалити'
                            severity='success'
                            outlined
                            onClick={() =>
                              item.status !== 1 && onApprove(user.id, item.id)
                            }
                          />
                          <Button
                            label='Відхилити'
                            severity='danger'
                            outlined
                            onClick={() =>
                              item.status !== 2 &&
                              setInfoForDeclinedDialog({
                                userId: user.id,
                                docId: item.id,
                              })
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </ShadowContainer>
        ))}
    </div>
  );
}
export default ModerationDocuments;

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import GeneratePage from '../../generate-page/GeneratePage';

function ConsiderationСlaims() {
  const user = useSelector(state => state.user);

  const [t, i18n] = useTranslation('pages/introduction/consideration-claims');

  const tree = {
    1: [1, 2, 3, 4, 5, 6],
    2: [1, 2, 3],
    3: [1, 2],
    4: [{1: [1, 2]}, 2, 3],
    5: [1, 2],
    6: [1, 2],
    7: [1],
  };

  const inserts = {
    link_underlot: (
      <a
        className='link_underlot'
        href='https://www.underlot.com/'
        target='_blank'
        rel='noreferrer'>
        www.underlot.com
      </a>
    ),
    'support@underlot': (
      <a
        className='support_underlot'
        href='mailto:support@underlot.com'
        target='_blank' rel='noreferrer'>
        support@underlot.com
      </a>
    ),
    bold: '<span className="bold">',
    '/bold': '</span>',
    remark: '<div className="remark">',
    '/remark': '</div>',
    'icon-for-downloading': (
      <a
        href={`./locales/${
          i18n.currentLang ||
          localStorage.getItem('i18nextLng') ||
          user?.lang ||
          'en'
        }/static-files/Claim-form.docx`}
        download='Claim Form.docx'>
        <span className='icon-for-downloading'></span>
      </a>
    ),
  };

  return (
    <GeneratePage
      Name='ConsiderationСlaims'
      tree={tree}
      inserts={inserts}
      t={t}
    />
  );
}
export default ConsiderationСlaims;

import {
  LOT_FEATURE_VALUE_TYPE_NUMBER,
  LOT_FEATURE_VALUE_TYPE_STRING,
  LOT_FEATURE_VALUE_TYPE_BOOLEAN,
  LOT_FEATURE_VALUE_TYPE_DATE,
  LOT_FEATURE_VALUE_TYPE_ENUM,
  LOT_FEATURE_VALUE_TYPE_SET,
  FILTER_OPTION_MANUFACTURER,
  FILTER_OPTION_YEAR,
  FILTER_OPTION_FUEL,
  FILTER_OPTION_TRANSMISSION,
  FILTER_OPTION_DAMAGE,
  FILTER_OPTION_MILES,
  FILTER_OPTION_POWER_KW,
  FILTER_OPTION_POWER_HP,
  FILTER_OPTION_ENGINE_SIZE,
  FILTER_OPTION_COLOR,
  FILTER_OPTION_DOORS,
} from '@helpers/models';

export const config = {
  header: 'Filters',
  type: 'table',
  fields: {
    id: {
      header: 'Id',
      type: 'number',
    },
    filterOption: {
      required: true,
      type: 'list',
      header: 'Фільтер опція',
      modifier: 'public',
      editorData: [
        {
          indexInDB: FILTER_OPTION_MANUFACTURER,
          title: 'Марка',
          severity: 'info',
        },
        {indexInDB: FILTER_OPTION_YEAR, title: 'Рік', severity: 'info'},
        {indexInDB: FILTER_OPTION_FUEL, title: 'Паливо', severity: 'info'},
        {
          indexInDB: FILTER_OPTION_TRANSMISSION,
          title: 'Трансмісія',
          severity: 'info',
        },
        {
          indexInDB: FILTER_OPTION_DAMAGE,
          title: 'Пошкодження',
          severity: 'info',
        },
        {indexInDB: FILTER_OPTION_MILES, title: 'Пробіг', severity: 'info'},
        {
          indexInDB: FILTER_OPTION_POWER_KW,
          title: 'Потужність KW',
          severity: 'info',
        },
        {
          indexInDB: FILTER_OPTION_POWER_HP,
          title: 'Кінські сили HP',
          severity: 'info',
        },
        {
          indexInDB: FILTER_OPTION_ENGINE_SIZE,
          title: 'Обʼєм двигуна',
          severity: 'info',
        },
        {indexInDB: FILTER_OPTION_COLOR, title: 'Колір', severity: 'info'},
        {indexInDB: FILTER_OPTION_DOORS, title: 'Двері', severity: 'info'},
      ],
    },
    valueType: {
      required: true,
      header: 'Тип значення',
      type: 'list',
      modifier: 'public',
      editorData: [
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_NUMBER,
          title: 'Число',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_STRING,
          title: 'Рядок',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_BOOLEAN,
          title: 'Булеве',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_DATE,
          title: 'Дата',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_ENUM,
          title: 'Масив',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_SET,
          title: 'Набір',
          severity: 'info',
        },
      ],
    },
    title: {
      type: 'table',
      header: 'Заголовки',
      fields: {
        en: {
          header: 'Заголовок en',
          modifier: 'public',
          type: 'text',
          required: true,
        },
        uk: {
          header: 'Заголовок uk',
          modifier: 'public',
          type: 'text',
          required: true,
        },
        ru: {
          header: 'Заголовок ru',
          modifier: 'public',
          type: 'text',
          required: true,
        },
        pl: {
          header: 'Заголовок pl',
          modifier: 'public',
          type: 'text',
          required: true,
        },
      },
    },
    valueStructure: {
      header: 'Таблиця значень',
      type: 'syncFilter',
      fields: {
        code: {
          header: 'Код',
          modifier: 'public',
          type: 'number',
          required: true,
        },
        sortIndex: {
          header: 'Сорт індекс',
          modifier: 'public',
          type: 'number',
          required: true,
        },
        'title.en': {
          header: 'Заголовок en',
          modifier: 'public',
          type: 'text',
          required: true,
        },
        'title.uk': {
          header: 'Заголовок uk',
          modifier: 'public',
          type: 'text',
          required: true,
        },
        'title.ru': {
          header: 'Заголовок ru',
          modifier: 'public',
          type: 'text',
          required: true,
        },
        'title.pl': {
          header: 'Заголовок pl',
          modifier: 'public',
          type: 'text',
          required: true,
        },
        syncWithFeatures: {
          header: 'Таблиця значень',
          type: 'table',
          fields: {
            featureId: {
              header: 'Id Характеристики',
              type: 'text',
            },
            valueType: {
              header: 'Тип значення',
              type: 'list',
              editorData: [
                {
                  indexInDB: LOT_FEATURE_VALUE_TYPE_NUMBER,
                  title: 'Число',
                  severity: 'info',
                },
                {
                  indexInDB: LOT_FEATURE_VALUE_TYPE_STRING,
                  title: 'Рядок',
                  severity: 'info',
                },
                {
                  indexInDB: LOT_FEATURE_VALUE_TYPE_BOOLEAN,
                  title: 'Булеве',
                  severity: 'info',
                },
                {
                  indexInDB: LOT_FEATURE_VALUE_TYPE_DATE,
                  title: 'Дата',
                  severity: 'info',
                },
                {
                  indexInDB: LOT_FEATURE_VALUE_TYPE_ENUM,
                  title: 'Масив',
                  severity: 'info',
                },
                {
                  indexInDB: LOT_FEATURE_VALUE_TYPE_SET,
                  title: 'Набір',
                  severity: 'info',
                },
              ],
            },
            value: {
              header: 'Value',
              modifier: 'public',
            },
          },
        },
      },
    },
  },
};

import React, {useState, useEffect} from 'react';
import {NavLink, Link, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import ShapeIcon from '@assets/icons/shape.png';
import ShapeIconBlack from '@assets/icons/shapeBlack.png';
import {ReactComponent as Bell} from '@assets/icons/bell.svg';
import {ReactComponent as BellGreen} from '@assets/icons/bell-green.svg';
import {ReactComponent as BellActive} from '@assets/icons/bell-active.svg';

import LanguageChange from './LanguageChange/LanguageChange';

import './Navbar.scss';

function Navbar() {
  const [t] = useTranslation('', {keyPrefix: 'Navbar'});
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const {nickname, userInfo, companyInfoEU, companyInfoUA} = useSelector(
    state => state.user
  );
  const isTechnicalWorks = useSelector(state => state.home.isTechnicalWorks);

  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const isHaveNewEvents = useSelector(state => state.user.isHaveNewEvents);
  const location = useLocation();
  const pathname = location.pathname;
  const [isHomePage, setIsHomePage] = useState(false);
  useEffect(() => {
    if (
      pathname === '/' ||
      pathname === '/auth' ||
      pathname === '/password-recovery' ||
      pathname === '/password-recovery-confirm' ||
      pathname.includes('/set-new-password') ||
      pathname === '/set-new-password-confirm'
    ) {
      setIsHomePage(true); //In these pages is TopHomeContainer
    } else {
      setIsHomePage(false);
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () =>
        setScrolled(window.pageYOffset > 30)
      );
    }
    return setNavbarOpen(false);
  }, [location]);

  return (
    <div className='Navbar'>
      <div
        style={isTechnicalWorks ? {top: '25px'} : null}
        className={`navbar
          ${isHomePage ? 'home_page' : 'not_home_page'}
          ${scrolled ? 'scroll_back' : ''}`}>
        <div className='container'>
          <div className='right'>
            <Link to='/'>
              <h5 className={'title_underlot'}>UnderLot</h5>
            </Link>
          </div>

          <div className='left'>
            <div className={navbarOpen ? 'mobileLinks' : 'mobileLinks closed'}>
              <div className='links'>
                {!isHomePage ? (
                  <NavLink to='/' rel='noopener noreferrer'>
                    {t('home')}
                  </NavLink>
                ) : null}

                <NavLink to='/lots' rel='noopener noreferrer'>
                  {t('cars')}
                </NavLink>

                <NavLink to='/auctions' rel='noopener noreferrer'>
                  {t('auctions')}
                </NavLink>

                {isAuthenticated ? (
                  <NavLink to='/my-cars' rel='noopener noreferrer'>
                    {t('my-cars')}
                  </NavLink>
                ) : null}

                <NavLink to='/help' rel='noopener noreferrer'>
                  {t('help')}
                </NavLink>
                <LanguageChange />
              </div>

              <div className='profile'>
                {isAuthenticated ? (
                  <span className='profileAuthenticated'>
                    <NavLink to='/profile/messages' rel='noopener noreferrer'>
                      {isHaveNewEvents ? (
                        <BellActive />
                      ) : pathname === '/profile/messages' ? (
                        <BellGreen />
                      ) : (
                        <Bell />
                      )}
                    </NavLink>
                    <NavLink to='/profile' rel='noopener noreferrer'>
                      {`${
                        companyInfoEU?.companyName ||
                        companyInfoUA?.companyName ||
                        userInfo?.first_name ||
                        nickname ||
                        t('my_profile')
                      }`}
                    </NavLink>
                  </span>
                ) : (
                  <span>
                    <NavLink
                      to='/auth'
                      rel='noopener noreferrer'
                      className={pathname.includes('auth') ? 'activeLink' : ''}>
                      {t('login')} /
                    </NavLink>
                    <NavLink
                      to='/registration'
                      rel='noopener noreferrer'
                      className={
                        pathname.includes('registration') ? 'activeLink' : ''
                      }>
                      {t('register')}
                    </NavLink>
                  </span>
                )}
              </div>
            </div>
            {isAuthenticated ? (
              <div className='user-icon'>
                <Link to='/profile'>
                  <img src={isHomePage ? ShapeIcon : ShapeIconBlack} alt='' />
                </Link>
              </div>
            ) : null}
            <div
              className={navbarOpen ? 'burger opened' : 'burger'}
              onClick={() => setNavbarOpen(!navbarOpen)}>
              <div>
                <span className={isHomePage ? '' : 'black_menu'}></span>
                <span className={isHomePage ? '' : 'black_menu'}></span>
                <span className={isHomePage ? '' : 'black_menu last'}></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isHomePage && <div className='navbar-empty' />}
    </div>
  );
}

export default Navbar;

import {useTranslation} from 'react-i18next';
import {NavBarContainer} from '@containers';
import './MyCars.scss';

function MyCars() {
  const [t] = useTranslation('pages/my-cars');

  const links = [
    {
      path: '/my-cars/tracked-cars',
      title: t('tracked_cars'),
    },
    {
      path: '/my-cars/bids',
      title: t('bids'),
    },
    {
      path: '/my-cars/won-cars',
      title: t('won_cars'),
    },
    {
      path: '/my-cars/won-with-caveat',
      title: t('won_with_caveat'),
    },
    {
      path: '/my-cars/not-won',
      title: t('no_won_cars'),
    },
  ];
  return (
    <div className='MyCars'>
      <NavBarContainer links={links} />
    </div>
  );
}
export default MyCars;

import {
  ADD_LOT_TO_FAVORITE,
  GET_COUNT_CAR,
  GET_LOTS_DATA,
  LOAD_FILTERS,
} from './types';

export const loadFilters = auctionId => ({
  event: LOAD_FILTERS,
  link: '/api/filters',
  data: {
    auctionId: auctionId,
  },
});

export const getLotsData = urlQuery => ({
  event: GET_LOTS_DATA,
  link: `/api/filters/lots?${urlQuery}`,
});

export const addLotToFavorite = lotId => ({
  event: ADD_LOT_TO_FAVORITE,
  link: '/api/user/fav',
  data: {
    id: lotId,
  },
});

export const getCountCars = searchParamsStr => ({
  event: GET_COUNT_CAR,
  link: `/api/filters/lots?cc=1&${searchParamsStr}`,
});

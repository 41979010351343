export const GET_LOTS_DATA = 'GET_LOTS_DATA';
export const GET_LOTS_DATA_SUCCESS = 'GET_LOTS_DATA_SUCCESS';
export const GET_LOTS_DATA_FAILURE = 'GET_LOTS_DATA_FAILURE';

export const ADD_LOT_TO_FAVORITE = 'ADD_LOT_TO_FAVORITE';
export const ADD_LOT_TO_FAVORITE_SUCCESS = 'ADD_LOT_TO_FAVORITE_SUCCESS';
export const ADD_LOT_TO_FAVORITE_FAILURE = 'ADD_LOT_TO_FAVORITE_FAILURE';

export const GET_COUNT_CAR = 'GET_COUNT_CAR';
export const GET_COUNT_CAR_SUCCESS = 'GET_COUNT_CAR_SUCCESS';
export const GET_COUNT_CAR_FAILURE = 'GET_COUNT_CAR_FAILURE';

export const LOAD_FILTERS = 'LOAD_FILTERS';
export const LOAD_FILTERS_SUCCESS = 'LOAD_FILTERS_SUCCESS';
export const LOAD_FILTERS_FAILURE = 'LOAD_FILTERS_FAILURE';

import {
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  GET_USER_INFO_SUCCESS,
  SET_NEW_EMAIL_SUCCESS,
  SEND_CODE_TO_PHONE_SUCCESS,
  SET_NEW_PHONE_SUCCESS,
  GET_USER_EVENTS_SUCCESS,
  MARK_ALL_EVENTS_AS_READ,
  CHECK_NEW_EVENTS_SUCCESS,
  CLEAN_EVENTS,
  LOAD_DOCUMENTS_SUCCESS,
} from './types';

const USER_TOKEN = 'USER_TOKEN';
const initialState = {
  documents: [],
  token: localStorage.getItem(USER_TOKEN),
  userId: null,
  isAuthenticated: false,
  email: null,
  favLots: null,
  bidAllowed: null,
};

const userReducer = (state = initialState, action) => {
  let new_state = {};
  switch (action.type) {
    case GET_USER_INFO_SUCCESS: {
      if (!state.token) break;
      new_state = {
        favLots: action.payload.favLots,
        ...action.payload.user,

        verificationRights: action.payload.verificationRights,
        isAuthenticated: true,
      };
      break;
    }
    //---------------------------------------
    case USER_LOGIN_SUCCESS:
      new_state = {
        isAuthenticated: true,
        documents: [],
        ...action.payload.user,
      };
      localStorage.setItem(USER_TOKEN, action.payload.user.token);
      break;
    //---------------------------------------
    case USER_LOGOUT:
      localStorage.removeItem(USER_TOKEN);
      return {isAuthenticated: false};
    //---------------------------------------
    case SET_NEW_EMAIL_SUCCESS:
      new_state = {
        email: action.payload.email,
      };
      break;
    //---------------------------------------
    case SEND_CODE_TO_PHONE_SUCCESS:
      new_state = {
        phoneIdForNewPhone: action.payload.phoneId,
      };
      break;
    //---------------------------------------
    case SET_NEW_PHONE_SUCCESS:
      new_state = {
        phone: action.payload.phone,
      };
      break;
    //---------------------------------------
    case GET_USER_EVENTS_SUCCESS:
      new_state = {
        events: state.events.concat(action.payload.events),
      };
      break;
    //---------------------------------------
    case CHECK_NEW_EVENTS_SUCCESS:
      new_state = {
        isHaveNewEvents: action.payload.isHaveNewEvents,
      };
      break;
    //---------------------------------------
    case MARK_ALL_EVENTS_AS_READ:
      new_state = {
        isHaveNewEvents: false,
      };
      break;

    case CLEAN_EVENTS:
      new_state = {
        events: [],
      };
      break;
    //---------------------------------------
    case LOAD_DOCUMENTS_SUCCESS:
      new_state = {
        documents: action.payload.documents,
      };
      break;
    default:
      //Do nothing
      break;
  }
  return {...state, ...new_state};
};

export default userReducer;

import {useSearchParams} from 'react-router-dom';
import {ItemsLimit, PaginationComponent} from '@components';
import './SettingsPanel.scss';

function SettingsPanel({auctionsAll}) {
  const [searchParams] = useSearchParams();

  const lotData = {
    currentPage: searchParams.get('page') || 1,
    totalPages: Math.ceil(
      auctionsAll.length / (searchParams.get('limit') || 10)
    ),
  };

  return (
    <div className='SettingsPanel'>
      <div>
        <ItemsLimit />
      </div>
      <PaginationComponent data={lotData} />
    </div>
  );
}
export default SettingsPanel;

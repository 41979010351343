import {useTranslation} from 'react-i18next';

import GeneratePage from '../../generate-page/GeneratePage';
import './Rules.scss';

function Rules() {
  const [t] = useTranslation('pages/introduction/rules');
  const tree = {
    1: [1, 2],
    2: [1],
    3: [1, 2, 3],
    4: [1, 2],
    5: [1, 2],
    6: [1, 2],
    7: [1, 2, 3],
    8: [1, 2],

  };

  const inserts = {
    link_underlot: (
      <a
        className='link_underlot'
        href='https://www.underlot.com/'
        target='_blank' rel='noreferrer'
      >
        Underlot.com
      </a>
    ),
    'br': (<span> <br /> <br /></span>),
  };

  return (
    <GeneratePage Name='Rules' tree={tree} inserts={inserts} t={t} />
  );
}
export default Rules;

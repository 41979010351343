import {useEffect, useRef, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import CookieConsent from 'react-cookie-consent';

import {ButtonUp, Loader, ToasterContainer} from '@ui';
import {useDispatchAsync} from '@hooks';
import {Footer, Navbar} from '@layers';
import {ScrollTopContainer} from '@containers';
import {checkNewEvents, getUserInfo} from '@actions/user';
import {getIsTechnicalWorks} from '@actions/home';

import LineTechnicalWorks from '@layers/line-technical_works/LineTechnicalWorks';

import './MainContainer.scss';

function MainContainer() {
  const dispatch = useDispatchAsync('user');
  const location = useLocation();
  const navigate = useNavigate();
  const [t] = useTranslation('', {keyPrefix: 'CookiesAccept'});
  const prevLocation = useRef(location);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const [isLoading, setLoading] = useState(false);
  const token = useSelector(state => state.user.token);
  const lastVisit = useSelector(state => state.user.lastVisit);
  const userLastEventViewDate = localStorage.getItem('userLastEventViewDate');
  const lot = useSelector(state => state.lot);

  useEffect(() => {
    if (lot && lot.name) {
      document.title = `${lot.name} - UnderLot`;
    } else {
      document.title = 'UnderLot';
    }
  }, [lot]);

  useEffect(() => {
    if (
      location.search &&
      !prevLocation.current.search &&
      location.pathname === prevLocation.current.pathname
    ) {
      navigate(location.pathname, {replace: true});
      navigate(location.pathname + location.search);
    }
    prevLocation.current = location;
  }, [location]);

  useEffect(() => {
    loadData();
  }, [location.pathname]);

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    if (isAuthenticated && localStorage.getItem('USER_TOKEN')) {
      dispatch(getUserInfo());
      dispatch(checkNewEvents(userLastEventViewDate || lastVisit));
    }
  }

  useEffect(async () => {
    await dispatch(getIsTechnicalWorks());
    if (token) {
      await setLoading(() => true);
      await dispatch(getUserInfo());
      await setLoading(() => false);
    }
  }, []);

  return (
    <ScrollTopContainer>
      <LineTechnicalWorks />
      <Navbar />
      {!isLoading ? <Outlet /> : <Loader />}
      <Footer />
      <ButtonUp />
      <CookieConsent buttonText={t('btn')}>{t('title')}</CookieConsent>
      <ToasterContainer />
    </ScrollTopContainer>
  );
}
export default MainContainer;

import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {ReactComponent as CheckMark} from '@assets/icons/checkMark.svg';
import {useDispatchAsync} from '@hooks';
import {setUserLanguage} from '@actions/user';

import './LanguageChange.scss';

function LanguageChange() {
  const dispatch = useDispatchAsync();
  const dropDownListTimer = useRef();
  const dropDownList = useRef();
  const [t, i18n] = useTranslation();
  const userId = useSelector(state => state.user.id);
  const userLang = useSelector(state => state.user.lang);
  const [activeLanguage, setActiveLanguage] = useState(
    i18n._languages.find(it => it.key === i18n.currentLang) ||
      i18n._languages[0]
  );

  const [isOpenDropDownList, setIsOpenDropDownList] = useState(false);

  async function clickDropDownList(language, isSetLang = true) {
    setActiveLanguage(language);

    if (userId) {
      await dispatch(setUserLanguage(language.key));
    }

    if (isSetLang) {
      i18n.setLanguage(language.key);
    }
    setIsOpenDropDownList(false);

    const root = document.querySelector('#root');
    root.classList.add('flash');
    setTimeout(() => {
      root.classList.remove('flash');
    }, 400);
  }

  function clickLanChange() {
    setIsOpenDropDownList(p => !p);
  }

  function mouseout() {
    dropDownListTimer.current = setTimeout(() => {
      setIsOpenDropDownList(false);
    }, 500);
  }

  function mouseover() {
    clearTimeout(dropDownListTimer.current);
  }

  useEffect(() => {
    if (
      userId &&
      userLang &&
      activeLanguage.key &&
      userLang !== activeLanguage.key
    ) {
      i18n.setLanguage(userLang);
      setActiveLanguage(i18n._languages.find(it => it.key === userLang));
    }
  }, [userLang]);

  useEffect(() => {
    const lang = i18n._languages.find(it => it.key === i18n.currentLang);
    if (lang) {
      clickDropDownList(lang, false);
    }
  }, [i18n.currentLang]);

  useEffect(() => {
    dropDownList.current.addEventListener('mouseover', mouseover);
    dropDownList.current.addEventListener('mouseout', mouseout);
    return () => {
      dropDownList.current?.removeEventListener('mouseover', mouseover);
      dropDownList.current?.removeEventListener('mouseout', mouseout);
    };
  });

  useEffect(() => {
    if (isOpenDropDownList) {
      dropDownList.current.classList.add('open');
    } else {
      dropDownList.current.classList.remove('open');
    }
  }, [isOpenDropDownList]);

  return (
    <div className='LanguageChange'>
      <div onClick={clickLanChange}>
        <a>{activeLanguage.name}</a>
        <CheckMark open={isOpenDropDownList} />
      </div>

      <article className='dropDownList' ref={dropDownList}>
        {i18n._languages
          .filter(i => activeLanguage.key !== i.key)
          .map((item, index) => (
            <section
              key={index}
              className={'option'}
              selected={index === 0 ? 'selected' : ''}
              value={item.key}
              onClick={() => clickDropDownList(item)}>
              {item.name}
            </section>
          ))}
      </article>
    </div>
  );
}
export default LanguageChange;

import {
  SEND_VERIFICATION_CODE,
  SEND_VERIFICATION_CODE_SUCCESS,
  SEND_VERIFICATION_CODE_FAILURE,
  CHECK_CODE,
  CHECK_CODE_SUCCESS,
  CHECK_CODE_FAILURE,
  SEND_ACCOUNT_DATA,
  SEND_ACCOUNT_DATA_SUCCESS,
  SEND_ACCOUNT_DATA_FAILURE,
  CLEAN_ALL_REGISTRATION_DATA,
  SAVE_DATA,
} from './types';

const initialState = {
  userId: null,
  phoneNumber: null,
  phoneId: null,
  error: null,
  code: null,
  activePage: null,
  nickname: null,
  email: null,
  password: null,
  token: null,
};

const registrationReducer = (state = initialState, action) => {
  state.error = null;
  switch (action.type) {
    case SEND_VERIFICATION_CODE:
      return {...state, phoneNumber: action.payload.phone, userId: null};
    case SEND_VERIFICATION_CODE_SUCCESS:
      return {...state, phoneId: action.payload.phoneId};
    case SEND_VERIFICATION_CODE_FAILURE:
      return {...state, error: action.payload.code};

    case CHECK_CODE:
      return {...state, ...action.payload};
    case CHECK_CODE_SUCCESS:
      return {...state, userId: action.payload.userId};
    case CHECK_CODE_FAILURE:
      return {...state, code: null, error: action.payload.code};

    case SEND_ACCOUNT_DATA:
      return {...state, ...action.payload};
    case SEND_ACCOUNT_DATA_SUCCESS:
      return {...state, token: action.payload.token};
    case SEND_ACCOUNT_DATA_FAILURE:
      return {...state, error: action.payload.code};

    case SAVE_DATA:
      return {...state, ...action.payload};
    case CLEAN_ALL_REGISTRATION_DATA:
      return {...initialState, activePage: state.activePage};

    default:
      return state;
  }
};

export default registrationReducer;

import {
  LOT_FEATURE_VALUE_TYPE_ENUM,
  LOT_FEATURE_VALUE_TYPE_SET,
} from '@helpers/models';

import {
  AGENT_LOGIN,
  AGENT_LOGIN_FAILURE,
  AGENT_LOGIN_SUCCESS,
  AGENT_LOGOUT,
  CLEAR_UNDER_DRAFT_LOT,
  GET_AGENT_RIGHTS_SUCCESS,
  GET_FILTER_SOURCE_FEATURES_IDS_SUCCESS,
  GET_UNDER_FEATURES_BY_CATEGORY_SUCCESS,
  GET_UNDER_DRAFT_LOTS_SUCCESS,
  GET_UNDER_DRAFT_LOT_BY_ID_SUCCESS,
  GET_UNDER_FEATURES_SUCCESS,
} from './types';

const AGENT_TOKEN = 'AGENT_TOKEN';

const initialState = {
  token: localStorage.getItem(AGENT_TOKEN),
  isAuthenticated: false,
  rights: null,
  users: null,
};
const agentReducer = (state = initialState, action) => {
  let new_state = {};
  switch (action.type) {
    case AGENT_LOGIN:
      new_state = {isAuthenticated: false};
      break;
    case AGENT_LOGIN_SUCCESS:
      new_state = {
        isAuthenticated: true,
        ...action.payload.user,
      };
      localStorage.setItem(AGENT_TOKEN, action.payload.user.token);
      break;
    case AGENT_LOGIN_FAILURE:
      new_state = {isAuthenticated: false};
      break;
    //---------------------------------------
    case AGENT_LOGOUT:
      localStorage.removeItem(AGENT_TOKEN);
      return {isAuthenticated: false};
    //---------------------------------------
    case GET_AGENT_RIGHTS_SUCCESS:
      new_state = {
        isAuthenticated: true,
        rights: action.payload.rights,
      };
      break;
    case GET_FILTER_SOURCE_FEATURES_IDS_SUCCESS:
      new_state = {
        filterSourceFeatureIds: action.payload.filterSourceFeatureIds,
      };
      break;
    case GET_UNDER_DRAFT_LOTS_SUCCESS:
      new_state = {
        lots: action.payload.lots,
      };
      break;
    case GET_UNDER_FEATURES_SUCCESS:
      const underFeatures = action.payload.underFeatures;
      underFeatures.forEach(feature => {
        if (
          feature.valueType === LOT_FEATURE_VALUE_TYPE_ENUM ||
          feature.valueType === LOT_FEATURE_VALUE_TYPE_SET
        ) {
          if (!feature.valueStructure) {
            feature.valueStructure = [];
          }
        } else {
          feature.valueStructure = null;
        }
      });
      new_state = {underFeatures};
      break;
    case GET_UNDER_FEATURES_BY_CATEGORY_SUCCESS:
      new_state = {
        underFeatures: action.payload.underFeatures,
        categoryFeatures: action.payload.category,
      };
      break;
    case GET_UNDER_DRAFT_LOT_BY_ID_SUCCESS:
      new_state = {
        lot: action.payload.lot,
      };
      break;
    case CLEAR_UNDER_DRAFT_LOT:
      new_state = {
        lot: null,
      };
      break;

    default:
      //Do nothing
      break;
  }
  return {...state, ...new_state};
};

export default agentReducer;

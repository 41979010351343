import {ShadowContainer} from '@containers';

import './Settings.scss';

function Settings() {
  return (
    <div className='Settings'>
      <ShadowContainer className='notificationSettings'>
        {/*  <div className='title'>{t('title')}</div>
        <div className='list'>
          <div className='item'>
            <CheckBox state={watch} setState={setWatch} />
            <div>
              <div className='header'>{t('watch.header')}</div>
              <div className='text'>{t('watch.text')}</div>
            </div>
          </div>
          <div className='item'>
            <CheckBox state={offer} setState={setOffer} />
            <div>
              <div className='header'>{t('offer.header')}</div>
              <div className='text'>{t('offer.text')}</div>
            </div>
          </div>
        </div>*/}
      </ShadowContainer>
    </div>
  );
}

export default Settings;

import {useTranslation} from 'react-i18next';

import {ReactComponent as BigCheckMark} from '@assets/icons/big-check-mark.svg';
import {TopHomeContainer} from '@containers';
import {Button} from '@ui';

import './SetNewPasswordConfirm.scss';

function SetNewPasswordConfirm() {
  const [t] = useTranslation('pages/authentication', {
    keyPrefix: 'set-new-password-confirm',
  });

  return (
    <TopHomeContainer>
      <div className='SetNewPasswordConfirm'>
        <div className='content'>
          <div className='icon'>
            <BigCheckMark />
          </div>
          <div className='title'>{t('title')}</div>
          <div className='text'>{t('text')}</div>
          <Button to='/auth' text={t('btBack')} />
        </div>
      </div>
    </TopHomeContainer>
  );
}
export default SetNewPasswordConfirm;

// VFF = ValueFomField
export function getVFF(data, field) {
  if (field.includes('.')) {
    const fields = field.split('.');
    let value = data;
    for (const _field of fields) {
      if (value === undefined) break;
      value = value[_field];
    }
    return value;
  } else {
    return data[field];
  }
}

export function setVFF(data, field, value) {
  if (field.includes('.')) {
    const fields = field.split('.');
    let _data = data;
    for (let i = 0; i < fields.length; i++) {
      const _field = fields[i];
      if (i === fields.length - 2) {
        _data[_field] = {
          ..._data[_field],
          [fields[i + 1]]: value,
        };
        break;
      } else {
        _data = _data[_field];
      }
    }
  } else {
    return (data[field] = value);
  }
}

import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {DropDown} from '@ui';
import {useDispatchAsync} from '@hooks';
import {getLotInfo} from '@actions/lot';
import {
  LOT_FEATURE_VALUE_TYPE_BOOLEAN,
  LOT_FEATURE_VALUE_TYPE_DATE,
} from '@helpers/models';
import {timeToString} from '@helpers/utils';

import {ReactComponent as Dagger} from '@assets/icons/dagger.svg';
import {ReactComponent as CheckMark} from '@assets/icons/checkMark-gray.svg';

import './DescriptionFrame.scss';

function DescriptionFrame({openGallery = () => {}}) {
  const [t] = useTranslation('pages/lot');
  const dispatch = useDispatchAsync();
  const params = useParams();
  const info = useSelector(state => state.lot.info);

  useEffect(async () => {
    await dispatch(getLotInfo(params.id));
  }, []);

  useEffect(() => {
    document.body.style.minWidth = '100%';
  });

  function render({headerData}) {
    return (
      <ul>
        {headerData.map((feature, index) => (
          <li key={index}>
            <div className='name'>{feature.name}</div>

            {!feature.docs ? null : (
              <div className='docs'>
                {feature.docs?.map((value, index) =>
                  (() => {
                    switch (feature.type) {
                      case LOT_FEATURE_VALUE_TYPE_BOOLEAN:
                        switch (value) {
                          case 'false':
                            return <Dagger key={index} />;
                          case 'true':
                            return (
                              <CheckMark key={index} className='CheckMark' />
                            );
                          default:
                            break;
                        }
                        break;
                      case LOT_FEATURE_VALUE_TYPE_DATE: {
                        if (!value || isNaN(Date.parse(value))) {
                          return <p key={index}>{value}</p>;
                        } else {
                          return (
                            <p key={index}>{timeToString(new Date(value))}</p>
                          );
                        }
                      }
                      default:
                        return <p key={index}>{value}</p>;
                    }
                  })()
                )}
              </div>
            )}

            {!feature.img ? null : (
              <div className='img' onClick={() => openGallery(feature.img.src)}>
                <img src={feature.img.src} />
              </div>
            )}
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className='DescriptionFrame'>
      <div className='info'>
        {info &&
          info?.map((item, index) =>
            !item.headerName || !item.headerData ? null : (
              <DropDown title={item.headerName} key={index}>
                {render(item)}
              </DropDown>
            )
          )}
      </div>
    </div>
  );
}
export default DescriptionFrame;

export const LOAD_DATA_FOR_HOME = 'LOAD_DATA_FOR_HOME';
export const LOAD_DATA_FOR_HOME_SUCCESS = 'LOAD_DATA_FOR_HOME_SUCCESS';
export const LOAD_DATA_FOR_HOME_FAILURE = 'LOAD_DATA_FOR_HOME_FAILURE';

export const TOGGLE_IS_TECHNICAL_WORKS = 'TOGGLE_IS_TECHNICAL_WORKS';
export const TOGGLE_IS_TECHNICAL_WORKS_SUCCESS = 'TOGGLE_IS_TECHNICAL_WORKS_SUCCESS';
export const TOGGLE_IS_TECHNICAL_WORKS_FAILURE = 'TOGGLE_IS_TECHNICAL_WORKS_FAILURE';

export const GET_IS_TECHNICAL_WORKS = 'GET_IS_TECHNICAL_WORKS';
export const GET_IS_TECHNICAL_WORKS_SUCCESS = 'GET_IS_TECHNICAL_WORKS_SUCCESS';
export const GET_IS_TECHNICAL_WORKS_FAILURE = 'GET_IS_TECHNICAL_WORKS_FAILURE';

export const TOGGLE_IS_CRONS_RUNNING = 'TOGGLE_IS_CRONS_RUNNING';
export const TOGGLE_IS_CRONS_RUNNING_SUCCESS = 'TOGGLE_IS_CRONS_RUNNING_SUCCESS';
export const TOGGLE_IS_CRONS_RUNNING_FAILURE = 'TOGGLE_IS_CRONS_RUNNING_FAILURE';

export const GET_IS_CRONS_RUNNING = 'GET_IS_CRONS_RUNNING';
export const GET_IS_CRONS_RUNNING_SUCCESS = 'GET_IS_CRONS_RUNNING_SUCCESS';
export const GET_IS_CRONS_RUNNING_FAILURE = 'GET_IS_CRONS_RUNNING_FAILURE';

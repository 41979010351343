import {getWonWithCaveatCars} from '@actions/my-cars';

import PageCarsContainer from '../container/page-cars-container/PageCarsContainer';

import './WonWithCaveat.scss';

function WonWithCaveat() {
  return (
    <div className='WonWithCaveat'>
      <PageCarsContainer
        keyToData={'wonWithCaveatCars'}
        getData={getWonWithCaveatCars}
      />
    </div>
  );
}
export default WonWithCaveat;

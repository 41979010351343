import {Button} from 'primereact/button';
import {useState} from 'react';
import {Dialog} from 'primereact/dialog';
import OwnFeatures from 'src/org/pages/admin/features/own-features/OwnFeatures';

function SyncWithFeature({config, idRow, synchronizeOnlyFeature = false}) {
  const [visible, setVisible] = useState(false);

  async function synchronize(feature, featureVs) {
    if (config.onSyncFilter) {
      await config.onSyncFilter(idRow, feature, featureVs);
    } else {
      await config.onSynchronize(idRow, feature, featureVs);
    }
    setVisible(false);
    await config.onLoad();
  }
  return (
    <>
      <Button
        label='Синхронізувати'
        icon='pi pi-chevron-circle-right'
        severity='help'
        onClick={() => {
          setVisible(true);
        }}
      />
      <Dialog
        modal
        visible={visible}
        style={{width: '80vw', height: '95vh'}}
        breakpoints={{'960px': '75vw', '641px': '90vw'}}
        onHide={() => setVisible(false)}>
        <div>
          <OwnFeatures
            height={'100%'}
            autoHeight={false}
            synchronize={synchronize}
            synchronizeValueStructure={
              synchronizeOnlyFeature === false ? synchronize : null
            }
          />
        </div>
      </Dialog>
    </>
  );
}

export default SyncWithFeature;

import Logger from './logger';

class Toast {
  constructor() {
    this.toast = null;
  }

  init(_toast) {
    if (!_toast) {
      return;
    }
    this.toast = _toast;
  }

  _show(props) {
    if (!this.toast) {
      Logger.error('Error toast is not defined');
      return;
    }
    this.toast.show(props);
  }

  info(msg, timer) {
    this._show({
      severity: 'info',
      detail: msg,
      life: timer || 3000,
    });
  }
  success(msg, timer) {
    this._show({
      severity: 'success',
      detail: msg,
      life: timer || 3000,
    });
  }
  warning(msg, timer) {
    this._show({
      severity: 'warn',
      detail: msg,
      life: timer || 3000,
    });
  }
  error(msg, timer) {
    this._show({
      severity: 'error',
      detail: msg,
      life: timer || 3000,
    });
  }
}

export default new Toast();

import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Tag} from 'primereact/tag';

import {ShadowContainer} from '@containers';
import {LotMiniGalleria} from '@components';
import {formatDataTime, formatPriceWithCent} from '@utils';
import {useDownloadOrderDocs} from '@hooks';
import {Button} from '@ui';

import {
  ORDER_DOC_TYPE_RECEIPT,
  ORDER_PAYMENT_PROVIDER_ING_PL,
  ORDER_PAYMENT_PROVIDER_PAYNOW,
  ORDER_PAYMENT_PROVIDER_MANUAL,
  ORDER_PRODUCT_TYPE_LOT,
  ORDER_PRODUCT_TYPE_TARIFF,
  ORDER_STATUS_SUCCESSFUL,
  ORDER_STATUS_SUCCESSFUL_MANUAL,
} from '@models';

import pp_paynow from '@assets/images/pp_paynow.png';
import imoje from '@assets/images/imoje.jpg';
import {ReactComponent as WireTransfer} from '@assets/icons/wire-transfer.svg';

import './Payments.scss';

function PaymentItem({payment}) {
  const {
    order_no,
    total_amount,
    currency,
    payment_provider,
    status,
    product_info,
    createdAt,
    updatedAt,
    tax_amount,
    tax_rate,
    amount,
  } = payment;
  const [t] = useTranslation('pages/profile', {
    keyPrefix: 'payments',
  });
  const [tariffs] = useTranslation('pages/profile', {
    keyPrefix: 'tariffs',
  });
  const receipt = useDownloadOrderDocs(ORDER_DOC_TYPE_RECEIPT);

  const [tariff, _] = useState(
    () =>
      tariffs('basicTariffs', {returnObjects: true})[product_info.product_id]
  );

  let tagInfo = {};

  switch (status) {
    case ORDER_STATUS_SUCCESSFUL:
    case ORDER_STATUS_SUCCESSFUL_MANUAL:
      tagInfo = {
        value: t('successfully_paid'),
        severity: 'success',
      };
      break;
    default:
      tagInfo = {
        value: t('unknown'),
        severity: 'danger',
      };
  }

  return (
    <ShadowContainer className='PaymentItem'>
      <div className='product'>
        {(() => {
          switch (product_info.product_type) {
            case ORDER_PRODUCT_TYPE_TARIFF:
              return (
                <div className='tariffInfo_container'>
                  <ShadowContainer className='tariffInfo'>
                    <p>{tariff.header}</p>
                    <p>{tariff.limit}</p>
                    <p>{tariff.active_auctions}</p>
                  </ShadowContainer>
                </div>
              );
            case ORDER_PRODUCT_TYPE_LOT: {
              if (product_info.lot) {
                return (
                  <LotMiniGalleria
                    lot={product_info.lot}
                    isShowLotStatus={false}
                  />
                );
              } else {
                return t('lot_not_found');
              }
            }
            default:
              return <></>;
          }
        })()}
      </div>
      <div className='info'>
        <h4 className='header'>
          <div>
            <div>{order_no}</div>
            {product_info.product_type === ORDER_PRODUCT_TYPE_TARIFF
              ? tariff.header
              : product_info.product_type === ORDER_PRODUCT_TYPE_LOT
                ? product_info.lot.name
                : null}
          </div>
          <div className='receipt_bt'>
            <Button
              className={'greenwhite'}
              text={t('receipt')}
              onClick={() => receipt({orderId: payment.id})}
            />
          </div>
        </h4>
        <div className='otherInfo'>
          <div className='first'>
            <div className='status'>
              {t('status')} :
              <Tag value={tagInfo.value} severity={tagInfo.severity} />
            </div>

            <div className='payment_provider'>
              <div>
                <p>{t('payment_method')} :</p>
                {payment_provider === ORDER_PAYMENT_PROVIDER_MANUAL ? (
                  <p>{t('wire_transfer')}</p>
                ) : null}
                {payment_provider === ORDER_PAYMENT_PROVIDER_ING_PL ? (
                  <p>ING Bank Śląski</p>
                ) : null}
                {payment_provider === ORDER_PAYMENT_PROVIDER_PAYNOW ? (
                  <p>Paynow</p>
                ) : null}
              </div>
              <div className='logo_payment_provider'>
                {payment_provider === ORDER_PAYMENT_PROVIDER_MANUAL ? (
                  <WireTransfer className='WireTransfer' />
                ) : null}
                {payment_provider === ORDER_PAYMENT_PROVIDER_ING_PL ? (
                  <div className='imoje'>
                    <img src={imoje} alt='imoje' />
                  </div>
                ) : null}
                {payment_provider === ORDER_PAYMENT_PROVIDER_PAYNOW ? (
                  <div className='pp_paynow'>
                    <img src={pp_paynow} alt='pp_paynow' />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className='prices'>
            <div>
              <div> {t('amount')} :</div>
              <div>
                {formatPriceWithCent(amount)} {currency}
              </div>
            </div>
            <div>
              <div> {t('tax_rate')} :</div>
              <div> {formatPriceWithCent(tax_rate)} % </div>
            </div>
            <div>
              <div>{t('tax_amount')} :</div>
              <div>
                {formatPriceWithCent(tax_amount)} {currency}
              </div>
            </div>
            <div>
              <div>{t('total_amount')} :</div>
              <div>
                {formatPriceWithCent(total_amount)} {currency}
              </div>
            </div>
          </div>
          <div className='dates'>
            <div>
              <div>{t('created')} :</div>
              <div> {formatDataTime(createdAt).reverse().join(' / ')}</div>
            </div>
            <div>
              <div>{t('updated')} :</div>
              <div>{formatDataTime(updatedAt).reverse().join(' / ')}</div>
            </div>
          </div>
        </div>
      </div>
    </ShadowContainer>
  );
}

export default PaymentItem;

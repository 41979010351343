import Tariffs from '../tariffs/Tariffs';
import './VerificationTariff.scss';

function VerificationTariff() {
  return (
    <div className='VerificationTariff'>
      <Tariffs />
    </div>
  );
}

export default VerificationTariff;

import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {useDispatchAsync} from '@hooks';
import {getLotInfo} from '@actions/lot';
import {Loader} from '@ui';
import {generateTariffInvoice, initOrder} from '@actions/user';
import {generateLotInvoice} from '@actions/admin';
import {Toast} from '@helpers';
import {Logger} from '@helpers';

import {
  ORDER_PAYMENT_PROVIDER_ING_PL,
  ORDER_PAYMENT_PROVIDER_PAYNOW,
  ORDER_PRODUCT_TYPE_LOT,
  ORDER_PRODUCT_TYPE_TARIFF,
} from '@models';

import ChoosingPaymentMethod from './choosing-payment-method/ChoosingPaymentMethod';
import InfoAboutCheckout from './info-about-checkout/InfoAboutCheckout';

function Payment() {
  const [t] = useTranslation('pages/payments');

  const dispatch = useDispatchAsync();
  const {id: userId} = useSelector(state => state.user);

  const [searchParams, setSearchParams] = useSearchParams();
  const orderType = parseFloat(searchParams.get('orderType'));
  const tariffType = parseFloat(searchParams.get('tariffType'));
  const lotId = searchParams.get('lotId');

  const [lot, setLot] = useState();
  const [isLoading, setLoading] = useState(null);

  const CHOOSING_PAYMENT_METHOD = 1;
  const INFO_ABOUT_CHECKOUT = 2;
  const [step, setStep] = useState(
    parseFloat(searchParams.get('step')) || CHOOSING_PAYMENT_METHOD
  );
  const [formParams, setFormParams] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(
    parseFloat(searchParams.get('paymentMethod'))
  );

  const [tariffs] = useTranslation('pages/profile', {
    keyPrefix: 'tariffs',
  });

  const [tariff, _] = useState(
    () => tariffs('basicTariffs', {returnObjects: true})[tariffType]
  );

  useEffect(async () => {
    if (lotId) {
      await setLoading(() => true);
      const _lot = await dispatch(getLotInfo(lotId), false);
      if (_lot.ok) {
        await setLot(_lot);
      } else {
        Toast.warning(t('error_receiving_payment_data'), 5000);
      }
      await setLoading(() => false);
    }
  }, []);

  useEffect(async () => {
    await getFormParams();
  }, []);

  async function onNext() {
    searchParams.set('step', INFO_ABOUT_CHECKOUT);
    searchParams.set('paymentMethod', paymentMethod);
    await setSearchParams(searchParams, {replace: true});
    await getFormParams();
    await setStep(INFO_ABOUT_CHECKOUT);
  }
  async function getFormParams() {
    switch (orderType) {
      case ORDER_PRODUCT_TYPE_TARIFF: {
        const res = await dispatch(
          generateTariffInvoice(userId, tariffType),
          false
        );
        if (!res.ok && res.ok !== null) {
          Toast.warning(t('error_receiving_payment_data', 5000));
          return;
        }
        break;
      }
      case ORDER_PRODUCT_TYPE_LOT: {
        const res = await dispatch(generateLotInvoice(lotId), false);
        if (!res.ok && res.ok !== null) {
          Toast.warning(t('error_receiving_payment_data', 5000));
        }
        break;
      }
      default:
        Logger.warn(`Some new ORDER_PRODUCT_TYPE_* = ${orderType}`);
        return;
    }

    if (
      paymentMethod === ORDER_PAYMENT_PROVIDER_ING_PL ||
      paymentMethod === ORDER_PAYMENT_PROVIDER_PAYNOW
    ) {
      const res = await dispatch(
        initOrder(orderType, tariffType, lotId, paymentMethod),
        false
      );
      if (res.ok) {
        setFormParams(res.params);
      } else {
        Toast.warning(t('error_receiving_payment_data', 5000));
        setFormParams(null);
      }
    }
  }
  if (isNaN(orderType)) return <></>;

  if (isLoading === true) {
    return <Loader />;
  }
  if (orderType === ORDER_PRODUCT_TYPE_LOT && isLoading === null) {
    return <Loader />;
  }
  switch (step) {
    case CHOOSING_PAYMENT_METHOD:
      return (
        <ChoosingPaymentMethod
          isLoading={isLoading}
          orderType={orderType}
          tariff={tariff}
          lot={lot}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          onNext={onNext}
        />
      );
    case INFO_ABOUT_CHECKOUT:
      return (
        <InfoAboutCheckout
          lot={lot}
          orderType={orderType}
          paymentMethod={paymentMethod}
          tariff={tariff}
          tariffType={tariffType}
          formParams={formParams}
        />
      );

    default:
      //Do nothing
      break;
  }
}

export default Payment;

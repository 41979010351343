import {Link} from 'react-router-dom';
import './Button.scss';

function Button({
  to,
  text,
  children,
  className,
  target = false,
  style,
  download,
  useRef,
  onClick,
  onClickInActive,
}) {
  function onClickMiddleware() {
    if (!to) {
      return e => {
        if (className?.includes('inactive')) {
          onClickInActive(e);
        } else if (onClick) {
          onClick(e);
        }
        e.preventDefault();
      };
    } else if (download) {
      return e => {
        if (onClick) onClick(e);
        e.stopPropagation();
      };
    } else if (target) {
      return e => {
        if (onClick) onClick(e);
        e.stopPropagation();
      };
    } else {
      return onClick;
    }
  }
  return (
    <Link
      ref={useRef}
      className={'Button ' + className}
      to={to}
      download={download}
      style={style}
      target={target ? '_blank' : ''}
      onClick={onClickMiddleware()}>
      {children}
      {text}
    </Link>
  );
}
export default Button;

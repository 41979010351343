import {
  USER_LOGIN,
  USER_LOGOUT,
  GET_USER_INFO,
  REQUEST_CHANGE_EMAIL,
  SET_NEW_EMAIL,
  SEND_CODE_TO_PHONE,
  SET_NEW_PHONE,
  PASSWORD_CHANGE,
  GET_USER_EVENTS,
  SET_EVENT_AS_READ,
  CHECK_NEW_EVENTS,
  SEND_VERIFICATION_DATA,
  SIGN_SALE_CONTRACT,
  SET_USER_LANGUAGE,
} from './types';

export const userLogin = (login, password) => ({
  event: USER_LOGIN,
  link: '/api/auth/login',
  data: {login: login, password: password},
});

export const userLogout = () => ({
  type: USER_LOGOUT,
});

export const getUserInfo = () => ({
  event: GET_USER_INFO,
  link: '/api/user/info',
});

export const setUserLanguage = language => ({
  event: SET_USER_LANGUAGE,
  link: '/api/user/set_user_language',
  data: {language},
});

export const requestChangeEmail = (email, prevEmail) => ({
  event: REQUEST_CHANGE_EMAIL,
  link: '/api/user/change_email',
  data: {
    email: email,
    prevEmail: prevEmail,
  },
});

export const setNewEmail = (accessToken, email, prevEmail) => ({
  event: SET_NEW_EMAIL,
  link: '/api/user/set_new_email',
  data: {
    token: accessToken,
    email: email,
    prevEmail: prevEmail,
  },
});

export const sentCodeToPhone = (phone, prevPhone) => ({
  event: SEND_CODE_TO_PHONE,
  link: '/api/user/sent_code_to_phone',
  data: {
    phone: phone,
    prevPhone: prevPhone,
  },
});

export const setNewPhone = (code, phone, prevPhone, phoneIdForNewPhone) => ({
  event: SET_NEW_PHONE,
  link: '/api/user/set_new_phone',
  data: {
    code: code,
    phone: phone,
    prevPhone: prevPhone,
    phoneId: phoneIdForNewPhone,
  },
});

export const passwordChange = (oldPass, newPass) => ({
  event: PASSWORD_CHANGE,
  link: '/api/user/password_change',
  data: {
    oldPass: oldPass,
    newPass: newPass,
  },
});

export const getEvents = date => ({
  event: GET_USER_EVENTS,
  link: '/api/user/events',
  data: {
    date: date,
  },
});

export const checkNewEvents = date => ({
  event: CHECK_NEW_EVENTS,
  link: '/api/user/check_new_events',
  data: {
    date: date,
  },
});

export const setEventAsRead = id => ({
  event: SET_EVENT_AS_READ,
  link: '/api/user/set_event_as_read',
  data: {
    id: id,
  },
});

export const sendVerificationData = data => ({
  event: SEND_VERIFICATION_DATA,
  link: '/api/user/verification',
  data: data,
});

export const signSaleContract = () => ({
  event: SIGN_SALE_CONTRACT,
  link: '/api/user/sign_sale_contract',
});

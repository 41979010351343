import {InputText} from 'primereact/inputtext';

function TextEditor(type = 'text') {
  if (type === 'price') type = 'number';
  return options => {
    return (
      <InputText
        type={type}
        value={options.value}
        onChange={e =>
          options.editorCallback(
            type === 'number'
              ? parseFloat(e.target.value) || 0
              : typeof e.target.value === 'string'
                ? e.target.value.trim()
                : e.target.value
          )
        }
      />
    );
  };
}

export default TextEditor;

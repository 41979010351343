import {config as exportedConfig} from '../../../admin/features/exported-features/config';

export const config = {
  ...exportedConfig,
  header: 'Underlot Features',
  type: 'table',
  fields: {
    id: exportedConfig.fields.id,
    name: exportedConfig.fields.name,
    valueType: exportedConfig.fields.valueType,
    valueStructure: exportedConfig.fields.valueStructure,
    code: exportedConfig.fields.code,
    category: exportedConfig.fields.category,
  },
};

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useTranslation} from 'react-i18next';
import ReactHtmlParser from 'html-react-parser';

import {Loader} from '@ui';
import {ShadowContainer} from '@containers';
import {PayBtn} from '@components';
import {useDispatchAsync} from '@hooks';
import {timeToString, formatPrice} from '@utils';
import {getEvents} from '@actions/user';

import {
  ACCOUNT_DOCUMENT_STATUS_DECLINED,
  EVENT_TYPE_DOCUMENT_CHECKED,
  EVENT_TYPE_LOT_PROCESSING_STATUS_ADMIN_CHECKED,
  ORDER_PRODUCT_TYPE_LOT,
} from '@models';

import {ReactComponent as CheckMark} from '@assets/icons/checkMark-gray.svg';
import {ReactComponent as DoubleCheckMark} from '@assets/icons/checkMark-double-green.svg';

import {
  CLEAN_EVENTS,
  MARK_ALL_EVENTS_AS_READ,
} from '../../../../store/user/types';

import './Messages.scss';

function Messages() {
  const dispatch = useDispatchAsync();
  const [t] = useTranslation('pages/profile', {
    keyPrefix: 'messages',
  });
  const events = useSelector(state => state.user.events) || [];
  const isHaveNewEvents = useSelector(state => state.user.isHaveNewEvents);
  const lastVisit = useSelector(state => state.user.lastVisit);
  const [userLastEventViewDate, _] = useState(
    localStorage.getItem('userLastEventViewDate') || lastVisit
  );
  const [hasMore, setHasMore] = useState(true);

  function convertEvent(type, context) {
    if (type === EVENT_TYPE_DOCUMENT_CHECKED && context) {
      if (
        context.docStatus === ACCOUNT_DOCUMENT_STATUS_DECLINED &&
        context.reason !== null &&
        context.reason !== undefined
      ) {
        return ReactHtmlParser(
          t([`events.${type}${'-' + context?.reason}`, 'events.unknown'], {
            docTitle: context?.docTitle || context?.title,
            docStatus: t('documentsStatus.' + context?.docStatus),
          })
        );
      }
    }

    const res = ReactHtmlParser(
      t(
        [
          `events.${type}${
            context?.status === undefined ? '' : '-' + context.status
          }`,
          'events.unknown',
        ],
        {
          docTitle: context?.docTitle || context?.title,
          docStatus: t('documentsStatus.' + context?.docStatus),
          link:
            context?.lotId && context?.lotName
              ? `<a href='/lot/${context?.lotId}'>${context?.lotName}</a>`
              : '',
          totalPrice: formatPrice(context?.totalPrice) ?? '',
          verificationContract: `<a href='/profile/verification/contract' target="_blank">${t(
            'verificationContractTitle'
          )}</a>`,
          verification: `<a href='/profile/verification' target="_blank">${t(
            'verification'
          )}</a>`,
          currentPrice: formatPrice(context?.currentPrice) ?? '',
        }
      )
    );
    if (type === EVENT_TYPE_LOT_PROCESSING_STATUS_ADMIN_CHECKED) {
      return (
        <>
          {res}
          {context?.lotId ? (
            <PayBtn
              orderType={ORDER_PRODUCT_TYPE_LOT}
              lotId={context.lotId}
              className={'white buttonDownloadInvoice'}>
              {t('pay')}
            </PayBtn>
          ) : (
            ''
          )}
        </>
      );
    }
    return res;
  }

  async function resize() {
    const windowHeight = document.documentElement.clientHeight;
    const positionLastEvent = Array.from(document.querySelectorAll('.Event'))
      .at(-1)
      ?.getBoundingClientRect().top;
    if (windowHeight > positionLastEvent && hasMore) await fetchMoreData();
  }
  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [events]);

  useEffect(async () => {
    await dispatch({type: CLEAN_EVENTS});
    dispatch(getEvents(new Date()));
    localStorage.setItem('userLastEventViewDate', new Date());
    isHaveNewEvents && dispatch({type: MARK_ALL_EVENTS_AS_READ});
  }, []);

  async function fetchMoreData() {
    const res = await dispatch(getEvents(events.at(-1)?.createdAt));
    if (res.ok && res.events.length === 0) setHasMore(false);
  }
  return (
    <div className='Messages'>
      <InfiniteScroll
        dataLength={events.length}
        next={fetchMoreData}
        hasMore={hasMore}
        style={{overflow: 'inherit'}}
        loader={<Loader />}>
        {events.map((event, index) => (
          <ShadowContainer key={index} className='Event'>
            <div>
              <div>
                {new Date(userLastEventViewDate) > new Date(event.createdAt) ? (
                  <DoubleCheckMark />
                ) : (
                  <CheckMark />
                )}
              </div>
              <div className='content'>
                {convertEvent(event.type, event.context)}
              </div>
            </div>
            <div className='date'>{timeToString(event.createdAt)}</div>
          </ShadowContainer>
        ))}
      </InfiniteScroll>
    </div>
  );
}
export default Messages;

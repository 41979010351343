import React from 'react';
import {useTranslation} from 'react-i18next';

import {ReactComponent as Timer} from '@assets/icons/timer.svg';
import {CountdownTimer} from '@components';
import {Logger} from '@helpers';

import {
  LOT_STATUS_NEW,
  LOT_STATUS_READY,
  LOT_STATUS_PREPARATION,
  LOT_STATUS_ACTIVE,
  LOT_STATUS_FINISHED_SOLD,
  LOT_STATUS_FINISHED_NOT_SOLD,
  LOT_STATUS_FINISHED_RESERVED,
  AUCTION_STATUS_LIVE,
  AUCTION_STATUS_FUTURE,
  AUCTION_STATUS_FINISHED,
} from '@models';

import './ConvertStatus.scss';

function ConvertStatus({status, date, type}) {
  const [t] = useTranslation('pages/auctions');

  let near = (
    <div>
      <Timer />
      <CountdownTimer date={date} isShowFullDate={true} />
    </div>
  );
  if (+new Date(date) < Date.now()) {
    near = null;
  }
  const started = (
    <div className='green'>
      <Timer />
      <span>{t('auction_started')}</span>
    </div>
  );
  const ended = (
    <div className='red'>
      <Timer />
      <span>{t('auction_finished')}</span>
    </div>
  );

  return (
    <div className='ConvertStatus'>
      {(() => {
        if (type === 'lot') {
          switch (status) {
            case LOT_STATUS_NEW:
            case LOT_STATUS_READY:
            case LOT_STATUS_PREPARATION:
              return near;
            case LOT_STATUS_ACTIVE:
              return started;
            case LOT_STATUS_FINISHED_SOLD:
            case LOT_STATUS_FINISHED_NOT_SOLD:
            case LOT_STATUS_FINISHED_RESERVED:
              return ended;
            default:
              Logger.error('Unknown lot status: ' + status);
          }
        } else if (type === 'auction') {
          switch (status) {
            case AUCTION_STATUS_FUTURE:
              return near;
            case AUCTION_STATUS_LIVE:
              return started;
            case AUCTION_STATUS_FINISHED:
              return ended;
            default:
              Logger.error('Unknown auction status: ' + status);
          }
        }
      })()}
    </div>
  );
}

export default ConvertStatus;

import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {io} from 'socket.io-client';

/* eslint-disable no-console */
function LogSockets({logFilterRef, backLightRef}) {
  const token = useSelector(state => state.admin.token);
  const userId = useSelector(state => state.admin.id);

  const ENDPOINT = ['production', 'staging'].includes(process.env.NODE_ENV)
    ? window.location.protocol + '//' + window.location.host
    : 'http://localhost:5000';

  const socketRef = useRef(
    io(`${ENDPOINT}/debug`, {
      withCredentials: true,
      query: {token: token},
      autoConnect: false,
      transports: ['websocket'],
    })
  );
  const ws = socketRef.current;

  const WS_Events = {
    connect_error(data) {
      let msg = data?.props;
      switch (msg) {
        case 'Not authorized':
        case 'Invalid token':
        case 'Access Denied':
          break;
        case 'xhr poll error':
          msg = "Втрачено зв'язок з сервером, перепідключення...!";
          break;
        case 'websocket error':
          msg = "Втрачено зв'язок з сервером. Перезавантажте сторінку!";
          break;
        case 'timeout':
          msg = 'timeout';
          return;
        default:
          console.error('Unknown props for socket: ' + data);
          return;
      }
      console.error(msg);
    },
    debug(props) {
      infoLogMiddleware(props);
    },
    info(props) {
      infoLogMiddleware(props);
    },
    warn(message) {
      console.warn(message);
    },
    error(message) {
      console.error(message);
    },
    catch({error, message = ''}) {
      const receivedErrorObject = JSON.parse(error);
      const reconstructedError = new Error(receivedErrorObject.message);
      reconstructedError.stack = receivedErrorObject.stack;
      reconstructedError.name = receivedErrorObject.name;
      if (message) console.error(message);
      console.error(reconstructedError);
    },
    table(props) {
      console.table(props.message);
    },
    time(props) {
      console.time(props.message);
    },
    timeEnd(props) {
      console.timeEnd(props.message);
    },
    timeLog(props) {
      console.timeLog(props.message);
    },
  };

  function infoLogMiddleware(props) {
    if (props.find(prop => typeof prop !== 'string')) {
      console.info(...props);
      return;
    }

    const log = props.join(' ');
    if (logFilterRef.current.find(el => log.includes(el))) {
      return;
    }

    if (backLightRef.current.find(el => log.includes(el))) {
      console.info(`%c${log}`, 'color: green;');
      return;
    }

    console.info(...props);
  }

  useEffect(() => {
    ws.connect();
    for (const event in WS_Events) {
      ws.on(event, WS_Events[event]);
    }
  }, []);

  useEffect(() => {
    const cleanup = () => {
      ws.emit('leave', {uid: userId});
      ws.disconnect();
    };
    window.addEventListener('beforeunload', cleanup);

    return () => {
      cleanup();
      window.removeEventListener('beforeunload', cleanup);
    };
  }, []);

  return <div className='LogSockets'></div>;
}

export default LogSockets;

import {useState} from 'react';
import {CheckBox, Input} from '@ui';
import {useUpdatedState} from '@hooks';
import SearchIcon from '@assets/icons/search.png';

import './Select.scss';

function Select({filter, onChange}) {
  const [inputText, setInputText] = useState('');
  const [list, setList] = useUpdatedState(filter.data);

  function onChangeInput(e) {
    const value = e.target.value;
    setInputText(e.target.value);
    setList(
      filter.data.filter(it =>
        it.name.toLowerCase().includes(value.toLowerCase())
      )
    );
  }

  return (
    <div className='Select'>
      {filter.isHaveInput ? (
        <Input
          icon={filter.withSearchIcon ? SearchIcon : false}
          placeholder={filter.placeHolder}
          onChange={onChangeInput}
          value={inputText}
          underTitle={filter.footer}
        />
      ) : null}
      <div className='scroll_container'>
        {list && list.length === 0 && filter.notFoundMessage ? (
          <label>{filter.notFoundMessage}</label>
        ) : null}
        {list && list.length > 0
          ? list.map((item, index) => (
            <label
              key={index}
              tabIndex={index}
              onClick={() => {
                setInputText('');
                setList(filter.data);
                onChange(filter.title, item.id);
              }}>
              <CheckBox type='square' id={item.id} state={item.checked} />
              <span>{item.name}</span>
            </label>
          ))
          : null}
      </div>
    </div>
  );
}
export default Select;

import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useDispatchAsync} from '@hooks';
import {adminLogin, getAdminRights} from '@actions/admin';
import {AuthenticationForm} from '@components';

import './Authentication.scss';

function Authentication() {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatchAsync();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(state => state.admin.isAuthenticated);

  useEffect(() => {
    i18n.setLanguage('uk');
    if (isAuthenticated === true) {
      redirect();
    }
  }, []);

  async function onLogin(login, password) {
    const res = await dispatch(adminLogin(login, password));
    if (res.ok) {
      redirect();
    }
  }
  async function redirect() {
    const res = await dispatch(getAdminRights());
    if (res.ok) {
      return navigate('/org/admin', {replace: true});
    }
  }

  return (
    <AuthenticationForm
      header={'Вхід для Адміна'}
      onLogin={onLogin}
      style={{alignItems: 'center'}}
    />
  );
}
export default Authentication;

import './SelectButton.scss';
import {Button} from '..';

function SelectButton({options, value, onChange}) {
  return (
    <div className='SelectButton'>
      {options.map((el, key) => (
        <Button
          key={key}
          text={el}
          className={`${value === el ? '' : 'white'}`}
          onClick={e => onChange({...e, value: el})}
        />
      ))}
    </div>
  );
}

export default SelectButton;

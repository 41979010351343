import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {Toast} from '@helpers';
import {useDispatchAsync} from '@hooks';
import {deleteUnderDraftLot, getUnderDraftLots} from '@actions/agent';

import Table from '../../../../2-components/table/Table';

import {config} from './config';
import './LotsUnderlot.scss';

function LotsUnderlot() {
  const navigate = useNavigate();
  const dispatch = useDispatchAsync();
  const lots = useSelector(state => state.agent.lots);

  useEffect(() => {
    config.onDelete = async (selectedProduct) => {
      const res = await dispatch(deleteUnderDraftLot(selectedProduct.id));
      if (res.ok) {
        Toast.success('Лот видалено!');
      }
    };
    config.onEdit = async selectedProduct => {
      navigate(`/org/agent/add_lots_underlot/${selectedProduct.id}`);
    };
    config.onLoad = async () => {
      await dispatch(getUnderDraftLots());
    };
    config.onLoad();
  }, []);
  return (
    <div className='LotsUnderlot'>
      <Table config={config} data={lots} name={'Lots'} />
    </div>
  );
}
export default LotsUnderlot;

import {
  AUTO_SORT_INDEX,
  CREATE_EXPORTED_FEATURE,
  CREATE_OWN_FEATURE,
  CREATE_VS_EXPORTED_FEATURES,
  CREATE_VS_OWN_FEATURES,
  DELETE_VS_EXPORTED_FEATURES,
  DELETE_VS_OWN_FEATURES,
  GET_EXPORTED_FEATURES,
  GET_OWN_FEATURES,
  SYNCHRONIZE_FEATURE,
  SYNCHRONIZE_VALUE_STRUCTURE,
  UPDATE_EXPORTED_FEATURES,
  UPDATE_OWN_FEATURES,
  UPDATE_VS_EXPORTED_FEATURES,
  UPDATE_VS_OWN_FEATURES,
} from './types';

export const getOwnFeatures = () => ({
  event: GET_OWN_FEATURES,
  link: '/api/features/own_features',
});

export const getExportedFeatures = () => ({
  event: GET_EXPORTED_FEATURES,
  link: '/api/features/exported_features',
});

export const updateOwnFeature = (id, field, value) => ({
  event: UPDATE_OWN_FEATURES,
  link: '/api/features/update_own_feature',
  data: {
    id: id,
    field: field,
    value: value,
  },
});

export const updateExportedFeature = (id, field, value) => ({
  event: UPDATE_EXPORTED_FEATURES,
  link: '/api/features/update_exported_feature',
  data: {
    id: id,
    field: field,
    value: value,
  },
});

export const updateValueStructureOwnFeature = (id, vs_id, field, value) => ({
  event: UPDATE_VS_OWN_FEATURES,
  link: `/api/features/update_value_structure_own_feature`,
  excludeAbort: true,
  data: {
    id: id,
    vs_id: vs_id,
    field: field,
    value: value,
  },
});

export const autoSortIndex = id => ({
  event: AUTO_SORT_INDEX,
  link: `/api/features/auto_sort_index`,
  data: {
    id: id,
  },
});

export const updateValueStructureExportedFeatures = (
  id,
  vs_id,
  field,
  value
) => ({
  event: UPDATE_VS_EXPORTED_FEATURES,
  link: '/api/features/update_value_structure_exported_feature',
  data: {
    id: id,
    vs_id: vs_id,
    field: field,
    value: value,
  },
});

export const createValueStructureOwnFeatures = (id, next) => ({
  event: CREATE_VS_OWN_FEATURES,
  link: '/api/features/create_value_structure_own_feature',
  data: {
    id: id,
    next: next,
  },
});

export const createValueStructureExportedFeatures = (id, next) => ({
  event: CREATE_VS_EXPORTED_FEATURES,
  link: '/api/features/create_value_structure_exported_feature',
  data: {
    id: id,
    next: next,
  },
});

export const deleteValueStructureOwnFeatures = (id, next) => ({
  event: DELETE_VS_OWN_FEATURES,
  link: '/api/features/delete_value_structure_own_feature',
  data: {
    id: id,
    next: next,
  },
});

export const deleteValueStructureExportedFeatures = (id, next) => ({
  event: DELETE_VS_EXPORTED_FEATURES,
  link: '/api/features/delete_value_structure_exported_feature',
  data: {
    id: id,
    next: next,
  },
});

export const createOwnFeature = next => ({
  event: CREATE_OWN_FEATURE,
  link: '/api/features/create_own_feature',
  data: {
    next: next,
  },
});
export const deleteOwnFeature = id => ({
  event: 'delete_own_feature',
  link: '/api/features/delete_own_feature',
  data: {
    id: id,
  },
});
export const createExportedFeature = next => ({
  event: CREATE_EXPORTED_FEATURE,
  link: '/api/features/create_exported_feature',
  data: {
    next: next,
  },
});
export const deleteExportedFeature = id => ({
  event: 'delete_exported_feature',
  link: '/api/features/delete_exported_feature',
  data: {
    id,
  },
});

export const synchronizeFeatures = (export_id, own_id) => ({
  event: SYNCHRONIZE_FEATURE,
  link: '/api/features/synchronize_features',
  data: {
    export_id: export_id,
    own_id: own_id,
  },
});

export const synchronizeValueStructure = (id, vs_id, syncWith) => ({
  event: SYNCHRONIZE_VALUE_STRUCTURE,
  link: `/api/features/synchronize_value_structure`,
  excludeAbort: true,
  data: {
    id: id,
    vs_id: vs_id,
    syncWith: syncWith,
  },
});

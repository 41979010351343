import {Link} from 'react-router-dom';
import {LOT_PROCESSING_STATUS_NEW} from '@models';

function OrdersLink() {
  return rowData => {
    if (rowData.status === LOT_PROCESSING_STATUS_NEW) return <>Не має</>;
    return (
      <Link
        to={'/org/admin/payments?search=' + rowData.lotId}
        style={{
          lineHeight: 1.4,
          color: 'rgb(49 153 118)',
          textDecoration: 'underline',
        }}
        target={'_blank'}>
        Ордери
      </Link>
    );
  };
}
export default OrdersLink;

import {config as own_config} from '../own-features/config';
export const config = {
  header: 'SynchronizeFeatures',
  type: 'table',
  fields: {
    value: {
      header: 'Значення',
    },
    title: {
      header: 'Заголовок',
    },
    syncWith: {
      header: 'Синхронізовано з',
      modifier: 'public',
      type: 'text',
    },
    valueStructure: {...own_config.fields.valueStructure, synchronize: true},
  },
};

import {
  SCRAPE_AUTOBID3_DATA,
  SCRAPE_CAR_AUCTIONS_DATA,
  TEST_DEBUG,
} from './types';

export const scrapeAutobid3Data = () => ({
  event: SCRAPE_AUTOBID3_DATA,
  link: '/api/debug/scrape_autobid3_data',
});

export const testDebug = () => ({
  event: TEST_DEBUG,
  link: '/api/debug/test',
});
export const runTestProxy = () => ({
  event: 'run_test_proxy',
  link: '/api/debug/run_test_proxy',
});

export const scrapeCarAuctionsData = () => ({
  event: SCRAPE_CAR_AUCTIONS_DATA,
  link: '/api/debug/scrape_car_auctions_data',
});

export const deleteLotPhotos = lotId => ({
  event: 'delete_lot_photos',
  link: '/api/debug/delete_lot_photos',
  data: {
    lotId: lotId,
  },
});

export const scrapeProductionsLots = collections => ({
  event: 'scrape_productions_lots',
  link: '/api/debug/scrape_productions_lots',
  data: {
    collections,
  },
});

export const runUpdateFilters = () => ({
  event: 'run_update_filters',
  link: '/api/debug/run_update_filters',
});

export const runUpdateFeatures = () => ({
  event: 'run_update_features',
  link: '/api/debug/run_update_features',
});

export const runClearOldData = () => ({
  event: 'run_clear_old_data',
  link: '/api/debug/run_clear_old_data',
});

export const checkAutobid3LotInfo = lotId => ({
  event: 'check_autobid3_lot_info',
  link: '/api/debug/check_autobid3_lot_info',
  data: {
    lotId,
  },
});

import React from 'react';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Logger, Toast} from '@helpers';
import {useDispatchAsync} from '@hooks';
import {getUsers} from '@actions/admin';

import {
  updateBidAllowed,
  updateLotCountLimit,
  updateLotPriceLimit,
  updateLotPriceReserve,
  updateRights,
  updateStatus,
  updateVerificationRights,
  updateAgentRights,
} from '@actions/admin';

import {
  ACCOUNT_TYPE_COMPANY_EU,
  ACCOUNT_TYPE_COMPANY_UA,
  ACCOUNT_TYPE_PERSONAL,
} from '@models';

import useUpdateData from '../../../hooks/update-data.hook';
import Table from '../../../2-components/table/Table';

import {config} from './config';
import './Moderation.scss';

function Moderation() {
  const dispatch = useDispatchAsync();
  const api = useUpdateData();
  const users = useSelector(state => state.admin.users);
  const [tR] = useTranslation('common');
  const listCountry = tR('listCountry', {returnObjects: true});

  useEffect(() => {
    async function onSave({idRow, fieldMustToUpdated}) {
      for (const {field, value} of fieldMustToUpdated) {
        switch (field) {
          case 'status':
            await api(updateStatus(idRow, value));
            break;
          case 'moderation':
          case 'support':
          case 'manager':
          case 'moderation_of_documents':
          case 'events':
          case 'features':
          case 'invoices':
          case 'payments':
          case 'debug':
            await api(updateRights(idRow, field, value));
            break;
          case 'add_lots_underlot':
            await api(updateAgentRights(idRow, field, value));
            break;
          case 'document':
          case 'contract':
          case 'tariff':
            const res = await api(
              updateVerificationRights(idRow, field, value)
            );
            if (field === 'document' && value && res.ok) {
              Toast.success(
                'Договір купівлі продажу успішно згенерований',
                5000
              );
            }
            break;
          case 'bidAllowed':
            await api(updateBidAllowed(idRow, value));
            break;
          case 'lotCountLimit':
            await api(updateLotCountLimit(idRow, value));
            break;
          case 'lotPriceLimit':
            await api(updateLotPriceLimit(idRow, value));
            break;
          case 'lotPriceReserve':
            await api(updateLotPriceReserve(idRow, value));
            break;
          default:
            Logger.warn(`Some unknown type field: ${field}`);
        }
      }
    }

    async function onLoad() {
      await dispatch(getUsers());
    }
    config.fields.verificationRights.onSave = onSave;
    config.fields.adminRights.onSave = onSave;
    config.fields.agentRights.onSave = onSave;
    config.fields.accountData.onSave = onSave;
    config.fields.bidsSettings.onSave = onSave;
    config.fields.additionalData.onSave = onSave;

    config.onLoad = onLoad;
    config.fields.verificationRights.onLoad = onLoad;
    config.fields.adminRights.onLoad = onLoad;
    config.fields.agentRights.onLoad = onLoad;
    config.fields.accountData.onLoad = onLoad;
    config.fields.bidsSettings.onLoad = onLoad;
    config.fields.additionalData.onLoad = onLoad;
    config.fields.additionalData.fields.country.editorData = Object.entries(
      listCountry
    ).map(([key, value]) => ({indexInDB: key, title: value, severity: 'info'}));
  });

  function filterColumns(_columns, {config, products}) {
    if (
      config.header === 'Верифікаційні дані' &&
      products &&
      products.length === 1
    ) {
      let newColumns = null;
      switch (products[0].type) {
        case ACCOUNT_TYPE_PERSONAL:
          newColumns = _columns.filter(i =>
            [
              'last_name',
              'first_name',
              'birthday',
              'identification_number',
              'passport_no',
              'passport_date',
              'passport_issued_by',
            ].includes(i.field)
          );
          break;
        case ACCOUNT_TYPE_COMPANY_UA:
          newColumns = _columns.filter(i =>
            ['companyName', 'IPNCompany', 'CodeEDRPOYCompany'].includes(i.field)
          );
          break;
        case ACCOUNT_TYPE_COMPANY_EU:
          newColumns = _columns.filter(i =>
            ['companyName', 'NIPCompany', 'VATCompany'].includes(i.field)
          );
          break;
        default:
          Logger.warn(`Some unknown account type: ${products[0].type}`);
      }

      return [
        ...newColumns,
        ..._columns.filter(i =>
          ['country', 'address', 'ZIPCode'].includes(i.field)
        ),
      ];
    } else {
      return _columns;
    }
  }

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <div className='Moderation'>
      <Table
        config={config}
        data={users}
        middlewareSetColumns={filterColumns}
      />
    </div>
  );
}
export default Moderation;

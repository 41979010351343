import {ReactComponent as CheckMark} from '@assets/icons/checkMark.svg';

import './CheckBox.scss';

function CheckBox({
  id = null,
  type = '',
  state,
  setState = () => {},
  name = null,
}) {
  return (
    <div
      name={name}
      id={id}
      className={`CheckBox ${state ? 'active' : ''} ${type}`}
      onClick={e => setState(!state, e)}>
      <CheckMark />
    </div>
  );
}

export default CheckBox;

import {useEffect, useRef, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Loader, Button} from '@ui';
import {Lots, ConvertStatus} from '@components';
import {ShadowContainer} from '@containers';
import {getUserInfo} from '@actions/user';
import {getLotsData} from '@actions/lots';
import {useDispatchAsync} from '@hooks';

import SettingsPanel from './settings-panel/SettingsPanel';
import SelectedFilterOptions from './selected-filter-options/SelectedFilterOptions';
import LotsFilters from './lots-filters/LotsFilters';
import AuctionFilters from './auction-filters/AuctionFilters';

import './LotsPage.scss';

function LotsPage({auction}) {
  const location = useLocation();
  const [t] = useTranslation('pages/cars');
  const dispatch = useDispatchAsync();
  const lotsData = useSelector(state => state.lots.lots);
  const [isLoading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const params = useRef('' + location.search);

  useEffect(async () => {
    if (isAuthenticated) await dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    Load(searchParams);
  }, [searchParams, t]);

  async function Load(search) {
    if ('' + params.current === '' + search && '' + search !== '') return;
    params.current = search;
    const _search = new URLSearchParams(search);
    auction && _search.set('aid', auction.id);
    await setLoading(() => true);
    const res = await dispatch(getLotsData(_search));
    if (res.ok) {
      await setLoading(() => false);
    }
  }

  function clickClearFilters() {
    setSearchParams({}, {replace: true});
  }

  return (
    <div className='LotsPage container'>
      <div className='filters_panel'>
        <div className='filters_header'>
          <div className='totalLots'>
            {t('foundCars')} : {lotsData?.totalCars}
          </div>
        </div>
        {auction ? <AuctionFilters /> : null}
        <LotsFilters auctionId={auction?.id} />
      </div>

      <div className='lots_main'>
        <SettingsPanel auctionId={auction?.id} />
        <SelectedFilterOptions />
        {auction ? (
          <ShadowContainer className='auction_title'>
            <div className='auction_name'>{auction.name}</div>
            <div className='info'>
              <span>
                {t('auction')} ID: {auction.id}
              </span>
              <ConvertStatus
                type={'auction'}
                status={auction.auctionStatus}
                date={auction.startsAt}
              />
            </div>
          </ShadowContainer>
        ) : null}
        {isLoading === false ? (
          lotsData?.cars.length !== 0 ? (
            <>
              <Lots lotsData={lotsData} auction={auction} />
              <SettingsPanel auctionId={auction?.id} />
            </>
          ) : (
            <div className='noCarsFound'>
              <h1>{t('no_auctions_found')}</h1>
              <h2>{t('try_select_another_filter')}</h2>
              <Button text={t('clear_filter')} onClick={clickClearFilters} />
            </div>
          )
        ) : (
          <Loader msg={t('Loader_MSG')} />
        )}
      </div>
    </div>
  );
}
export default LotsPage;

import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';

import {
  ACCOUNT_STATUS_EMPTY,
  ACCOUNT_STATUS_NEW,
  ACCOUNT_STATUS_VERIFIED,
} from '@models';

import './TopHomeContainer.scss';

function TopHomeContainer({children}) {
  const [t] = useTranslation('', {keyPrefix: 'TopHomeContainer'});

  const location = useLocation();
  const user = useSelector(state => state.user);

  function render() {
    if (user.isAuthenticated) {
      if (
        user.status === ACCOUNT_STATUS_EMPTY ||
        user.status === ACCOUNT_STATUS_NEW
      ) {
        return (
          <>
            <div className='text'>{t('not_verification.text')}</div>
            <div className='button'>
              <Link to='/profile/verification'>
                {t('not_verification.button')}
              </Link>
            </div>
          </>
        );
      }
      if (user.status === ACCOUNT_STATUS_VERIFIED && !user.bidAllowed) {
        return (
          <>
            <div className='text'>{t('not_bit_allowed.text')}</div>
            <div className='button'>
              <Link to='/lots'>{t('not_bit_allowed.button')}</Link>
            </div>
          </>
        );
      }

      return (
        <>
          <div className='text'>{t('Authenticated.text')}</div>
          <div className='button'>
            <Link to='/lots'>{t('Authenticated.button')}</Link>
          </div>
        </>
      );
    }

    if (location.pathname === '/') {
      <>
        <div className='text'>{t('notAuthorized.text')}</div>
        <div className='button'>
          <Link to='/auth'>{t('notAuthorized.button')}</Link>
        </div>
      </>;
    }

    return (
      <>
        <div className='text'>{t('notRegistration.text')}</div>
        <div className='button'>
          <Link to='/registration'>{t('notRegistration.button')}</Link>
        </div>
      </>
    );
  }

  return (
    <div className='TopHomeContainer'>
      <div className='TopHomeContainer_container'>
        <div className='left'>{children}</div>
        <div className='right'>
          <div className='photo'></div>
          <div className='bloc'>{render()}</div>
        </div>
      </div>
    </div>
  );
}
export default TopHomeContainer;

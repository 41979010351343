import {GET_LOTS_DATA_SUCCESS, LOAD_FILTERS_SUCCESS} from './types';

const initialState = {};

const lotsReducer = (state = initialState, action) => {
  let new_state = {};
  switch (action.type) {
    case LOAD_FILTERS_SUCCESS:
      new_state = {
        filters: action.payload,
      };
      break;
    case GET_LOTS_DATA_SUCCESS:
      new_state = {
        lots: action.payload,
      };
      break;
    default:
      //Do nothing
      break;
  }

  return {...state, ...new_state};
};

export default lotsReducer;

import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {TopHomeContainer} from '@containers';
import {ReactComponent as ArrowGreen} from '@assets/icons/arrow-green.svg';

import './PasswordRecoveryConfirm.scss';

function PasswordRecoveryConfirm() {
  const [t] = useTranslation('pages/authentication', {
    keyPrefix: 'password-recovery-confirm',
  });

  return (
    <TopHomeContainer>
      <div className='PasswordRecoveryConfirm'>
        <header>
          <div className='title'>{t('title')}</div>
          <div className='subTitle'>
            <span>{t('subTitle')}</span>
          </div>
        </header>

        <div className='links'>
          <Link to='/password-recovery' className='back'>
            <ArrowGreen />
            {t('btBack')}
          </Link>
        </div>
      </div>
    </TopHomeContainer>
  );
}
export default PasswordRecoveryConfirm;

import * as Sentry from '@sentry/react';
/* eslint-disable no-console */
function convertTo(props) {
  const res = [];
  for (const prop of props) {
    if (typeof prop === 'object') {
      res.push(JSON.stringify(prop));
    } else {
      res.push(prop);
    }
  }
  return res;
}
class Logger {
  constructor() {
    this.toSentry = false;
    if (process.env.REACT_APP_LOGGER_SENTRY_DSN) {
      Sentry.init({
        dsn: process.env.REACT_APP_LOGGER_SENTRY_DSN,
        tracesSampleRate: 1.0,
      });
      this.toSentry = true;
    }
  }

  info(...props) {
    console.log(...props);
  }

  warn(...props) {
    console.warn(...props);
    if (this && this.toSentry) {
      props = convertTo(props);
      Sentry.captureMessage(props);
    }
  }

  error(...props) {
    console.error(...props);
    if (this && this.toSentry) {
      props = convertTo(props);
      Sentry.captureException(props);
    }
  }

  catch(error, message = '') {
    if (message) this.error(message);
    this.error(error);
  }

  time(message) {
    console.time(message);
  }

  timeEnd(message) {
    console.timeEnd(message);
  }

  timeLog(message) {
    console.timeLog(message);
  }
}

export default new Logger();

import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {Loader} from '@ui';
import {useDispatchAsync} from '@hooks';
import {getAuctionData} from '@actions/auction';

import LotsPage from '../cars/LotsPage';

import './Auction.scss';

function Auction() {
  const params = useParams();
  const dispatch = useDispatchAsync();
  const auction = useSelector(state => state.auctions.auction);
  const [isLoading, setLoading] = useState(false);

  useEffect(async () => {
    await setLoading(() => true);
    const res = await dispatch(getAuctionData(params.id));
    if (res.ok) {
      await setLoading(() => false);
    }
  }, []);

  if (!auction || isLoading === true) return <Loader />;

  return (
    <div className='Auction'>
      <LotsPage auction={auction} />
    </div>
  );
}

export default Auction;

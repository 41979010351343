import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Button} from '@ui';
import {formatPrice} from '@utils';

import {
  LOT_STATUS_ACTIVE,
  LOT_STATUS_PREPARATION,
  LOT_STATUS_READY,
} from '@models';

import './ConfirmBit.scss';

function ConfirmBit({socketRef, newBit, setNewBit}) {
  const [t] = useTranslation('pages/lot');
  const lot = useSelector(state => state.lot);

  function makeBitHandler() {
    socketRef.current.emit('place-bid', {
      lid: lot.lotId,
      step: lot.currentPrice - newBit,
      newPrice: newBit,
    });
    setNewBit(null);
  }

  function render() {
    switch (lot.lotStatus) {
      case LOT_STATUS_READY:
      case LOT_STATUS_PREPARATION:
      case LOT_STATUS_ACTIVE:
        if (newBit)
          return (
            <div className='confirmation'>
              <div className='title'>
                {t('confirm_bit_message')}{' '}
                <span className='price'>{formatPrice(newBit)}</span>?
              </div>
              <div className='buttons'>
                <Button text={t('yes')} onClick={makeBitHandler} />
                <Button
                  text={t('no')}
                  onClick={() => {
                    setNewBit(null);
                  }}
                />
              </div>
            </div>
          );
        break;
      default:
        break;
    }
  }
  const res = render();
  if (res) {
    return <div className='ConfirmBit'>{res}</div>;
  } else {
    return <></>;
  }
}
export default ConfirmBit;

import {
  ADD_SURCHARGES,
  DELETE_SURCHARGES,
  GENERATE_LOT_INVOICE,
  GET_LOT_PROCESSING_DATA,
  UPDATE_LOT_PROCESSING_PRICES,
  UPDATE_LOT_PROCESSING_INVOICE,
  UPDATE_LOT_PROCESSING_STATUS,
  UPDATE_SURCHARGES_VALUE,
} from './types';

export const getLotProcessingData = () => ({
  event: GET_LOT_PROCESSING_DATA,
  link: '/api/org/lot_processing',
});

export const updateLotProcessingPrices = (lotId, field, value) => ({
  event: UPDATE_LOT_PROCESSING_PRICES,
  link: '/api/org/update_lot_processing_prices',
  data: {
    lotId: lotId,
    field: field,
    value: value,
  },
});

export const updateLotProcessingStatus = (lotId, status) => ({
  event: UPDATE_LOT_PROCESSING_STATUS,
  link: '/api/org/update_lot_processing_status',
  data: {
    lotId: lotId,
    status: status,
  },
});

export const updateLotProcessingInvoice = (lotId, invoiceData) => ({
  event: UPDATE_LOT_PROCESSING_INVOICE,
  link: '/api/org/update_lot_processing_invoice',
  data: {
    lotId: lotId,
    ...invoiceData,
  },
});

export const updateSurchargesValue = (lotId, taxType, field, value) => ({
  event: UPDATE_SURCHARGES_VALUE,
  link: '/api/org/update_surcharges',
  data: {
    lotId: lotId,
    taxType: taxType,
    field: field,
    value: value,
  },
});

export const createSurcharges = (lotId, taxType, value) => ({
  event: ADD_SURCHARGES,
  link: '/api/org/add_surcharges',
  data: {
    lotId: lotId,
    taxType: taxType,
    value: value,
  },
});

export const deleteSurcharges = (lotId, taxType) => ({
  event: DELETE_SURCHARGES,
  link: '/api/org/delete_surcharges',
  data: {
    lotId: lotId,
    taxType: taxType,
  },
});

export const generateLotInvoice = lotId => ({
  event: GENERATE_LOT_INVOICE,
  link: '/api/payment/generate_lot_invoice',
  data: {
    lotId: lotId,
  },
});

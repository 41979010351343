import {useSelector} from 'react-redux';

import {ItemsLimit, PaginationComponent} from '@components';

import Sorting from './sorting/Sorting';

import './SettingsPanel.scss';

function SettingsPanel({auctionId}) {
  const lotsData = useSelector(state => state.lots.lots);

  return (
    <div className='SettingsPanel'>
      <div>
        <Sorting auctionId={auctionId} />
        <ItemsLimit />
      </div>
      <PaginationComponent data={lotsData} />
    </div>
  );
}
export default SettingsPanel;

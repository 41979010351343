import {
  LOAD_DOCUMENTS,
  ADD_DOCUMENTS,
  DOWNLOAD_DOCUMENTS,
  DELETE_DOCUMENTS,
  APPROVE_DOCUMENT,
  DECLINED_DOCUMENT,
  DOWNLOAD_SALE_CONTRACT,
} from './types';

export const loadDocuments = () => ({
  event: LOAD_DOCUMENTS,
  link: '/api/documents/load_documents',
});

export const addDocument = formData => ({
  event: ADD_DOCUMENTS,
  link: '/api/documents/add_documents',
  data: formData,
});

export const downloadDocument = (userId, docId) => ({
  event: DOWNLOAD_DOCUMENTS,
  link: `/api/documents/download_document/${userId}/${docId}`,
});

export const deleteDocument = (userId, docId) => ({
  event: DELETE_DOCUMENTS,
  link: '/api/documents/delete_document',
  data: {userId: userId, docId: docId},
});

export const approveDocument = (userId, docId) => ({
  event: APPROVE_DOCUMENT,
  link: '/api/documents/approve_document',
  data: {userId: userId, docId: docId},
});

export const declinedDocument = (userId, docId, reason) => ({
  event: DECLINED_DOCUMENT,
  link: '/api/documents/declined_document',
  data: {userId: userId, docId: docId, reason: reason},
});

export const downloadSaleContract = () => ({
  event: DOWNLOAD_SALE_CONTRACT,
  link: '/api/documents/download_sale_contract',
});

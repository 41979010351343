import {getBidsCars} from '@actions/my-cars';

import PageCarsContainer from '../container/page-cars-container/PageCarsContainer';
import './Bids.scss';

function Bids() {
  return (
    <div className='Bids'>
      <PageCarsContainer keyToData={'bidsCars'} getData={getBidsCars} />
    </div>
  );
}
export default Bids;

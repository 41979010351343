import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {TopHomeContainer} from '@containers';
import {userLogin} from '@actions/user';
import {useDispatchAsync} from '@hooks';
import {AuthenticationForm} from '@components';


function AuthenticationPage() {
  const [t] = useTranslation('pages/authentication', {
    keyPrefix: 'authentication',
  });
  const dispatch = useDispatchAsync();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      return navigate('/', {replace: true});
    }
  }, [isAuthenticated]);

  async function onLogin(login, password) {
    await dispatch(userLogin(login, password));
  }

  return (
    <TopHomeContainer>
      <AuthenticationForm header={t('title')} onLogin={onLogin} />
    </TopHomeContainer>
  );
}

export default AuthenticationPage;

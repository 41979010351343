import {
  GET_BIDS_CARS_SUCCESS,
  GET_FAVORITES_CARS_SUCCESS,
  GET_NO_WON_CARS_SUCCESS,
  GET_WON_CARS_SUCCESS,
  GET_WON_WITH_CAVEAT_CARS_SUCCESS,
} from './types';

const initialState = {};

const myCarsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FAVORITES_CARS_SUCCESS:
      state = {
        trackedCars: action.payload,
      };
      break;
    case GET_BIDS_CARS_SUCCESS:
      state = {
        bidsCars: action.payload,
      };
      break;
    case GET_WON_CARS_SUCCESS:
      state = {
        wonCars: action.payload,
      };
      break;
    case GET_NO_WON_CARS_SUCCESS:
      state = {
        noWonCars: action.payload,
      };
      break;
    case GET_WON_WITH_CAVEAT_CARS_SUCCESS:
      state = {
        wonWithCaveatCars: action.payload,
      };
      break;
    default:
      //Do nothing
      break;
  }
  return state;
};

export default myCarsReducer;

import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import {useDispatchAsync} from '@hooks';
import {Button, CheckBox, Input} from '@ui';
import {
  scrapeAutobid3Data,
  // scrapeCarAuctionsData,
  testDebug,
  scrapeProductionsLots,
  runUpdateFilters,
  runUpdateFeatures,
  checkAutobid3LotInfo,
  runClearOldData,
  runTestProxy,
} from '@actions/admin';
import {
  getIsCronsRunnig,
  getIsTechnicalWorks,
  togglIsCronsRunning,
  togglIsTechnicalWorks,
} from '@actions/home';

import LogSockets from './LogSockets';
import LogFilter from './LogFilter';
import BackLight from './BackLight';

import './Debug.scss';

function Debug() {
  const dispatch = useDispatchAsync();
  const logFilterRef = useRef();
  const backLightRef = useRef();

  const [lotId, setLotId] = useState([]);
  const [collections, setCollections] = useState([]);

  const isTechnicalWorks = useSelector(state => state.home.isTechnicalWorks);
  const isCronsRunning = useSelector(state => state.home.isCronsRunning);

  useEffect(() => {
    dispatch(getIsCronsRunnig());
  }, []);

  //const [value, setValue] = useState();
  return (
    <div className='Debug container'>
      <LogSockets logFilterRef={logFilterRef} backLightRef={backLightRef} />
      <div className='threads'>
        <Button
          style={{backgroundColor: 'green'}}
          onClick={() => dispatch(testDebug())}>
          Провірити відлатку
        </Button>
        <Button
          style={{backgroundColor: 'green'}}
          onClick={() => dispatch(runTestProxy())}>
          Провірити роботу Proxy, і відобразити статистику
        </Button>
        <Button
          style={{backgroundColor: 'red'}}
          onClick={() => dispatch(scrapeAutobid3Data())}>
          Запустити парсер autobid3
        </Button>
        <Button
          style={{backgroundColor: 'orange'}}
          onClick={() => dispatch(runUpdateFilters())}>
          Запустити оновлення фільтрів всіх лотів
        </Button>
        <Button
          style={{backgroundColor: 'orange'}}
          onClick={() => dispatch(runUpdateFeatures())}>
          Запустити оновлення характеристик всіх лотів
        </Button>
        <Button
          style={{backgroundColor: 'orange'}}
          onClick={() => dispatch(runClearOldData())}>
          Запустити очистку застарілих даних
        </Button>
        <Button
          style={{backgroundColor: 'red'}}
          onClick={async () => {
            await dispatch(togglIsTechnicalWorks());
            await dispatch(getIsTechnicalWorks());
          }}>
          {isTechnicalWorks ? 'Виключити' : 'Включити'} відображення
          повідомлення про технічні роботи
        </Button>

        <Button
          style={{backgroundColor: 'red'}}
          onClick={async () => {
            await dispatch(togglIsCronsRunning());
            await dispatch(getIsCronsRunnig());
          }}>
          {isCronsRunning ? 'Зупинити' : 'Запустити'} крони
        </Button>

        <div className='checkLotBloc'>
          <Input
            placeholder={'Вкажіть id лота для оновлення'}
            style={{margin: '3px'}}
            value={lotId}
            onChange={e => setLotId(e.target.value)}
          />
          <Button
            style={{backgroundColor: 'blue'}}
            onClick={() => lotId && dispatch(checkAutobid3LotInfo(lotId))}>
            Оновити дані по лоту
          </Button>
        </div>
        <div>
          {[
            'autobid2_auctions',
            'autobid2_lots',

            'autobid3_auctions',
            'autobid3_lots',

            'autobid3_logs',
            'autobid3_requests',

            'auctions',
            'lots',
            'bids',

            'processes',
            'logos',
            'surcharges',

            'features',
            'source_features',
            'filters',
          ].map(collection => (
            <div className='CheckBox_container' key={collection}>
              <CheckBox
                id={collection}
                name={collection}
                type='square'
                state={collections.includes(collection)}
                setState={() => {
                  if (collections.includes(collection)) {
                    setCollections(collections.filter(el => el !== collection));
                  } else {
                    setCollections([...collections, collection]);
                  }
                }}
              />
              {collection}
            </div>
          ))}
        </div>
        <Button
          style={{backgroundColor: 'orange'}}
          onClick={() =>
            collections.length > 0 &&
            dispatch(scrapeProductionsLots(collections))
          }>
          Стягнути дані з Production
        </Button>
        {/*<div>
          <Input
            placeholder={'lot Id ...'}
            value={value}
            onChange={e => setValue(e.target.value)}
          />
          <Button onClick={async () => await dispatch(deleteLotPhotos(value))}>
            deleteLotPhotos
          </Button>
        </div>
        */}
      </div>

      <div className='controlPanel'>
        <LogFilter logFilterRef={logFilterRef} />
        <BackLight backLightRef={backLightRef} />
      </div>
    </div>
  );
}

export default Debug;

import {useState} from 'react';
import {Galleria as GalleriaPrime} from 'primereact/galleria';
import {PhotoSlider} from 'react-photo-view';

import {Loader} from '@ui';

import 'react-photo-view/dist/react-photo-view.css';
import '../result-line/ResultLine.scss';
import './Galleria.scss';

function Galleria({className, images = [], setImages, onDelete}) {
  const [visible, setVisible] = useState();
  const [activeIndex, setActiveIndex] = useState(0);

  if (!images) {
    return <Loader />;
  }
  return (
    <div className='Galleria'>
      <GalleriaPrime
        responsiveOptions={[
          {
            breakpoint: '100000px',
            numVisible: 5,
          },
        ]}
        className={`GalleriaPrime ${className}`}
        value={images.map((i, index) => ({index: index, ...i}))}
        activeIndex={activeIndex}
        onItemChange={({index}) => {
          if (typeof index === 'number') {
            setActiveIndex(index);
          }
        }}
        numVisible={5}
        circular
        style={{maxWidth: '1040px'}}
        showItemNavigators
        onClick={e => {
          if (e.target.parentElement.className === 'p-galleria-item') {
            setVisible(true);
          }
        }}
        item={item => {
          if (item)
            return (
              <img
                src={item.src}
                key={item.src}
                alt={item.alt}
                style={{display: 'block'}}
              />
            );
        }}
        thumbnail={item => (
          <img
            id={item.id}
            key={item.src}
            src={item.src}
            alt={item.alt}
            style={{width: 'auto', display: 'block'}}
            onTouchEnd={e => {
              const newIndex = parseInt(e.target.id);
              if (
                isNaN(newIndex) ||
                newIndex === null ||
                newIndex === undefined
              ) {
                return;
              }
              setActiveIndex(newIndex);

              e.preventDefault();
              e.stopPropagation();
            }}
          />
        )}
      />

      <PhotoSlider
        index={activeIndex}
        onIndexChange={index => {
          setActiveIndex(index);
        }}
        images={images.map(item => ({src: item.src, key: item.src}))}
        visible={visible}
        onClose={() => setVisible(false)}
        toolbarRender={({scale, onScale, rotate, onRotate}) => {
          return (
            <div className='PhotoSlider_toolbar'>
              <span
                className='pi pi-replay'
                onClick={() => onRotate(rotate - 90)}
              />
              <span
                className='pi pi-minus'
                onClick={() => onScale(scale - 1)}
              />
              <span className='pi pi-plus' onClick={() => onScale(scale + 1)} />
              {setImages && onDelete ? (
                <span
                  className='pi pi-trash'
                  onClick={() => {
                    onDelete(images.find((_, index) => index === activeIndex));
                    setImages(
                      images.filter((_, index) => index !== activeIndex)
                    );
                    if (images.length - 1 === activeIndex) {
                      if (activeIndex !== 0) {
                        setActiveIndex(activeIndex - 1);
                      }
                      if (activeIndex === 0 || activeIndex === 1) {
                        setVisible(false);
                      }
                    }
                  }}
                />
              ) : null}
            </div>
          );
        }}
      />
    </div>
  );
}
export default Galleria;

import React, {useState, useEffect} from 'react';
import './ButtonUp.scss';

function ButtonUp() {
  const [isButtonActive, setIsButtonActive] = useState(false);

  const scrollHandler = () => {
    if (window.scrollY > 90) {
      setIsButtonActive(true);
    } else {
      setIsButtonActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    return () => {
      document.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
    <div
      className={`buttonUp-container ${
        isButtonActive === true ? 'active-btnTop' : ''
      }`}
      onClick={scrollToTop}>
      <span
        className='pi pi-chevron-up'
        style={{fontSize: '1.3rem', color: '#fff'}}></span>
    </div>
  );
}

export default ButtonUp;

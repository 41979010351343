import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {DropDown, Input, Loader} from '@ui';
import {Interval, Select} from '@components';
import {getCountCars, loadFilters} from '@actions/lots';
import {useDispatchAsync} from '@hooks';
import {useUpdatedState} from '@hooks';

import {ReactComponent as ImageArrowGreen} from '@assets/icons/arrow-green.svg';

import './Filters.scss';

function Filters() {
  const [t, i18n] = useTranslation('', {keyPrefix: 'Filters'});
  const dispatch = useDispatchAsync();
  const filters = useSelector(state => state.lots.filters);

  const [count, setCount] = useState(0);
  const [searchParams, setSearchParams] = useState(new URLSearchParams());
  const [manufacture, setManufacture] = useUpdatedState();

  useEffect(() => {
    if (filters?.manufacturers) {
      setManufacture(JSON.parse(JSON.stringify(filters?.manufacturers)));
    }
  }, [filters]);

  function carBrands(title, id) {
    const res = manufacture.find(i => i.id === id);
    res.checked = !res.checked;
    const selectedManufactures = manufacture
      .filter(i => i.checked)
      .map(i => i.id);
    if (selectedManufactures.length > 0) {
      searchParams.set('manufacturers', selectedManufactures);
    } else {
      searchParams.delete('manufacturers');
    }
    setSearchParams(new URLSearchParams(searchParams));
    setManufacture([...manufacture]);
  }

  useEffect(() => {
    dispatch(loadFilters());
  }, [i18n.currentLang]);

  useEffect(async () => {
    const res = await dispatch(getCountCars(searchParams.toString()));
    if (res.ok) {
      setCount(res.totalCount);
    }
  }, [searchParams]);

  if (!filters) {
    return <h3 className='loader'>{t('filtersLoading')}</h3>;
  }
  if (!manufacture) {
    return <Loader />;
  }
  return (
    <div className={'Filters'}>
      <div className='containerDD'>
        <DropDown title={t('carBrand')}>
          <Select
            filter={{
              id: 'manufacturers',
              title: t('carBrand'),
              isHaveInput: true,
              withSearchIcon: true,
              placeHolder: t('carBrandHolder'),
              notFoundMessage: t('carBrandNotFound'),
              footer: t('carBrandExample'),
              data: [
                ...manufacture.filter(el => el.checked),
                ...manufacture.filter(el => !el.checked),
              ],
            }}
            onChange={carBrands}
          />
        </DropDown>
        <div>
          <DropDown title={t('carModel')}>
            <Input
              placeholder={t('carModelHolder')}
              underTitle={t('carModelExample')}
              value={searchParams.get('model')}
              onChange={e => {
                if (e.target.value) {
                  searchParams.set('model', e.target.value);
                } else {
                  searchParams.delete('model');
                }
                setSearchParams(new URLSearchParams(searchParams));
              }}
            />
          </DropDown>

          <DropDown title={t('registrationDate')} className={'third-filter'}>
            <Interval
              filter={{
                id: 'year',
                type: 'interval',
                title: t('registration_date'),
                data:
                  filters.years?.map(i => ({
                    title: i,
                    value: i,
                  })) || [],
                from: parseFloat(searchParams.get('yearFrom') || 0),
                to: parseFloat(searchParams.get('yearTo') || 0),
              }}
              onChangeFieldFrom={(title, value) => {
                if (value) {
                  searchParams.set('yearFrom', value);
                } else {
                  searchParams.delete('yearFrom');
                }
                setSearchParams(new URLSearchParams(searchParams));
              }}
              onChangeFieldTo={(title, value) => {
                if (value) {
                  searchParams.set('yearTo', value);
                } else {
                  searchParams.delete('yearTo');
                }
                setSearchParams(new URLSearchParams(searchParams));
              }}
            />
          </DropDown>
        </div>
      </div>

      <div className='homePage_more'>
        <Link to={`/lots?${searchParams}`}>
          {count === 0 ? t('waiting') : t('showCarsCount', {count: count})}
          <ImageArrowGreen />
        </Link>
      </div>
    </div>
  );
}
export default Filters;

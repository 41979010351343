import {useEffect, useState} from 'react';
import {Button, Input} from '@ui';
import {ReactComponent as Dagger} from '@assets/icons/dagger.svg';

function BackLight({backLightRef}) {
  const [inputValue, setInputValue] = useState('');
  const ls_backLight = localStorage.getItem('backLight');
  const [backLight, setBlacklist] = useState(
    ls_backLight ? JSON.parse(ls_backLight) : []
  );

  useEffect(() => {
    backLightRef.current = backLight;
    localStorage.setItem('backLight', JSON.stringify(backLight));
  }, [backLight]);

  function onAdd() {
    setBlacklist([inputValue, ...backLight]);
    setInputValue('');
  }
  function onDelete(backLightItem) {
    setBlacklist([...backLight.filter(i => i !== backLightItem)]);
  }

  return (
    <div className='BackLight'>
      <div className='enter'>
        <Input
          headerTitle={'Підсвітка'}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
        <Button style={{backgroundColor: 'green'}} onClick={onAdd}>
          Добавити
        </Button>
      </div>

      <div className='list'>
        {backLight.map(item => (
          <div className='item' key={item}>
            <div>{item}</div>
            <Dagger onClick={() => onDelete(item)} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default BackLight;

import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import './LineTechnicalWorks.scss';

function LineTechnicalWorks() {
  const [t] = useTranslation('', {keyPrefix: 'Navbar'});

  const isTechnicalWorks = useSelector(state => state.home.isTechnicalWorks);

  return (
    <div className='LineTechnicalWorks'>
      {isTechnicalWorks ? (
        <div className='line'>{t('technical_works')}</div>
      ) : null}
    </div>
  );
}

export default LineTechnicalWorks;

import {useRef} from 'react';
import {Toast as PrimeReactToast} from 'primereact/toast';
import {Toast} from '@helpers';
import './Toast.scss';

function ToasterContainer() {
  const toastRef = useRef(null);
  Toast.init(toastRef.current);
  return <PrimeReactToast ref={toastRef} />;
}

export default ToasterContainer;

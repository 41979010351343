import {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {Input, CheckBox, Button} from '@ui';
import {ReactComponent as ArrowGreen} from '@assets/icons/arrow-green.svg';
import {sentAccountData} from '@actions/registration';
import {useValidation, useDispatchAsync} from '@hooks';

import {SAVE_DATA} from '../../../../store/registration/types';

import {inputsData} from './inputsData';

import './Step_3.scss';

function Step_3() {
  const dispatch = useDispatchAsync();
  const navigate = useNavigate();
  const isInputsValid = useValidation('Step_3_input');
  const [_, i18n] = useTranslation();
  const [t] = useTranslation('pages/registration', {
    keyPrefix: 'step_3',
  });
  const registrationData = useSelector(state => state.registration);
  const [errorSMS, setErrorSMS] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const [userDate, setUserDate] = useState({
    nickname: null,
    email: null,
    password: null,
    repeatpassword: null,
    userId: registrationData.userId,
    language: i18n.currentLang || 'en',
  });

  useEffect(() => {
    const newData = {};
    for (const key in userDate) {
      newData[key] = registrationData[key] ?? userDate[key];
    }
    setUserDate(newData);
  }, [registrationData]);

  useEffect(() => {
    localStorage.setItem('userLastEventViewDate', new Date());
  }, []);

  async function onClickHandler() {
    const res = await dispatch(sentAccountData(userDate), false);
    if (res.ok) {
      return navigate('/registration/Step_4');
    } else {
      setErrorSMS(t(`errors.${res.code}`, t('errors.unknown')));
    }
  }

  function onChangeHandlers(e) {
    if (e) {
      setUserDate(p => {
        p[e.target.id] = e.target.value;
        return {...p};
      });
      dispatch({type: SAVE_DATA, payload: {[e.target.id]: e.target.value}});
      if (e.target.id === 'email') {
        setErrorSMS(' ');
      }
      if (e.target.id === 'repeatpassword') {
        document
          .querySelector('#repeatpassword')
          .setAttribute('pattern', userDate.password);
      }
      if (e.target.id === 'password') {
        document
          .querySelector('#repeatpassword')
          .setAttribute('pattern', e.target.value);
      }
    }
  }
  return (
    <div className='Step_3'>
      <div className='titles'>
        <div className='main_title'>{t('main_title')}</div>
        <div className='sub_title'>{t('sub_title')}</div>
      </div>
      <div className='content'>
        <form className='data_entry'>
          {inputsData.map((item, index) => (
            <Input
              className='Step_3_input'
              type={item.type}
              pattern={item.pattern}
              key={index}
              id={item.id}
              value={userDate[item.id]}
              headerTitle={t(`inputsData.${item.id}.headerTitle`)}
              placeholder={t(`inputsData.${item.id}.placeholder`)}
              required={t(`inputsData.${item.id}.required`)}
              onChange={onChangeHandlers}
              underTitle={t(`inputsData.${item.id}.required`)}
            />
          ))}
        </form>
        <div className='errorSMS'>{errorSMS}</div>
      </div>
      <div className='privacyPolicy'>
        <CheckBox
          type='square'
          state={privacyPolicy}
          setState={state => setPrivacyPolicy(state)}
        />
        <span>
          {t('privacyPolicy.main')}&nbsp;
          <Link to='/privacy-policy' target='_blank'>
            {t('privacyPolicy.link')}
          </Link>
        </span>
      </div>
      <div className='buttons'>
        <Button
          className='greenwhite'
          to='/registration/Step_2'
          text={t('btback')}>
          <ArrowGreen />
        </Button>
        <Button
          type='submit'
          className={!privacyPolicy || !isInputsValid ? 'inactive' : ''}
          onClick={onClickHandler}
          text={t('btnext')}
        />
      </div>
    </div>
  );
}
export default Step_3;

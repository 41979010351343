import {useEffect,  useState} from 'react';
import {useTranslation} from 'react-i18next';

import {CheckBox, Select} from '@ui';

import './Interval.scss';

function Interval({
  filter,
  onChangeUnitMeasurement,
  onChangeFieldFrom,
  onChangeFieldTo,
}) {
  const [t] = useTranslation('pages/cars');
  const _data = filter.unitMeasurement ? filter.data[filter.unit] : filter.data;
  const [data, setData] = useState(_data);

  useEffect(() => {
    setData([
      {
        title: t('not_selected'),
        value: 0,
      },
      ..._data,
    ]);
  }, [_data]);

  if (data.length === 0) return <></>;
  return (
    <section className='Interval'>
      {filter.unitMeasurement && (
        <div>
          {filter.unitMeasurement.map((unitItem, index) => (
            <div key={index}>
              <CheckBox
                id={index}
                state={index === filter.unit}
                setState={() => onChangeUnitMeasurement(filter.title, index)}
              />
              {unitItem}
            </div>
          ))}
        </div>
      )}

      <section>
        <article key={0}>
          <span className='text'>{t('from')}:</span>
          <Select
            data={data}
            value={filter.from}
            onChange={e => {
              onChangeFieldFrom(filter.title, e.target.value);
            }}
          />
        </article>

        <article key={1}>
          <span className='text'>{t('to')}:</span>
          <Select
            data={data}
            value={filter.to}
            onChange={e => {
              onChangeFieldTo(filter.title, parseFloat(e.target.value));
            }}
          />
        </article>
      </section>
    </section>
  );
}
export default Interval;

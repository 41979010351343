import {useEffect, useState} from 'react';
import {Button, Input} from '@ui';
import {ReactComponent as Dagger} from '@assets/icons/dagger.svg';

function LogFilter({logFilterRef}) {
  const [inputValue, setInputValue] = useState('');
  const ls_logFilter = localStorage.getItem('logFilter');
  const [logFilter, setBlacklist] = useState(
    ls_logFilter ? JSON.parse(ls_logFilter) : []
  );

  useEffect(() => {
    logFilterRef.current = logFilter;
    localStorage.setItem('logFilter', JSON.stringify(logFilter));
  }, [logFilter]);

  function onAdd() {
    setBlacklist([inputValue, ...logFilter]);
    setInputValue('');
  }
  function onDelete(blackItem) {
    setBlacklist([...logFilter.filter(i => i !== blackItem)]);
  }

  return (
    <div className='LogFilter'>
      <div className='enter'>
        <Input
          headerTitle={'Фільтр'}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
        <Button style={{backgroundColor: 'green'}} onClick={onAdd}>
          Добавити
        </Button>
      </div>

      <div className='list'>
        {logFilter.map(item => (
          <div className='item' key={item}>
            <div>{item}</div>
            <Dagger onClick={() => onDelete(item)} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default LogFilter;

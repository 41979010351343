import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom';

import {getAgentRights} from '@actions/agent';
import {useDispatchAsync} from '@hooks';

import './AgentContainer.scss';

function AgentContainer() {
  const dispatch = useDispatchAsync();
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  const lot = useSelector(state => state.agent.lot);
  const agentRights = useSelector(state => state.agent.rights);
  const links = [
    {
      right: agentRights.add_lots_underlot,
      path: 'add_lots_underlot' + (lot ? `/${lot.id}` : ''),
      title: 'Новий лот',
    },
    {
      right: agentRights.add_lots_underlot,
      path: 'lots_underlot',
      title: 'Лоти',
    },
    {
      right: agentRights.add_lots_underlot,
      path: 'features_underlot',
      title: 'Характеристики',
    },
  ];

  useEffect(() => {
    dispatch(getAgentRights());
  }, []);

  useEffect(() => {
    if (pathname === '/org/agent') {
      return navigate(pathname + '/' + links.find(i => i.right).path, {
        replace: true,
      });
    }
  }, [location]);

  let page = '';
  if (pathname.includes('/org/agent/')) {
    page = pathname.split('/org/agent/')[1];
  }

  return (
    <div className='AgentContainer'>
      <div className='inner_container'>
        <ul className='navbar'>
          {links
            .filter(i => i.right)
            .map((it, index) => (
              <li key={index} className={page === it.path ? 'active' : ''}>
                <Link to={'/org/agent/' + it.path}>{it.title}</Link>
              </li>
            ))}
        </ul>
        <div className='bloc-info'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
export default AgentContainer;

import {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Toast} from '@helpers';
import {useDispatchAsync} from '@hooks';
import {getUserPayments} from '@actions/user';
import {ItemsLimit} from '@components';
import {PaginationComponent} from '@components';
import {Loader} from '@ui';
import {ORDER_PRODUCT_TYPE_LOT} from '@models';

import PaymentItem from './PaymentItem';

import './Payments.scss';

function Payments() {
  const dispatch = useDispatchAsync();
  const [data, setData] = useState();
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [t] = useTranslation('pages/profile', {
    keyPrefix: 'payments',
  });

  useEffect(() => {
    let isMounted = true;

    setLoading(() => true);
    dispatch(getUserPayments(location.search)).then(res => {
      if (isMounted && res.ok) {
        setData(res);
        setLoading(() => false);
      }
      if (!res.ok) {
        Toast.error(t('error_load_data'));
      }
    });

    return () => {
      isMounted = false;
    };
  }, [location]);

  if (isLoading || data === undefined) {
    return (
      <div className='PageCarsContainer'>
        <Loader />
      </div>
    );
  }

  return (
    <div className='Payments'>
      {data && data.payments && data.payments.length > 0 ? (
        <>
          <div className='SettingsPanel'>
            <ItemsLimit />
            <PaginationComponent
              data={{
                currentPage: data.currentPage,
                totalPages: data.totalPages,
              }}
            />
          </div>
          <div className='paymentsData'>
            {data.payments.map((payment, key) => (
              <div key={key}>
                {payment.product_info.product_type ===
                ORDER_PRODUCT_TYPE_LOT ? (
                    <Link
                      to={`/lot/${payment.product_info.product_id}`}
                      rel='noopener noreferrer'>
                      <PaymentItem payment={payment} key={key} />
                    </Link>
                  ) : (
                    <PaymentItem payment={payment} key={key} />
                  )}
              </div>
            ))}
          </div>

          <div className='SettingsPanel'>
            <ItemsLimit />
            <PaginationComponent
              data={{
                currentPage: data.currentPage,
                totalPages: data.totalPages,
              }}
            />
          </div>
        </>
      ) : (
        <div className='noCarsFound'>
          <h1>{t('no_found_payments')}</h1>
        </div>
      )}
    </div>
  );
}

export default Payments;

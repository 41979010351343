import {GET_BIDS_CARS, GET_FAVORITES_CARS, GET_NO_WON_CARS, GET_WON_CARS, GET_WON_WITH_CAVEAT_CARS} from './types';

export const getTrackedCars = search => ({
  event: GET_FAVORITES_CARS,
  link: '/api/user/favorites' + search,
});

export const getBidsCars = search => ({
  event: GET_BIDS_CARS,
  link: '/api/user/bids' + search,
});

export const getWonCars = search => ({
  event: GET_WON_CARS,
  link: '/api/user/bids/won-cars' + search,
});

export const getNotWonCars = search => ({
  event: GET_NO_WON_CARS,
  link: '/api/user/bids/no-won-cars' + search,
});

export const getWonWithCaveatCars = search => ({
  event: GET_WON_WITH_CAVEAT_CARS,
  link: '/api/user/bids/won-with-caveat-cars' + search,
});

import Load from '@assets/gif/Subtract.png';

import './Loader.scss';

function Loader({msg = ''}) {
  return (
    <div className='Loader'>
      {msg ? <p>{msg}</p> : null}
      <img src={Load} alt='loader gif' />
    </div>
  );
}

export default Loader;

import {useEffect} from 'react';
import {useOutletContext} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {
  createExportedFeature,
  createValueStructureExportedFeatures,
  deleteExportedFeature,
  deleteValueStructureExportedFeatures,
  getExportedFeatures,
  updateExportedFeature,
  updateValueStructureExportedFeatures,
} from '@actions/admin';

import {Toast} from '@helpers';
import {useDispatchAsync} from '@hooks';

import Table from '../../../../2-components/table/Table';
import checkRequiredFields from '../../../../modules/checkRequiredFields';
import useUpdateData from '../../../../hooks/update-data.hook';

import {config} from './config';

function ExportedFeatures() {
  const api = useUpdateData();
  const dispatch = useDispatchAsync();
  const {synchronize} = useOutletContext();

  const exportedFeatures = useSelector(state => state.admin.exportedFeatures);

  useEffect(() => {
    config.onSave = async ({next, fieldMustToUpdated}) => {
      for (const {field, value} of fieldMustToUpdated) {
        await api(updateExportedFeature(next.id, field, value));
      }
    };
    config.onCreate = async (idRow, newProduct) => {
      const res = checkRequiredFields(config.fields, newProduct);
      if (!res) {
        return await api(createExportedFeature(newProduct));
      } else {
        Toast.warning('Заповніть обов`язкове поле для введення - ' + res);
      }
      return false;
    };
    config.onDelete = async selectedProduct => {
      await api(deleteExportedFeature(selectedProduct.id));
    };

    config.fields.valueStructure.onSave = async ({
      idRow, prev, fieldMustToUpdated,
    }) => {
      for (const {field, value} of fieldMustToUpdated) {
        await api(
          updateValueStructureExportedFeatures(idRow, prev.id, field, value)
        );
      }
    };

    config.fields.valueStructure.onCreate = async (idRow, newProduct) => {
      const res = checkRequiredFields(
        config.fields.valueStructure.fields,
        newProduct
      );
      if (!res) {
        return await api(
          createValueStructureExportedFeatures(idRow, newProduct)
        );
      } else {
        Toast.warning('Заповніть обов`язкове поле для введення - ' + res);
      }
      return false;
    };
    config.fields.valueStructure.onDelete = async (selectedProduct, idRow) => {
      await api(deleteValueStructureExportedFeatures(idRow, selectedProduct));
    };
    config.onLoad = async () => {
      await dispatch(getExportedFeatures());
    };
    config.fields.valueStructure.onLoad = async () => {
      await dispatch(getExportedFeatures());
    };
  });

  return (
    <div className='ExportedFeatures'>
      <Table
        config={config}
        data={exportedFeatures}
        synchronize={synchronize}
      />
    </div>
  );
}
export default ExportedFeatures;

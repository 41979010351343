import {useTranslation} from 'react-i18next';

import {DropDownList} from '@ui';

import './SelectCountry.scss';

const CODESCountry = [
  'at',
  'al',
  'ad',
  'be',
  'by',
  'bg',
  'ba',
  'va',
  'gb',
  'gr',
  'dk',
  'ee',
  'ie',
  'is',
  'es',
  'it',
  'xk',
  'lv',
  'lt',
  'li',
  'lu',
  'mk',
  'mt',
  'md',
  'mc',
  'nl',
  'de',
  'no',
  'pl',
  'pt',
  'ro',
  'sm',
  'rs',
  'sk',
  'si',
  'ua',
  'hu',
  'fi',
  'fr',
  'hr',
  'cz',
  'me',
  'ch',
  'se',
];

const UA = 'ua';

function SelectCountry({headerTitle, value, onChange}) {
  const [tR] = useTranslation('common');

  const listCountry = tR('listCountry', {returnObjects: true});

  return (
    <div className='SelectCountry'>
      <DropDownList
        value={{title: listCountry[value || UA], key: value || UA}}
        onChange={e => onChange(e.value.key)}
        options={CODESCountry.map(county => ({
          title: listCountry[county],
          key: county,
        }))}
        optionLabel='title'
        headerTitle={headerTitle}
      />
    </div>
  );
}
export default SelectCountry;

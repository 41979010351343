import {useState} from 'react';
import {MultiSelect as MultiSelectPrime} from 'primereact/multiselect';

import './MultiSelect.scss';

function MultiSelect({
  headerTitle,
  value,
  onChange,
  options,
  optionLabel = 'name',
}) {
  const [selectedValues, setSelectedValues] = useState(value);

  return (
    <div className='MultiSelect'>
      {headerTitle ? <div className='headerTitle'>{headerTitle}</div> : null}

      <MultiSelectPrime
        value={selectedValues}
        onChange={e => {
          setSelectedValues(e.value);
          onChange(e.value);
        }}
        options={options}
        optionLabel={optionLabel}
        display='chip'
        filter
        placeholder=' '
        className='w-full md:w-20rem'
      />
    </div>
  );
}
export default MultiSelect;

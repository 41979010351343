import {GET_AUCTIONS_INFO, GET_AUCTION_DATA} from './types';

export const getAuctionsInfo = () => ({
  event: GET_AUCTIONS_INFO,
  link: '/api/info/auctions',
});

export const getAuctionData = id => ({
  event: GET_AUCTION_DATA,
  link: `/api/info/auction/${id}`,
});

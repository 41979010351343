import {Link} from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';

import {getVFF} from '../../../modules/getValueFomField';


function UserLink(field) {
  return rowData => {
    const value = getVFF(rowData, field);
    if (!value) return <></>;
    return (
      <Link
        to={'/org/admin/moderation?search=' + rowData.userId}
        style={{
          lineHeight: 1.4,
          color: 'rgb(49 153 118)',
          textDecoration: 'underline',
        }}
        target={'_blank'}>
        {ReactHtmlParser(value)}
      </Link>
    );
  };
}
export default UserLink;

import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {adminLogout} from '@actions/admin';
import {useDispatchAsync} from '@hooks';

function GoOut() {
  const dispatch = useDispatchAsync();
  const navigate = useNavigate();
  useEffect(async () => {
    dispatch(adminLogout());
    return navigate('/org/admin/auth', {replace: true});
  });
  return <></>;
}
export default GoOut;

import React from 'react';

import TopSection from './TopSection/TopSection';
import BottomSection from './BottomSection/BottomSection';
import './HomePage.scss';


function HomePage() {
  return (
    <div className='HomePage'>
      <TopSection />
      <BottomSection />
    </div>
  );
}

export default HomePage;

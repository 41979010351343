//
export const SEND_VERIFICATION_CODE = 'SEND_VERIFICATION_CODE';
export const SEND_VERIFICATION_CODE_SUCCESS = 'SEND_VERIFICATION_CODE_SUCCESS';
export const SEND_VERIFICATION_CODE_FAILURE = 'SEND_VERIFICATION_CODE_FAILURE';
//
export const CHECK_CODE = 'CHECK_CODE';
export const CHECK_CODE_SUCCESS = 'CHECK_CODE_SUCCESS';
export const CHECK_CODE_FAILURE = 'CHECK_CODE_FAILURE';
//
export const SEND_ACCOUNT_DATA = 'SEND_ACCOUNT_DATA';
export const SEND_ACCOUNT_DATA_SUCCESS = 'SEND_ACCOUNT_DATA_SUCCESS';
export const SEND_ACCOUNT_DATA_FAILURE = 'SEND_ACCOUNT_DATA_FAILURE';
//
export const CLEAN_ALL_REGISTRATION_DATA = 'CLEAN_ALL_DATA';

export const COMPLETE_REGISTRATION = 'COMPLETE_REGISTRATION';
export const COMPLETE_REGISTRATION_SUCCESS = 'COMPLETE_REGISTRATION_SUCCESS';
export const COMPLETE_REGISTRATION_FAILURE = 'COMPLETE_REGISTRATION_FAILURE';


export const SAVE_DATA = 'SAVE_DATA';

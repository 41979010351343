import Editor from './Editor';
import PaymentStatus from './PaymentStatus';
import TextEditor from './TextEditor';
import ListStatus from './ListStatus';

function buildEditor(props, isForce = false) {
  const {config, field} = props;
  const col = config.fields[field];

  if (!col?.type || (isForce === false && col?.modifier !== 'public')) return;
  switch (col?.type) {
    case 'list':
      return Editor(col.editorData);
    case 'price':
    case 'number':
      return TextEditor('number');
    case 'listStatus':
      return ListStatus(col.editorData);
    case 'paymentStatus':
      return PaymentStatus(col.editorData);
    default:
      return TextEditor(col.type);
  }
}
export default buildEditor;

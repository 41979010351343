import {useTranslation} from 'react-i18next';

import GeneratePage from '../../generate-page/GeneratePage';
import './GeneralTermsUse.scss';

function GeneralTermsUse() {
  const [t] = useTranslation('pages/introduction/general_terms_of_use');
  const tree = {
    0: [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
    1: [1, 2, 3, 4],
    2: [1, 2, 3, 4, 5, 6],
    3: [1, 2, 3, 4, 5, 6, 7, 8, {9: [1]}],
    4: [1, 2],
    5: [
      1,
      2,
      3,
      4,
      5,
      6,
      {7: [1, 2, 3]},
      {8: [1, 2, 3, 4]},
      {9: [1, 2]},
      {10: [1, 2, 3, 4, 5]},
    ],
    6: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    7: [1, 2, 3, 4, 5, 6],
    8: [1, 2, 3, 4, 5, 6],
    9: [1, 2, 3],
    10: [1, 2, 3, 4, 5, 6, 7],
    11: [1, 2],
    12: [1, 2, 3, 4],
    13: [1, 2, 3, 4],
    14: [1, 2, 3, 4, 5, 6],
  };

  const inserts = {
    link_underlot: (
      <a
        className='link_underlot'
        href='https://www.underlot.com/'
        target='_blank'
        rel='noreferrer'>
        www.underlot.com
      </a>
    ),
    'support@underlot': (
      <a
        className='support_underlot'
        href='mailto:support@underlot.com'
        target='_blank' rel='noreferrer'>
        support@underlot.com
      </a>
    ),
    bold: '<span className="bold">',
    '/bold': '</span>',
    'icon-for-downloading': (
      <a
        href={`./locales/${localStorage.getItem(
          'i18nextLng'
        )}/static-files/Contract-of-vehicle-sale.docx`}
        download='Contract of vehicle sale.docx'>
        <span className='icon-for-downloading'></span>
      </a>
    ),
  };

  return (
    <GeneratePage Name='GeneralTermsUse' tree={tree} inserts={inserts} t={t} />
  );
}
export default GeneralTermsUse;

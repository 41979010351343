import {useRef, useState} from 'react';

import {ReactComponent as EyeOpen} from '@assets/icons/eye-open.svg';
import {ReactComponent as EyeClosed} from '@assets/icons/eye-closed.svg';
import {ReactComponent as Dagger} from '@assets/icons/dagger.svg';

import './Input.scss';

function Input({
  id = undefined,
  icon,
  type = 'text',
  value,
  pattern,

  autocomplete = 'new-password',

  placeholder,
  headerTitle,
  underTitle = '',

  className,
  classNameUnderTitle,

  onChange,
  onClick,
}) {
  const [isOpenEye, setIsOpenEye] = useState(false);
  const input = useRef();

  function getType() {
    if (type === 'password') {
      return !isOpenEye ? 'password' : 'text';
    }
    return type;
  }

  function isValidDate(date) {
    // An invalid date object returns NaN for getTime() and NaN is the only
    // object not strictly equal to itself.
    return date.getTime() === date.getTime(); // eslint-disable-line
  }
  return (
    <div className='Input'>
      {headerTitle ? <div className='headerTitle'>{headerTitle}</div> : null}

      <div className='inputBox'>
        {icon ? <img className='icon' src={icon} alt='' /> : null}

        <input
          style={{
            paddingLeft: `${icon ? '25px' : '0px'}`,
            paddingRight: `${type === 'password' ? '50px' : '0px'}`,
          }}
          name={type}
          autoComplete={autocomplete}
          ref={input}
          className={className}
          id={id}
          placeholder={placeholder}
          type={getType()}
          pattern={pattern?.source}
          value={
            (type === 'date' && value
              ? isValidDate(new Date(value))
                ? new Date(value).toISOString().substr(0, 10)
                : value
              : value) ?? ''
          }
          onChange={onChange}
          onClick={onClick}
        />
        {underTitle ? (
          <div className={`under_input_title ${classNameUnderTitle}`}>
            {underTitle}
          </div>
        ) : null}

        {type === 'password' ? (
          <div className='btns'>
            <div className='eye' onClick={() => setIsOpenEye(prev => !prev)}>
              {isOpenEye ? <EyeOpen /> : <EyeClosed />}
            </div>
            <div
              className='clear'
              onClick={() => {
                input.current.value = '';
              }}>
              <Dagger />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default Input;

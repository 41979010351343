import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Galleria} from '@components';

import {
  LOT_STATUS_FINISHED_NOT_SOLD,
  LOT_STATUS_FINISHED_RESERVED,
  LOT_STATUS_FINISHED_SOLD,
} from '@models';

import 'react-photo-view/dist/react-photo-view.css';
import '../../../2-components/result-line/ResultLine.scss';
import './LotGalleria.scss';

function LotGalleria() {
  const [t] = useTranslation('pages/lot');

  const lot = useSelector(state => state.lot);
  const images = useSelector(state => state.lot.images).map((el, index) => ({
    ...el,
    id: index,
  }));

  useEffect(() => {
    const el = document.querySelector('.p-galleria-item');
    const ResultLine = document.createElement('div');
    ResultLine.setAttribute('class', 'ResultLineBloc');
    const div = document.createElement('div');
    div.setAttribute('class', 'ResultLine');
    const span = document.createElement('span');
    span.setAttribute('class', 'ResultLineSpan');
    div.appendChild(span);
    ResultLine.appendChild(div);
    el?.appendChild(ResultLine);
  }, []);

  useEffect(() => {
    if (lot.lotStatus === null) return;
    const el = document.querySelector('.ResultLineSpan');
    if (!el) return;

    el.innerText = '';
    switch (lot.lotStatus) {
      case LOT_STATUS_FINISHED_SOLD:
        el.innerText = t('sold_out');
        break;
      case LOT_STATUS_FINISHED_NOT_SOLD:
        el.innerText = t('not_sold');
        break;
      case LOT_STATUS_FINISHED_RESERVED:
        el.innerText = t('sold_with_reservation');
        break;
      default:
        break;
    }
  }, [lot.lotStatus]);

  function determineStatus() {
    if (lot.lotStatus === null) return;
    switch (lot.lotStatus) {
      case LOT_STATUS_FINISHED_SOLD:
        return 'sold';
      case LOT_STATUS_FINISHED_NOT_SOLD:
        return 'not-sold';
      case LOT_STATUS_FINISHED_RESERVED:
        return 'reserved';
      default:
        break;
    }
  }

  return (
    <div className='LotGalleria'>
      <Galleria className={determineStatus()} images={images} />
    </div>
  );
}
export default LotGalleria;

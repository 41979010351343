import {
  ACCOUNT_TARIFF_1000,
  ACCOUNT_TARIFF_300,
  ACCOUNT_TARIFF_500,
  ACCOUNT_TARIFF_INDIVIDUAL,
  ACCOUNT_TARIFF_PREMIUM,
} from '@helpers/models';

export const config = {
  header: 'Moderation',
  type: 'table',
  fields: {
    info: {
      header: 'Інформація',
      type: 'htmlText',
    },
    contacts: {
      header: 'Контакти',
      type: 'htmlText',
    },

    accountData: {
      header: 'Дані аккаунта',
      type: 'table',
      fields: {
        id: {
          header: 'ID',
        },
        email: {
          header: 'Email',
        },
        type: {
          header: 'Тип аккаунта',
          type: 'list',
          editorData: [
            {indexInDB: 0, title: 'Персональний', severity: 'info'},
            {indexInDB: 1, title: 'Компанія з України', severity: 'success'},
            {indexInDB: 2, title: 'Компанія Європи', severity: 'danger'},
          ],
        },
        status: {
          header: 'Статус аккаунта',
          modifier: 'public',
          type: 'list',
          editorData: [
            {indexInDB: 0, title: 'Пустий', severity: 'danger'},
            {indexInDB: 1, title: 'Новий', severity: 'info'},
            {indexInDB: 2, title: 'Верифікований', severity: 'success'},
          ],
        },

        nickname: {
          header: 'Нікнейм',
        },
        phone: {
          header: 'Телефон',
        },
        lang: {
          header: 'Мова',
          type: 'list',
          editorData: [
            {indexInDB: 'uk', title: 'Українська', severity: 'info'},
            {indexInDB: 'en', title: 'English', severity: 'info'},
            {indexInDB: 'pl', title: 'Polski', severity: 'info'},
            {indexInDB: 'ru', title: 'російська', severity: 'info'},
          ],
        },
      },
    },

    adminRights: {
      header: 'Права Адміна',
      type: 'table',
      fields: {
        moderation: {
          header: 'Право модерації',
          modifier: 'public',
          type: 'list',
          editorData: [
            {indexInDB: true, title: 'Так', severity: 'info'},
            {indexInDB: false, title: 'Ні', severity: 'danger'},
          ],
        },
        moderation_of_documents: {
          header: 'Право модерації документів',
          modifier: 'public',
          type: 'list',
          editorData: [
            {indexInDB: true, title: 'Так', severity: 'info'},
            {indexInDB: false, title: 'Ні', severity: 'danger'},
          ],
        },
        events: {
          header: 'Право переглянути подій',
          modifier: 'public',
          type: 'list',
          editorData: [
            {indexInDB: true, title: 'Так', severity: 'info'},
            {indexInDB: false, title: 'Ні', severity: 'danger'},
          ],
        },
        features: {
          header: 'Право синхронізувати характеристики',
          modifier: 'public',
          type: 'list',
          editorData: [
            {indexInDB: true, title: 'Так', severity: 'info'},
            {indexInDB: false, title: 'Ні', severity: 'danger'},
          ],
        },
        invoices: {
          header: 'Право виставляти invoices',
          modifier: 'public',
          type: 'list',
          editorData: [
            {indexInDB: true, title: 'Так', severity: 'info'},
            {indexInDB: false, title: 'Ні', severity: 'danger'},
          ],
        },
        payments: {
          header: 'Право управляти Оплатами',
          modifier: 'public',
          type: 'list',
          editorData: [
            {indexInDB: true, title: 'Так', severity: 'info'},
            {indexInDB: false, title: 'Ні', severity: 'danger'},
          ],
        },
        debug: {
          header: 'Право Розробника',
          modifier: 'public',
          type: 'list',
          editorData: [
            {indexInDB: true, title: 'Так', severity: 'info'},
            {indexInDB: false, title: 'Ні', severity: 'danger'},
          ],
        },
      },
    },
    agentRights: {
      header: 'Права Агента',
      type: 'table',
      fields: {
        add_lots_underlot: {
          header: 'Право додавання лотів',
          modifier: 'public',
          type: 'list',
          editorData: [
            {indexInDB: true, title: 'Так', severity: 'info'},
            {indexInDB: false, title: 'Ні', severity: 'danger'},
          ],
        },
      },
    },
    bidsSettings: {
      header: 'Налаштування ставок',
      type: 'table',
      fields: {
        tariff: {
          header: 'Тариф',
          type: 'list',
          editorData: [
            {indexInDB: null, title: 'Немає', severity: 'danger'},
            {indexInDB: undefined, title: 'Немає', severity: 'danger'},

            {
              indexInDB: ACCOUNT_TARIFF_300,
              title: 'Тариф 300',
              severity: 'info',
            },
            {
              indexInDB: ACCOUNT_TARIFF_500,
              title: 'Тариф 500',
              severity: 'info',
            },
            {
              indexInDB: ACCOUNT_TARIFF_1000,
              title: 'Тариф 1000',
              severity: 'info',
            },
            {
              indexInDB: ACCOUNT_TARIFF_PREMIUM,
              title: 'Тариф Преміум',
              severity: 'info',
            },
            {
              indexInDB: ACCOUNT_TARIFF_INDIVIDUAL,
              title: 'Тариф Індивідуальний',
              severity: 'info',
            },
          ],
        },
        bidAllowed: {
          header: 'Дозвіл на ставки',
          modifier: 'public',
          type: 'list',
          editorData: [
            {indexInDB: true, title: 'Так', severity: 'info'},
            {indexInDB: false, title: 'Ні', severity: 'danger'},
          ],
        },
        lotCountLimit: {
          modifier: 'public',
          header: 'Ліміт активних аукціонів',
          type: 'number',
        },
        lotPriceLimit: {
          modifier: 'public',
          header: 'Ліміт на авто',
          type: 'price',
        },
        lotPriceReserve: {
          modifier: 'public',
          type: 'price',
          header: 'Кошти в резерві',
        },
      },
    },
    verificationRights: {
      header: 'Верифікація',
      type: 'table',
      fields: {
        document: {
          header: 'Документи верифіковані',
          modifier: 'public',
          type: 'list',
          editorData: [
            {indexInDB: true, title: 'Так', severity: 'info'},
            {indexInDB: false, title: 'Ні', severity: 'danger'},
          ],
        },
        contract: {
          header: 'Договір підписано',
          modifier: 'public',
          type: 'list',
          editorData: [
            {indexInDB: true, title: 'Так', severity: 'info'},
            {indexInDB: false, title: 'Ні', severity: 'danger'},
          ],
        },
        tariff: {
          header: 'Тариф оплачено',
          modifier: 'public',
          type: 'list',
          editorData: [
            {indexInDB: true, title: 'Так', severity: 'info'},
            {indexInDB: false, title: 'Ні', severity: 'danger'},
          ],
        },
      },
    },
    additionalData: {
      header: 'Верифікаційні дані',
      type: 'table',
      fields: {
        country: {
          header: 'Країна',
          type: 'list',
          editorData: [],
        },
        address: {
          header: 'Адреса',
        },
        ZIPCode: {
          header: 'ZIP Code',
        },

        last_name: {
          header: 'Прізвище',
        },
        first_name: {
          header: 'Імʼя',
        },
        birthday: {
          header: 'День народження',
          type: 'date',
        },
        identification_number: {
          header: 'Ідентифікаційний номер',
        },
        passport_no: {
          header: 'Номер паспорта',
        },
        passport_date: {
          header: 'Дата видачі паспорта',
          type: 'date',
        },
        passport_issued_by: {
          header: 'Ким видано паспорт',
        },

        companyName: {
          header: 'Назва компанії',
        },
        NIPCompany: {
          header: 'NIP',
        },
        VATCompany: {
          header: 'VAT',
        },
        IPNCompany: {
          header: 'IPN',
        },
        CodeEDRPOYCompany: {
          header: 'Code EDRPOY',
        },
      },
    },

    createdAt: {
      header: 'Дата створення',
      type: 'date',
    },
  },
};

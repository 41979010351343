import {getTrackedCars} from '@actions/my-cars';

import PageCarsContainer from '../container/page-cars-container/PageCarsContainer';

import './TrackedCars.scss';

function TrackedCars() {
  return (
    <div className='TrackedCars'>
      <PageCarsContainer keyToData={'trackedCars'} getData={getTrackedCars} />
    </div>
  );
}
export default TrackedCars;

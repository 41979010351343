import React, {useEffect, useState} from 'react';
import {Link, Outlet, useLocation} from 'react-router-dom';
import './GeneralPages.scss';
import {useTranslation} from 'react-i18next';

function GeneralPages() {
  const location = useLocation();
  const [activePage, setActivePage] = useState(0);

  const [t] = useTranslation('pages/introduction/general');

  const [links, _] = useState([
    {
      path: '/info-company',
      title: 'info-company.title',
    },
    {
      path: '/rules',
      title: 'rules.title',
    },
    {
      path: '/info-about-auction',
      title: 'info-about-auction.title',
    },
    {
      path: '/general-terms-of-use',
      title: 'general-terms-of-use.title',
      shortTitle: 'general-terms-of-use.short-title',
    },
    {
      path: '/privacy-policy',
      title: 'privacy-policy.title',
    },
    {
      path: '/consideration-claims',
      title: 'consideration-claims.title',
    },
    {
      path: '/contacts',
      title: 'contacts.title',
    },
  ]);

  useEffect(() => {
    setActivePage(links.findIndex(link => link.path === location.pathname));
  }, [location]);

  return (
    <div className='GeneralPages'>
      <div className='container'>
        <ul className='navbar'>
          {links.map((it, index) => (
            <li key={index} className={it.path === location.pathname ? 'active' : ''}>
              <Link to={it.path}>{t(it.shortTitle || it.title)}</Link>
            </li>
          ))}
        </ul>
        <div className='bloc-info'>
          <h1>{t(links[activePage].title)}</h1>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
export default GeneralPages;

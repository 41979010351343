import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import {Loader, ToasterContainer} from '@ui';
import {useDispatchAsync} from '@hooks';
import {getAdminRights} from '@actions/admin';
import {getAgentRights} from '@actions/agent';
import {getIsTechnicalWorks} from '@actions/home';

import './OrgContainer.scss';

function OrgContainer() {
  const location = useLocation();
  const navigate = useNavigate();
  const [role, setRole] = useState();
  useEffect(() => {
    if (location.pathname.includes('/org/admin')) {
      setRole('admin');
    }
    if (location.pathname.includes('/org/agent')) {
      setRole('agent');
    }
  }, []);
  const dispatch = useDispatchAsync(role);

  const [isLoading, setLoading] = useState(false);
  const adminToken = useSelector(state => state.admin.token);
  const agentToken = useSelector(state => state.agent.token);

  useEffect(() => {
    if (location.pathname === '/org') {
      setRole('admin');
      return navigate('/org/admin/auth', {replace: true});
    }
  }, [location]);

  useEffect(async () => {
    getRights();
  }, []);

  useEffect(async () => {
    getRights();
  }, [role]);

  useEffect(() => {
    dispatch(getIsTechnicalWorks());
  }, []);

  async function getRights() {
    if (role === 'admin' && adminToken) {
      await setLoading(() => true);
      await dispatch(getAdminRights());
      await setLoading(() => false);
    }
    if (role === 'agent' && agentToken) {
      await setLoading(() => true);
      await dispatch(getAgentRights());
      await setLoading(() => false);
    }
  }

  return (
    <div className='OrgContainer'>
      {!isLoading ? <Outlet /> : <Loader />}
      <ToasterContainer />
    </div>
  );
}
export default OrgContainer;

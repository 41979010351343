import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';


import {useDispatchAsync} from '@hooks';
import {agentLogin, getAgentRights} from '@actions/agent';
import {AuthenticationForm} from '@components';

import './Authentication.scss';

function Authentication() {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatchAsync();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(state => state.agent.isAuthenticated);

  useEffect(() => {
    i18n.setLanguage('uk');
    if (isAuthenticated === true) {
      redirect();
    }
  }, []);

  async function onLogin(login, password) {
    const res = await dispatch(agentLogin(login, password));
    if (res.ok) {
      redirect();
    }
  }
  async function redirect() {
    const res = await dispatch(getAgentRights());
    if (res.ok) {
      return navigate('/org/agent', {replace: true});
    }
  }

  return (
    <AuthenticationForm
      header={'Вхід для Агента'}
      onLogin={onLogin}
      style={{alignItems: 'center'}}
    />
  );
}
export default Authentication;

import {Tag} from 'primereact/tag';

import {getVFF} from '../../../modules/getValueFomField';

function TagField(col, field) {
  const editorData = col?.editorData;
  return rowData => {
    const _data = getVFF(rowData, field);
    const tag = editorData.find(i => i.indexInDB === _data);
    if (!tag) {
      if (col.default) {
        return <Tag value={_data} severity={col.default.severity} />;
      }
      return;
    }
    return <Tag value={tag?.title} severity={tag?.severity} />;
  };
}
export default TagField;

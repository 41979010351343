import {useSelector} from 'react-redux';
import {Loader} from '@ui/index';

import FeatureElement from './FeatureElement';

function OtherOptions({data, setData}) {
  const underFeatures = useSelector(state => state.agent.underFeatures);
  const filterSourceFeatureIds = useSelector(
    state => state.agent.filterSourceFeatureIds
  );
  if (!underFeatures || !filterSourceFeatureIds) return <Loader />;

  const groupsOptions = Object.groupBy(
    underFeatures,
    ({sourceType}) => sourceType
  );

  const result = [];
  for (let groupOptionKey in groupsOptions) {
    groupOptionKey = parseInt(groupOptionKey);
    const group = groupsOptions[groupOptionKey];
    result.push(
      <div className='group'>
        <div>
          {group
            .filter(el => !filterSourceFeatureIds.includes(el.id))
            .sort((a, b) => a.valueType - b.valueType)
            .map(featureData => (
              <FeatureElement
                feature={featureData}
                value={
                  data.features.find(el => el.featureId === featureData.id)
                    ?.value
                }
                onChange={value => {
                  setData(prev => ({
                    ...prev,
                    features: [
                      ...prev.features.filter(
                        el => el.featureId !== featureData.id
                      ),
                      {
                        featureId: featureData.id,
                        value: value.toString(),
                      },
                    ],
                  }));
                }}
              />
            ))}
        </div>
      </div>
    );
  }

  return <div className='OtherOptions'>{result}</div>;
}

export default OtherOptions;

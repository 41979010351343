import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useDispatchAsync} from '@hooks';
import {Loader} from '@ui';

import Toast from '@helpers/toast';

import CarsContainer from '../cars-container/CarsContainer';

import './PageCarsContainer.scss';

function PageCarsContainer({keyToData, getData}) {
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatchAsync();
  const data = useSelector(state => state.myCars[keyToData]);
  const [t] = useTranslation('pages/my-cars');

  useEffect(() => {
    let isMounted = true;

    setLoading(() => true);
    dispatch(getData(location.search)).then(res => {
      if (isMounted) {
        setLoading(() => false);
      }
      if (!res.ok) {
        Toast.error(t('error_loading_data'));
      }
    });

    return () => {
      isMounted = false;
    };
  }, [location, t]);

  return (
    <div className='PageCarsContainer'>
      {isLoading || data === undefined ? (
        <div className='PageCarsContainer'>
          <Loader />
        </div>
      ) : (
        <CarsContainer lotsData={data} />
      )}
    </div>
  );
}
export default PageCarsContainer;

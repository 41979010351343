import {useTranslation} from 'react-i18next';

import {ShadowContainer} from '@containers';
import {UploadDocuments, TableDocuments} from '@components';
import {useDispatchAsync} from '@hooks';
import {loadDocuments} from '@actions/user';

import './Documents.scss';

function Documents() {
  const dispatch = useDispatchAsync();
  const [t] = useTranslation('pages/profile', {
    keyPrefix: 'documents',
  });

  return (
    <div className='Documents'>
      <ShadowContainer>
        <div className='title'>{t('title')}</div>
        <div className='text'>{t('text')}</div>
        <UploadDocuments
          onUploadDocument={() => {
            dispatch(loadDocuments());
          }}
        />
        <TableDocuments />
      </ShadowContainer>
    </div>
  );
}
export default Documents;

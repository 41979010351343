import {
  LOT_CATEGORY_CAR,
  LOT_CATEGORY_STUFF,
  LOT_PRICE_TYPE_GROSS,
  LOT_PRICE_TYPE_NET,
} from '@helpers/models';

export const config = {
  header: 'Lots',
  type: 'table',
  fields: {
    id: {
      header: 'Id',
      type: 'number',
    },
    name: {
      type: 'text',
      header: "Ім'я",
      modifier: 'public',
    },
    VIN: {
      type: 'text',
      header: 'VIN',
      modifier: 'public',
    },
    category: {
      type: 'list',
      header: 'Категорія',
      modifier: 'public',
      editorData: [
        {indexInDB: LOT_CATEGORY_CAR, title: 'Авто', severity: 'info'},
        {indexInDB: LOT_CATEGORY_STUFF, title: 'Речі', severity: 'info'},
      ],
    },
    'prices.priceType': {
      type: 'list',
      header: 'Тип ціни',
      modifier: 'public',
      editorData: [
        {indexInDB: LOT_PRICE_TYPE_NET, title: 'Нетто', severity: 'info'},
        {indexInDB: LOT_PRICE_TYPE_GROSS, title: 'Брутто', severity: 'info'},
      ],
    },
    'prices.ownerPrice': {
      type: 'price',
      header: 'Ціна від власника',
      modifier: 'public',
    },
    'prices.minimalPrice': {
      type: 'price',
      header: 'Початкова ціна',
      modifier: 'public',
    },

    createdAt: {
      header: 'Створено',
      type: 'date',
    },
  },
};

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {ShadowContainer} from '@containers';
import {getUserInfo} from '@actions/user';
import {Input, Loader, Button} from '@ui';
import {Logger} from '@helpers';
import {timeToString} from '@utils';
import {useDispatchAsync} from '@hooks';
import {TypeAccount} from '@components';
import {
  ACCOUNT_TYPE_COMPANY_EU,
  ACCOUNT_TYPE_COMPANY_UA,
  ACCOUNT_TYPE_PERSONAL,
} from '@models';

import './PersonalInformation.scss';

function PersonalInformation() {
  const dispatch = useDispatchAsync();
  const [t] = useTranslation('pages/profile', {
    keyPrefix: 'personal-information',
  });
  const [tR] = useTranslation('common');

  const {nickname, userInfo, addressInfo, companyInfoUA, companyInfoEU, type} =
    useSelector(state => state.user);
  const [electType, setElectType] = useState(type);

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  const addressInfoInputs = [
    {
      id: 'country',
      value: tR('listCountry.' + addressInfo?.country),
    },
    {
      id: 'address',
      value: addressInfo?.address,
    },
    {
      id: 'ZIPCode',
      value: addressInfo?.ZIPCode,
    },
  ];

  let inputsKeys = [];
  let inputsData = [];

  switch (type) {
    case ACCOUNT_TYPE_PERSONAL:
      inputsKeys = [
        'first_name',
        'last_name',
        'passport_no',
        'passport_date',
        'passport_issued_by',
        'identification_number',
        'birthday',
      ];
      inputsData = {
        ...userInfo,
        birthday: timeToString(userInfo.birthday),
        passport_date: timeToString(userInfo.passport_date),
      };
      break;
    case ACCOUNT_TYPE_COMPANY_UA:
      inputsKeys = ['companyName', 'IPNCompany', 'CodeEDRPOYCompany'];
      inputsData = companyInfoUA;
      break;
    case ACCOUNT_TYPE_COMPANY_EU:
      inputsKeys = ['companyName', 'NIPCompany', 'VATCompany'];
      inputsData = companyInfoEU;
      break;
    default:
      Logger.error(`Some unknown user type: ${type}`);
      break;
  }

  function onChangeHandlers(e) {
    e.target.blur();
  }
  if (!inputsData) return <Loader />;
  return (
    <div className='PersonalInformation'>
      <ShadowContainer className='previewData'>
        <div>
          <div className='name'>
            {type === ACCOUNT_TYPE_PERSONAL
              ? userInfo.last_name + ' ' + userInfo.first_name
              : type === ACCOUNT_TYPE_COMPANY_UA
                ? companyInfoUA.companyName
                : type === ACCOUNT_TYPE_COMPANY_EU
                  ? companyInfoEU.companyName
                  : null}
          </div>
          <div>{nickname}</div>
        </div>
        <Button text={t('message')} />
      </ShadowContainer>
      <ShadowContainer className='personalDate'>
        <div className='title'>{t('personalDate.title')}</div>
        <div className='typeAccountbloc'>
          <div className='typeTitle'>{t('personalDate.typeTitle')}</div>
          <TypeAccount
            electType={
              electType === ACCOUNT_TYPE_PERSONAL ? 'personal' : 'company'
            }
            setElectType={setElectType}
            exclude={true}
          />
        </div>
        <div className='inputs'>
          {inputsKeys.map((key, index) => (
            <Input
              key={index}
              value={inputsData[key]}
              headerTitle={t(`inputsData.${key}.headerTitle`)}
              onChange={() => {}}
              onClick={onChangeHandlers}
            />
          ))}
        </div>
      </ShadowContainer>

      <ShadowContainer className='addressInfo'>
        <div className='title'>{t('addressInfo.title')}</div>
        <div className='inputs'>
          {addressInfoInputs.map((item, index) => (
            <Input
              key={index}
              headerTitle={t(`headerTitles.${item.id}`)}
              value={item.value}
              onChange={() => {}}
              onClick={onChangeHandlers}
            />
          ))}
        </div>
      </ShadowContainer>
    </div>
  );
}

export default PersonalInformation;

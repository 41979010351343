import {useTranslation} from 'react-i18next';
import './Contacts.scss';

function Contacts() {
  const [t] = useTranslation('pages/introduction/contacts');
  return (
    <div className='Contacts'>
      <h3>{t('contact_information')}</h3>
      <div>
        <p>UNDER INC. Sp z o.o.</p>
      </div>

      <div>
        <h4>{t('phones')}:</h4>
        <p>+48 662 533 889, +380 67 765 2858</p>
      </div>
      <div>
        <h4>Email:</h4>
        <p>support@underlot.com</p>
      </div>
      <div>
        <h4>{t('address')}:</h4>
        <p>
          20-029, ul. Marii Curie-Skłodowskiej 3/27, Lublin, Lubelskie, Polska
        </p>
      </div>
    </div>
  );
}

export default Contacts;

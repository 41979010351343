import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {LotMiniGalleria, Tags, Note} from '@components';
import {ShadowContainer} from '@containers';
import {AddToFavoriteBtn} from '@ui';
import VAT from '@assets/icons/VAT.png';
import {formatPrice} from '@utils';

import './Lot.scss';

function Lot({lot, auction}) {
  const [t] = useTranslation('pages/cars');
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const Prices = ({className}) => (
    <div className={`prices ${className}`}>
      {auction ? (
        <div className='index'>
          {lot.index}/{auction.lotCount}
        </div>
      ) : null}
      <div className='prices_container'>
        <div className='price'>{t('price')} </div>
        <span className='number'>
          {lot.prices.priceType === 2 ? (
            <div className='vat'>
              <img src={VAT} title={t('vat')} />
            </div>
          ) : null}

          {formatPrice(lot.prices.ownerPrice)}
        </span>
      </div>

      <div className='prices_container'>
        <div
          className='highestBid'
          style={{
            color:
              lot.prices.ownerPrice < lot.prices.currentPrice
                ? '#00DF94'
                : 'rgb(244, 3, 3)',
          }}>
          {t('highest_rate')}
        </div>
        <span className='number'>
          {lot.prices.priceType === 2 ? (
            <div className='vat'>
              <img src={VAT} title={t('vat')} />
            </div>
          ) : null}
          {formatPrice(lot.prices.currentPrice)}
        </span>
      </div>
    </div>
  );
  return (
    <Link className='Lot' to={`/lot/${lot.lotId}`} rel='noopener noreferrer'>
      <ShadowContainer>
        <LotMiniGalleria lot={lot} />
        <div className='description'>
          <div className='main'>
            <div className='header'>
              {isAuthenticated ? (
                <>
                  <AddToFavoriteBtn
                    lotId={lot.lotId}
                    onClick={e => e.stopPropagation()}
                  />
                  <Note lot={lot} />
                </>
              ) : null}
              <div className='name'>{lot.name}</div>
            </div>
          </div>

          <Tags tagsInfo={lot.tags} />
          <Prices className={'second'} />
        </div>
        <Prices className={'main'} />
      </ShadowContainer>
    </Link>
  );
}
export default Lot;

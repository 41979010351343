import {
  ORDER_CURRENCY_EUR,
  ORDER_CURRENCY_PLN,
  ORDER_PAYMENT_PROVIDER_ING_PL,
  ORDER_PAYMENT_PROVIDER_PAYNOW,
  ORDER_PAYMENT_PROVIDER_MANUAL,
  ORDER_PRODUCT_TYPE_LOT,
  ORDER_PRODUCT_TYPE_TARIFF,
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_FAILED,
  ORDER_STATUS_NEW,
  ORDER_STATUS_RETRY,
  ORDER_STATUS_SUCCESSFUL,
  ORDER_STATUS_SUCCESSFUL_MANUAL,
} from '@models';

export const config = {
  header: 'Payments',
  type: 'table',
  fields: {
    order_no: {
      header: 'Документи',
      type: 'paymentDocs',
    },
    accountInfo: {
      header: 'Користувач',
      type: 'userLink',
    },
    status: {
      header: 'Статус',
      modifier: 'public',
      type: 'paymentStatus',
      editorData: [
        {indexInDB: ORDER_STATUS_NEW, title: 'Нове', severity: 'warning'},
        {
          indexInDB: ORDER_STATUS_SUCCESSFUL,
          title: 'Оплачено',
          severity: 'success',
        },
        {indexInDB: ORDER_STATUS_FAILED, title: 'Невдале', severity: 'danger'},
        {indexInDB: ORDER_STATUS_RETRY, title: 'На повторі', severity: 'info'},
        {
          indexInDB: ORDER_STATUS_CANCELLED,
          title: 'Відхилено',
          severity: 'warn',
        },
        {
          indexInDB: ORDER_STATUS_SUCCESSFUL_MANUAL,
          title: 'Оплачено в ручну',
          severity: 'success',
        },
      ],
    },

    'product_info.product_type': {
      header: 'Тип',
      type: 'list',
      editorData: [
        {
          indexInDB: ORDER_PRODUCT_TYPE_TARIFF,
          title: 'Тариф',
          severity: 'info',
        },
        {
          indexInDB: ORDER_PRODUCT_TYPE_LOT,
          title: 'Лот',
          severity: 'warning',
        },
      ],
    },
    'product_info.product_id': {
      header: 'Товар Id',
      type: 'productIdInOrder',
    },

    payment_provider: {
      header: 'Платіжний провайдер',
      modifier: 'public',
      type: 'list',
      editorData: [
        {
          indexInDB: ORDER_PAYMENT_PROVIDER_MANUAL,
          title: 'Wire Transfer',
          severity: 'success',
        },
        {
          indexInDB: ORDER_PAYMENT_PROVIDER_ING_PL,
          title: 'Provider ING PL',
          severity: 'info',
        },
        {
          indexInDB: ORDER_PAYMENT_PROVIDER_PAYNOW,
          title: 'Provider Paynow',
          severity: 'info',
        },
      ],
    },
    otherInfo: {
      header: 'Додаткова інформація',
      type: 'table',
      fields: {
        amount: {
          header: 'Сума',
          type: 'price',
        },
        tax_amount: {
          header: 'Сума податку',
          type: 'price',
        },
        tax_rate: {
          header: 'Ставка податку',
        },
        completed_at: {
          header: 'Завершено в',
          type: 'date',
        },
        rate_value: {
          header: 'Курс PLN/EUR',
          type: 'text',
        },
        table_number_rate: {
          header: 'Номер таблиці курсів',
          type: 'text',
        },
        date_publication_rate: {
          header: 'Публікація курса',
          type: 'date',
        },
        createdAt: {
          header: 'Дата створення',
          type: 'date',
        },
      },
    },

    total_amount: {
      header: 'Загальна сума',
      type: 'price',
    },
    currency: {
      header: 'Валюта',
      type: 'list',
      editorData: [
        {
          indexInDB: ORDER_CURRENCY_PLN,
          title: ORDER_CURRENCY_PLN,
          severity: 'danger',
        },
        {
          indexInDB: ORDER_CURRENCY_EUR,
          title: ORDER_CURRENCY_EUR,
          severity: 'warn',
        },
      ],
    },

    updatedAt: {
      header: 'Дата оновлення',
      type: 'date',
    },
  },
};

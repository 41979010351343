import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {Toast} from '@helpers';
import {useDispatchAsync} from '@hooks';

import {
  autoSortIndex,
  createOwnFeature,
  createValueStructureOwnFeatures,
  deleteOwnFeature,
  deleteValueStructureOwnFeatures,
  getOwnFeatures,
  updateOwnFeature,
  updateValueStructureOwnFeature,
} from '@actions/admin';

import Table from '../../../../2-components/table/Table';
import checkRequiredFields from '../../../../modules/checkRequiredFields';
import useUpdateData from '../../../../hooks/update-data.hook';

import {config} from './config';

function OwnFeatures({
  synchronize,
  selectedProduct = null,
  height,
  autoHeight,
  synchronizeValueStructure,
}) {
  const dispatch = useDispatchAsync();
  const api = useUpdateData();
  const ownFeatures = useSelector(state => state.admin.ownFeatures);

  useEffect(() => {
    dispatch(getOwnFeatures());
  }, []);

  useEffect(() => {
    config.onSave = async ({next, fieldMustToUpdated}) => {
      for (const {field, value} of fieldMustToUpdated) {
        await api(updateOwnFeature(next.id, field, value));
      }
    };
    config.fields.title.onSave = async ({idRow, fieldMustToUpdated}) => {
      for (const {field, value} of fieldMustToUpdated) {
        await api(updateOwnFeature(idRow, 'title.' + field, value));
      }
    };

    config.onCreate = async (idRow, newProduct) => {
      const res = checkRequiredFields(config.fields, newProduct);
      if (!res) {
        return await api(createOwnFeature(newProduct));
      } else {
        Toast.warning('Заповніть обов`язкове поле для введення - ' + res);
      }
      return false;
    };
    config.onDelete = async selectedProduct => {
      await api(deleteOwnFeature(selectedProduct.id));
    };

    config.fields.valueStructure.onSave = async ({
      idRow,
      prev,
      fieldMustToUpdated,
    }) => {
      for (const {field, value} of fieldMustToUpdated) {
        if (field === 'code') {
          if (
            ownFeatures
              .find(el => el.id === idRow)
              ?.valueStructure?.find(el => el.code === value)
          ) {
            Toast.warning('Значення code має бути унікальним !');
            return;
          }
        }
        await api(updateValueStructureOwnFeature(idRow, prev.id, field, value));
      }
    };

    config.fields.valueStructure.onCreate = async (idRow, newProduct) => {
      if (newProduct.code !== null && newProduct.code !== undefined) {
        if (
          ownFeatures
            .find(el => el.id === idRow)
            ?.valueStructure?.find(el => el.code === newProduct.code)
        ) {
          Toast.warning('Значення code має бути унікальним !');
          return;
        }
      }

      const res = checkRequiredFields(
        config.fields.valueStructure.fields,
        newProduct
      );

      if (!res) {
        return await api(createValueStructureOwnFeatures(idRow, newProduct));
      } else {
        Toast.warning('Заповніть обов`язкове поле для введення - ' + res);
      }
      return false;
    };
    config.fields.valueStructure.onDelete = async (selectedProduct, idRow) => {
      await api(deleteValueStructureOwnFeatures(idRow, selectedProduct));
    };
    config.fields.valueStructure.onAutoSortIndex = async idRow => {
      await api(autoSortIndex(idRow));
    };
    async function onLoad() {
      await dispatch(getOwnFeatures());
    }

    config.onLoad = onLoad;
    config.fields.title.onLoad = onLoad;
    config.fields.valueStructure.onLoad = onLoad;
    if (synchronize) {
      config.fields.valueStructure.synchronize = true;
    }
  });

  useEffect(() => {
    if (synchronize && selectedProduct) {
      synchronize(ownFeatures.find(el => el.id === selectedProduct.id));
    }
  }, [ownFeatures]);

  return (
    <div className='OwnFeatures'>
      <Table
        height={height}
        config={config}
        data={ownFeatures}
        autoHeight={autoHeight}
        synchronize={synchronize}
        selectedProduct={selectedProduct}
        synchronizeValueStructure={synchronizeValueStructure}
      />
    </div>
  );
}
export default OwnFeatures;

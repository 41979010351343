import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Filters} from '@components';
import {
  AUCTION_STATUS_FINISHED,
  AUCTION_STATUS_FUTURE,
  AUCTION_STATUS_LIVE,
} from '@models';

function AuctionsFilters() {
  const [t] = useTranslation('pages/auctions');
  const [filtersConfig, setFiltersConfig] = useState([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const _filtersConfig = [
      {
        id: 'status_auctions',
        type: 'radio',
        title: t('status_auctions_title'),
        data: [
          {
            checked: false,
            id: AUCTION_STATUS_FUTURE,
            name: t('start_in_future'),
          },
          {
            checked: true,
            id: AUCTION_STATUS_LIVE,
            name: t('active_lot_now'),
          },
          {
            checked: false,
            id: AUCTION_STATUS_FINISHED,
            name: t('finished'),
          },
        ],
      },
    ];

    _filtersConfig.forEach(i => {
      const params = searchParams.get(i.id)?.split(',');
      if (params) {
        i.data.forEach(el => {
          el.checked = params.includes('' + el.id);
        });
      }
    });
    setFiltersConfig([..._filtersConfig]);
  }, [searchParams, t]);

  return (
    <div className='AuctionsFilters'>
      <Filters
        filtersConfig={filtersConfig}
        setFiltersConfig={setFiltersConfig}
      />
    </div>
  );
}
export default AuctionsFilters;

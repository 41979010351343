import {useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {ProgressBar} from '@ui';
import {Logger} from '@helpers';
import {LotMiniGalleria, PayBtn, Tags} from '@components';
import {ShadowContainer} from '@containers';
import VAT from '@assets/icons/VAT.png';
import {formatPrice} from '@utils';

import {
  LOT_PROCESSING_STATUS_ADMIN_CHECKED,
  LOT_PROCESSING_STATUS_DONE,
  LOT_PROCESSING_STATUS_DELIVERED,
  LOT_PROCESSING_STATUS_DELIVERY,
  LOT_PROCESSING_STATUS_PAYED,
  LOT_PROCESSING_STATUS_NEW,
  ORDER_PRODUCT_TYPE_LOT,
} from '@models';

import './CardLotProcessing.scss';

function CardLotProcessing({lot, isShowPayBtn = true}) {
  const [t] = useTranslation('pages/cars');
  const steps = [
    t('new'),
    t('admin_checked'),
    t('payed'),
    t('delivery'),
    t('delivered'),
  ];
  const [activeStep, _] = useState(() => {
    if (!lot.lotProcessing) return null;
    switch (lot.lotProcessing.status) {
      case LOT_PROCESSING_STATUS_NEW:
        return 1;
      case LOT_PROCESSING_STATUS_ADMIN_CHECKED:
        return 2;
      case LOT_PROCESSING_STATUS_PAYED:
        return 3;
      case LOT_PROCESSING_STATUS_DELIVERY:
        return 4;
      case LOT_PROCESSING_STATUS_DELIVERED:
        return 5;
      case LOT_PROCESSING_STATUS_DONE:
        return 6;
      default:
        Logger.error(
          'Unknown LOT_PROCESSING_STATUS : ' + lot.lotProcessing.status
        );
        return null;
    }
  });

  const Prices = ({className}) => (
    <div className={`prices ${className}`}>
      <div className='totalPrice'>
        <div className='price'>{t('final_price')} :</div>
        <span className='number'>
          {lot.prices.priceType === 2 ? (
            <div className='vat'>
              <img src={VAT} title={t('vat')} />
            </div>
          ) : null}

          {formatPrice(lot.lotProcessing.prices.totalPrice)}
        </span>
      </div>
      {lot.lotProcessing.status === LOT_PROCESSING_STATUS_ADMIN_CHECKED &&
      isShowPayBtn ? (
          <div className='pay'>
            <PayBtn orderType={ORDER_PRODUCT_TYPE_LOT} lotId={lot.lotId}>
              {t('pay')}
            </PayBtn>
          </div>
        ) : null}
    </div>
  );

  return (
    <Link
      className='CardLotProcessing'
      to={`/lot/${lot.lotId}`}
      rel='noopener noreferrer'>
      <ShadowContainer>
        <div className='CardLotProcessing_container'>
          <LotMiniGalleria lot={lot} isShowLotStatus={false} />
          <div className='description'>
            <div className='main'>
              <div className='header'>
                <div className='name'>{lot.name}</div>
              </div>
              <Tags tagsInfo={lot.tags} />
            </div>
            <Prices className={'Prices_main'} />
          </div>
          <ProgressBar
            className='main'
            steps={steps}
            activeStep={activeStep}
            gap={10}
          />
        </div>
        <div className='ProgressBar_container'>
          <ProgressBar
            className='second'
            orientation={'row'}
            steps={steps}
            activeStep={activeStep}
            gap={100}
          />
        </div>
      </ShadowContainer>
    </Link>
  );
}
export default CardLotProcessing;

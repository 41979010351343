import {formatDataTime} from '@utils';

import {getVFF} from '../../../modules/getValueFomField';

function Date(field) {
  return rowData => {
    const value = getVFF(rowData, field);
    const [data, time] = formatDataTime(value);
    return value ? (
      <p style={{textAlign: 'center'}}>
        {data} <br></br>
        {time}
      </p>
    ) : null;
  };
}
export default Date;

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Input, Button} from '@ui';
import {Toast} from '@helpers';
import {loadDocuments} from '@actions/user';
import {
  TypeAccount,
  UploadDocuments,
  TableDocuments,
  SelectCountry,
} from '@components';
import {useUpdatedState, useDispatchAsync, useValidation} from '@hooks';
import {getUserInfo, sendVerificationData} from '@actions/user';

import {
  ACCOUNT_DOCUMENT_STATUS_APPROVED,
  ACCOUNT_DOCUMENT_STATUS_NEW,
  ACCOUNT_TYPE_COMPANY_EU,
  ACCOUNT_TYPE_COMPANY_UA,
  ACCOUNT_TYPE_PERSONAL,
} from '@models';

import {
  firstInputs,
  personalInputs,
  secondEuropeInputs,
  secondUkraineInputs,
} from './inputsData';

import './VerificationDocuments.scss';

function VerificationDocuments() {
  const dispatch = useDispatchAsync();
  const [isThisUkraineCompany, setIsThisUkraineCompany] = useState(true);
  const [isModified, setIsModified] = useState(false);

  const isInputsValid = useValidation('Verification_inputs');

  const user = useSelector(state => state.user);
  const documents = useSelector(state => state.user.documents);

  const [isUploadedDocs, setIsUploadedDocs] = useUpdatedState(false);

  const [t] = useTranslation('pages/profile', {
    keyPrefix: 'verification',
  });

  const UA = 'ua';

  const [inputsData, setInputsData] = useState({
    type: null,
    kindAccount: null,

    last_name: null,
    first_name: null,

    birthday: null,
    identification_number: null,
    passport_no: null,
    passport_date: null,
    passport_issued_by: null,

    country: UA,
    address: null,
    ZIPCode: null,

    companyName: null,

    IPNCompany: null,
    CodeEDRPOYCompany: null,

    NIPCompany: null,
    VATCompany: null,
  });

  useEffect(() => {
    if (user.type === null || user.type === undefined) return;
    const baseData = {
      type: user.type,
      kindAccount: user.type === ACCOUNT_TYPE_PERSONAL ? 'personal' : 'company',
      ...user.addressInfo,
      country: user.addressInfo.country,
    };
    switch (user.type) {
      case ACCOUNT_TYPE_PERSONAL:
        setInputsData(p => ({...p, ...baseData, ...user.userInfo}));
        break;
      case ACCOUNT_TYPE_COMPANY_UA:
        setInputsData(p => ({...p, ...baseData, ...user.companyInfoUA}));
        break;
      case ACCOUNT_TYPE_COMPANY_EU:
        setInputsData(p => ({...p, ...baseData, ...user.companyInfoEU}));
        break;

      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (user.verificationRights.document) return;
    let _type = null;
    if (inputsData.kindAccount === 'personal') {
      _type = ACCOUNT_TYPE_PERSONAL;
    } else {
      if (isThisUkraineCompany) {
        _type = ACCOUNT_TYPE_COMPANY_UA;
      } else {
        _type = ACCOUNT_TYPE_COMPANY_EU;
      }
    }
    if (inputsData['type'] !== _type) {
      setInputsData(p => ({...p, type: _type}));
    }
  });

  useEffect(() => {
    if (inputsData.country) setIsThisUkraineCompany(inputsData.country === UA);
  }, [inputsData.country]);

  function onChangeCountry(country) {
    if (user.verificationRights.document) return;
    setIsModified(true);
    setIsThisUkraineCompany(country === UA);
    setInputsData(p => ({...p, country: country}));
  }

  function onInput(id, value, e, type) {
    if (type === 'date') {
      e.target._is_valid = new Date(value) < new Date();
    }
    if (user.verificationRights.document) return;
    setIsModified(true);
    setInputsData(p => ({...p, [id]: value}));
  }

  async function onSendData() {
    const res = await dispatch(sendVerificationData(inputsData), false);
    if (res.ok) {
      dispatch(getUserInfo());
      Toast.success(t('wait_admin_check_docs'), 10000);
      setIsModified(false);
      setIsUploadedDocs(false);
    } else {
      Toast.error(t(res.code), 5000);
    }
  }

  function renderInputs(configInputs) {
    return configInputs.map((item, index) => (
      <Input
        {...item}
        key={index}
        type={item.type}
        pattern={item.pattern}
        value={inputsData[item.id]}
        className='Verification_inputs'
        placeholder={t(`inputsData.${item.id}.placeholder`)}
        headerTitle={t(`inputsData.${item.id}.headerTitle`)}
        underTitle={t(`inputsData.${item.id}.required`)}
        onChange={e => onInput(item.id, e.target.value, e, item.type)}
      />
    ));
  }

  return (
    <div className='VerificationDocuments'>
      <div className='content'>
        <section>
          <div className='titles'>
            <div className='main_title'>{t('type_account')}</div>
          </div>
          <TypeAccount
            electType={inputsData.kindAccount}
            setElectType={value => {
              if (!user.verificationRights.document) {
                setIsModified(true);
                setInputsData(p => ({...p, kindAccount: value}));
              }
            }}
          />
        </section>
        <section>
          <div className='titles'>
            <div className='main_title'>{t('location_info')}</div>
          </div>
          <div className='data_entry'>
            <div className='Input'>
              <SelectCountry
                headerTitle={t('country')}
                value={inputsData.country}
                onChange={onChangeCountry}
              />
              <div className='under_input_title'>
                {t('first_section.under_input_title')}
              </div>
            </div>

            {renderInputs(firstInputs)}
          </div>
        </section>
        {inputsData.kindAccount ? (
          <section>
            <div className='titles'>
              <div className='main_title'>
                {t(`second_section.${inputsData.kindAccount}.main_title`)}
              </div>
              <div className='sub_title'>
                {t(`second_section.${inputsData.kindAccount}.sub_title`)}
              </div>
            </div>
            <div className='data_entry'>
              {inputsData.kindAccount === 'personal'
                ? renderInputs(personalInputs)
                : null}
              {inputsData.kindAccount === 'company'
                ? renderInputs([
                  ...(isThisUkraineCompany
                    ? secondUkraineInputs
                    : secondEuropeInputs),
                ])
                : null}
            </div>
          </section>
        ) : null}
        <section className='documents'>
          <div className='titles'>
            <div className='main_title'>{t('documents')}</div>
            <div className='sub_title'>
              {t('confirm_identity')}{' '}
              {inputsData.kindAccount === 'company'
                ? t('confirm_identity_for_company')
                : null}
              {inputsData.kindAccount === 'personal'
                ? t('confirm_identity_for_personal')
                : null}
            </div>
          </div>
          <div className='data_entry'>
            <UploadDocuments
              onUploadDocument={() => {
                setIsUploadedDocs(true);
                dispatch(loadDocuments());
              }}
            />
            <TableDocuments />
          </div>
        </section>
        <div className='buttons'>
          <Button
            className={
              inputsData.type !== null &&
              !user.verificationRights.document &&
              isInputsValid &&
              (isUploadedDocs || isModified) &&
              documents.length > 0 &&
              (documents.filter(
                i => i.status === ACCOUNT_DOCUMENT_STATUS_APPROVED
              )?.length >= 2 ||
                documents.filter(i => i.status === ACCOUNT_DOCUMENT_STATUS_NEW)
                  ?.length >= 1)
                ? ''
                : 'inactive'
            }
            onClick={onSendData}
            onClickInActive={() => {
              for (const key in inputsData) {
                if (!inputsData[key]) {
                  const el = document.getElementById(key);
                  if (el) {
                    Toast.warning(
                      `${t('empty_field')}: '${t(
                        `inputsData.${key}.headerTitle`
                      )}'`,
                      5000
                    );
                    el.scrollIntoView({block: 'center', behavior: 'smooth'});
                  }
                }
              }
            }}
            text={t('send_data')}
          />
        </div>
      </div>
    </div>
  );
}

export default VerificationDocuments;

import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Toast} from '@helpers';
import {useValidation, useDispatchAsync, useUpdatedState} from '@hooks';
import {CheckBox, Input, InputNumber, Button} from '@ui';
import {REGEX_NOT_SPACES} from '@helpers/regex';
import {sendСomplaints} from '@actions/user';

import {
  PROBLEM_ID_0,
  PROBLEM_ID_1,
  PROBLEM_ID_2,
  PROBLEM_ID_3,
  PROBLEM_ID_4,
  PROBLEM_ID_5,
  PROBLEM_ID_6,
  PROBLEM_ID_7,
} from '@models';

import './HelpPage.scss';

function HelpPage() {
  const dispatch = useDispatchAsync();
  const isInputsValid = useValidation('HelpPage_input', 'color');
  const userId = useSelector(state => state.user.id);
  const first_name_state = useSelector(
    state => state.user.userInfo?.first_name
  );
  const [first_name, setFirstName] = useUpdatedState(first_name_state || null);

  const phone_state = useSelector(state => state.user.phone);
  const [phone, setPhone] = useUpdatedState(phone_state || null);

  const [t] = useTranslation('pages/help');

  const initialProblems = [
    {id: PROBLEM_ID_1, checked: false},
    {id: PROBLEM_ID_2, checked: false},
    {id: PROBLEM_ID_3, checked: false},
    {id: PROBLEM_ID_4, checked: false},
    {id: PROBLEM_ID_5, checked: false},
    {id: PROBLEM_ID_6, checked: false},
    {id: PROBLEM_ID_7, checked: false},
    {id: PROBLEM_ID_0, checked: false},
  ];
  const [problems, setProblems] = useState(initialProblems);
  const [otherProblem, setOtherProblems] = useState();

  function addProblems(state, problemId) {
    problems.find(i => i.id === problemId).checked = state;
    setProblems([...problems]);
  }

  async function onSentHandler() {
    if (!isInputsValid) {
      Toast.warning(t('message.invalid_inputs'), 5000);
      return;
    }
    const probs = problems
      .filter(item => item.checked)
      .map(item => ({id: item.id, text: item.text}));
    if (probs.find(item => item.id === PROBLEM_ID_0)) {
      probs.find(item => item.id === PROBLEM_ID_0).text = otherProblem;
    }
    if (probs.length === 0) {
      Toast.warning(t('message.format'), 5000);
      return;
    }
    const res = await dispatch(
      sendСomplaints(userId, first_name, phone, probs)
    );
    if (res.ok) {
      setOtherProblems('');
      setProblems([...problems.map(it => ({...it, checked: false}))]);
      Toast.success(t('message.ok'), 5000);
    }
  }

  return (
    <div className='HelpPage container'>
      <div className='greetings'>
        <div className='hello'>{t('hello')}</div>
        <div className='questions'>{t('questions')}</div>
      </div>

      <div className='selectProblems'>
        <div className='title'>{t('selectProblems.title')}</div>
        <div className='problems'>
          {problems.map(item => (
            <div className='problem' key={item.id}>
              <CheckBox
                id={item.id}
                state={item.checked}
                setState={state => addProblems(state, item.id)}
              />

              {item.id === PROBLEM_ID_0 && item.checked ? (
                <Input
                  value={otherProblem}
                  onChange={e => setOtherProblems(e.target.value)}
                  placeholder={t('selectProblems.placeholderInputOther')}
                />
              ) : (
                <span>{t(`problems.${item.id}`)}</span>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className='inputs'>
        <div className='text'>{t('inputs.text')}</div>
        <Input
          className='HelpPage_input'
          type='text'
          pattern={REGEX_NOT_SPACES}
          value={first_name}
          headerTitle={t('inputs.name.headerTitle')}
          placeholder={t('inputs.name.placeholder')}
          onChange={e => setFirstName(e.target.value)}
          underTitle={t('inputs.name.underTitle')}
        />
        <InputNumber
          className='HelpPage_input'
          phone={phone}
          title={t('inputs.phone.headerTitle')}
          placeholder={t('inputs.phone.placeholder')}
          under_input_text={t('inputs.phone.underTitle')}
          pattern={REGEX_NOT_SPACES}
          onChangeHandler={setPhone}
        />
        <Button onClick={onSentHandler} text={t('btSent')} />
      </div>
    </div>
  );
}

export default HelpPage;

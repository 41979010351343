import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Button, Loader} from '@ui';
import {useDispatchAsync} from '@hooks';
import {Logger, Toast} from '@helpers';
import {OrderCheck} from '@actions/user';
import {ReactComponent as BigCheckMark} from '@assets/icons/big-check-mark.svg';

import {
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_SUCCESSFUL,
  ORDER_STATUS_FAILED,
  ORDER_STATUS_NEW,
  ORDER_STATUS_RETRY,
} from '@models';

import './PaymentResult.scss';

function PaymentResult() {
  const dispatch = useDispatchAsync();
  const [t] = useTranslation('pages/payments');
  const [searchParams] = useSearchParams();
  const [orderId, _] = useState(searchParams.get('id'));
  const [status, setStatus] = useState(null);
  let intervalId = null;
  useEffect(() => {
    getOrder();
    intervalId = setInterval(async () => {
      getOrder();
    }, 3000);
    return () => clearInterval(intervalId);
  }, []);

  async function getOrder() {
    const res = await dispatch(OrderCheck(orderId));
    if (!res || !res.ok || !res.order) {
      Toast.warning(t('error_receiving_payment_data', 5000));
      return;
    }
    if (res.order.status > 0) clearInterval(intervalId);
    setStatus(res.order.status);
  }

  return (
    <div className='PaymentResult container'>
      <div className='content'>
        {(() => {
          switch (status) {
            case null:
              return <Loader msg={t('loading') + ' ...'} />;
            case ORDER_STATUS_NEW:
              return <Loader msg={t('waiting_result') + ' ...'} />;
            case ORDER_STATUS_SUCCESSFUL:
              return (
                <div className='completed'>
                  <div className='icon'>
                    <BigCheckMark />
                  </div>
                  <div className='title'>{t('congratulate')}</div>
                  <div className='text'>
                    {t('payment')} №{orderId} {t('passed_successfully')}
                  </div>
                  <Button to='/' text={t('to_home_page')} />
                </div>
              );
            case ORDER_STATUS_FAILED:
              return (
                <div className='failed'>
                  <div className='icon'>
                    <BigCheckMark />
                  </div>
                  <div className='title'>{t('not_successful')}</div>
                  <div className='text'>
                    {t('payment')} №{orderId} {t('ended_with_an_error')}
                  </div>
                  <Button to='/' text={t('to_home_page')} />
                </div>
              );
            case ORDER_STATUS_RETRY:
              return <Loader msg={t('repeat_payment') + ' ...'} />;
            case ORDER_STATUS_CANCELLED:
              return (
                <div className='cancelled'>
                  <div className='icon'>
                    <BigCheckMark />
                  </div>
                  <div className='title'>{t('Rejected')}</div>
                  <div className='text'>
                    {t('payment')} №{orderId} {t('rejected')}
                  </div>
                  <Button to='/' text={t('to_home_page')} />
                </div>
              );
            default:
              Logger.warn(`Some new ORDER_STATUS_* = ${status}`);
              return t('error_update_page');
          }
        })()}
      </div>
    </div>
  );
}

export default PaymentResult;

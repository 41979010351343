import ReactHtmlParser from 'html-react-parser';
import {Link} from 'react-router-dom';
import {
  ACCOUNT_TARIFF_1000,
  ACCOUNT_TARIFF_300,
  ACCOUNT_TARIFF_500,
  ACCOUNT_TARIFF_INDIVIDUAL,
  ACCOUNT_TARIFF_PREMIUM,
  ORDER_PRODUCT_TYPE_LOT,
  ORDER_PRODUCT_TYPE_TARIFF,
} from '@models';

import {getVFF} from '../../../modules/getValueFomField';

function ProductIdInOrder() {
  return rowData => {
    const product_id = getVFF(rowData, 'product_info.product_id');
    const product_type = getVFF(rowData, 'product_info.product_type');
    const lot = getVFF(rowData, 'lotInfo');
    switch (product_type) {
      case ORDER_PRODUCT_TYPE_TARIFF:
        switch (parseFloat(product_id)) {
          case ACCOUNT_TARIFF_300:
            return 'TARIFF 300';
          case ACCOUNT_TARIFF_500:
            return 'TARIFF 500';
          case ACCOUNT_TARIFF_1000:
            return 'TARIFF 1000';
          case ACCOUNT_TARIFF_PREMIUM:
            return 'TARIFF PREMIUM';
          case ACCOUNT_TARIFF_INDIVIDUAL:
            return 'TARIFF INDIVIDUAL';
          default:
            return;
        }
      case ORDER_PRODUCT_TYPE_LOT:
        return (
          <Link
            to={'/org/admin/lots?search=' + product_id}
            style={{
              lineHeight: 1.4,
              color: 'rgb(49 153 118)',
              textDecoration: 'underline',
            }}
            target={'_blank'}>
            {ReactHtmlParser(lot || product_id)}
          </Link>
        );
      default:
        break;
    }
  };
}
export default ProductIdInOrder;

import {
  CRATE_FILTER,
  CRATE_FILTER_VALUE,
  DELETE_FILTER,
  DELETE_SYNC_WITH_FEATURE,
  DELETE_VALUE_STRUCTURE,
  GET_FILTERS,
  UPDATE_FILTER,
  UPDATE_SYNC_WITH_FEATURES,
  UPDATE_VALUE_STRUCTURE,
} from './types';

export const getFilters = () => ({
  event: GET_FILTERS,
  link: '/api/filters/get_filters',
});
export const createFilter = newFilter => ({
  event: CRATE_FILTER,
  link: '/api/filters/create_filter',
  data: newFilter,
});
export const createFilterValue = (id, newVS) => ({
  event: CRATE_FILTER_VALUE,
  link: '/api/filters/create_filter_value',
  data: {
    id: id,
    value: newVS,
  },
});
export const createSyncWithFeatures = (
  filterId,
  featureId,
  valueType,
  code
) => ({
  event: CRATE_FILTER_VALUE,
  link: '/api/filters/create_sync_with_feature',
  data: {
    filterId,
    featureId,
    valueType,
    code,
  },
});
export const updateFilter = (id, field, value) => ({
  event: UPDATE_FILTER,
  link: '/api/filters/update_filter',
  data: {
    id: id,
    field: field,
    value: value,
  },
});

export const updateValueStructure = (filterId, vsId, field, value) => ({
  event: UPDATE_VALUE_STRUCTURE,
  link: '/api/filters/update_value_structure',
  data: {
    filterId: filterId,
    vsId: vsId,
    field: field,
    value: value,
  },
});
export const updateSyncWithFeatures = (id, field, value) => ({
  event: UPDATE_SYNC_WITH_FEATURES,
  link: '/api/filters/update_sync_with_feature',
  data: {
    id: id,
    field: field,
    value: value,
  },
});
export const deleteFilter = filterId => ({
  event: DELETE_FILTER,
  link: '/api/filters/delete_filter',
  data: {
    filterId: filterId,
  },
});
export const deleteValueStructure = (filterId, vsId) => ({
  event: DELETE_VALUE_STRUCTURE,
  link: '/api/filters/delete_value_structure',
  data: {
    filterId: filterId,
    vsId: vsId,
  },
});
export const deleteSyncWithFeatures = (vsId, featureId) => ({
  event: DELETE_SYNC_WITH_FEATURE,
  link: '/api/filters/delete_sync_with_feature',
  data: {
    vsId: vsId,
    featureId: featureId,
  },
});

import {useTranslation} from 'react-i18next';

import {TopHomeContainer} from '@containers';

import Filters from './Filters/Filters';
import './TopSection.scss';

function TopSection() {
  const [t] = useTranslation('', {keyPrefix: 'TopSection'});
  return (
    <TopHomeContainer>
      <div className='filterContainer'>
        <div className='filtersBlock'>
          <h4 className='title'>{t('findYourCar')}</h4>
          <h5 className='short'>{t('buyCarOnAuctions')}</h5>
          <Filters />
        </div>
      </div>
    </TopHomeContainer>
  );
}

export default TopSection;

import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Swiper, SwiperSlide} from 'swiper/react';

import {useDispatchAsync} from '@hooks';
import {compareTwoStringDigits, formatPrice} from '@utils';
import {loadDataForHome} from '@actions/home';
import {TopViews} from '@layers';

import {ReactComponent as ImageArrowGreen} from '@assets/icons/arrow-green.svg';
import {ReactComponent as ImageArrowGray} from '@assets/icons/arrow-gray.svg';

import 'swiper/swiper.min.css';
import './BottomSection.scss';
import {Tags} from '@components/index';

function BottomSection() {
  const [t] = useTranslation('', {keyPrefix: 'BottomSection'});

  const dispatch = useDispatchAsync();

  const expensiveCar = useSelector(state => state.home.expensiveCar);
  const topManufacs = useSelector(state => state.home.topManufacs);

  const faq = [
    {
      title: t('faq.title1'),
      text: t('faq.text1'),
    },
    {
      title: t('faq.title2'),
      text: t('faq.text2'),
    },
    {
      title: t('faq.title3'),
      text: t('faq.text3'),
    },
    {
      title: t('faq.title4'),
      text: t('faq.text4'),
    },
  ];

  useEffect(() => {
    dispatch(loadDataForHome());
  }, []);

  return (
    <div className='home-page-bottom-section'>
      <div className='container'>
        <section>
          <h2 className='popular_title'>{t('mostPopular')}</h2>
          <div className='popular_short'>{t('mostPopularDesc')}</div>
          {!topManufacs ? (
            t('waiting')
          ) : (
            <div className='popular_manufacturers_swiper'>
              <Swiper
                spaceBetween={50}
                breakpoints={{
                  400: {slidesPerView: 1.2},
                  639: {slidesPerView: 2.2},
                  865: {slidesPerView: 3.2},
                  1200: {slidesPerView: 4.1},
                  1500: {slidesPerView: 5},
                }}>
                {topManufacs.map((car, index) => (
                  <SwiperSlide key={`home_popular_card-${index}`}>
                    <Link to={`/lots?manufacturers=${car.manufacId}`}>
                      <div className='popular_card'>
                        <div className='popular_img'>
                          <img src={car.logo.src} />
                        </div>
                        <div className='popular_name'>
                          <h5>{car.manufacName}</h5>
                          <h6>
                            {car.count}
                            <ImageArrowGray />
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </section>

        <section className='home_faq_section'>
          <h2 className='home_faq_title'>{t('howWeWork')}</h2>
          <div className='home_faq_short'>{t('howWeWorkDesc')}</div>
          {!faq ? (
            t('waiting')
          ) : (
            <div className='faq_swiper'>
              <Swiper
                spaceBetween={50}
                breakpoints={{
                  400: {slidesPerView: 1.2},
                  639: {slidesPerView: 2.2},
                  865: {slidesPerView: 3},
                  1200: {slidesPerView: 4},
                  1500: {slidesPerView: 4},
                }}>
                {faq.map((faqItem, index) => (
                  <SwiperSlide key={`home_faq_card-${index}`}>
                    <div className='home_faq_card'>
                      <div className='title'>{faqItem.title}</div>
                      <div className='text'>{faqItem.text}</div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </section>

        <section className='expensive_section'>
          <h2 className='expensive_title'>{t('mostExpensiveLot')}</h2>
          <div className='expensive_short'>{t('mostExpensiveLotDesc')}</div>
          {!expensiveCar ? (
            t('waiting')
          ) : (
            <div className='expensive_car'>
              <div className='expensive_car_img'>
                <img src={expensiveCar.img} alt={expensiveCar.name} />
              </div>
              <div className='expensive_car_info'>
                <h5 className='expensive_car_title'>{expensiveCar.name}</h5>
                <div className='expensive_car_tags'>
                  <Tags tagsInfo={expensiveCar.tags} />
                </div>
                <div className='expensive_car_price'>
                  <div className='expensive_price'>
                    <h5>{t('price')}:</h5>
                    <h4>{formatPrice(expensiveCar.price)}</h4>
                  </div>
                  <div className='expensive_current_rate'>
                    <h5
                      className={
                        compareTwoStringDigits(
                          expensiveCar.highestBid,
                          expensiveCar.price
                        ) === true
                          ? 'color-green'
                          : 'color-red'
                      }>
                      {t('highestPrice')}:
                    </h5>
                    <h4
                      className={
                        compareTwoStringDigits(
                          expensiveCar.highestBid,
                          expensiveCar.price
                        ) === true
                          ? 'color-green'
                          : 'color-red'
                      }>
                      {expensiveCar.highestBid === null
                        ? t('noBids')
                        : formatPrice(expensiveCar.highestBid)}
                    </h4>
                  </div>
                </div>
                <div className='view_car'>
                  <Link to={`/lot/${expensiveCar.lotId}`}>
                    <>{t('viewInfo')}</>
                    <ImageArrowGreen />
                  </Link>
                </div>
              </div>
            </div>
          )}
        </section>
        <TopViews />
      </div>
    </div>
  );
}

export default BottomSection;

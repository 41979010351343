import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import {Loader, SelectButton} from '@ui';
import {useDispatchAsync} from '@hooks';
import {Toast, Logger} from '@helpers';
import {ShadowContainer} from '@containers';
import {formatPriceWithCent, timeToString} from '@utils';
import {getEventsByTypes, getUsers, setAsProcessedEvent} from '@actions/admin';

import {ReactComponent as CheckMark} from '@assets/icons/checkMark-gray.svg';
import {ReactComponent as DoubleCheckMark} from '@assets/icons/checkMark-double-green.svg';

import {
  EVENT_STATUS_PROCESSED,
  EVENT_TYPE_ADD_NEW_DOCUMENT,
  EVENT_TYPE_LOT_PROCESSING_STATUS_NEW,
  EVENT_TYPE_LOT_PROCESSING_STATUS_PAYED,
  EVENT_TYPE_REGISTRATION,
  EVENT_TYPE_UPDATE_TARIFF,
  EVENT_TYPE_VERIFICATION_CONTRACT_SIGNED,
  EVENT_TYPE_VERIFICATION_DOCUMENT_SEND,
  EVENT_TYPE_VERIFICATION_USER_VERIFIED,
} from '@models';

import './Events.scss';

function Events() {
  const PATH = '/org/admin/events';
  const location = useLocation();
  const navigate = useNavigate();
  const [selectPage, setSelectPage] = useState();

  const dispatch = useDispatchAsync();
  const users = useSelector(state => state.admin.users);
  const [hasMore, setHasMore] = useState(true);
  const [events, setEvents] = useState([]);

  const pages = [
    {
      title: 'Користувачі',
      statuses: [
        EVENT_TYPE_REGISTRATION,
        EVENT_TYPE_ADD_NEW_DOCUMENT,
        EVENT_TYPE_UPDATE_TARIFF,
        EVENT_TYPE_VERIFICATION_DOCUMENT_SEND,
        EVENT_TYPE_VERIFICATION_USER_VERIFIED,
        EVENT_TYPE_VERIFICATION_CONTRACT_SIGNED,
      ],
      url: 'users',
    },

    {
      title: 'Лоти',
      statuses: [
        EVENT_TYPE_LOT_PROCESSING_STATUS_NEW,
        EVENT_TYPE_LOT_PROCESSING_STATUS_PAYED,
      ],
      url: 'lots',
    },
  ];

  function convertEvent(event) {
    const eventCopy = {...event};
    const user =
      users.find(user => user.id === event.userId)?.email ||
      'З id ' + event.userId;

    const userLink = (
      <Link
        to={'/org/admin/moderation?search=' + eventCopy.userId}
        className='userLink'
        target={'_blank'}>
        {user}
      </Link>
    );

    const lotLink =
      eventCopy.context &&
      eventCopy.context.lotId &&
      eventCopy.context.lotName ? (
          <Link
            to={'/org/admin/lots?search=' + eventCopy.context.lotId}
            className='userLink'
            target={'_blank'}>
            {eventCopy.context.lotName}
          </Link>
        ) : null;

    let message = '';
    switch (eventCopy.type) {
      case EVENT_TYPE_REGISTRATION:
        message = <>Користувач ({userLink}) зареєструвався</>;
        break;
      case EVENT_TYPE_ADD_NEW_DOCUMENT:
        message = <>Користувач ({userLink}) надіслав документи на перевірку</>;
        break;
      case EVENT_TYPE_UPDATE_TARIFF:
        message = <>Користувач ({userLink}) змінив тариф</>;
        break;
      case EVENT_TYPE_LOT_PROCESSING_STATUS_NEW:
        message = (
          <>
            Користувач ({userLink}) виграв лот: ({lotLink}) ставка:{' '}
            {formatPriceWithCent(eventCopy.context.currentPrice, true)}
          </>
        );
        break;
      case EVENT_TYPE_LOT_PROCESSING_STATUS_PAYED:
        message = (
          <>
            Користувач ({userLink}) оплатив лот: ({lotLink}) ціна:{' '}
            {formatPriceWithCent(eventCopy.context.totalPrice, true)}
          </>
        );
        break;
      case EVENT_TYPE_VERIFICATION_DOCUMENT_SEND:
        message = <>Користувач ({userLink}) надіслав дані для верифікації</>;
        break;
      case EVENT_TYPE_VERIFICATION_USER_VERIFIED:
        message = <>Користувач ({userLink}) пройшов верифікацію !!!</>;
        break;
      case EVENT_TYPE_VERIFICATION_CONTRACT_SIGNED:
        message = (
          <>Користувач ({userLink}) підписав договір Купівлі-Продажу!</>
        );
        break;
      default:
        Logger.warn('unknown type event :' + eventCopy.type);
    }
    eventCopy['message'] = <div>{message}</div>;
    return eventCopy;
  }
  async function resize() {
    const windowHeight = document.documentElement.clientHeight;
    const positionLastEvent = Array.from(document.querySelectorAll('.Event'))
      .at(-1)
      ?.getBoundingClientRect().top;
    if (windowHeight > positionLastEvent && hasMore) await fetchMoreData();
  }

  async function fetchMoreData() {
    if (hasMore === false || !events.length || !selectPage) return;
    const res = await dispatch(
      getEventsByTypes(events.at(-1)?.createdAt, selectPage.statuses)
    );
    if (!res) return;
    if (!res.events || res.events.length === 0) {
      setHasMore(false);
      return;
    }
    setEvents([...events, ...res.events]);
  }

  async function setAsProcessed(event) {
    const res = await dispatch(setAsProcessedEvent(event.id));
    if (!res.ok) {
      Toast.error('Виникли проблеми з оновленням події!!!');
    }
    events.find(_event => _event.id === event.id).processing =
      EVENT_STATUS_PROCESSED;
    setEvents([...events]);
  }

  useEffect(async () => {
    if (!users) {
      dispatch(getUsers());
    }
  }, []);

  useEffect(async () => {
    if (location.pathname === PATH) {
      return navigate(PATH + '/' + pages[0].url, {replace: true});
    } else {
      const currentPageStatus = location.pathname.split('/').at(-1);
      const page = pages.find(i => i.url === currentPageStatus) || pages[0];
      const res = await dispatch(getEventsByTypes(new Date(), page.statuses));
      if (res.ok) {
        localStorage.setItem('adminLastEventViewDate', new Date());
        setEvents(res.events);
        setSelectPage(page);
        setHasMore(res.events.length !== 0);
      }
    }
  }, [location]);

  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  });
  if (!events || !users) return <Loader />;
  return (
    <div className='Events'>
      <div className='card flex justify-content-center m-3'>
        <SelectButton
          value={selectPage?.title}
          options={pages.map(i => i.title)}
          onChange={e => {
            if (!e.value) return;
            navigate(PATH + '/' + pages.find(i => i.title === e.value).url);
          }}
        />
      </div>
      <InfiniteScroll
        dataLength={events.length}
        next={fetchMoreData}
        hasMore={hasMore}
        style={{overflow: 'inherit'}}
        loader={<Loader />}>
        {events
          .map(event => convertEvent(event))
          .map((event) => (
            <ShadowContainer
              key={event.type + event.createdAt}
              className='Event'>
              <div>
                <div onDoubleClick={() => setAsProcessed(event)}>
                  {event.processing === EVENT_STATUS_PROCESSED ? (
                    <DoubleCheckMark />
                  ) : (
                    <CheckMark />
                  )}
                </div>
                {event.message}
              </div>
              <div className='date'>{timeToString(event.createdAt)}</div>
            </ShadowContainer>
          ))}
      </InfiniteScroll>
    </div>
  );
}
export default Events;

import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import {Loader, Button} from '@ui';
import {AUCTION_STATUS_LIVE} from '@models';
import {useDispatchAsync} from '@hooks';
import {getAuctionsInfo} from '@actions/auction';

import AuctionsFilters from './auctions-filters/AuctionsFilters';
import SettingsPanel from './settings-panel/SettingsPanel';
import AuctionsList from './auctions-list/AuctionsList';

import './Auctions.scss';

function Auctions() {
  const [t] = useTranslation('pages/auctions');
  const [searchParams] = useSearchParams();

  const dispatch = useDispatchAsync();
  const _auctions = useSelector(state => state.auctions.auctions);
  const [auctionsAll, setAuctionsAll] = useState();
  const [auctions, setAuctions] = useState();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (_auctions) {
      const limit = +searchParams.get('limit') || 10;
      const page = (+searchParams.get('page') || 1) - 1;
      const auctionStatuses =
        searchParams.get('status_auctions') ?? AUCTION_STATUS_LIVE;
      const res = _auctions.filter(i => i.auctionStatus === +auctionStatuses);

      setAuctionsAll(res);
      setAuctions(res.slice(page * limit, page * limit + limit));
    }
  }, [searchParams, _auctions]);

  useEffect(async () => {
    await setLoading(() => true);
    await dispatch(getAuctionsInfo());
    await setLoading(() => false);
  }, []);

  if (!auctionsAll || isLoading === true)
    return (
      <div className='Auctions container'>
        <Loader />
      </div>
    );

  return (
    <div className='Auctions container'>
      <div className='filters_panel'>
        <div className='filters_header'>
          <div className='totalLots'>
            {t('all_auctions')} : {_auctions.length}
          </div>
        </div>
        <AuctionsFilters />
      </div>
      <div className='auctions_main'>
        <SettingsPanel auctionsAll={auctionsAll} />
        {isLoading === true ? (
          <Loader />
        ) : auctionsAll.length !== 0 ? (
          <>
            <AuctionsList auctions={auctions} />
            <SettingsPanel auctionsAll={auctionsAll} />
          </>
        ) : (
          <div className='noCarsFound'>
            <h1>{t('no_auctions_found')}</h1>
            <h2>{t('try_select_another_filter')}</h2>
            <Button text={t('clear_filter')} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Auctions;

import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {Toast} from '@helpers';
import {useDispatchAsync} from '@hooks';

import {
  createFilter,
  createFilterValue,
  createSyncWithFeatures,
  deleteFilter,
  deleteSyncWithFeatures,
  deleteValueStructure,
  getFilters,
  updateFilter,
  updateSyncWithFeatures,
  updateValueStructure,
} from '@actions/admin/filters';

import Table from '../../../2-components/table/Table';
import checkRequiredFields from '../../../modules/checkRequiredFields';
import useUpdateData from '../../../hooks/update-data.hook';

import {config} from './config';

import './Filters.scss';

function Filters() {
  const dispatch = useDispatchAsync();
  const api = useUpdateData();
  const filters = useSelector(state => state.admin.filters);

  useEffect(() => {
    dispatch(getFilters());
  }, []);

  useEffect(() => {
    //onSave
    config.onSave = async ({next, fieldMustToUpdated}) => {
      for (const {field, value} of fieldMustToUpdated) {
        await api(updateFilter(next.id, field, value));
      }
    };
    config.fields.title.onSave = async ({idRow, fieldMustToUpdated}) => {
      for (const {field, value} of fieldMustToUpdated) {
        await api(updateFilter(idRow, 'title.' + field, value));
      }
    };
    config.fields.valueStructure.onSave = async ({
      idRow,
      next,
      fieldMustToUpdated,
    }) => {
      for (const {field, value} of fieldMustToUpdated) {
        await api(updateValueStructure(idRow, next.id, field, value));
      }
    };
    config.fields.valueStructure.fields.syncWithFeatures.onSave = async ({
      next,
      fieldMustToUpdated,
    }) => {
      for (const {field, value} of fieldMustToUpdated) {
        await api(updateSyncWithFeatures(next.id, field, value));
      }
    };

    //onCreate
    config.onCreate = async (idRow, newFilter) => {
      const res = checkRequiredFields(config.fields, newFilter);
      if (!res) {
        if (filters.find(el => el.filterOption === newFilter.filterOption)) {
          Toast.warning('Фільтер опція має бути унікальною !');
          return;
        }
        return await api(createFilter(newFilter));
      } else {
        Toast.warning('Заповніть обов`язкове поле для введення - ' + res);
      }
    };
    config.fields.valueStructure.onCreate = async (idRow, newVS) => {
      const res = checkRequiredFields(
        config.fields.valueStructure.fields,
        newVS
      );
      if (!res) {
        if (filters.find(el => el.code === newVS.code)) {
          Toast.warning('Сode має бути унікальним !');
          return;
        }
        return await api(createFilterValue(idRow, newVS));
      } else {
        Toast.warning('Заповніть обов`язкове поле для введення - ' + res);
      }
    };

    //onSynchronize
    config.fields.valueStructure.fields.syncWithFeatures.onSynchronize = async (
      idRow,
      feature,
      featureVs
    ) => {
      return await api(
        createSyncWithFeatures(
          idRow,
          feature.id,
          feature.valueType,
          featureVs.code
        )
      );
    };
    config.fields.valueStructure.onSyncFilter = async (idRow, feature) => {
      return await api(updateFilter(idRow, 'syncFeatureId', feature.id));
    };

    //onDelete
    config.onDelete = async selectedProduct => {
      await api(deleteFilter(selectedProduct.id));
    };
    config.fields.valueStructure.onDelete = async (selectedProduct, idRow) => {
      await api(deleteValueStructure(idRow, selectedProduct.id));
    };
    config.fields.valueStructure.fields.syncWithFeatures.onDelete = async (
      selectedProduct,
      idRow
    ) => {
      await api(deleteSyncWithFeatures(idRow, selectedProduct.id));
    };

    //onLoad
    async function onLoad() {
      await dispatch(getFilters());
    }
    config.onLoad = onLoad;
    config.fields.title.onLoad = onLoad;
    config.fields.valueStructure.onLoad = onLoad;
    config.fields.valueStructure.fields.syncWithFeatures.onLoad = onLoad;
  });
  return (
    <div className='Filters'>
      <Table config={config} data={filters} name={'Filters'} />
    </div>
  );
}

export default Filters;

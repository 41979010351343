import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useUpdatedState} from '@hooks';
import {Select} from '@ui';

import './Sorting.scss';

function Sorting({auctionId}) {
  const [t] = useTranslation('pages/cars');

  const [searchParams, setSearchParams] = useSearchParams();

  const sortOptions = [
    {value: 'po_1', title: '↓ ' + t('popularity') + ' (1-9)'},
    {value: 'po_-1', title: '↑ ' + t('popularity') + ' (9-1)'},
    {value: 'n_1', title: '↓ ' + t('number') + ' (1-9)'},
    {value: 'n_-1', title: '↑ ' + t('number') + ' (9-1)'},
    {value: 'p_1', title: '↓ ' + t('price') + ' (1-9)'},
    {value: 'p_-1', title: '↑ ' + t('price') + ' (9-1)'},
    {value: 'kw_1', title: '↓ ' + t('power_kW') + ' (1-9)'},
    {value: 'kw_-1', title: '↑ ' + t('power_kW') + ' (9-1)'},
    {value: 'hp_1', title: '↓ ' + t('power_k.s.') + ' (1-9)'},
    {value: 'hp_-1', title: '↑ ' + t('power_k.s.') + ' (9-1)'},
    {value: 'd_1', title: '↓ ' + t('registration_date') + ' (1-9)'},
    {value: 'd_-1', title: '↑ ' + t('registration_date') + ' (9-1)'},
    {value: 'ml_1', title: '↓ ' + t('race') + ' (1-9)'},
    {value: 'ml_-1', title: '↑ ' + t('race') + ' (9-1)'},
  ];
  const [sorting, setSorting] = useUpdatedState(
    searchParams.get('sort') || sortOptions[auctionId ? 3 : 1].value
  );

  function setSortingHandler(_sorting) {
    if (!_sorting) return;
    searchParams.set('sort', _sorting);
    setSorting(_sorting);
    setSearchParams(searchParams, {replace: true});
  }

  return (
    <div className='Sorting'>
      <Select
        value={sorting || t('sort_by')}
        onChange={e => setSortingHandler(e.target.value)}
        data={sortOptions}
      />
    </div>
  );
}

export default Sorting;

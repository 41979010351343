import React from 'react';
import {Dropdown} from 'primereact/dropdown';
import {Tag} from 'primereact/tag';
import {
  ORDER_STATUS_NEW,
  ORDER_STATUS_SUCCESSFUL,
  ORDER_STATUS_SUCCESSFUL_MANUAL,
} from '@models';

function PaymentStatus(_editorData) {
  const editorData = {};
  return options => {
    const {value, editorCallback, rowData} = options;
    const tag = _editorData.find(i => i.indexInDB === value);

    if (editorData[rowData.id] === undefined) {
      if (value === ORDER_STATUS_NEW) {
        editorData[rowData.id] = _editorData;
      } else if (
        value !== ORDER_STATUS_SUCCESSFUL &&
        value !== ORDER_STATUS_SUCCESSFUL_MANUAL
      ) {
        editorData[rowData.id] = [
          _editorData.find(i => i.indexInDB === value),
          _editorData.find(i => i.indexInDB === ORDER_STATUS_SUCCESSFUL_MANUAL),
        ];
      } else {
        editorData[rowData.id] = [_editorData.find(i => i.indexInDB === value)];
      }
    }

    const data = editorData[rowData.id];
    return (
      <Dropdown
        value={tag?.title || ''}
        placeholder='Select...'
        style={{height: '30px', alignItems: 'center'}}
        options={data.map(i => i.title)}
        onChange={e => {
          editorData[rowData.id] = undefined;
          editorCallback(data.find(i => i.title === e.value).indexInDB);
        }}
        itemTemplate={option => {
          const tag = data.find(i => i.title === option);
          return <Tag value={tag.title} severity={tag.severity}></Tag>;
        }}
      />
    );
  };
}
export default PaymentStatus;

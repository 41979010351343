import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import {Logger} from '@helpers';

i18n._languages = [
  {
    key: 'uk',
    name: 'Українська',
  },
  {
    key: 'en',
    name: 'English',
  },
  {
    key: 'pl',
    name: 'Polski',
  },
  {
    key: 'ru',
    name: 'російська',
  },
];

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    ns: ['components'],
    defaultNS: 'components',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

i18n.setLanguage = langCode => {
  i18n.changeLanguage(langCode);
  i18n.currentLang = langCode;
  localStorage.setItem('i18nextLng', langCode);
};

async function languageDetector() {
  const i18nextLng = localStorage.getItem('i18nextLng');
  if (i18nextLng) {
    const findLang = i18n._languages.find(it => it.key === i18nextLng);
    if (findLang) {
      i18n.setLanguage(i18nextLng);
      return;
    }
  }
  // Only for first visit site when do not setted value i18nextLng
  try {
    const response = await fetch(process.env.REACT_APP_GEOAPIFY, {
      method: 'GET',
    });

    const data = await response.json();
    const languages = data?.country?.languages;
    if (!data || !languages) {
      throw new Error(`Bad response from geopify`);
    }
    for (const {iso_code} of languages) {
      const findLang = i18n._languages.find(it => it.key === iso_code);
      if (findLang) {
        i18n.setLanguage(iso_code);
        return;
      }
    }
    throw new Error(
      `Some Languages is not detected Codes: ${languages.map(
        el => el.iso_code
      ).join(', ')}`
    );
  } catch (error) {
    Logger.catch(error, `Error in language Detector`);
    i18n.setLanguage(i18n._languages[0].key);
  }
}
languageDetector();

export default i18n;

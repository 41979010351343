import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {ReactComponent as ArrowGreen} from '@assets/icons/arrow-green.svg';
import {checkCode, sentNumberPhone} from '@actions/registration';
import {InputCode, Button} from '@ui';
import {useDispatchAsync} from '@hooks';

import './Step_2.scss';

function Step_2() {
  const dispatch = useDispatchAsync();
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [t] = useTranslation('pages/registration', {
    keyPrefix: 'step_2',
  });
  const {phoneId, phoneNumber, code, userId} = useSelector(
    state => state.registration
  );

  useEffect(() => {
    if (!phoneId && !code) {
      return navigate('/registration/Step_1');
    }
    setValue(code || '');
  }, []);

  function showMessage(message, isError = true) {
    const itemMessage = document.querySelector('.under_input_text');

    if (isError) {
      itemMessage.innerHTML = message;
      itemMessage.style.color = 'red';
    } else {
      itemMessage.innerHTML = t('under_input_text');
      itemMessage.style.color = '#999999';
    }
  }

  async function onClickHandler() {
    if (code) {
      return navigate('/registration/Step_3');
    }
    const res = await dispatch(checkCode(value, phoneId), false);
    if (res.ok) {
      return navigate('/registration/Step_3');
    } else {
      showMessage(t(`errors.${res.code}`, t('errors.unknown')), true);
    }
  }

  async function onSendAgain() {
    const res = await dispatch(sentNumberPhone(phoneNumber, userId), false);
    if (res.ok) {
      setValue('');
      showMessage(t('under_input_text'), false);
    }

    if (res.ok === false) {
      showMessage(t(`errors.${res.code}`, t('errors.unknown')), true);
    }
  }

  return (
    <div className='Step_2'>
      <div className='titles'>
        <div className='main_title'>{t('main_title')}</div>
        <div className='sub_title'>
          {t('sub_title')} <br></br>
          {t('sub_title2')}
        </div>
      </div>
      <div className='content'>
        <InputCode
          title={t('title')}
          value={value}
          onChangeHandler={num => setValue(num)}
          under_input_text={t('under_input_text')}
        />
        <div className='navigationBloc'>
          <div className='navigationBtn'>
            <Button onClick={onClickHandler} text={t('btnext')} />
            <Button
              className='gray'
              onClick={onSendAgain}
              text={t('btSentAgain')}
            />
          </div>

          <Button
            className='greenwhite'
            to='/registration/Step_1'
            text={t('btback')}>
            <ArrowGreen />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Step_2;

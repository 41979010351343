import './ProgressBar.scss';
function ProgressBar({
  steps,
  activeStep,
  activeNumber,
  gap = 10,
  className,
  orientation = 'column',
}) {
  if (!steps || steps.length === 0 || steps.length < activeNumber) return <></>;

  const circleDiameter = 30;
  return (
    <div
      className={`ProgressBar ${className} ${orientation}`}
      style={{gap: gap + 'px'}}>
      {steps.map((it, index) => (
        <div
          key={index}
          style={
            (orientation === 'column'
              ? {maxHeight: circleDiameter + 'px'}
              : null,
            orientation === 'row' ? {maxWidth: circleDiameter + 'px'} : null)
          }
          className={`step step_${index}
          ${index === activeStep ? 'current' : ''}
          ${index < activeStep ? 'passed' : ''}`}>
          <div
            className='circle'
            style={{
              width: circleDiameter + 'px',
              height: circleDiameter + 'px',
            }}>
            <span>{index + 1}</span>
          </div>
          <div className='title'>{it}</div>
          {!index || (
            <div
              className='line'
              style={
                orientation === 'column'
                  ? {
                    height: circleDiameter + gap + 'px',
                    top: -(gap + circleDiameter) + 'px',
                  }
                  : {
                    height: 0,
                    width: circleDiameter + gap + 'px',
                    top: circleDiameter / 2 + 'px',
                    left: -gap + 'px',
                  }
              }></div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ProgressBar;

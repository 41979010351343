import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button} from '@ui';
import {useLocation} from 'react-router-dom';

import './Page404.scss';

function Page404() {
  const [t] = useTranslation('', {keyPrefix: '404'});
  const location = useLocation();
  return (
    <div className='Page404'>
      <div className='title404'>
        <svg
          width='341'
          height='142'
          viewBox='0 0 341 142'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M106.142 91.3811V116.177H91.6137V139.229H64.6874V116.177H0.568V91.3811L45.7034 3.62852H74.3731L29.4314 91.3811H64.6874V59.2245H91.6137V91.3811H106.142ZM169.889 141.941C152.972 141.941 139.67 135.548 129.984 122.763C120.428 109.849 115.649 92.7371 115.649 71.4285C115.649 50.1199 120.428 33.0731 129.984 20.288C139.67 7.37367 152.972 0.916522 169.889 0.916522C186.936 0.916522 200.238 7.37367 209.795 20.288C219.351 33.0731 224.129 50.1199 224.129 71.4285C224.129 92.7371 219.351 109.849 209.795 122.763C200.238 135.548 186.936 141.941 169.889 141.941ZM149.356 104.554C154.005 112.044 160.849 115.789 169.889 115.789C178.929 115.789 185.774 111.979 190.423 104.36C195.201 96.7405 197.591 85.7634 197.591 71.4285C197.591 56.9645 195.201 45.9228 190.423 38.3034C185.774 30.6839 178.929 26.8742 169.889 26.8742C160.849 26.8742 154.005 30.6839 149.356 38.3034C144.707 45.9228 142.382 56.9645 142.382 71.4285C142.382 85.8925 144.707 96.9342 149.356 104.554ZM340.529 91.3811V116.177H326V139.229H299.074V116.177H234.955V91.3811L280.09 3.62852H308.76L263.818 91.3811H299.074V59.2245H326V91.3811H340.529Z'
            fill='#00DF94'
          />
        </svg>
      </div>
      <div className='title'>{t('title')}</div>
      <div className='description'>{t('description')}</div>
      <Button
        to={location.pathname.includes('/org') ? '/org' : '/'}
        text={t('btGoHome')}
      />
    </div>
  );
}

export default Page404;

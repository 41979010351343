import {
  AGENT_LOGIN,
  AGENT_LOGOUT,
  GET_AGENT_RIGHTS,
  GET_FILTER_SOURCE_FEATURES_IDS,
  GET_UNDER_DRAFT_LOTS,
  GET_UNDER_DRAFT_LOT_BY_ID,
  GET_UNDER_FEATURES,
  GET_UNDER_FEATURES_BY_CATEGORY,
} from './types';

export const agentLogin = (login, password) => ({
  event: AGENT_LOGIN,
  link: '/api/org/login',
  data: {
    login: login,
    password: password,
  },
});

export const agentLogout = () => ({
  event: AGENT_LOGOUT,
  link: '/api/org/logout',
  data: {},
});

export const getAgentRights = () => ({
  event: GET_AGENT_RIGHTS,
  link: '/api/agent/agent_rights',
});

export const createUnderDraftLot = info => ({
  event: 'create_under_draft_lot',
  link: '/api/agent/create_under_draft_lot',
  data: info,
});

export const setUnderDraftLotInfo = info => ({
  event: 'set_under_draft_lot_info',
  link: '/api/agent/set_under_draft_lot_info',
  data: info,
});

export const setUnderDraftLotImages = info => ({
  event: 'set_under_draft_lot_images',
  link: '/api/agent/set_under_draft_lot_images',
  data: info,
});

export const getUnderDraftLots = () => ({
  event: GET_UNDER_DRAFT_LOTS,
  link: '/api/agent/get_under_draft_lots',
});

export const getSourceFeatureIdsForFilters = () => ({
  event: GET_FILTER_SOURCE_FEATURES_IDS,
  link: '/api/agent/get_filter_source_features_ids',
});

export const getUnderDraftLotInfoById = lotId => ({
  event: GET_UNDER_DRAFT_LOT_BY_ID,
  link: '/api/agent/get_under_draft_lot_info_by_id',
  data: {
    lotId,
  },
});

export const getUnderDraftLotImagesById = lotId => ({
  event: 'get_under_draft_lot_images_by_id',
  link: '/api/agent/get_under_draft_lot_images_by_id',
  data: {
    lotId,
  },
});

export const deleteLotImage = (lotId, imageId) => ({
  event: 'delete_lot_image',
  link: '/api/agent/delete_lot_image',
  data: {
    lotId,
    imageId,
  },
});

export const deleteUnderDraftLot = lotId => ({
  event: 'delete_under_draft_lot',
  link: '/api/agent/delete_under_draft_lot',
  data: {
    lotId,
  },
});

export const getUnderFeatures = () => ({
  event: GET_UNDER_FEATURES,
  link: '/api/features/under_features',
});

export const getUnderFeaturesByCategory = category => ({
  event: GET_UNDER_FEATURES_BY_CATEGORY,
  link: '/api/features/under_features_by_category',
  data: {
    category,
  },
});

import React, {useState} from 'react';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import Table from '../Table';

import {getVFF} from '../../../modules/getValueFomField';

function InsertTable({
  config,
  field,
  synchronizeValueStructure,
  middlewareSetColumns,
}) {
  function InsertTable(rowData) {
    if ('rowData' in rowData) {
      rowData = rowData.rowData;
    }
    const _data = getVFF(rowData, field);
    const [visible, setVisible] = useState(false);

    async function onSynchronizeValueStructure(rowDataCurrent) {
      await setVisible(false);
      synchronizeValueStructure(rowData, rowDataCurrent);
    }

    return (
      <div className='card flex justify-content-center'>
        {_data ? (
          <Button
            label='Показати'
            icon='pi pi-external-link'
            onClick={() => setVisible(true)}
          />
        ) : null}

        <Dialog
          header={config.header}
          visible={visible}
          modal
          maximizable
          onHide={() => setVisible(false)}>
          <Table
            height={'70vh'}
            autoHeight={false}
            config={config}
            idRow={rowData.id}
            data={_data}
            synchronizeValueStructure={
              !!synchronizeValueStructure && onSynchronizeValueStructure
            }
            selectedProduct={_data?.find(i => i.id === rowData.syncWith)}
            middlewareSetColumns={middlewareSetColumns}
          />
        </Dialog>
      </div>
    );
  }
  return InsertTable;
}
export default InsertTable;

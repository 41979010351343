import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {formatPrice} from '@utils';
import Toast from '@helpers/toast';
import {Button, Input} from '@ui';
import {useDispatchAsync} from '@hooks';
import {bidAllowedHelpRequest} from '@actions/user';
import {
  ACCOUNT_STATUS_VERIFIED,
  LOT_STATUS_ACTIVE,
  LOT_STATUS_PREPARATION,
  LOT_STATUS_READY,
} from '@models';

import './MakeBit.scss';

function MakeBit({newBit, setNewBit}) {
  const dispatch = useDispatchAsync();
  const [t] = useTranslation('pages/lot');

  const lot = useSelector(state => state.lot);

  const minPrice =
    lot.prices.currentPrice || lot.prices.minimalPrice - lot.bidSteps[0];

  const {
    id: userId,
    status,
    lotPriceLimit,
    lotCountLimit,
    isAuthenticated,
    bidAllowed,
  } = useSelector(state => state.user);
  const [isLeaveRequest, setIsLeaveRequest] = useState(false);
  const [activeBidStep, setActiveBidStep] = useState(null);
  const [newBitInput, setNewBitInput] = useState(minPrice);
  const [warningMessage, setWarningMessage] = useState(' ');
  const [newBitLocal, setNewBitLocal] = useState(null);

  useEffect(() => {
    if (activeBidStep) {
      const value = minPrice + activeBidStep;
      if (value !== newBitLocal) {
        setNewBitLocal(value);
        setNewBitInput(value);
      }
    } else if (minPrice >= newBitLocal) {
      setNewBitLocal(null);
      setNewBitInput(minPrice);
    }
  }, [minPrice]);

  useEffect(() => {
    if (activeBidStep) {
      const value = minPrice + activeBidStep;
      setNewBitInput(value);
      setNewBitMiddleWare(value);
    }
  }, [activeBidStep]);

  useEffect(() => {
    if (newBit === null) {
      setActiveBidStep(null);
      setNewBitLocal(null);
    }
  }, [newBit]);

  useEffect(() => {
    setNewBit(newBitLocal);
  }, [newBitLocal]);

  function setNewBitMiddleWare(_value) {
    // if (!_value) return; //Сommented because it didn't work with 0
    const value = parseInt(_value);
    if (value === null || value === minPrice) {
      setNewBitLocal(null);
      setWarningMessage(' ');
      return;
    }
    const minBid = Math.min(...lot.bidSteps);
    const bidStep = value - minPrice;
    if (bidStep % minBid !== 0) {
      setNewBitLocal(null);
      setWarningMessage(t('in_multiples') + formatPrice(minBid));
      return;
    }

    if (value >= minPrice) {
      setWarningMessage(' ');
      setNewBitLocal(value);
    } else {
      if (value === minPrice) {
        setWarningMessage(' ');
      } else {
        setWarningMessage(t('low_bit', {minPrice: minPrice + lot.bidSteps[0]}));
      }
      setNewBitLocal(null);
    }
  }

  async function leaveRequestHandler() {
    const res = await dispatch(bidAllowedHelpRequest(userId));
    if (res.ok) {
      Toast.info(t('application_sent'));
    } else {
      Toast.error(t('application_sent_error'));
    }
    setIsLeaveRequest(true);
  }

  function render() {
    if (!isAuthenticated)
      return (
        <div className='noAuthenticated'>
          <h4>{t('not_authorized')}</h4>
          <div>{t('log_in')}</div>
          <Button text={t('sign_in')} to={'/auth'} />
        </div>
      );

    if (status !== ACCOUNT_STATUS_VERIFIED) {
      return (
        <div className='account_restrictions'>
          <h4 className='title'>{t('account_not_verified')}</h4>
          <h5>{t('need_verified')}</h5>
          <Button
            to={'/profile/verification'}
            target={'_blank'}
            text={t('to_verification')}
          />
        </div>
      );
    }

    if (!bidAllowed) {
      if (isLeaveRequest) {
        return (
          <div className='account_restrictions'>
            <h4 className='title'>{t('account_limits')}</h4>
            <h5>{t('manager_answer')}</h5>
          </div>
        );
      } else {
        return (
          <div className='account_restrictions'>
            <h4 className='title'>{t('account_limits')}</h4>
            <h5>{t('message')}</h5>
            <Button text={t('leave_request')} onClick={leaveRequestHandler} />
          </div>
        );
      }
    }

    switch (lot.lotStatus) {
      case LOT_STATUS_READY:
      case LOT_STATUS_PREPARATION:
      case LOT_STATUS_ACTIVE:
        return (
          <div className='bid'>
            <h4 className='title'>{t('place_bet')}</h4>
            <div className='enter'>
              <Input
                type='number'
                value={newBitInput}
                onChange={e => {
                  setActiveBidStep(null);
                  setNewBitInput(parseInt(e.target.value));
                  setNewBitMiddleWare(e.target.value);
                }}
                placeholder={t('enter_bet')}
                underTitle={warningMessage}
              />
              <div className='bidSteps'>
                {lot.bidSteps?.map((bidStep, key) => (
                  <div
                    key={key}
                    className={`bidStep ${
                      bidStep === activeBidStep ? 'active' : ''
                    }`}
                    onClick={() => {
                      if (bidStep === activeBidStep) {
                        setActiveBidStep(null);
                        setNewBitInput(minPrice);
                        setNewBitMiddleWare(minPrice);
                      } else {
                        setActiveBidStep(bidStep);
                      }
                    }}>
                    + {bidStep} €
                  </div>
                ))}
              </div>
            </div>

            <p className='bottom_text'>
              {t('should_be_increment')} <br />
              {t('your_betting_limit')} {formatPrice(lotPriceLimit)},{' '}
              {t('available_auctions')} {lotCountLimit}
            </p>
          </div>
        );
      default:
        break;
    }
  }
  const res = render();
  if (res) {
    return <div className='MakeBit'>{res}</div>;
  } else {
    return <></>;
  }
}
export default MakeBit;

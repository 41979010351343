import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';

import {useDownloadOrderDocs} from '@hooks';
import {LotMiniGalleria} from '@components';
import {ShadowContainer} from '@containers';
import {CheckBox, Loader, Button} from '@ui';
import {formatPriceWithCent} from '@utils';
import {Logger} from '@helpers';

import {ReactComponent as WireTransfer} from '@assets/icons/wire-transfer.svg';

import imoje from '@assets/images/imoje.jpg';
import pp_paynow from '@assets/images/pp_paynow.png';

import {
  ACCOUNT_TARIFF_1000,
  ACCOUNT_TARIFF_300,
  ACCOUNT_TARIFF_500,
  ACCOUNT_TARIFF_PREMIUM,
  ORDER_PAYMENT_PROVIDER_ING_PL,
  ORDER_PAYMENT_PROVIDER_MANUAL,
  ORDER_PAYMENT_PROVIDER_PAYNOW,
  ORDER_PRODUCT_TYPE_LOT,
  ORDER_PRODUCT_TYPE_TARIFF,
} from '@models';

import '../choosing-payment-method/ChoosingPaymentMethod.scss';
import './InfoAboutCheckout.scss';

function InfoAboutCheckout({
  lot,
  orderType,
  tariff,
  paymentMethod = null,
  tariffType,
  formParams,
}) {
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const invoice = useDownloadOrderDocs();
  const [t] = useTranslation('pages/payments');

  async function downloadInvoice() {
    switch (orderType) {
      case ORDER_PRODUCT_TYPE_LOT:
        invoice({lotId: lot.lotId});
        break;
      case ORDER_PRODUCT_TYPE_TARIFF:
        invoice({tariffId: tariffType});
        break;
      default:
        Logger.error(`Some unknown ORDER_PRODUCT_TYPE_*:${orderType}`);
    }
  }

  if (
    paymentMethod !== ORDER_PAYMENT_PROVIDER_PAYNOW &&
    paymentMethod !== ORDER_PAYMENT_PROVIDER_ING_PL &&
    paymentMethod !== ORDER_PAYMENT_PROVIDER_MANUAL
  ) {
    navigate(-1);
  }

  return (
    <div className='InfoAboutCheckout container'>
      <div className='main'>
        <div className='product'>
          <div className='title'>{t('product')}</div>
          {(() => {
            switch (orderType) {
              case ORDER_PRODUCT_TYPE_TARIFF:
                return (
                  <ShadowContainer className='tariffInfo'>
                    <p>{t('tariff_options')} :</p>
                    <div className='info'>
                      <p>{tariff.header}</p>
                      <p>{tariff.limit}</p>
                      <p>{tariff.active_auctions}</p>
                    </div>
                  </ShadowContainer>
                );
              case ORDER_PRODUCT_TYPE_LOT:
                return <LotMiniGalleria lot={lot} isShowLotStatus={false} />;
              default:
                return <></>;
            }
          })()}
        </div>
        <div className='paymentMethods'>
          <div className='title'>{t('payment_method')}</div>
          <div>
            <ShadowContainer>
              {paymentMethod === ORDER_PAYMENT_PROVIDER_MANUAL ? (
                <>
                  <h4>{t('wire_transfer')}</h4>
                  <WireTransfer className='WireTransfer' />
                </>
              ) : null}
              {paymentMethod === ORDER_PAYMENT_PROVIDER_ING_PL ? (
                <>
                  <h4>{t('payment_by_card')}</h4>
                  <div>ING Bank Śląski</div>
                  <div className='imoje'>
                    <img src={imoje} alt='imoje' />
                  </div>
                </>
              ) : null}
              {paymentMethod === ORDER_PAYMENT_PROVIDER_PAYNOW ? (
                <>
                  <h4>{t('payment_by_card')}</h4>
                  <div>Paynow</div>
                  <div className='pp_paynow'>
                    <img src={pp_paynow} alt='Paynow' />
                  </div>
                </>
              ) : null}
            </ShadowContainer>
            <CheckBox state={true} />
          </div>
        </div>
      </div>
      <div>
        {paymentMethod === ORDER_PAYMENT_PROVIDER_ING_PL ||
        paymentMethod === ORDER_PAYMENT_PROVIDER_PAYNOW ? (
            <p className='explanatoryInformation'>
              {t('exp_info_ing_pl')}
              <ul>
                <li> {t('info_for_support_1')}</li>
                <li> {t('info_for_support_2')}</li>
                <li> {t('info_for_support_3')}</li>
                <li> {t('info_for_support_4')}</li>
              </ul>
            </p>
          ) : null}
        {paymentMethod === ORDER_PAYMENT_PROVIDER_MANUAL ? (
          <p className='explanatoryInformation'>
            <p>{t('exp_info_manual')}</p>
            <p>
              {t('info_for_support_title')}
              <ul>
                <li> {t('info_for_support_1')}</li>
                <li> {t('info_for_support_2')}</li>
                <li> {t('info_for_support_3')}</li>
                <li> {t('info_for_support_4')}</li>
              </ul>
            </p>
          </p>
        ) : null}
      </div>
      <div className='btNext'>
        <div className='price'>
          <div>{t('price')} : </div>
          {orderType === ORDER_PRODUCT_TYPE_LOT ? (
            <p>{formatPriceWithCent(lot.lotProcessing.prices.totalPrice)}</p>
          ) : null}
          {orderType === ORDER_PRODUCT_TYPE_TARIFF ? (
            <p>
              {formatPriceWithCent(
                (() => {
                  let price = 0;
                  switch (tariffType) {
                    case ACCOUNT_TARIFF_300:
                      price = 300;
                      break;
                    case ACCOUNT_TARIFF_500:
                      price = 500;
                      break;
                    case ACCOUNT_TARIFF_1000:
                      price = 1000;
                      break;
                    case ACCOUNT_TARIFF_PREMIUM:
                      price = 2000;
                      break;
                    default:
                      Logger.error(
                        `Some new type ACCOUNT_TARIFF_* : ${tariffType}`
                      );
                      break;
                  }
                  if (price < user.lotPriceReserve) {
                    return 0;
                  }
                  return price - user.lotPriceReserve;
                })()
              )}
            </p>
          ) : null}
          <div>EUR</div>
        </div>
        {paymentMethod === ORDER_PAYMENT_PROVIDER_MANUAL ? (
          <Button onClick={downloadInvoice}>{t('download_invoice')}</Button>
        ) : null}

        {paymentMethod === ORDER_PAYMENT_PROVIDER_ING_PL ? (
          <>
            {formParams ? (
              <form action={formParams.url} method='post'>
                {Object.entries(formParams.fields).map(field => (
                  <input type='hidden' value={field[1]} name={field[0]} />
                ))}

                <input type='submit' className='Button' value={t('pay')} />
              </form>
            ) : (
              <Loader />
            )}
          </>
        ) : null}

        {paymentMethod === ORDER_PAYMENT_PROVIDER_PAYNOW ? (
          <>
            {formParams ? (
              <Link to={formParams.url}>
                <input type='submit' className='Button' value={t('pay')} />
              </Link>
            ) : (
              <Loader />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
}

export default InfoAboutCheckout;

import {
  GET_PAYMENTS,
  UPDATE_PAYMENT_PROVIDER,
  UPDATE_PAYMENT_STATUS,
} from './types';

export const getPayments = () => ({
  event: GET_PAYMENTS,
  link: '/api/org/payments',
});

export const updatePaymentStatus = (orderId, value) => ({
  event: UPDATE_PAYMENT_STATUS,
  link: '/api/org/update_payment_status',
  data: {
    orderId: orderId,
    value: value,
  },
});

export const updatePaymentProvider = (orderId, provider) => ({
  event: UPDATE_PAYMENT_PROVIDER,
  link: '/api/org/update_payment_provider',
  data: {
    orderId: orderId,
    provider: provider,
  },
});

export const getCurrentRate = () => ({
  event: 'get_current_rate',
  link: '/api/org/get_current_rate',
  excludeAbort: true,
});

import {useTranslation} from 'react-i18next';

import {DropDown} from '@ui';

import GeneratePage from '../../generate-page/GeneratePage';

import './InfoAboutAuction.scss';

function InfoAboutAuction() {
  const [t] = useTranslation('pages/introduction/info-about-auction');
  const tree = {
    1: [1],
    2: [1],
    3: [1],
    4: [1],
    5: [1],
    6: [1, 2],
  };


  const inserts = {
    link_underlot: (
      <a
        className='link_underlot'
        href='https://www.underlot.com/'
        target='_blank' rel='noreferrer'
      >
        Underlot.com
      </a>
    ),
    'br': (<span> <br /> <br /></span>),
  };
  function toUL(text) {
    const data = text.split('{{br}}');
    return (
      <span>
        <span>{data[0]}</span>
        <ul>
          {data.slice(1).map((it, index) => (
            <li key={index}>{it}</li>
          ))}
        </ul>
      </span>
    );
  }
  return (
    <div className='InfoAboutAuction'>
      <GeneratePage  tree={tree} inserts={inserts} t={t} />

      <DropDown key={7} title={t('7')}>
        <div>
          <table>
            <tr>
              <td>{t('7-1-1')}</td>
              <td>{t('7-1-2')}</td>
              <td>{t('7-1-3')}</td>
              <td>{t('7-1-4')}</td>
            </tr>
            <tr>
              <td>{t('7-2-1')}</td>
              <td>{t('7-2-2')}</td>
              <td>{toUL(t('7-2-3'))}</td>
              <td>{toUL(t('7-2-4'))}</td>
            </tr>
            <tr>
              <td>{t('7-3-1')}</td>
              <td>{t('7-3-2')}</td>
              <td>{t('7-3-3')}</td>
              <td>{t('7-3-4')}</td>
            </tr>
            <tr>
              <td>{t('7-4-1')}</td>
              <td>{t('7-4-2')}</td>
              <td>{t('7-4-3')}</td>
              <td>{toUL(t('7-4-4'))}</td>
            </tr>
          </table>
        </div>
      </DropDown >
    </div>
  );
}
export default InfoAboutAuction;

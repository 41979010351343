import {SelectCountry} from '@components/index';
import {Logger} from '@helpers';
import {
  LOT_FEATURE_VALUE_TYPE_NUMBER,
  LOT_FEATURE_VALUE_TYPE_STRING,
  LOT_FEATURE_VALUE_TYPE_BOOLEAN,
  LOT_FEATURE_VALUE_TYPE_DATE,
  LOT_FEATURE_VALUE_TYPE_ENUM,
  LOT_FEATURE_VALUE_TYPE_SET,
} from '@helpers/models';
import {CheckBox, DropDownList, Input, MultiSelect} from '@ui/index';

function FeatureElement({feature, value = '', onChange}) {
  const {code, valueStructure} = feature;
  const featureTitle = feature.name;
  let featureElement;

  const getInputByType = type => (
    <Input
      type={type}
      headerTitle={featureTitle}
      value={value}
      onChange={e => onChange(e.target.value)}
    />
  );
  switch (feature.valueType) {
    case LOT_FEATURE_VALUE_TYPE_BOOLEAN:
      featureElement = (
        <div className='CheckBoxItem'>
          <CheckBox
            name={featureTitle}
            type={'square'}
            state={value === 'true' ? true : false}
            setState={state => onChange(state.toString())}
          />
          <p>{featureTitle}</p>
        </div>
      );
      break;
    case LOT_FEATURE_VALUE_TYPE_NUMBER:
      if (code === 'location') {
        featureElement = (
          <SelectCountry
            headerTitle={featureTitle}
            value={value}
            onChange={value => onChange(value)}
          />
        );
      } else {
        featureElement = getInputByType('number');
      }
      break;
    case LOT_FEATURE_VALUE_TYPE_STRING:
      featureElement = getInputByType('text');
      break;
    case LOT_FEATURE_VALUE_TYPE_DATE:
      featureElement = getInputByType('date');
      break;
    case LOT_FEATURE_VALUE_TYPE_ENUM: {
      if (!valueStructure || valueStructure.length === 0) {
        return <></>;
      }
      featureElement = (
        <DropDownList
          headerTitle={featureTitle}
          value={valueStructure.find(
            i => i.value.toString() === value.toString()
          )}
          onChange={e => {
            onChange(e.value);
          }}
          options={valueStructure}
        />
      );
      break;
    }
    case LOT_FEATURE_VALUE_TYPE_SET:
      if (!valueStructure || valueStructure.length === 0) {
        return <></>;
      }
      featureElement = (
        <MultiSelect
          optionLabel={'title'}
          headerTitle={featureTitle}
          value={
            value.length > 0 && value.split(';').length > 0
              ? value.split(';')
              : ''
          }
          onChange={values => {
            onChange(values.join(';'));
          }}
          options={valueStructure}
        />
      );
      break;
    default:
      Logger.error(`Some new LOT_FEATURE_VALUE_TYPE_*: ${feature.valueType}`);
  }

  return (
    <div className='FeatureElement' key={code}>
      {featureElement}
    </div>
  );
}

export default FeatureElement;

export const AGENT_LOGOUT = 'AGENT_LOGOUT';

export const AGENT_LOGIN = 'AGENT_LOGIN';
export const AGENT_LOGIN_SUCCESS = 'AGENT_LOGIN_SUCCESS';
export const AGENT_LOGIN_FAILURE = 'AGENT_LOGIN_FAILURE';

export const GET_AGENT_RIGHTS = 'GET_AGENT_RIGHTS';
export const GET_AGENT_RIGHTS_SUCCESS = 'GET_AGENT_RIGHTS_SUCCESS';
export const GET_AGENT_RIGHTS_FAILURE = 'GET_AGENT_RIGHTS_FAILURE';

export const GET_FILTER_SOURCE_FEATURES_IDS = 'GET_FILTER_SOURCE_FEATURES_IDS';
export const GET_FILTER_SOURCE_FEATURES_IDS_SUCCESS =
  'GET_FILTER_SOURCE_FEATURES_IDS_SUCCESS';
export const GET_FILTER_SOURCE_FEATURES_IDS_FAILURE =
  'GET_FILTER_SOURCE_FEATURES_IDS_FAILURE';

export const GET_UNDER_DRAFT_LOTS = 'GET_UNDER_DRAFT_LOTS';
export const GET_UNDER_DRAFT_LOTS_SUCCESS = 'GET_UNDER_DRAFT_LOTS_SUCCESS';
export const GET_UNDER_DRAFT_LOTS_FAILURE = 'GET_UNDER_DRAFT_LOTS_FAILURE';

export const GET_UNDER_FEATURES_BY_CATEGORY = 'GET_UNDER_FEATURES_BY_CATEGORY';
export const GET_UNDER_FEATURES_BY_CATEGORY_SUCCESS = 'GET_UNDER_FEATURES_BY_CATEGORY_SUCCESS';
export const GET_UNDER_FEATURES_BY_CATEGORY_FAILURE = 'GET_UNDER_FEATURES_BY_CATEGORY_FAILURE';

export const GET_UNDER_FEATURES = 'GET_UNDER_FEATURES';
export const GET_UNDER_FEATURES_SUCCESS = 'GET_UNDER_FEATURES_SUCCESS';
export const GET_UNDER_FEATURES_FAILURE = 'GET_UNDER_FEATURES_FAILURE';


export const GET_UNDER_DRAFT_LOT_BY_ID = 'GET_UNDER_DRAFT_LOT_BY_ID';
export const GET_UNDER_DRAFT_LOT_BY_ID_SUCCESS = 'GET_UNDER_DRAFT_LOT_BY_ID_SUCCESS';
export const GET_UNDER_DRAFT_LOT_BY_ID_FAILURE = 'GET_UNDER_DRAFT_LOT_BY_ID_FAILURE';

export const CLEAR_UNDER_DRAFT_LOT = 'CLEAR_UNDER_DRAFT_LOT';

import {
  LOT_SURCHARGES_TYPE_SERVICE_FEE,
  LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_REGULAR,
  LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_SUV,
  LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_BUS,
  LOT_SURCHARGES_TYPE_AUTOBID_FEE_NET,
  LOT_SURCHARGES_TYPE_AUTOBID_FEE_GROSS,
  LOT_SURCHARGES_TYPE_AUTOBID_NET_SERVICE,
  LOT_SURCHARGES_TYPE_AUTOBID_ONLINE_HANDLING_GROSS,
  LOT_SURCHARGES_TYPE_AUTOBID_ONLINE_HANDLING_NET,
  LOT_SURCHARGES_TYPE_AUTOBID_DOCUMENTS_HANDLING,
  LOT_SURCHARGES_TYPE_AUTOBID_VAT_EU,
  LOT_PROCESSING_STATUS_NEW,
  LOT_PROCESSING_STATUS_ADMIN_CHECKED,
  LOT_PROCESSING_STATUS_PAYED,
  LOT_PROCESSING_STATUS_DELIVERY,
  LOT_PROCESSING_STATUS_DELIVERED,
  LOT_PROCESSING_STATUS_DONE,
  LOT_PROCESSING_STATUS_REJECTED,
} from '@models';

export const config = {
  header: 'LotsProcessing',
  type: 'table',
  fields: {
    accountInfo: {
      header: 'User info',
      type: 'userLink',
    },
    lotInfo: {
      header: 'Lot Id',
      type: 'lotLink',
    },

    status: {
      header: 'Статус',
      modifier: 'public',
      type: 'listStatus',
      editorData: [
        {
          indexInDB: LOT_PROCESSING_STATUS_NEW,
          title: 'Нове',
          severity: 'warning',
        },
        {
          indexInDB: LOT_PROCESSING_STATUS_ADMIN_CHECKED,
          title: 'Перевірено Адміністратором',
          severity: 'warning',
        },
        {
          indexInDB: LOT_PROCESSING_STATUS_PAYED,
          title: 'Оплачено',
          severity: 'info',
        },
        {
          indexInDB: LOT_PROCESSING_STATUS_DELIVERY,
          title: 'Відправлено',
          severity: 'info',
        },
        {
          indexInDB: LOT_PROCESSING_STATUS_DELIVERED,
          title: 'Доставлено',
          severity: 'success',
        },
        {
          indexInDB: LOT_PROCESSING_STATUS_DONE,
          title: 'Завершено',
          severity: 'success',
        },
        {
          indexInDB: LOT_PROCESSING_STATUS_REJECTED,
          title: 'Відхилено',
          severity: 'danger',
        },
      ],
    },
    'prices.priceType': {
      header: 'Тип ціни',
      modifier: 'public',
      type: 'list',
      editorData: [
        {indexInDB: 1, title: 'NET', severity: 'info'},
        {indexInDB: 2, title: 'GROSS', severity: 'warning'},
      ],
    },

    'prices.currentPrice': {
      header: 'Поточна ціна',
      modifier: 'public',
      type: 'price',
    },
    'prices.totalPrice': {
      header: 'Кінцева ціна',
      type: 'price',
    },
    'prices.surcharges': {
      header: 'Доплати',
      type: 'table',
      fields: {
        taxType: {
          header: 'Тип податку',
          modifier: 'public',
          type: 'list',
          required: true,
          editorData: [
            {
              indexInDB: LOT_SURCHARGES_TYPE_SERVICE_FEE,
              title: 'Комісія Underlot',
              severity: 'info',
            },
            {
              indexInDB: LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_REGULAR,
              title: 'Доставка легковика',
              severity: 'info',
            },
            {
              indexInDB: LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_SUV,
              title: 'Доставка позашляховика ',
              severity: 'info',
            },
            {
              indexInDB: LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_BUS,
              title: 'Доставка буса',
              severity: 'info',
            },
            {
              indexInDB: LOT_SURCHARGES_TYPE_AUTOBID_FEE_NET,
              title: 'Комісія AutoBid Нетто',
              severity: 'info',
            },
            {
              indexInDB: LOT_SURCHARGES_TYPE_AUTOBID_FEE_GROSS,
              title: 'Комісія AutoBid Брутто',
              severity: 'info',
            },
            {
              indexInDB: LOT_SURCHARGES_TYPE_AUTOBID_NET_SERVICE,
              title: 'Плата за обслуговування',
              severity: 'info',
            },
            {
              indexInDB: LOT_SURCHARGES_TYPE_AUTOBID_ONLINE_HANDLING_GROSS,
              title: 'Онлайн-обробка Брутто',
              severity: 'info',
            },
            {
              indexInDB: LOT_SURCHARGES_TYPE_AUTOBID_ONLINE_HANDLING_NET,
              title: 'Онлайн-обробка Нетто',
              severity: 'info',
            },
            {
              indexInDB: LOT_SURCHARGES_TYPE_AUTOBID_DOCUMENTS_HANDLING,
              title: 'Обробка документів',
              severity: 'info',
            },
            {
              indexInDB: LOT_SURCHARGES_TYPE_AUTOBID_VAT_EU,
              title: 'Податок на додану вартість',
              severity: 'info',
            },
          ],
        },
        value: {
          header: 'Значення',
          modifier: 'public',
          type: 'price',
          required: true,
        },
      },
    },
    orders: {
      header: 'Ордери',
      type: 'ordersLink',
    },
    invoice: {
      header: 'Інвойси',
      type: 'generateLotInvoice',
    },
    createdAt: {
      header: 'Створено',
      type: 'date',
    },
  },
};

import {
  CLEAR_ACTIVE_LOT_DATA,
  GET_ACTIVE_LOT_INFO_SUCCESS,
  UPDATE_ACTIVE_LOT_DATA,
} from './types';

const initialState = {};

const activeLotReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVE_LOT_INFO_SUCCESS:
      return {...action.payload};

    case UPDATE_ACTIVE_LOT_DATA: {
      if (JSON.stringify(state) === '{}') break;
      const {field, value} = action.payload;
      const _state = {...state};
      eval('_state.' + field + '=' + JSON.stringify(value));
      return _state;
    }

    case CLEAR_ACTIVE_LOT_DATA:
      return {};
    default:
      //Do nothing
      break;
  }

  return state;
};

export default activeLotReducer;

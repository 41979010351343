export const GET_FAVORITES_CARS = 'GET_FAVORITES_CARS';
export const GET_FAVORITES_CARS_SUCCESS = 'GET_FAVORITES_CARS_SUCCESS';
export const GET_FAVORITES_CARS_FAILURE = 'GET_FAVORITES_CARS_FAILURE';

export const GET_BIDS_CARS = 'GET_BIDS_CARS';
export const GET_BIDS_CARS_SUCCESS = 'GET_BIDS_CARS_SUCCESS';
export const GET_BIDS_CARS_FAILURE = 'GET_BIDS_CARS_FAILURE';

export const GET_WON_CARS = 'GET_WON_CARS';
export const GET_WON_CARS_SUCCESS = 'GET_WON_CARS_SUCCESS';
export const GET_WON_CARS_FAILURE = 'GET_WON_CARS_FAILURE';

export const GET_NO_WON_CARS = 'GET_NO_WON_CARS';
export const GET_NO_WON_CARS_SUCCESS = 'GET_NO_WON_CARS_SUCCESS';
export const GET_NO_WON_CARS_FAILURE = 'GET_NO_WON_CARS_FAILURE';

export const GET_WON_WITH_CAVEAT_CARS = 'GET_WON_WITH_CAVEAT_CARS';
export const GET_WON_WITH_CAVEAT_CARS_SUCCESS =
  'GET_WON_WITH_CAVEAT_CARS_SUCCESS';
export const GET_WON_WITH_CAVEAT_CARS_FAILURE =
  'GET_WON_WITH_CAVEAT_CARS_FAILURE';

import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import {useUpdatedState} from '@hooks';
import {Select} from '@ui';

import './ItemsLimit.scss';

function ItemsLimit() {
  const [t] = useTranslation('pages/cars');
  const [searchParams, setSearchParams] = useSearchParams();

  const limitOptions = [
    {value: 10, title: t('number_cars') + '10'},
    {value: 20, title: t('number_cars') + '20'},
    {value: 30, title: t('number_cars') + '30'},
  ];
  const [limit, setLimit] = useUpdatedState(
    parseInt(searchParams.get('limit')) || limitOptions[0].value
  );

  function setLimitHandler(_limit) {
    if (!_limit || _limit === searchParams.get('limit')) return;
    setLimit(_limit);
    searchParams.set('limit', _limit);
    if (searchParams.has('page')) {
      searchParams.delete('page');
    }
    setSearchParams(searchParams, {replace: true});
  }

  return (
    <div className='ItemsLimit'>
      <Select
        value={limit}
        onChange={e => setLimitHandler(e.target.value)}
        data={limitOptions}
      />
    </div>
  );
}

export default ItemsLimit;

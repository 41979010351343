export const GET_LOT_INFO = 'GET_LOT_INFO';
export const GET_LOT_INFO_SUCCESS = 'GET_LOT_INFO_SUCCESS';
export const GET_LOT_INFO_FAILURE = 'GET_LOT_INFO_FAILURE';

export const UPDATE_LOT_DATA = 'UPDATE_LOT_DATA';
export const CLEAR_LOT_DATA = 'CLEAR_LOT_DATA';

export const GET_LOT_PRICES = 'GET_LOT_PRICES';
export const GET_LOT_PRICES_SUCCESS = 'GET_LOT_PRICES_SUCCESS';
export const GET_LOT_PRICES_FAILURE = 'GET_LOT_PRICES_FAILURE';

export const GET_NEXT_LOT = 'GET_NEXT_LOT';
export const GET_NEXT_LOT_SUCCESS = 'GET_NEXT_LOT_SUCCESS';
export const GET_NEXT_LOT_FAILURE = 'GET_NEXT_LOT_FAILURE';

export const GET_PREV_LOT = 'GET_PREV_LOT';
export const GET_PREV_LOT_SUCCESS = 'GET_PREV_LOT_SUCCESS';
export const GET_PREV_LOT_FAILURE = 'GET_PREV_LOT_FAILURE';

export const SET_LOT_NOTE = 'SET_LOT_NOTE';
export const SET_LOT_NOTE_SUCCESS = 'SET_LOT_NOTE_SUCCESS';
export const SET_LOT_NOTE_FAILURE = 'SET_LOT_NOTE_FAILURE';
import {Logger} from '@helpers';

export function timeToString(time = Date.now()) {
  const date = new Date(time);
  const years = date.getFullYear();
  let mons = date.getMonth() + 1;
  let days = date.getDate();
  if (mons < 10) mons = '0' + mons;
  if (days < 10) days = '0' + days;
  return `${days}.${mons}.${years}`;
}

export function formatDataTime(time = Date.now()) {
  const date = new Date(time);
  const hours = date.getHours();
  const minutes = `0${date.getMinutes()}`.slice(-2);

  return [timeToString(time), `${hours < 10 ? '0' : ''}${hours}:${minutes}`];
}

export function formatPrice(price = '0', withCent = false, currency = '€') {
  if (price === null) {
    price = '0';
    Logger.error('Error formatPrice price === null');
  }

  let formattedPrice = price.toLocaleString('de-DE');

  if (withCent) {
    if (price % 1 === 0) {
      formattedPrice += ',00';
    } else {
      const res = formattedPrice.split(',');
      if (res.length === 2) {
        if (res[1].length === 1) {
          formattedPrice += '0';
        }
      }
    }
  }

  if (currency) {
    return `€ ${formattedPrice}`;
  } else {
    return formattedPrice;
  }
}

export function formatPriceWithCent(price = 0, withSign = false) {
  price = (Math.round(parseFloat(price) * 100) / 100).toFixed(2);
  if (price.length > 6) {
    const length = price.length;
    price = price.slice(0, length - 6) + ' ' + price.slice(length - 6, length);
  }
  if (withSign) {
    return `€ ${price}`;
  } else {
    return price;
  }
}

// TODO: Need to drop this shit
export function compareTwoStringDigits(bidPrice = '', carPrice = '') {
  if (bidPrice === null || carPrice === null) {
    return false;
  }

  let formatedBidPrice;
  let formatedCarPrice;

  if (typeof bidPrice === 'number' && typeof carPrice === 'number') {
    return bidPrice >= carPrice;
  } else if (typeof bidPrice === 'number') {
    formatedBidPrice = bidPrice;
  } else if (typeof carPrice === 'number') {
    formatedCarPrice = carPrice;
  }

  if (typeof bidPrice !== 'number') {
    const lenOfBidPrice = bidPrice.split('.').length;
    if (lenOfBidPrice === 2) {
      formatedBidPrice = bidPrice.split('.')[0] + bidPrice.split('.')[1];
    } else {
      formatedBidPrice = bidPrice;
    }
  }

  if (typeof carPrice !== 'number') {
    const lenOfCarPrice = carPrice.split('.').length;
    if (lenOfCarPrice === 2) {
      formatedCarPrice = carPrice.split('.')[0] + carPrice.split('.')[1];
    } else {
      formatedCarPrice = carPrice;
    }
  }

  return Number(formatedBidPrice) >= Number(formatedCarPrice);
}

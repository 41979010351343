import {Dropdown} from 'primereact/dropdown';
import {Tag} from 'primereact/tag';

function Editor(editorData) {
  return options => {
    const {value, editorCallback} = options;
    const tag = editorData.find(i => i.indexInDB === value);
    return (
      <Dropdown
        value={tag?.title || ''}
        placeholder='Вибрати...'
        style={{height: '30px', alignItems: 'center'}}
        options={editorData.map(i => i.title)}
        onChange={e => {
          editorCallback(editorData.find(i => i.title === e.value).indexInDB);
        }}
        itemTemplate={option => {
          const tag = editorData.find(i => i.title === option);
          return <Tag value={tag.title} severity={tag.severity}></Tag>;
        }}
      />
    );
  };
}
export default Editor;

import {useTranslation} from 'react-i18next';

import {PaginationComponent, ItemsLimit, Lots} from '@components';

import './CarsContainer.scss';

function CarsContainer({lotsData}) {
  const [t] = useTranslation('pages/my-cars');

  return (
    <div className='CarsContainer'>
      {lotsData && lotsData.cars && lotsData.cars.length > 0 ? (
        <>
          <div className='SettingsPanel'>
            <ItemsLimit />
            <PaginationComponent
              data={{
                currentPage: lotsData.currentPage,
                totalPages: lotsData.totalPages,
              }}
            />
          </div>
          <Lots lotsData={lotsData} />
          <div className='SettingsPanel'>
            <ItemsLimit />
            <PaginationComponent
              data={{
                currentPage: lotsData.currentPage,
                totalPages: lotsData.totalPages,
              }}
            />
          </div>
        </>
      ) : (
        <div className='noCarsFound'>
          <h1>{t('no_found_cars')}</h1>
        </div>
      )}
    </div>
  );
}
export default CarsContainer;

import {getNotWonCars} from '@actions/my-cars';

import PageCarsContainer from '../container/page-cars-container/PageCarsContainer';

import './NotWon.scss';

function NotWon() {
  return (
    <div className='NotWon'>
      <PageCarsContainer
        keyToData={'noWonCars'}
        getData={getNotWonCars}
      />
    </div>
  );
}
export default NotWon;

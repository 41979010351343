import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {ShadowContainer} from '@containers';
import {ConvertedDate, ConvertStatus} from '@components';
import {ReactComponent as Clock} from '@assets/icons/clock.svg';
import './AuctionsList.scss';

function AuctionsList({auctions}) {
  const [t] = useTranslation('pages/auctions');
  return (
    <div className='AuctionsList'>
      {auctions.map((auction, key) => (
        <Link
          key={key}
          className='auction'
          to={`/auction/${auction.id}`}
          rel='noopener noreferrer'>
          <ShadowContainer>
            <div className='top'>
              <div>
                <span className='name'>{auction.name}</span>
              </div>

              <div className='logo'>
                <img src={auction.logo} alt='' />
              </div>
            </div>

            <div className='bottom'>
              <div>
                <p>ID: {auction.id}</p>
              </div>

              <div className='cars'>
                <span className='pi pi-car'></span>
                <p>
                  {t('count_lots')}: {auction.lotCount}
                </p>
              </div>

              <div className='date'>
                <Clock />
                <p>{t('date_start')}: </p>
                <ConvertedDate date={auction.startsAt} />
              </div>

              <div>
                <ConvertStatus
                  type={'auction'}
                  status={auction.auctionStatus}
                  date={auction.startsAt}
                />
              </div>
            </div>
          </ShadowContainer>
        </Link>
      ))}
    </div>
  );
}

export default AuctionsList;

import {
  deleteLotImage,
  getUnderDraftLotImagesById,
  setUnderDraftLotImages,
} from '@actions/agent';
import {Galleria, UploadPhotos} from '@components';
import {useDispatchAsync} from '@hooks';
import {useEffect, useState} from 'react';
import {Toast} from '@helpers';
import {Loader} from '@ui/index';

function Photos({lotId}) {
  const dispatch = useDispatchAsync();
  const [images, setImages] = useState([]);
  const [isLoading, setLoading] = useState(false);

  async function loadLotImages() {
    const res = await dispatch(getUnderDraftLotImagesById(lotId));
    if (res.ok) {
      setImages(
        res.lot.images.filter(el => el.url).map(el => ({...el, src: el.url}))
      );
    }
  }
  useEffect(() => {
    if (lotId) {
      loadLotImages();
    }
  }, [lotId]);

  if (isLoading) {
    return (
      <div className='Photos'>
        <Loader msg={'Зачекайте хвилинку, це може зайняти трішки часу!'} />
      </div>
    );
  }

  return (
    <div className='Photos'>
      <Galleria
        images={images}
        setImages={setImages}
        onDelete={async img => {
          await dispatch(deleteLotImage(lotId, img.id));
        }}
      />
      <UploadPhotos
        onUpload={async files => {
          await setLoading(() => true);
          const batchSize = 10;
          for (let i = 0; i < files.length; i += batchSize) {
            const currentFiles = [...files].slice(i, i + batchSize);
            const formData = new FormData();
            formData.append('lotId', lotId);
            for (const file of currentFiles) {
              formData.append('images', file);
            }
            const res = await dispatch(setUnderDraftLotImages(formData));
            if (res.ok) {
              Toast.info(
                `${i + currentFiles.length}/${
                  files.length
                } фотографій завантажено!`
              );
            }
          }
          Toast.info('Завантаження фотографій завершено!');

          await loadLotImages();
          await setLoading(() => false);
        }}
      />
    </div>
  );
}

export default Photos;

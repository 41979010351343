import {
  GENERATE_TARIFF_INVOICE,
  INIT_ORDER,
  ORDER_CHECK,
  GET_USER_PAYMENTS,
  DOWNLOAD_ORDER_DOC,
} from './types';


export const generateTariffInvoice = (userId, tariffId) => ({
  event: GENERATE_TARIFF_INVOICE,
  link: '/api/payment/generate_tariff_invoice',
  data: {
    userId: userId,
    tariffId: tariffId,
  },
});

export const initOrder = (orderType, idTariff, lotId, paymentMethod) => ({
  event: INIT_ORDER,
  link: '/api/payment/init_order',
  data: {
    orderType: orderType,
    idTariff: idTariff,
    lotId: lotId,
    paymentMethod: paymentMethod,
  },
});

export const OrderCheck = orderId => ({
  event: ORDER_CHECK,
  link: `/api/payment/order_check/${orderId}`,
});

export const downloadOrderDoc = params => ({
  event: DOWNLOAD_ORDER_DOC,
  link: '/api/payment/download_order_doc?' + params,
});

export const getUserPayments = search => ({
  event: GET_USER_PAYMENTS,
  link: '/api/user/payments' + search,
});

import {
  SEND_VERIFICATION_CODE,
  CHECK_CODE,
  COMPLETE_REGISTRATION,
  SEND_ACCOUNT_DATA,
} from './types';

export const sentNumberPhone = phone => ({
  event: SEND_VERIFICATION_CODE,
  link: '/api/auth/new_phone',
  data: {
    phone: phone,
  },
});

export const checkCode = (code, phoneId) => ({
  event: CHECK_CODE,
  link: '/api/auth/verify_phone',
  data: {
    phoneId: phoneId,
    code: code,
  },
});

export const sentAccountData = userDate => ({
  event: SEND_ACCOUNT_DATA,
  link: '/api/auth/verify_account_data',
  data: userDate,
});

export const completeRegistration = userId => ({
  event: COMPLETE_REGISTRATION,
  link: '/api/auth/complete_registration',
  data: {userId: userId},
});

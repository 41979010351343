import {
  CLEAR_LOT_DATA,
  GET_LOT_INFO_SUCCESS,
  GET_LOT_PRICES_SUCCESS,
  UPDATE_LOT_DATA,
} from './types';

const initialState = {};

const lotReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOT_INFO_SUCCESS:
      return {...action.payload};

    case UPDATE_LOT_DATA: {
      if (JSON.stringify(state) === '{}') break;
      const {field, value} = action.payload;
      const _state = {...state};
      eval('_state.' + field + '=' + JSON.stringify(value));
      return _state;
    }

    case GET_LOT_PRICES_SUCCESS:
      state.prices = {
        ...state.prices,
        ...action.payload,
      };
      return {
        ...state,
      };
    case CLEAR_LOT_DATA:
      return {};
    default:
      //Do nothing
      break;
  }

  return state;
};

export default lotReducer;

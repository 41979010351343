import {useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Input, Button} from '@ui';
import {useValidation} from '@hooks';
import {REGEX_NOT_SPACES, REGEX_PASSWORD} from '@helpers/regex';

import './AuthenticationForm.scss';

function AuthenticationForm({header, onLogin, style}) {
  const [t] = useTranslation('pages/authentication', {
    keyPrefix: 'authentication',
  });
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const isInputsValid = useValidation('Authentication_input', 'color');

  function setLoginHandlers(e) {
    setLogin(e.target.value);
  }

  function setPasswordHandlers(e) {
    setPassword(e.target.value);
  }

  return (
    <div className='AuthenticationForm container' style={style}>
      <header>
        <div className='title'>{header}</div>
        <div className='subTitle'>
          <span>
            {t('subTitle')} <Link to='/registration'>{t('subTitleLink')}</Link>
          </span>
        </div>
      </header>

      <form className='form'>
        <Input
          id={'login'}
          autocomplete='login'
          className='Authentication_input'
          type='text'
          pattern={REGEX_NOT_SPACES}
          key={0}
          value={login}
          headerTitle={t('login.headerTitle')}
          placeholder={t('login.placeholder')}
          onChange={setLoginHandlers}
          underTitle={t('login.underTitle')}
        />
        <Input
          id={'password'}
          autocomplete='current-password'
          className='Authentication_input'
          type={'password'}
          pattern={REGEX_PASSWORD}
          key={1}
          value={password}
          headerTitle={t('password.headerTitle')}
          placeholder={t('password.placeholder')}
          onChange={setPasswordHandlers}
          underTitle={t('password.underTitle')}
        />
      </form>

      <div className='links'>
        <Button
          onClick={() => {
            if (isInputsValid) {
              onLogin(login, password);
            }
          }}
          text={t('btNext')}
        />
        <Link to='/password-recovery' className='forgotPass'>
          {t('forgotPass')}
        </Link>
      </div>
    </div>
  );
}
export default AuthenticationForm;

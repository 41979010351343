import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {getUserInfo, passwordChange} from '@actions/user';
import {useValidation, useDispatchAsync} from '@hooks';
import {ShadowContainer} from '@containers';
import {Button, Message, Input} from '@ui';
import {Toast} from '@helpers';
import {REGEX_PASSWORD} from '@helpers/regex';

import './PasswordChange.scss';

function PasswordChange() {
  const dispatch = useDispatchAsync();
  const isInputsValid = useValidation('PasswordChange_input', 'color');
  const [activeMessage, setActiveMessage] = useState({});
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [t] = useTranslation('pages/profile', {
    keyPrefix: 'password-change',
  });
  const [errors] = useTranslation('common', {keyPrefix: 'errors'});
  const inputsData = [
    {
      id: 'oldPassword',
      value: oldPassword,
      set: setOldPassword,
      pattern: REGEX_PASSWORD,
    },
    {
      id: 'newPassword',
      value: newPassword,
      set: setNewPassword,
      pattern: REGEX_PASSWORD,
    },
    {
      id: 'repeatPassword',
      value: repeatPassword,
      set: setRepeatPassword,
    },
  ];
  function onChange(e) {
    inputsData.find(i => i.id === e.target.id).set(e.target.value);
    if (e.target.id === 'newPassword') {
      document
        .querySelector('#repeatPassword')
        .setAttribute('pattern', e.target.value);
    }
  }
  async function onClick() {
    if (isInputsValid && oldPassword !== newPassword) {
      const res = await dispatch(
        passwordChange(oldPassword, newPassword),
        false
      );
      if (res?.status === 'ok') {
        setActiveMessage(t('messages.passwordSuccess', {returnObjects: true}));
        inputsData.forEach(el => el.set(''));
      } else {
        setActiveMessage({title: errors(res.code), type: 'error'});
      }
    } else {
      Toast.warning(t('no_valid_inputs'));
    }
  }

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);
  return (
    <div className='PasswordChange'>
      <Message type={activeMessage.type} title={activeMessage.title} />
      <ShadowContainer className='PasswordChange'>
        <div className='title'>{t('title')}</div>
        <div className='inputs'>
          {inputsData.map((item, index) => (
            <Input
              className='PasswordChange_input'
              type={'password'}
              pattern={item.pattern}
              key={index}
              id={item.id}
              value={item.value}
              headerTitle={t(`inputsData.${item.id}.headerTitle`)}
              placeholder={t(`inputsData.${item.id}.placeholder`)}
              required={t(`inputsData.${item.id}.required`)}
              onChange={onChange}
              underTitle={t(`inputsData.${item.id}.required`)}
            />
          ))}
        </div>
        <Button text={t('bt')} onClick={onClick} />
      </ShadowContainer>
    </div>
  );
}

export default PasswordChange;

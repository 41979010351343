import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import {loadFilters} from '@actions/lots';
import {useDispatchAsync} from '@hooks';
import {Filters} from '@components';

function sortByName(array) {
  return array?.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });
}

function LotsFilters({auctionId}) {
  const [t, i18n] = useTranslation('pages/cars');
  const dispatch = useDispatchAsync();
  const filters = useSelector(state => state.lots.filters);
  const [filtersConfig, setFiltersConfig] = useState([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(loadFilters(auctionId));
  }, [i18n.currentLang]);

  useEffect(() => {
    if (!filters) return;
    const _filters = JSON.parse(JSON.stringify(filters));
    setFiltersConfig([
      {
        id: 'manufacturers',
        type: 'select',
        title: t('manufacturers_title'),
        isHaveInput: true,
        placeHolder: t('manufacturers_placeHolder'),
        footer: t('manufacturers_footer'),
        data: _filters.manufacturers,
      },
      {
        id: 'model',
        type: 'input',
        title: t('model_title'),
        placeHolder: t('model_placeHolder'),
        footer: t('model_footer'),
        data: {
          value: searchParams.get('model') || '',
        },
      },
      {
        id: 'year',
        type: 'interval',
        title: t('registration_date'),
        data: _filters.years.map(i => ({
          value: i,
          title: i,
        })),
        from: searchParams.get('yearFrom') || null,
        to: searchParams.get('yearTo') || null,
      },
      {
        id: 'fuels',
        type: 'select',
        title: t('fuels'),
        data: _filters.fuelTypes,
      },
      {
        id: 'transmitions',
        type: 'select',
        title: t('transmitions'),
        data: _filters.transmitions,
      },
      {
        id: 'mileage',
        type: 'interval',
        title: t('mileage'),
        data: [
          {
            value: 10000,
            title: '10 000 km',
          },
          {
            value: 50000,
            title: '50 000 km',
          },
          {
            value: 100000,
            title: '100 000 km',
          },
          {
            value: 150000,
            title: '150 000 km',
          },
          {
            value: 200000,
            title: '200 000 km',
          },
          {
            value: 250000,
            title: '250 000 km',
          },
          {
            value: 500000,
            title: '500 000 km',
          },
        ],
        from: searchParams.get('mileageFrom') || null,
        to: searchParams.get('mileageTo') || null,
      },
      {
        id: 'power',
        type: 'interval',
        title: t('power'),
        data: [
          [
            {
              value: 25,
              title: '25 kW',
            },
            {
              value: 37,
              title: '37 kW',
            },
            {
              value: 44,
              title: '44 kW',
            },
            {
              value: 55,
              title: '55 kW',
            },
            {
              value: 66,
              title: '66 kW',
            },
            {
              value: 74,
              title: '74 kW',
            },
            {
              value: 87,
              title: '87 kW',
            },
            {
              value: 96,
              title: '96 kW',
            },
            {
              value: 110,
              title: '110 kW',
            },
            {
              value: 147,
              title: '147 kW',
            },
            {
              value: 185,
              title: '185 kW',
            },
            {
              value: 223,
              title: '223 kW',
            },
            {
              value: 263,
              title: '263 kW',
            },
            {
              value: 296,
              title: '296 kW',
            },
          ],
          [
            {
              value: 34,
              title: '34 k.s',
            },
            {
              value: 50,
              title: '50 k.s',
            },
            {
              value: 60,
              title: '60 k.s',
            },
            {
              value: 75,
              title: '75 k.s',
            },
            {
              value: 101,
              title: '101 k.s',
            },
            {
              value: 118,
              title: '118 k.s',
            },
            {
              value: 150,
              title: '150 k.s',
            },
            {
              value: 200,
              title: '200 k.s',
            },
            {
              value: 252,
              title: '252 k.s',
            },
            {
              value: 303,
              title: '303 k.s',
            },
            {
              value: 358,
              title: '358 k.s',
            },
            {
              value: 402,
              title: '402 k.s',
            },
            {
              value: 454,
              title: '454 k.s',
            },
          ],
        ],
        unitMeasurement: ['kW', 'k.s.'],
        unit: +searchParams.get('ptype') || 0,
        from: searchParams.get('powerFrom') || null,
        to: searchParams.get('powerTo') || null,
      },
      {
        id: 'engine',
        type: 'interval',
        title: t('engine'),
        data: [
          {
            value: 0.5,
            title: '0.5 л',
          },
          {
            value: 1,
            title: '1 л',
          },
          {
            value: 2,
            title: '2 л',
          },
          {
            value: 5,
            title: '5 л',
          },
          {
            value: 10,
            title: '10 л',
          },
          {
            value: 15,
            title: '15 л',
          },
          {
            value: 22,
            title: '22 л',
          },
          {
            value: 23,
            title: '23+ л',
          },
        ],

        from: searchParams.get('engineFrom') || null,
        to: searchParams.get('engineTo') || null,
      },
      {
        id: 'damages',
        type: 'select',
        title: t('damages'),
        data: _filters.damages.filter(
          i => !!i.name && i.id !== null && i.id !== undefined
        ),
      },
      {
        id: 'colors',
        type: 'select',
        title: t('colors_title'),
        placeHolder: t('colors_placeHolder'),
        footer: t('colors_footer'),
        data: _filters.colors,
      },
      {
        id: 'doors',
        type: 'select',
        title: t('doors_title'),
        data: _filters.doors,
      },
    ]);
  }, [filters, t]);

  return (
    <div className='LotsFilters'>
      {filters && (
        <Filters
          filtersConfig={filtersConfig}
          setFiltersConfig={newFiltersConfig => {
            const manufacturers = newFiltersConfig.find(
              el => el.id === 'manufacturers'
            );

            manufacturers.data = [
              ...sortByName(manufacturers.data.filter(el => el.checked)),
              ...sortByName(manufacturers.data.filter(el => !el.checked)),
            ];
            setFiltersConfig(newFiltersConfig);
          }}
        />
      )}
    </div>
  );
}
export default LotsFilters;

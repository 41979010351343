import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Button} from 'primereact/button';
import {
  getExportedFeatures,
  getOwnFeatures,
  synchronizeFeatures,
  synchronizeValueStructure,
} from '@actions/admin';

import {useDispatchAsync, useUpdatedState} from '@hooks';
import {ShadowContainer} from '@containers';

import useUpdateData from '../../../../hooks/update-data.hook';
import Table from '../../../../2-components/table/Table';
import OwnFeatures from '../own-features/OwnFeatures';
import {config as exported_config} from '../exported-features/config';

import {config} from './config';

import './SynchronizeFeatures.scss';

function SynchronizeFeatures() {
  const api = useUpdateData();
  const location = useLocation();
  const dispatch = useDispatchAsync();

  const id = location.pathname.split('/').at(-1);
  const exportedFeatures = useSelector(state => state.admin.exportedFeatures);
  const [synchronizeItem, _] = useUpdatedState(
    exportedFeatures.find(i => i.id === id)
  );

  const [item, setItem] = useUpdatedState(synchronizeItem);
  const [itemOld] = useState(() =>
    synchronizeItem ? JSON.parse(JSON.stringify(synchronizeItem)) : null
  );

  const [rowData, setRowData] = useUpdatedState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const ownFeatures = useSelector(state => state.admin.ownFeatures);

  function synchronize(rowData) {
    if (!rowData) return;
    setRowData(rowData);
    setItem({
      ...item,
      syncWith: rowData.id,
      valueStructure: item.valueStructure?.map(i => {
        i.valueStructure = rowData.valueStructure;
        return i;
      }),
    });
  }

  async function onSave() {
    if (!rowData) return;

    if (itemOld.syncWith !== rowData.id) {
      await api(synchronizeFeatures(item.id, rowData.id));
    }
    item.valueStructure
      .filter(
        (i, index) => i.syncWith !== itemOld.valueStructure[index].syncWith
      )
      .forEach(async i => {
        await api(synchronizeValueStructure(item.id, i.id, i.syncWith));
      });

    dispatch(getOwnFeatures());
    dispatch(getExportedFeatures());
  }

  function synchronizeVS(rowData, rowDataVS) {
    rowData.syncWith = rowDataVS.id;
    setItem({...item});
  }

  useEffect(() => {
    if (item && item.syncWith) {
      const data = ownFeatures.find(i => i.id === item.syncWith);
      synchronize(data);
      setSelectedProduct(data);
    }
  }, [synchronizeItem]);

  if (!item)
    return (
      <ShadowContainer key={'default'}>
        Для синхронізації перейдіть у вкладу "Експортовані", і виберіть потрібну
        характеристику для синхронізації!!!
      </ShadowContainer>
    );
  return (
    <div className='SynchronizeFeatures flex flex-column gap-5 '>
      <ShadowContainer key={'SynchronizeFeatures'}>
        <span>Вибрана Експортована характеристика для синхронізації : </span>
        <Table
          name='SynchronizeFeatures'
          config={exported_config}
          data={[item]}
        />
      </ShadowContainer>

      <ShadowContainer key={'OwnFeatures'}>
        <span>
          Виберіть! З якою Власною характеристикою бажаєте синхронізувати :
        </span>
        <div className='overflow-auto'>
          <OwnFeatures
            height={'500px'}
            autoHeight={false}
            synchronize={synchronize}
            selectedProduct={selectedProduct}
          />
        </div>
      </ShadowContainer>

      {rowData && item.valueStructure?.length > 0 ? (
        <ShadowContainer key={'SynchronizeFeaturesTableVS'}>
          <span>Синхронізуйте поля ValueStructure :</span>
          <Table
            name='SynchronizeValueStructure'
            height={'500px'}
            config={config}
            autoHeight={false}
            data={item.valueStructure}
            synchronizeValueStructure={synchronizeVS}
          />
        </ShadowContainer>
      ) : null}

      <div className='card flex justify-content-center'>
        <Button
          size='large'
          severity='success'
          label='Зберегти'
          onClick={onSave}
        />
      </div>
    </div>
  );
}
export default SynchronizeFeatures;

export const config_invoice_form = [
  {
    type: 'text',
    key: 'no',
    headerTitle: 'Номер Invoice',
    underTitle: 'У форматі FV dd/mm/yyyy',
  },
  {
    type: 'text',
    key: 'lot_name',
    headerTitle: 'Повна назва лота',
    underTitle: 'У форматі : Марка/Повна назва/VIN code/номер',
  },
  {
    type: 'number',
    key: 'rate',
    headerTitle: 'Курс PLN/EUR',
    underTitle: 'У форматі : *.****',
  },
  {
    type: 'text',
    key: 'table_number',
    headerTitle: 'Номер таблиці курсів',
    underTitle: 'У форматі : ***/A/NBP/202*',
  },
  {
    type: 'date',
    key: 'date_publication',
    headerTitle: 'Дата публікації курсів',
    underTitle: 'У форматі  dd/mm/yyyy',
  },
  {
    type: 'date',
    key: 'issued_at',
    headerTitle: 'Дата виставлення invoice',
    underTitle: 'У форматі  dd/mm/yyyy',
  },
];

import {
  GET_IS_CRONS_RUNNING,
  GET_IS_TECHNICAL_WORKS,
  LOAD_DATA_FOR_HOME,
  TOGGLE_IS_CRONS_RUNNING,
  TOGGLE_IS_TECHNICAL_WORKS,
} from './types';

export const loadDataForHome = () => ({
  event: LOAD_DATA_FOR_HOME,
  link: '/api/home',
});

export const togglIsTechnicalWorks = () => ({
  event: TOGGLE_IS_TECHNICAL_WORKS,
  link: '/api/home/toggle_is_technical_works',
});

export const getIsTechnicalWorks = () => ({
  event: GET_IS_TECHNICAL_WORKS,
  link: '/api/home/is_technical_works',
});

export const togglIsCronsRunning = () => ({
  event: TOGGLE_IS_CRONS_RUNNING,
  link: '/api/home/toggle_is_crons_running',
});

export const getIsCronsRunnig = () => ({
  event: GET_IS_CRONS_RUNNING,
  link: '/api/home/is_crons_running',
});

import {useState} from 'react';
import './DropDown.scss';

function DropDown({title, children, className = ''}) {
  const [isActive, setActive] = useState(true);

  return (
    <div className={`DropDown ${className}`}>
      <div
        className={`header ${isActive ? 'active' : ''}`}
        onClick={() => setActive(prev => !prev)}>
        {title}
        <div className='toggler'>
          <i className='pi pi-chevron-up'></i>
        </div>
      </div>
      <div className={`content ${isActive ? 'active' : ''}`}>{children}</div>
    </div>
  );
}
export default DropDown;

import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import Countdown, {zeroPad} from 'react-countdown';
import './CountdownTimer.scss';

function CountdownTimer({date, isShowFullDate = false}) {
  const [t] = useTranslation('', {keyPrefix: 'CountdownTimer'});

  const clockRef = useRef();

  useEffect(() => {
    clockRef.current.start();
  }, [date]);

  const renderer = ({days, hours, minutes, seconds, completed}) => {
    if (completed) {
      return <></>;
    } else {
      const dd = days;
      const hh = zeroPad(hours);
      const mm = zeroPad(minutes);
      const ss = zeroPad(seconds);
      if (days === 0 && hours === 0 && minutes === 0 && !isShowFullDate) {
        document.querySelector('.timer')?.classList.add('big');
        return (
          <p className='CountdownTimer'>
            <div>
              <span key={0} className='seconds'>
                {ss}
              </span>
              <span key={1} className='seconds'>
                {t('second')}
              </span>
            </div>
          </p>
        );
      } else {
        document.querySelector('.timer')?.classList.remove('big');
        return (
          <p className='CountdownTimer'>
            <div>
              <span key={2} className='number'>
                {dd}
              </span>
              <span key={3}>{t('day')}</span>
            </div>
            <div>
              <span key={4} className='number'>
                {hh}
              </span>
              <span key={5}>{t('hour')}</span>
            </div>
            <div>
              <span key={6} className='number'>
                {mm}
              </span>
              <span key={7}>{t('minute')}</span>
            </div>
            <div>
              {' '}
              <span key={8} className='number'>
                {ss}
              </span>
              <span key={9}>{t('second')}</span>
            </div>
          </p>
        );
      }
    }
  };

  return <Countdown ref={clockRef} date={new Date(date)} renderer={renderer} />;
}

export default CountdownTimer;

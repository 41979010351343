import React from 'react';
import {useTranslation} from 'react-i18next';

function ConvertedDate({date}) {
  const [t] = useTranslation('', {keyPrefix: 'ConvertedDate'});

  const monthsList = [
    {name: t('months.january'), short: t('monthsShort.january')},
    {name: t('months.february'), short: t('monthsShort.february')},
    {name: t('months.march'), short: t('monthsShort.march')},
    {name: t('months.april'), short: t('monthsShort.april')},
    {name: t('months.may'), short: t('monthsShort.may')},
    {name: t('months.june'), short: t('monthsShort.june')},
    {name: t('months.july'), short: t('monthsShort.july')},
    {name: t('months.august'), short: t('monthsShort.august')},
    {name: t('months.september'), short: t('monthsShort.september')},
    {name: t('months.october'), short: t('monthsShort.october')},
    {name: t('months.november'), short: t('monthsShort.november')},
    {name: t('months.december'), short: t('monthsShort.december')},
  ];

  const converted = new Date(date);
  const month = converted.getMonth();
  const day = converted.getDate();
  const hour = converted.getHours();
  const minutes = `0${converted.getMinutes()}`.slice(-2);
  const convertedStr = `${day} ${monthsList[month]?.short}, ${hour}:${minutes}`;

  return <p>{convertedStr}</p>;
}

export default ConvertedDate;

import {useCallback} from 'react';
import jsxToString from 'jsx-to-string';
import ReactHtmlParser from 'html-react-parser';

import {DropDown} from '@ui';

import './GeneratePage.scss';

function GeneratePage({Name, tree, inserts, t}) {
  const insertsFunc = useCallback(() => {
    for (const key of Object.keys(inserts)) {
      inserts[key] =
        typeof inserts[key] === 'object'
          ? jsxToString(inserts[key])
          : inserts[key];
    }
    return inserts;
  });

  function toUL(text) {
    const data = text.split('{{br}}');
    return jsxToString(
      <span>
        <span>{data[0]}</span>
        <ul>
          {data.slice(1).map((it, index) => (
            <li key={index}>{it}</li>
          ))}
        </ul>
      </span>
    );
  }

  function isList(text) {
    if (text.includes('{{br}}')) {
      return toUL(text);
    }
    return text;
  }

  function pushing(key, array) {
    array.push(
      <div className='paragraph' key={key}>
        <span>
          {key[0] !== '0' && <h4>{key.split('-').join('.') + '.'}</h4>}
          {ReactHtmlParser(isList(t(key, insertsFunc())))}
        </span>
      </div>
    );
  }

  function recursion(list, index, array) {
    for (const paragraph of list) {
      if (typeof paragraph === 'object') {
        for (const key of Object.keys(paragraph)) {
          pushing(index + '-' + key, array);
          recursion(paragraph[key], index + '-' + key, array);
        }
      } else {
        pushing(index + '-' + paragraph, array);
      }
    }
    return array;
  }

  return (
    <div className={`${Name} GeneratePage`}>
      {Object.keys(tree).map((article, index) => (
        <DropDown
          key={index}
          title={ReactHtmlParser(t('' + article, insertsFunc()))}>
          {recursion(tree[article], article, [])}
        </DropDown>
      ))}
    </div>
  );
}
export default GeneratePage;

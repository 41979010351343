const REGEX_EMAIL =
  /^([a-z0-9]+.)*[a-z0-9]+@[a-z0-9]+(.[a-z0-9]+)*.[a-z]{2,6}$/;
const REGEX_NICK_NAME = /^[a-zA-Z0-9\-_]{2,36}$/;

const REGEX_NUMBERS = /^[0-9\-]{2,}/;
const REGEX_ADDRESS = /^[A-Za-z0-9\s.,#\/\-]+$/;
const REGEX_WORDS_NUMBERS = /^[\w0-9]*$/;
const REGEX_USER_NAME = /^[a-zA-Z\s\-]{2,36}$/;
const REGEX_GENERAL_NAME = /^[\w\s\/\|\.\,0-9]*$/;
const REGEX_NO = /^[\w\s0-9\-]*$/;
const REGEX_NOT_SPACES = /^\S{2,40}$/;
const REGEX_PASSWORD = /^\S{8,30}$/;

const REGEX_DEFAULT = /^.{2,100}$/;

export {
  REGEX_EMAIL,
  REGEX_NICK_NAME,

  REGEX_NUMBERS,
  REGEX_ADDRESS,
  REGEX_WORDS_NUMBERS,
  REGEX_USER_NAME,
  REGEX_GENERAL_NAME,
  REGEX_NO,
  REGEX_NOT_SPACES,
  REGEX_PASSWORD,

  REGEX_DEFAULT,
};

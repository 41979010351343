import {
  DATA_SOURCE_TYPE_AUTOBID2,
  DATA_SOURCE_TYPE_AUTOBID3,
  DATA_SOURCE_TYPE_OWN,
  LOT_CATEGORY_CAR,
  LOT_CATEGORY_STUFF,
  LOT_FEATURE_VALUE_TYPE_NUMBER,
  LOT_FEATURE_VALUE_TYPE_STRING,
  LOT_FEATURE_VALUE_TYPE_BOOLEAN,
  LOT_FEATURE_VALUE_TYPE_DATE,
  LOT_FEATURE_VALUE_TYPE_ENUM,
  LOT_FEATURE_VALUE_TYPE_SET,
  LOT_FEATURE_SYNC_STATUS_WAITING_ADMIN,
  LOT_FEATURE_SYNC_STATUS_WAITING_SYNC,
  LOT_FEATURE_SYNC_STATUS_READY,
  LOT_FEATURE_SYNC_STATUS_ERROR,
} from '@helpers/models';

export const config = {
  header: 'ExportedFeatures',
  type: 'table',
  fields: {
    syncStatus: {
      type: 'list',
      header: 'Статус синхронізації',
      modifier: 'public',
      editorData: [
        {
          indexInDB: LOT_FEATURE_SYNC_STATUS_WAITING_ADMIN,
          title: 'Очікує адміна',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_SYNC_STATUS_WAITING_SYNC,
          title: 'Очікує синхронізації',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_SYNC_STATUS_READY,
          title: 'Синхронізовано',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_SYNC_STATUS_ERROR,
          title: 'Невдале',
          severity: 'info',
        },
      ],
    },
    id: {
      header: 'Id',
    },
    syncWith: {
      header: 'Синхронізовано з',
      type: 'text',
      modifier: 'public',
    },
    name: {
      type: 'text',
      header: "Ім'я",
      modifier: 'public',
      required: true,
    },
    valueType: {
      type: 'list',
      header: 'Тип значення',
      modifier: 'public',
      required: true,
      editorData: [
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_NUMBER,
          title: 'Число',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_STRING,
          title: 'Рядок',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_BOOLEAN,
          title: 'Булеве',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_DATE,
          title: 'Дата',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_ENUM,
          title: 'Масив',
          severity: 'info',
        },
        {
          indexInDB: LOT_FEATURE_VALUE_TYPE_SET,
          title: 'Набір',
          severity: 'info',
        },
      ],
    },
    valueStructure: {
      header: 'Таблиця значень',
      type: 'table',
      fields: {
        value: {
          header: 'Значення',
          modifier: 'public',
          type: 'text',
          required: true,
        },
        title: {
          header: 'Заголовок',
          modifier: 'public',
          type: 'text',
          required: true,
        },
        syncWith: {
          header: 'Синхронізовано з',
          type: 'text',
          modifier: 'public',
        },
      },
    },
    code: {
      type: 'text',
      header: 'Код',
      required: true,
    },
    category: {
      type: 'list',
      header: 'Категорія',
      modifier: 'public',
      required: true,
      editorData: [
        {indexInDB: LOT_CATEGORY_CAR, title: 'Авто', severity: 'info'},
        {indexInDB: LOT_CATEGORY_STUFF, title: 'Речі', severity: 'info'},
      ],
    },
    sourceType: {
      type: 'list',
      header: 'Тип джерела',
      modifier: 'public',
      required: true,
      editorData: [
        {indexInDB: DATA_SOURCE_TYPE_OWN, title: 'OWN', severity: 'info'},
        {
          indexInDB: DATA_SOURCE_TYPE_AUTOBID2,
          title: 'AUTOBID2',
          severity: 'info',
        },
        {
          indexInDB: DATA_SOURCE_TYPE_AUTOBID3,
          title: 'AUTOBID3',
          severity: 'danger',
        },
      ],
    },
    lang: {required: true, type: 'text', header: 'Мова', modifier: 'public'},
  },
};

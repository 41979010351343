import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Button, CheckBox, Loader} from '@ui';
import {Toast} from '@helpers';
import {getUserInfo, signSaleContract} from '@actions/user';
import {downloadSaleContract} from '@actions/user';
import {useDispatchAsync} from '@hooks';

import './VerificationContract.scss';

function VerificationContract() {
  const dispatch = useDispatchAsync();
  const [t] = useTranslation('pages/profile', {
    keyPrefix: 'verification',
  });

  const user = useSelector(state => state.user);
  const [acquainted, setAcquainted] = useState(
    user.verificationRights.contract || false
  );
  const [link, setLink] = useState();

  useEffect(async () => {
    const res = await dispatch(downloadSaleContract(), false);
    if (res.status === 'error') {
      if (res.code) {
        res.code !== 'document_not_found' &&
          Toast.error(t([res.code, 'unknown']));
      } else {
        Toast.error(t('error_query'));
      }
      setLink(null);
      return;
    }

    setLink(URL.createObjectURL(res));
    return () => {
      setLink(null);
    };
  }, []);

  async function onSignDocument() {
    const res = await dispatch(signSaleContract());
    if (res.ok) {
      Toast.success(
        user.verificationRights.tariff
          ? t('confirm_msg')
          : t('confirm_msg_go_tariff'),
        10000
      );
    } else {
      Toast.error(t('unknown'));
    }

    await dispatch(getUserInfo());
  }
  return (
    <div className='VerificationContract'>
      {link === undefined ? (
        <Loader />
      ) : link === null ? (
        <div>{t('not_created')}</div>
      ) : (
        <>
          <div className='text'>
            <p>{t('text_for_confirm_contract')}</p>
          </div>
          <Button
            to={link}
            download={'SaleContract.pdf'}
            text={t('download_contract')}
            className={'greenwhite document'}
            target={'_blank'}
          />
          <div className='checkBox'>
            <CheckBox
              type={'square'}
              state={acquainted}
              setState={setAcquainted}
            />
            {t('acquainted')}
          </div>
          <div className='btns'>
            <Button
              text={t('confirm')}
              className={
                !acquainted || user.verificationRights.contract
                  ? 'inactive'
                  : ''
              }
              onClick={onSignDocument}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default VerificationContract;

import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {ReactComponent as ProgressBarLine} from '@assets/icons/progress-bar.svg';
import {ReactComponent as Timer} from '@assets/icons/timer.svg';
import {CountdownTimer} from '@components';
import {Logger} from '@helpers';

import {
  LOT_STATUS_ACTIVE,
  LOT_STATUS_FINISHED_NOT_SOLD,
  LOT_STATUS_FINISHED_RESERVED,
  LOT_STATUS_FINISHED_SOLD,
  LOT_STATUS_HIDDEN,
  LOT_STATUS_NEW,
  LOT_STATUS_PREPARATION,
  LOT_STATUS_READY,
} from '@models';

import './ProgressBar.scss';

function ProgressBar({lot}) {
  const [t] = useTranslation('pages/lot');
  const [date, setData] = useState(lot.finishedAt || lot.auctionStartsAt);

  useEffect(() => {
    setData(lot.finishedAt || lot.auctionStartsAt);
  }, [lot.finishedAt, lot.auctionStartsAt]);

  function render() {
    switch (lot.lotStatus) {
      case LOT_STATUS_NEW:
      case LOT_STATUS_READY:
      case LOT_STATUS_PREPARATION:
        if (lot.index === 1) {
          return <CountdownTimer date={date} />;
        }
        break;
      case LOT_STATUS_ACTIVE:
        return <CountdownTimer date={date} />;
      case LOT_STATUS_FINISHED_SOLD:
      case LOT_STATUS_FINISHED_NOT_SOLD:
      case LOT_STATUS_FINISHED_RESERVED:
        return t('auction_is_over');
      case LOT_STATUS_HIDDEN:
      default:
        Logger.warn(`Some Unknown lot status: ${lot.lotStatus}`);
    }
  }

  return (
    <div
      className={`Auction_ProgressBar
        ${lot.lotStatus === 3 ? 'green' : ''}
        ${lot.lotStatus >= 10 ? 'red' : ''}`}>
      <div className='Auction_ProgressBar_main'>
        <Timer className='timer' />
        {render()}
      </div>
      <div className='ProgressBarLine'>
        <ProgressBarLine />
      </div>
    </div>
  );
}
export default ProgressBar;

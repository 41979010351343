function checkRequiredFields(fields, next) {
  const requiredFields = [];
  for (const field in fields) {
    if (fields[field].required) {
      requiredFields.push([field, fields[field]]);
    }
  }
  const emptyField = requiredFields.find(
    i => next[i[0]] === null || next[i[0]] === undefined || next[i[0]] === ''
  );
  return emptyField ? emptyField[1]?.header : null;
}
export default checkRequiredFields;

import './InputCode.scss';

function InputCode({title, value, onChangeHandler, under_input_text}) {
  function onChange(e) {
    let value = e.target.value;
    const letter = value[value.length - 1];

    if (!Number.isInteger(+letter)) {
      value = value.slice(0, value.length - 1);
    }

    if (value.length >= 4) {
      value = value.slice(0, 4);
    }
    onChangeHandler(value);
  }

  return (
    <div className='InputCode'>
      <label htmlFor='title'>{title}</label>
      <div className='codeInput'>
        <input type='text' onChange={onChange} value={value} />
        <div className='numbers'>
          <div key={1} className='number' type='text' />
          <div key={2} className='number' type='text' />
          <div key={3} className='number' type='text' />
          <div key={4} className='number' type='text' />
        </div>
      </div>
      <span className='under_input_text'>{under_input_text}</span>
    </div>
  );
}
export default InputCode;

import {useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';

import buildEditor from '../editor/_index';

export function CreateProductsDialog({idRow, config}) {
  const [emptyProduct, setEmptyProduct] = useState({});
  const [visible, setVisible] = useState(false);

  function generateInputs() {
    const res = [];
    for (const field in config.fields) {
      const col = config.fields[field];
      if (col.required !== true) continue;
      const render = buildEditor({config, field}, true);
      if (!render) continue;
      res.push(
        <div key={field}>
          <div>{col.header}</div>
          {render({
            value: emptyProduct[field],
            editorCallback: value => {
              setEmptyProduct(p => ({
                ...p,
                [field]: value,
              }));
            },
          })}
        </div>
      );
    }
    return res;
  }

  return (
    <>
      <Button
        label='Створити'
        icon='pi pi-plus'
        severity='success'
        onClick={() => setVisible(true)}
      />
      <Dialog
        modal
        visible={visible}
        style={{width: '32rem'}}
        breakpoints={{'960px': '75vw', '641px': '90vw'}}
        header={'Створити новий запис в таблицю : ' + config.header}
        onHide={() => {
          setVisible(false);
          setEmptyProduct({});
        }}
        footer={
          <>
            <Button
              label='Відмінити'
              icon='pi pi-times'
              outlined
              onClick={() => {
                setVisible(false);
                setEmptyProduct({});
              }}
            />
            <Button
              label='Зберегти'
              icon='pi pi-check'
              severity='success'
              onClick={async () => {
                const res = await config.onCreate(idRow, emptyProduct);
                if (res) {
                  setEmptyProduct({});
                  setVisible(false);
                  await config.onLoad();
                }
              }}
            />
          </>
        }>
        <div className='ProductsDialog_form'>{generateInputs()}</div>
      </Dialog>
    </>
  );
}
export default CreateProductsDialog;

import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {Button} from '@ui';
import {userLogin} from '@actions/user';
import {completeRegistration} from '@actions/registration';
import {useDispatchAsync} from '@hooks';
import {ReactComponent as BigCheckMark} from '@assets/icons/big-check-mark.svg';

import {CLEAN_ALL_REGISTRATION_DATA} from '../../../../store/registration/types';

import './Step_4.scss';

function Step_4() {
  const navigate = useNavigate();
  const dispatch = useDispatchAsync();

  const [t] = useTranslation('pages/registration', {
    keyPrefix: 'step_4',
  });
  const {token, userId, email, password} = useSelector(
    state => state.registration
  );

  function popstate() {
    return navigate('/');
  }

  useEffect(() => {
    window.addEventListener('popstate', popstate);
    return () => window.removeEventListener('popstate', popstate);
  }, []);

  useEffect(async () => {
    if (token) {
      const res = await dispatch(completeRegistration(userId));
      if (!res.ok) return;
      await dispatch(userLogin(email, password));
      await dispatch({type: CLEAN_ALL_REGISTRATION_DATA});
    }
  }, []);

  return (
    <div className='Step_4'>
      <div className='content'>
        <div className='icon'>
          <BigCheckMark />
        </div>
        <div className='title'>{t('congratulations')}</div>
        <div className='text'>{t('text')}</div>
        <div className='text'>{t('text2')}</div>
        <Button to='/profile/verification' text={t('bt')} />
      </div>
    </div>
  );
}

export default Step_4;

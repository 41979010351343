import {getWonCars} from '@actions/my-cars';

import PageCarsContainer from '../container/page-cars-container/PageCarsContainer';

import './WonCars.scss';

function WonCars() {
  return (
    <div className='WonCars'>
      <PageCarsContainer keyToData={'wonCars'} getData={getWonCars} />
    </div>
  );
}
export default WonCars;

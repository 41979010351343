import {useSelector} from 'react-redux';

import {Loader} from '@ui/index';

import FeatureElement from './FeatureElement';

function FilterOptions({data, setData}) {
  const filterSourceFeatureIds = useSelector(
    state => state.agent.filterSourceFeatureIds
  );
  const underFeatures = useSelector(state => state.agent.underFeatures);

  if (!underFeatures || !filterSourceFeatureIds) return <Loader />;

  return (
    <div className='FilterOptions'>
      {underFeatures
        .filter(el => filterSourceFeatureIds.includes(el.id))
        .map(featureData => (
          <FeatureElement
            feature={featureData}
            value={
              data.features.find(el => el.featureId === featureData.id)?.value
            }
            onChange={value => {
              setData(prev => ({
                ...prev,
                features: [
                  ...prev.features.filter(
                    el => el.featureId !== featureData.id
                  ),
                  {
                    featureId: featureData.id,
                    value: value.toString(),
                  },
                ],
              }));
            }}
          />
        ))}
    </div>
  );
}

export default FilterOptions;

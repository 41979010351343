import {useSelector} from 'react-redux';
import {useDispatchAsync} from '@hooks';
import {getUserInfo} from '@actions/user';
import {addLotToFavorite} from '@actions/lots';

import './AddToFavoriteBtn.scss';
import {Button} from '..';

function AddToFavoriteBtn({lotId}) {
  const dispatch = useDispatchAsync();
  const favLots = useSelector(state => state.user.favLots);
  const isFav = favLots ? favLots.find(i => i === lotId) : false;

  function onClick(e) {
    request();
    e.preventDefault();
  }

  async function request() {
    await dispatch(addLotToFavorite(lotId));
    await dispatch(getUserInfo());
  }

  return (
    <Button
      className={`AddToFavoriteBtn  ${isFav ? 'active' : 'gray'}`}
      onClick={onClick}>
      {isFav ? (
        <span className='pi pi-bookmark-fill'></span>
      ) : (
        <span className='pi pi-bookmark'></span>
      )}
    </Button>
  );
}
export default AddToFavoriteBtn;

import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {ProgressBar} from '@ui';
import {PayBtn} from '@components';
import Logger from '@helpers/logger';

import {
  LOT_PROCESSING_STATUS_ADMIN_CHECKED,
  LOT_PROCESSING_STATUS_DONE,
  LOT_PROCESSING_STATUS_REJECTED,
  LOT_PROCESSING_STATUS_DELIVERED,
  LOT_PROCESSING_STATUS_DELIVERY,
  LOT_PROCESSING_STATUS_PAYED,
  LOT_PROCESSING_STATUS_NEW,
  ORDER_PRODUCT_TYPE_LOT,
} from '@models';

import './PurchaseProgress.scss';

function PurchaseProgress() {
  const lot = useSelector(state => state.lot);

  const [t] = useTranslation('pages/cars');

  const [activeStep, _] = useState(() => {
    if (!lot.lotProcessing) return null;
    switch (lot.lotProcessing.status) {
      case LOT_PROCESSING_STATUS_NEW:
        return 1;
      case LOT_PROCESSING_STATUS_ADMIN_CHECKED:
        return 2;
      case LOT_PROCESSING_STATUS_PAYED:
        return 3;
      case LOT_PROCESSING_STATUS_DELIVERY:
        return 4;
      case LOT_PROCESSING_STATUS_DELIVERED:
        return 5;
      case LOT_PROCESSING_STATUS_DONE:
        return 6;
      case LOT_PROCESSING_STATUS_REJECTED:
        return 7;
      default:
        Logger.error(
          'Unknown LOT_PROCESSING_STATUS : ' + lot.lotProcessing.status
        );
        return null;
    }
  });

  function additionalTextToStatus() {
    switch (lot.lotProcessing.status) {
      case LOT_PROCESSING_STATUS_NEW:
        return t('text_status_new');
      case LOT_PROCESSING_STATUS_ADMIN_CHECKED:
        return t('text_status_admin_checked');
      case LOT_PROCESSING_STATUS_PAYED:
        return t('text_status_payed');
      case LOT_PROCESSING_STATUS_DELIVERY:
        return t('text_status_delivery');
      case LOT_PROCESSING_STATUS_DELIVERED:
        return t('text_status_delivered');
      case LOT_PROCESSING_STATUS_DONE:
        return t('text_status_done');
      case LOT_PROCESSING_STATUS_REJECTED:
        return t('text_status_rejected');
      default:
        Logger.error(
          'Unknown LOT_PROCESSING_STATUS : ' + lot.lotProcessing.status
        );
        return null;
    }
  }

  if (
    !(
      lot.lotProcessing &&
      lot.lotProcessing.status !== undefined &&
      lot.lotProcessing.status !== null
    )
  ) {
    return <></>;
  }
  return (
    <div className='PurchaseProgress'>
      <div className='info'>
        <h3>{t('congratulations')}</h3>
        <p>{additionalTextToStatus()}</p>
      </div>
      {lot.lotProcessing.status !== LOT_PROCESSING_STATUS_REJECTED ? (
        <ProgressBar
          steps={[
            t('new'),
            t('admin_checked'),
            t('payed'),
            t('delivery'),
            t('delivered'),
          ]}
          activeStep={activeStep}
          gap={10}
        />
      ) : null}
      {lot.lotProcessing.status === LOT_PROCESSING_STATUS_ADMIN_CHECKED ? (
        <div className='pay'>
          <PayBtn orderType={ORDER_PRODUCT_TYPE_LOT} lotId={lot.lotId}>
            {t('pay')}
          </PayBtn>
        </div>
      ) : null}
    </div>
  );
}

export default PurchaseProgress;

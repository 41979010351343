import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import ReactHtmlParser from 'html-react-parser';

import {useDispatchAsync} from '@hooks';
import {ShadowContainer} from '@containers';
import {Message, CheckBox} from '@ui';
import {PayBtn} from '@components';
import {getUserInfo} from '@actions/user';

import {
  ACCOUNT_TARIFF_INDIVIDUAL,
  ACCOUNT_TARIFF_1000,
  ACCOUNT_TARIFF_300,
  ACCOUNT_TARIFF_500,
  ACCOUNT_TARIFF_PREMIUM,
  ORDER_PRODUCT_TYPE_TARIFF,
} from '@models';

import './Tariffs.scss';

function Tariffs() {
  const dispatch = useDispatchAsync();

  const [activeMessage, setActiveMessage] = useState({});
  const [t] = useTranslation('pages/profile', {
    keyPrefix: 'tariffs',
  });

  const {
    lotCountLimit,
    lotPriceLimit,
    lotPriceReserve,
    tariff,
    verificationRights,
  } = useSelector(state => state.user);

  const [basicTariffs] = useState(() => {
    const tariffs = t('basicTariffs', {returnObjects: true});
    tariffs[ACCOUNT_TARIFF_300].id = ACCOUNT_TARIFF_300;
    tariffs[ACCOUNT_TARIFF_500].id = ACCOUNT_TARIFF_500;
    tariffs[ACCOUNT_TARIFF_1000].id = ACCOUNT_TARIFF_1000;
    tariffs[ACCOUNT_TARIFF_PREMIUM].id = ACCOUNT_TARIFF_PREMIUM;
    return tariffs;
  });

  const [electType, setElectType] = useState(tariff);

  useEffect(() => {
    if (tariff === null || tariff === undefined) {
      setActiveMessage({
        type: 'info',
        title: t('noActiveTariff'),
      });
    } else {
      setActiveMessage({
        type: 'success',
        title: ReactHtmlParser(
          '<div>' +
            t('ActiveTariff', {
              activeTariff: basicTariffs[tariff].header,
              lotCountLimit: lotCountLimit,
              lotPriceLimit: lotPriceLimit,
              lotPriceReserve: lotPriceReserve,
            }).replaceAll('{{br}}', '<br />') +
            '</div>'
        ),
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);
  return (
    <div className='Tariffs'>
      <Message type={activeMessage.type} title={activeMessage.title} />
      <ShadowContainer className='Tariffs'>
        <div className='title'>{t('title')}</div>
        <div className='text'>{t('text')}</div>
        <div className='select'>
          {basicTariffs.map((item, index) => (
            <div
              className={`selectItem ${item.id < tariff ? 'unActive' : null}`}
              key={index}>
              <CheckBox
                state={item.id < tariff ? false : electType === item.id}
                setState={() =>
                  item.id < tariff ? null : setElectType(item.id)
                }
              />
              <div>
                <div className='title'>{item.header}</div>
                <div className='limit'>{item.limit}</div>
                <div className='active_auctions'>{item.active_auctions}</div>
              </div>
            </div>
          ))}

          {tariff === ACCOUNT_TARIFF_INDIVIDUAL ? (
            <div className='selectItem' key={tariff}>
              <CheckBox
                state={electType === tariff}
                setState={() => setElectType(tariff)}
              />
              <div>
                <div className='title'>{t('individualTariff.header')}</div>
                <div className='limit'>
                  {t('individualTariff.limit', {
                    lotPriceLimit: lotPriceLimit,
                  })}
                </div>
                <div className='active_auctions'>
                  {t('individualTariff.active_auctions', {
                    lotCountLimit: lotCountLimit,
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className='notes'>
          <div>{t('note1')}</div>
          <div>{t('note2')}</div>
        </div>
        <div className='btns'>
          {electType !== tariff ? (
            <>
              {verificationRights?.document !== true ? (
                <div className='text'>{t('not_verified_documents')}</div>
              ) : (
                <PayBtn
                  orderType={ORDER_PRODUCT_TYPE_TARIFF}
                  tariffType={electType}>
                  {t('btpay')}
                </PayBtn>
              )}
            </>
          ) : null}
        </div>
      </ShadowContainer>
    </div>
  );
}

export default Tariffs;

import {
  SEND_EMAIL_LIST,
} from './types';

const initialState = {
  token: null,
  error: null,
  status: null,
  email: '',
};

const passwordRecoveryReducer = (state = initialState, action) => {
  let new_state = {};
  switch (action.type) {
    case SEND_EMAIL_LIST:
      new_state = {
        email: action.payload.email,
      };
      break;
    default:
  }
  return {...state, ...new_state};
};

export default passwordRecoveryReducer;

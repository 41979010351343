import {Logger} from '@helpers';
import {
  ACCOUNT_TYPE_COMPANY_EU,
  ACCOUNT_TYPE_COMPANY_UA,
  ACCOUNT_TYPE_PERSONAL,
  LOT_FEATURE_VALUE_TYPE_ENUM,
  LOT_FEATURE_VALUE_TYPE_SET,
} from '@models';

import {
  ADMIN_LOGIN,
  ADMIN_LOGIN_FAILURE,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGOUT,
  CHECK_NEW_ADMIN_EVENTS_SUCCESS,
  GET_ADMIN_RIGHTS_SUCCESS,
  GET_EXPORTED_FEATURES_SUCCESS,
  GET_FILTERS_SUCCESS,
  GET_LOT_PROCESSING_DATA_SUCCESS,
  GET_OWN_FEATURES_SUCCESS,
  GET_PAYMENTS_SUCCESS,
  GET_USERS_SUCCESS,
} from './types';

const ADMIN_TOKEN = 'ADMIN_TOKEN';

const initialState = {
  token: localStorage.getItem(ADMIN_TOKEN),
  isAuthenticated: false,
  rights: null,
  users: null,
};
const adminReducer = (state = initialState, action) => {
  let new_state = {};
  switch (action.type) {
    case ADMIN_LOGIN:
      new_state = {isAuthenticated: false};
      break;
    case ADMIN_LOGIN_SUCCESS:
      new_state = {
        isAuthenticated: true,
        ...action.payload.user,
      };
      localStorage.setItem(ADMIN_TOKEN, action.payload.user.token);
      localStorage.setItem(
        'adminLastEventViewDate',
        action.payload.user.lastVisit
      );
      break;
    case ADMIN_LOGIN_FAILURE:
      new_state = {isAuthenticated: false};
      break;
    //---------------------------------------
    case ADMIN_LOGOUT:
      localStorage.removeItem(ADMIN_TOKEN);
      return {isAuthenticated: false};
    //---------------------------------------
    case GET_ADMIN_RIGHTS_SUCCESS:
      new_state = {
        isAuthenticated: true,
        rights: action.payload.rights,
      };
      break;
    //---------------------------------------
    case GET_USERS_SUCCESS:
      const users = action.payload.users;
      users.forEach(user => {
        const {nickname, id, email, phone, type} = user;
        switch (type) {
          case ACCOUNT_TYPE_PERSONAL:
            if (user.userInfo?.last_name && user.userInfo?.first_name) {
              user['info'] = `${id} <br>
              ${user.userInfo?.last_name} ${user.userInfo?.first_name} (${nickname})`;
            } else {
              user['info'] = `${id}`;
            }
            break;
          case ACCOUNT_TYPE_COMPANY_UA:
            user['info'] = `${id} <br>
            ${user.companyInfoUA.companyName} (${nickname})`;
            break;
          case ACCOUNT_TYPE_COMPANY_EU:
            user['info'] = `${id} <br>
            ${user.companyInfoEU.companyName} (${nickname})`;
            break;
          default:
            user['info'] = `${id}`;
        }
        user['contacts'] = `${email}<br>
                            ${phone}`;
      });
      new_state = {
        users: users,
      };
      if (
        state.users &&
        action.payload.users &&
        state.users.length < action.payload.users.length
      ) {
        Logger.warn(
          "Some User(s) is deleting, or don't loaded ",
          'Last State : ',
          state.users?.map(i => i.id),
          'New State : ',
          action.payload.users.map(i => i.id)
        );
      }
      break;
    //---------------------------------------
    case GET_OWN_FEATURES_SUCCESS: {
      const features = action.payload.features;
      features.forEach(feature => {
        feature['name'] =
          feature['title'].uk || feature['title'].en || feature['name'];
        feature['title'] = [feature['title']];
        if (
          !feature.valueStructure &&
          (feature.valueType === LOT_FEATURE_VALUE_TYPE_ENUM ||
            feature.valueType === LOT_FEATURE_VALUE_TYPE_SET)
        ) {
          feature.valueStructure = [];
        }
      });
      new_state = {
        ownFeatures: features,
      };
      break;
    }
    //---------------------------------------
    case GET_EXPORTED_FEATURES_SUCCESS:
      const features = action.payload.features;
      features.forEach(feature => {
        if (
          feature.valueType === LOT_FEATURE_VALUE_TYPE_ENUM ||
          feature.valueType === LOT_FEATURE_VALUE_TYPE_SET
        ) {
          if (!feature.valueStructure) {
            feature.valueStructure = [];
          }
        } else {
          feature.valueStructure = null;
        }
      });
      new_state = {
        exportedFeatures: features,
      };
      break;
    //---------------------------------------
    case GET_LOT_PROCESSING_DATA_SUCCESS:
      const array = action.payload.lots_processing?.map(i => ({
        id: i.lotId,
        ...i,
      }));
      if (!array) {
        new_state = {
          lots_processing: [],
        };
        break;
      }
      array.forEach(i => {
        i.prices.surcharges = i.prices.surcharges.map(l => ({
          id: l.taxType,
          ...l,
        }));
      });
      array.forEach(lot => {
        const {lotId, name, VIN} = lot['lotInfo'];
        lot['lotName'] = name;
        lot['lotInfo'] = `${lotId} <br>
          ${name || ''}${VIN ? `<br>${VIN}` : ''}`;
        //---
        if (lot['accountInfo']) {
          const {id, email, phone, last_name, first_name, companyName, type} =
            lot['accountInfo'];
          lot['accountInfo'] = `${id} <br>${
            type === ACCOUNT_TYPE_PERSONAL
              ? last_name && first_name
                ? last_name + ' ' + first_name
                : ''
              : companyName
          }<br>${email}<br>${phone}`;
        }
      });

      new_state = {
        lots_processing: array,
      };

      break;
    //---------------------------------------
    case CHECK_NEW_ADMIN_EVENTS_SUCCESS:
      new_state = {
        isHaveNewEvents: action.payload.isHaveNewEvents,
      };
      break;
    //---------------------------------------
    case GET_PAYMENTS_SUCCESS:
      action.payload.payments.forEach(payment => {
        if (payment['lotInfo']) {
          const {lotId, name} = payment['lotInfo'];
          payment['lotName'] = name;
          payment['lotInfo'] = `${lotId} <br>
            ${name || ''}`;
        }

        //---
        if (payment['accountInfo']) {
          const {id, email, phone, last_name, first_name, companyName, type} =
            payment['accountInfo'];
          payment['accountInfo'] = `${id} <br>${
            type === ACCOUNT_TYPE_PERSONAL
              ? last_name && first_name
                ? last_name + ' ' + first_name
                : ''
              : companyName
          }<br>${email}<br>${phone}`;
          payment['otherInfo'] = [
            {
              amount: payment.amount,
              tax_amount: payment.tax_amount,
              tax_rate: payment.tax_rate,
              completed_at: payment.completed_at,
              createdAt: payment.createdAt,

              rate_value: payment.rate_value,
              table_number_rate: payment.table_number_rate,
              date_publication_rate: payment.date_publication_rate,
            },
          ];
        }
      });

      new_state = {
        payments: action.payload.payments,
      };
      break;
    case GET_FILTERS_SUCCESS:
      new_state = {
        filters: action.payload.filters.map(filter => {
          filter['title'] = [filter['title']];
          if (
            !filter.valueStructure &&
            (filter.valueType === LOT_FEATURE_VALUE_TYPE_ENUM ||
              filter.valueType === LOT_FEATURE_VALUE_TYPE_SET)
          ) {
            filter.valueStructure = [];
          }
          return filter;
        }),
      };
      break;

    default:
      //Do nothing
      break;
  }
  return {...state, ...new_state};
};

export default adminReducer;

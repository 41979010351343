import {Link} from 'react-router-dom';

import {
  LOT_FEATURE_VALUE_TYPE_ENUM,
  LOT_FEATURE_VALUE_TYPE_SET,
} from '@helpers/models';

import {getVFF} from 'src/org/modules/getValueFomField';

import SyncWithFeature from '../dialogs/SyncWithFeature';

import InsertTable from './InsertTable';

function SyncFilter(props) {
  function res(rowData) {
    const valueType = getVFF(rowData, 'valueType');
    const syncFeatureId = getVFF(rowData, 'syncFeatureId');
    let result = null;
    if (
      valueType === LOT_FEATURE_VALUE_TYPE_ENUM ||
      valueType === LOT_FEATURE_VALUE_TYPE_SET
    ) {
      result = InsertTable(props)(rowData);
    } else if (syncFeatureId) {
      result = (
        <Link
          to={'/org/admin/features/own?search=' + syncFeatureId}
          style={{
            lineHeight: 1.4,
            color: 'rgb(49 153 118)',
            textDecoration: 'underline',
          }}
          target={'_blank'}>
          {syncFeatureId}
        </Link>
      );
    } else {
      result = (
        <SyncWithFeature
          config={props.config}
          idRow={rowData.id}
          synchronizeOnlyFeature={true}
        />
      );
    }

    return <div className='card flex justify-content-center'>{result}</div>;
  }
  return res;
}

export default SyncFilter;

import {useRef, useState} from 'react';
import {Dropdown} from 'primereact/dropdown';

import './DropDownList.scss';

function DropDownList({
  headerTitle,
  options, // its object which must don't have field 'value'
  defaultValue = null,
  optionLabel = 'title',
  value = {},
  onChange = () => {},
  ...props
}) {
  const dropDownListRef = useRef();
  const [inputValue, setInputValue] = useState(null);
  const [isValid, setIsValid] = useState(true);
  return (
    <div className={`DropDownList ${!isValid ? 'invalid' : ''}`}>
      {headerTitle ? <div className='headerTitle'>{headerTitle}</div> : null}
      <Dropdown
        editable
        emptyMessage=' '
        className='w-full'
        ref={dropDownListRef}
        optionLabel={optionLabel}
        value={inputValue === null && value ? value[optionLabel] : inputValue}
        options={(() => {
          let res = options;
          if (inputValue) {
            const filteredValues = options.filter(it =>
              it[optionLabel]?.toLowerCase().includes(inputValue?.toLowerCase())
            );
            if (filteredValues.length === 0 && !defaultValue) {
              dropDownListRef.current.hide();
              if (isValid) setIsValid(false);
            }
            res = filteredValues;
          }
          if (defaultValue) {
            return [defaultValue, ...res];
          } else {
            return res;
          }
        })()}
        onChange={e => {
          if (!e.originalEvent) {
            return;
          }
          setIsValid(true);
          setInputValue(null);
          onChange(e);
        }}
        onInput={e => {
          setInputValue(e.target.value);
          dropDownListRef.current.show();
        }}
        onFocus={() => {
          dropDownListRef.current.show();
          if (!inputValue) return;
          const filteredValues = options.filter(it =>
            it[optionLabel]?.toLowerCase().includes(inputValue?.toLowerCase())
          );
          if (filteredValues.length === 0 && !defaultValue) {
            dropDownListRef.current.hide();
          }
        }}
        {...props}
      />
    </div>
  );
}
export default DropDownList;

import ReactHtmlParser from 'html-react-parser';

import {getVFF} from '../../../modules/getValueFomField';

function HtmlText(field) {
  return rowData => {
    const value = getVFF(rowData, field);
    return <p style={{lineHeight: 1.4}}>{ReactHtmlParser(value)}</p>;
  };
}
export default HtmlText;

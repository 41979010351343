import {useTranslation} from 'react-i18next';
import './UploadPhotos.scss';

function UploadPhotos({onUpload = () => {}}) {
  const [t] = useTranslation('', {keyPrefix: 'UploadPhotos'});

  return (
    <form
      method='post'
      className='UploadDocuments'
      encType='multipart/form-data'>
      <label className='fileUpload'>{t('upload_photos')}</label>
      <input
        multiple
        id='file'
        type='file'
        accept='image/png, image/jpeg'
        name='file'
        onChange={e => onUpload(e.target.files)}
      />
    </form>
  );
}

export default UploadPhotos;

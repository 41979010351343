import React from 'react';
import {Button} from '@ui';
import {useDownloadOrderDocs} from '@hooks';
import {
  ORDER_DOC_TYPE_INVOICE,
  ORDER_DOC_TYPE_RECEIPT,
  ORDER_STATUS_SUCCESSFUL,
  ORDER_STATUS_SUCCESSFUL_MANUAL,
} from '@helpers/models';

function PaymentDocs() {
  function Invoice(rowData) {
    if ('rowData' in rowData) {
      rowData = rowData.rowData;
    }
    const invoice = useDownloadOrderDocs(ORDER_DOC_TYPE_INVOICE);
    const receipt = useDownloadOrderDocs(ORDER_DOC_TYPE_RECEIPT);

    return (
      <div className='card flex flex-column justify-content-center gap-2'>
        <div className='linkDownloadInvoice-container  gap-1'>
          <span>Invoice</span>
          <Button
            onClick={() => invoice({orderId: rowData.id})}
            className={'white linkDownloadInvoice '}>
            {rowData.order_no}
          </Button>
        </div>

        {rowData.status === ORDER_STATUS_SUCCESSFUL ||
        rowData.status === ORDER_STATUS_SUCCESSFUL_MANUAL ? (
            <div className='linkDownloadInvoice-container  gap-1'>
              <span>Receipt</span>
              <Button
                onClick={() => receipt({orderId: rowData.id})}
                className={'white linkDownloadInvoice '}>
                {rowData.order_no}
              </Button>
            </div>
          ) : null}
      </div>
    );
  }
  return Invoice;
}
export default PaymentDocs;

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {InputNumber, Button} from '@ui';
import {sentNumberPhone} from '@actions/registration';
import {useDispatchAsync} from '@hooks';
import {REGEX_NOT_SPACES} from '@helpers/regex';

import {SAVE_DATA} from '../../../../store/registration/types';

import './Step_1.scss';

function Step_1() {
  const dispatch = useDispatchAsync();
  const navigate = useNavigate();
  const [t] = useTranslation('pages/registration', {
    keyPrefix: 'step_1',
  });
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const {phoneNumber, phoneId} = useSelector(state => state.registration);
  const [phone, setPhone] = useState(phoneNumber || '');

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, []);

  async function onClickHandler() {
    if (phoneNumber !== phone || !phoneId) {
      if (phoneNumber) {
        //clear if user return from next pages and change password
        await dispatch({type: SAVE_DATA, payload: {code: null}});
      }
      const res = await dispatch(sentNumberPhone(phone), false);
      if (res.ok) {
        return navigate('/registration/Step_2');
      } else {
        if (res.code) {
          const itemMessage = document.querySelector('.under_input_text');
          itemMessage.innerHTML = t(`errors.${res.code}`);
          itemMessage.style.color = 'red';
        }
      }
    } else {
      return navigate('/registration/Step_2');
    }
  }

  return (
    <div className='Step_1'>
      <div className='titles'>
        <div className='main_title'>{t('main_title')}</div>
        <div className='sub_title'>
          {t('sub_title')}
          <br></br>
          {t('sub_title2')}
        </div>
      </div>
      <div className='content'>
        <InputNumber
          phone={phone}
          title={t('title')}
          placeholder={t('placeholder')}
          under_input_text={t('under_input_text')}
          pattern={REGEX_NOT_SPACES}
          onChangeHandler={number => setPhone(number)}
        />
        <Button onClick={onClickHandler} text={t('continue')} />
      </div>
    </div>
  );
}

export default Step_1;

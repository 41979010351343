import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {DropDown} from '@ui';
import {formatPrice} from '@utils';

import './FinalPrice.scss';

function FinalPrice() {
  const [t] = useTranslation('pages/lot');
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const bidAllowed = useSelector(state => state.user.bidAllowed);
  const lot = useSelector(state => state.lot);

  if (isAuthenticated && bidAllowed) {
    const tax = lot.prices.surcharges.reduce(
      (sum, value) => sum + value.value,
      0
    );
    return (
      <div className='FinalPrice'>
        <DropDown
          title={
            <div className='title'>
              <p className='heading'>
                {t('final_price')} {formatPrice(lot.prices.totalPrice, true)}
              </p>
              <p className='note'>{t('note')}</p>
            </div>
          }>
          <div className='surcharge'>
            <div className='title'>{t('car_cost')}</div>
            <div className='price'>
              {formatPrice(lot.prices.currentPrice, true)}
            </div>
          </div>
          <div className='surcharge'>
            <div className='title'>{t('service_cost')}</div>
            <div className='price'>{formatPrice(tax, true)}</div>
          </div>
          <p className='note'>{t('service_cost_explanation')}</p>
        </DropDown>
      </div>
    );
  }
  return <></>;
}
export default FinalPrice;

import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom';

import {checkNewAdminEvents, getAdminRights} from '@actions/admin';
import {Toast} from '@helpers';
import {useDispatchAsync} from '@hooks';

import './AdminContainer.scss';

function AdminContainer() {
  const dispatch = useDispatchAsync();
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const rights = useSelector(state => state.admin.rights);
  const lastVisit = useSelector(state => state.admin.lastVisit);
  const isHaveNewEvents = useSelector(state => state.admin.isHaveNewEvents);
  const isAuthenticated = useSelector(state => state.admin.isAuthenticated);
  const isMessageSended = useRef(false);

  function showNotificationUnreadMessages() {
    setTimeout(() => {
      Toast.info('У вас є не прочитані повідомлення', 10000);
      isMessageSended.current = true;
    }, 400);
  }
  useEffect(() => {
    if (isHaveNewEvents && !isMessageSended.current) {
      showNotificationUnreadMessages();
      return;
    }
    if (!isHaveNewEvents) {
      isMessageSended.current = false;
    }
  }, [isHaveNewEvents]);

  const [links, _] = useState([
    {
      right: rights.moderation,
      path: 'moderation',
      title: 'Модерація',
    },
    {
      right: rights.moderation_of_documents,
      path: 'moderation_of_documents',
      title: 'Модерація документів',
    },
    // {right: rights.support, path: 'support', title: 'Підтримка'},
    {
      right: rights.events,
      path: 'events',
      title: 'Повідомлення',
    },
    {right: rights.invoices, path: 'lots', title: 'Виграні лоти'},
    {right: rights.payments, path: 'payments', title: 'Покупки'},
    {
      right: rights.features,
      path: 'filters',
      title: 'Фільтри',
    },
    {
      right: rights.features,
      path: 'features',
      title: 'Характеристики',
    },
    {right: rights.debug, path: 'debug', title: 'Логи'},
    {right: true, path: 'go-out', title: 'Вийти'},
  ]);

  useEffect(() => {
    dispatch(getAdminRights());
  }, []);

  useEffect(() => {
    if (pathname === '/org/admin') {
      return navigate(pathname + '/' + links.find(i => i.right).path, {
        replace: true,
      });
    }

    if (
      isAuthenticated &&
      rights.events &&
      localStorage.getItem('ADMIN_TOKEN')
    ) {
      const date = localStorage.getItem('adminLastEventViewDate') || lastVisit;
      if (date) {
        dispatch(checkNewAdminEvents(date), false);
      } else if (!isMessageSended.current) {
        showNotificationUnreadMessages();
      }
    }
  }, [location]);

  let page = '';
  if (pathname.includes('/org/admin/')) {
    const link = pathname.split('/org/admin/')[1];
    page = link.split('/')[0];
  }

  return (
    <div className='AdminContainer'>
      <div className='inner_container'>
        <ul className='navbar'>
          {links
            .filter(i => i.right)
            .map((it, index) => (
              <li key={index} className={page === it.path ? 'active' : ''}>
                <Link to={'/org/admin/' + it.path}>{it.title}</Link>
              </li>
            ))}
        </ul>
        <div className='bloc-info'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
export default AdminContainer;

import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {Loader} from '@ui';
import {Logger} from '@helpers';
import {useDispatchAsync} from '@hooks';

import {
  getPayments,
  updatePaymentProvider,
  updatePaymentStatus,
} from '@actions/admin';

import {
  ORDER_PAYMENT_PROVIDER_ING_PL,
  ORDER_PAYMENT_PROVIDER_PAYNOW,
  ORDER_PAYMENT_PROVIDER_MANUAL,
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_FAILED,
  ORDER_STATUS_NEW,
  ORDER_STATUS_RETRY,
  ORDER_STATUS_SUCCESSFUL,
  ORDER_STATUS_SUCCESSFUL_MANUAL,
} from '@models';

import useUpdateData from '../../../hooks/update-data.hook';
import Table from '../../../2-components/table/Table';

import {config} from './config';

import './Payments.scss';

function Payments() {
  const pages = [
    {title: 'Нові', status: ORDER_STATUS_NEW, url: 'new'},
    {
      title: 'Оплачені',
      status: ORDER_STATUS_SUCCESSFUL,
      url: 'successful',
    },
    {title: 'Невдалі', status: ORDER_STATUS_FAILED, url: 'failed'},
    {
      title: 'Повторні',
      status: ORDER_STATUS_RETRY,
      url: 'retry',
    },
    {
      title: 'Відхилені',
      status: ORDER_STATUS_CANCELLED,
      url: 'cancelled',
    },
    {
      title: 'Завершені manual',
      status: ORDER_STATUS_SUCCESSFUL_MANUAL,
      url: 'successful_manual',
    },
  ];
  const dispatch = useDispatchAsync();
  const api = useUpdateData();
  const payments = useSelector(state => state.admin.payments);

  useEffect(() => {
    dispatch(getPayments());
  }, []);

  useEffect(() => {
    config.onLoad = async () => {
      await dispatch(getPayments());
    };

    config.onSave = async ({next, fieldMustToUpdated}) => {
      for (const {field, value} of fieldMustToUpdated) {
        switch (field) {
          case 'status':
            await api(updatePaymentStatus(next.id, value));
            break;
          case 'payment_provider':
            switch (value) {
              case ORDER_PAYMENT_PROVIDER_MANUAL:
              case ORDER_PAYMENT_PROVIDER_ING_PL:
              case ORDER_PAYMENT_PROVIDER_PAYNOW:
                await api(updatePaymentProvider(next.id, value));
                break;
              default:
                Logger.warn(`Some unknown payment_provider:${value}`);
            }
            break;
          default:
            Logger.warn('NEW field :' + field + ' in admin Payments');
        }
      }
    };
  });

  if (!payments || payments.length === 0) return <Loader />;

  return (
    <div className='PaymentsOrg'>
      <Table
        config={config}
        PATH={'/org/admin/payments'}
        pages={pages}
        data={payments}
      />
    </div>
  );
}

export default Payments;

import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {addDocument} from '@actions/user';
import {Toast} from '@helpers';
import {useDispatchAsync} from '@hooks';

import './UploadDocuments.scss';

function UploadDocuments({onUploadDocument}) {
  const dispatch = useDispatchAsync();
  const [file, setFile] = useState();
  const [t] = useTranslation('pages/profile', {
    keyPrefix: 'documents',
  });

  function handleFileChange(e) {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size > 5 * 1024 * 1024) {
        Toast.error(t('large_file_size'));
      } else {
        setFile(e.target.files[0]);
      }
      e.target.files = new DataTransfer().files;
    }
  }

  async function handleUploadClick() {
    if (!file) {
      Toast.error(t('no_selected'));
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    const res = await dispatch(addDocument(formData));
    if (res.ok) {
      Toast.success(t('load_success'));
    }
    if (onUploadDocument) onUploadDocument(formData);
    setFile(null);
  }

  return (
    <form
      method='post'
      className='UploadDocuments'
      encType='multipart/form-data'>
      <label className='fileUpload'>{t('file_upload')}</label>
      <input id='file' type='file' name='file' onChange={handleFileChange} />
      {file ? (
        <div className='dataFile'>
          <div>{file.name}</div>
          <div className='btUpLoad' onClick={handleUploadClick}>
            {t('btUpLoad')}
          </div>
        </div>
      ) : null}
    </form>
  );
}

export default UploadDocuments;

import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {Loader} from '@ui';
import {compareTwoStringDigits, formatPrice} from '@utils';
import {loadDataForHome} from '@actions/home';
import {useDispatchAsync} from '@hooks';

import 'swiper/swiper.min.css';
import './TopViews.scss';
import {Tags} from '@components/index';

function TopViews() {
  const [t] = useTranslation('', {keyPrefix: 'TopViews'});
  const dispatch = useDispatchAsync();

  const topViews = useSelector(state => state.home.topViews);

  useEffect(() => {
    if (!topViews) dispatch(loadDataForHome());
  }, []);

  function render() {
    if (!topViews) {
      return <Loader msg={t('waiting')} />;
    }
    return (
      <div className='top_card_area'>
        {topViews.map((lot, index) => (
          <Link to={`/lot/${lot.lotId}`} key={index} className='top_card'>
            <div className='top_img'>
              <img src={lot.img} alt='' />
            </div>

            <div className='top_car_block_info'>
              <h5 className='top_car_name'>{lot.name}</h5>
              <div>
                <Tags tagsInfo={lot.tags} />
              </div>

              <div className='top_price_area'>
                <div className='top_price_left'>
                  <h5>{t('price')}:</h5>
                  <h4>{formatPrice(lot.price)}</h4>
                </div>

                <div className='top_price_right'>
                  <h5
                    className={
                      compareTwoStringDigits(lot.highestBid, lot.price)
                        ? 'color-green'
                        : 'color-red'
                    }>
                    {t('highestPrice')}:
                  </h5>
                  <h4
                    className={
                      compareTwoStringDigits(lot.highestBid, lot.price)
                        ? 'color-green'
                        : 'color-red'
                    }>
                    {lot.highestBid === null
                      ? t('noBids')
                      : formatPrice(lot.highestBid)}
                  </h4>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    );
  }
  return (
    <section className='TopViews'>
      <h2 className='top_title'>{t('mostViewed')}</h2>
      <div className='top_short'>{t('mostViewedDesc')}</div>
      {render()}
    </section>
  );
}

export default TopViews;

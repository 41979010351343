import {CheckBox} from '@ui';
import './Radio.scss';

function Radio({filter, onChange}) {
  function onChangeRadio(title, id) {
    filter.data.forEach(el => {
      if (el.id === id) {
        onChange(title, el.id);
      }
    });
  }

  return (
    <div className='Radio'>
      <div className='scroll_container'>
        {filter.data.map((item, index) => (
          <label
            key={index}
            tabIndex={index}
            onClick={() => {
              item.checked !== true && onChangeRadio(filter.title, item.id);
            }}>
            <CheckBox
              id={item.id}
              state={item.checked}
            />
            <span>{item.name}</span>
          </label>
        ))}
      </div>
    </div>
  );
}
export default Radio;

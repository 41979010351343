import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {useState} from 'react';

function ConfirmAutoSortIndexDialog({idRow, config}) {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button
        label='Автоматично виставити SortIndex'
        icon='pi pi-calculator'
        severity='info'
        onClick={() => setVisible(true)}
      />
      <Dialog
        visible={visible}
        style={{width: '32rem'}}
        breakpoints={{'960px': '75vw', '641px': '90vw'}}
        header='Підтвердження'
        modal
        footer={
          <>
            <Button
              label='Ні'
              icon='pi pi-times'
              outlined
              onClick={() => setVisible(false)}
            />
            <Button
              label='Так'
              icon='pi pi-check'
              severity='info'
              onClick={async () => {
                if (config.fields.sortIndex) {
                  await config.onAutoSortIndex(idRow);
                }
                setVisible(false);
                await config.onLoad();
              }}
            />
          </>
        }
        onHide={() => setVisible(false)}>
        <div className='confirmation-content'>
          <i
            className='pi pi-exclamation-triangle mr-3'
            style={{fontSize: '2rem'}}
          />
          <span>Виставити автоматично SortIndex ?</span>
        </div>
      </Dialog>
    </>
  );
}
export default ConfirmAutoSortIndexDialog;

const ACCOUNT_DOCUMENT_STATUS_NEW = 0;
const ACCOUNT_DOCUMENT_STATUS_APPROVED = 1;
const ACCOUNT_DOCUMENT_STATUS_DECLINED = 2;

const ACCOUNT_DOCUMENT_REASON_DECLINED_INAPPROPRIATE_DOCUMENT = 10;
const ACCOUNT_DOCUMENT_REASON_DECLINED_BAD_QUALITY = 11;
const ACCOUNT_DOCUMENT_REASON_DECLINED_NOT_FULLY_SCANNED = 12;
const ACCOUNT_DOCUMENT_REASON_DECLINED_PART_DATA_DO_NOT_MATCH = 13;
const ACCOUNT_DOCUMENT_REASON_DECLINED_FULL_DATA_DO_NOT_MATCH = 14;
const ACCOUNT_DOCUMENT_REASON_DECLINED_HAS_EXPIRED = 15;

const ACCOUNT_STATUS_EMPTY = 0;
const ACCOUNT_STATUS_NEW = 1;
const ACCOUNT_STATUS_VERIFIED = 2;

const AUCTION_STATUS_LIVE = 0;
const AUCTION_STATUS_FUTURE = 1;
const AUCTION_STATUS_FINISHED = 2;

const ACCOUNT_TARIFF_300 = 0;
const ACCOUNT_TARIFF_500 = 1;
const ACCOUNT_TARIFF_1000 = 2;
const ACCOUNT_TARIFF_PREMIUM = 3;
const ACCOUNT_TARIFF_INDIVIDUAL = 4;

const ACCOUNT_TYPE_PERSONAL = 0;
const ACCOUNT_TYPE_COMPANY_UA = 1;
const ACCOUNT_TYPE_COMPANY_EU = 2;

const BID_STATUS_NEW = 0;
const BID_STATUS_APPROVED = 1;
const BID_STATUS_ERROR = 2;
const BID_STATUS_CANCELED = 3;
const BID_STATUS_TOO_LOW = 4;

const EVENT_STATUS_PROCESSED = 1;

const EVENT_TYPE_REGISTRATION = 0;
const EVENT_TYPE_DOCUMENT_CHECKED = 1;
const EVENT_TYPE_UPDATE_BIT_ALLOWED = 2;
const EVENT_TYPE_ADD_NEW_DOCUMENT = 3;
const EVENT_TYPE_UPDATE_TARIFF = 4;

const EVENT_TYPE_LOT_PROCESSING_STATUS_NEW = 5;
const EVENT_TYPE_LOT_PROCESSING_STATUS_ADMIN_CHECKED = 6;
const EVENT_TYPE_LOT_PROCESSING_STATUS_PAYED = 7;
const EVENT_TYPE_LOT_PROCESSING_STATUS_DELIVERY = 8;
const EVENT_TYPE_LOT_PROCESSING_STATUS_DELIVERED = 9;
const EVENT_TYPE_LOT_PROCESSING_STATUS_DONE = 10;
const EVENT_TYPE_LOT_PROCESSING_STATUS_REJECTED = 11;

const EVENT_TYPE_VERIFICATION_DOCUMENT_SEND = 100;
const EVENT_TYPE_VERIFICATION_DOCUMENT_VERIFIED = 101;
const EVENT_TYPE_VERIFICATION_DOCUMENT_DECLINED = 102;
const EVENT_TYPE_VERIFICATION_CONTRACT_SIGNED = 105;
const EVENT_TYPE_VERIFICATION_USER_VERIFIED = 110;

const LOT_PRICE_TYPE_NET = 1;
const LOT_PRICE_TYPE_GROSS = 2;

const LOT_PROCESSING_STATUS_NEW = 0;
const LOT_PROCESSING_STATUS_ADMIN_CHECKED = 1;
const LOT_PROCESSING_STATUS_PAYED = 10;
const LOT_PROCESSING_STATUS_DELIVERY = 21;
const LOT_PROCESSING_STATUS_DELIVERED = 22;
const LOT_PROCESSING_STATUS_DONE = 100;
const LOT_PROCESSING_STATUS_REJECTED = 400;

const LOT_STATUS_NEW = 0;
const LOT_STATUS_READY = 1;
const LOT_STATUS_PREPARATION = 2;
const LOT_STATUS_ACTIVE = 3;
const LOT_STATUS_FINISHED_SOLD = 10;
const LOT_STATUS_FINISHED_NOT_SOLD = 11;
const LOT_STATUS_FINISHED_RESERVED = 12;
const LOT_STATUS_HIDDEN = 100;

const LOT_SURCHARGES_TYPE_SERVICE_FEE = 1;

const LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_REGULAR = 20;
const LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_SUV = 21;
const LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_BUS = 22;
const LOT_SURCHARGES_TYPE_AUTOBID_FEE_NET = 101;
const LOT_SURCHARGES_TYPE_AUTOBID_FEE_GROSS = 102;
const LOT_SURCHARGES_TYPE_AUTOBID_NET_SERVICE = 103;
const LOT_SURCHARGES_TYPE_AUTOBID_ONLINE_HANDLING_GROSS = 104;
const LOT_SURCHARGES_TYPE_AUTOBID_ONLINE_HANDLING_NET = 105;
const LOT_SURCHARGES_TYPE_AUTOBID_DOCUMENTS_HANDLING = 106;
const LOT_SURCHARGES_TYPE_AUTOBID_VAT_EU = 110;

const ORDER_CURRENCY_PLN = 'PLN';
const ORDER_CURRENCY_EUR = 'EUR';

const ORDER_PAYMENT_PROVIDER_MANUAL = 0;
const ORDER_PAYMENT_PROVIDER_ING_PL = 1;
const ORDER_PAYMENT_PROVIDER_PAYNOW = 2;

const ORDER_DOC_TYPE_INVOICE = 0;
const ORDER_DOC_TYPE_RECEIPT = 1;

const ORDER_PRODUCT_TYPE_TARIFF = 0;
const ORDER_PRODUCT_TYPE_LOT = 1;

const ORDER_STATUS_NEW = 0;
const ORDER_STATUS_SUCCESSFUL = 1;
const ORDER_STATUS_FAILED = 2;
const ORDER_STATUS_RETRY = 3;
const ORDER_STATUS_CANCELLED = 4;
const ORDER_STATUS_SUCCESSFUL_MANUAL = 5;

const PROBLEM_ID_0 = 0;
const PROBLEM_ID_1 = 1;
const PROBLEM_ID_2 = 2;
const PROBLEM_ID_3 = 3;
const PROBLEM_ID_4 = 4;
const PROBLEM_ID_5 = 5;
const PROBLEM_ID_6 = 6;
const PROBLEM_ID_7 = 7;

const LOT_CATEGORY_CAR = 1;
const LOT_CATEGORY_STUFF = 2;

const LOT_FEATURE_VALUE_TYPE_NUMBER = 1;
const LOT_FEATURE_VALUE_TYPE_STRING = 2;
const LOT_FEATURE_VALUE_TYPE_BOOLEAN = 3;
const LOT_FEATURE_VALUE_TYPE_DATE = 4;
const LOT_FEATURE_VALUE_TYPE_ENUM = 5;
const LOT_FEATURE_VALUE_TYPE_SET = 6;

function getSURCHARGES_NET() {
  return [
    LOT_SURCHARGES_TYPE_AUTOBID_FEE_NET,
    LOT_SURCHARGES_TYPE_AUTOBID_ONLINE_HANDLING_NET,
  ];
}

function getSURCHARGES_GROSS() {
  return [
    LOT_SURCHARGES_TYPE_AUTOBID_FEE_GROSS,
    LOT_SURCHARGES_TYPE_AUTOBID_ONLINE_HANDLING_GROSS,
  ];
}

const LOT_FEATURE_CATEGORY_GROUP_CAR_OTHER = 0;
const LOT_FEATURE_CATEGORY_GROUP_CAR_MAIN_DATA = 1;
const LOT_FEATURE_CATEGORY_GROUP_CAR_DOCUMENTATION = 2;
const LOT_FEATURE_CATEGORY_GROUP_CAR_OPTIONS = 3;
const LOT_FEATURE_CATEGORY_GROUP_CAR_SEATS = 4;
const LOT_FEATURE_CATEGORY_GROUP_CAR_TIRES = 5;
const LOT_FEATURE_CATEGORY_GROUP_CAR_BODY_DEFECTS = 6;
const LOT_FEATURE_CATEGORY_GROUP_CAR_BODY_SALON = 7;
const LOT_FEATURE_CATEGORY_GROUP_CAR_BODY_SELLER_INFO = 8;

const FILTER_OPTION_MANUFACTURER = 1;
const FILTER_OPTION_YEAR = 2;
const FILTER_OPTION_FUEL = 3;
const FILTER_OPTION_TRANSMISSION = 4;
const FILTER_OPTION_DAMAGE = 5;
const FILTER_OPTION_MILES = 6;
const FILTER_OPTION_POWER_KW = 7;
const FILTER_OPTION_POWER_HP = 8;
const FILTER_OPTION_ENGINE_SIZE = 10;
const FILTER_OPTION_COLOR = 11;
const FILTER_OPTION_DOORS = 12;

const DATA_SOURCE_TYPE_OWN = 0;
const DATA_SOURCE_TYPE_AUTOBID2 = 2;
const DATA_SOURCE_TYPE_AUTOBID3 = 3;

const LOT_FEATURE_SYNC_STATUS_WAITING_ADMIN = 0;
const LOT_FEATURE_SYNC_STATUS_WAITING_SYNC = 1;
const LOT_FEATURE_SYNC_STATUS_READY = 2;
const LOT_FEATURE_SYNC_STATUS_ERROR = 3;


// Alphabet order !!!
export {
  ACCOUNT_DOCUMENT_STATUS_NEW,
  ACCOUNT_DOCUMENT_STATUS_APPROVED,
  ACCOUNT_DOCUMENT_STATUS_DECLINED,

  ACCOUNT_DOCUMENT_REASON_DECLINED_INAPPROPRIATE_DOCUMENT,
  ACCOUNT_DOCUMENT_REASON_DECLINED_BAD_QUALITY,
  ACCOUNT_DOCUMENT_REASON_DECLINED_NOT_FULLY_SCANNED,
  ACCOUNT_DOCUMENT_REASON_DECLINED_PART_DATA_DO_NOT_MATCH,
  ACCOUNT_DOCUMENT_REASON_DECLINED_FULL_DATA_DO_NOT_MATCH,
  ACCOUNT_DOCUMENT_REASON_DECLINED_HAS_EXPIRED,

  ACCOUNT_STATUS_EMPTY,
  ACCOUNT_STATUS_NEW,
  ACCOUNT_STATUS_VERIFIED,

  AUCTION_STATUS_LIVE,
  AUCTION_STATUS_FUTURE,
  AUCTION_STATUS_FINISHED,
  ACCOUNT_TARIFF_300,
  ACCOUNT_TARIFF_500,
  ACCOUNT_TARIFF_1000,
  ACCOUNT_TARIFF_PREMIUM,
  ACCOUNT_TARIFF_INDIVIDUAL,

  ACCOUNT_TYPE_PERSONAL,
  ACCOUNT_TYPE_COMPANY_UA,
  ACCOUNT_TYPE_COMPANY_EU,

  BID_STATUS_NEW,
  BID_STATUS_APPROVED,
  BID_STATUS_ERROR,
  BID_STATUS_CANCELED,
  BID_STATUS_TOO_LOW,

  EVENT_STATUS_PROCESSED,
  EVENT_TYPE_REGISTRATION,
  EVENT_TYPE_DOCUMENT_CHECKED,
  EVENT_TYPE_UPDATE_BIT_ALLOWED,
  EVENT_TYPE_ADD_NEW_DOCUMENT,
  EVENT_TYPE_UPDATE_TARIFF,
  EVENT_TYPE_LOT_PROCESSING_STATUS_NEW,
  EVENT_TYPE_LOT_PROCESSING_STATUS_ADMIN_CHECKED,
  EVENT_TYPE_LOT_PROCESSING_STATUS_PAYED,
  EVENT_TYPE_LOT_PROCESSING_STATUS_DELIVERY,
  EVENT_TYPE_LOT_PROCESSING_STATUS_DELIVERED,
  EVENT_TYPE_LOT_PROCESSING_STATUS_DONE,
  EVENT_TYPE_LOT_PROCESSING_STATUS_REJECTED,
  EVENT_TYPE_VERIFICATION_DOCUMENT_SEND,
  EVENT_TYPE_VERIFICATION_DOCUMENT_VERIFIED,
  EVENT_TYPE_VERIFICATION_DOCUMENT_DECLINED,
  EVENT_TYPE_VERIFICATION_CONTRACT_SIGNED,
  EVENT_TYPE_VERIFICATION_USER_VERIFIED,

  LOT_PRICE_TYPE_NET,
  LOT_PRICE_TYPE_GROSS,

  LOT_PROCESSING_STATUS_NEW,
  LOT_PROCESSING_STATUS_ADMIN_CHECKED,
  LOT_PROCESSING_STATUS_PAYED,
  LOT_PROCESSING_STATUS_DELIVERY,
  LOT_PROCESSING_STATUS_DELIVERED,
  LOT_PROCESSING_STATUS_DONE,
  LOT_PROCESSING_STATUS_REJECTED,

  LOT_STATUS_NEW,
  LOT_STATUS_READY,
  LOT_STATUS_PREPARATION,
  LOT_STATUS_ACTIVE,
  LOT_STATUS_FINISHED_SOLD,
  LOT_STATUS_FINISHED_NOT_SOLD,
  LOT_STATUS_FINISHED_RESERVED,
  LOT_STATUS_HIDDEN,

  LOT_SURCHARGES_TYPE_SERVICE_FEE,
  LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_REGULAR,
  LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_SUV,
  LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_BUS,
  LOT_SURCHARGES_TYPE_AUTOBID_FEE_NET,
  LOT_SURCHARGES_TYPE_AUTOBID_FEE_GROSS,
  LOT_SURCHARGES_TYPE_AUTOBID_NET_SERVICE,
  LOT_SURCHARGES_TYPE_AUTOBID_ONLINE_HANDLING_GROSS,
  LOT_SURCHARGES_TYPE_AUTOBID_ONLINE_HANDLING_NET,
  LOT_SURCHARGES_TYPE_AUTOBID_DOCUMENTS_HANDLING,
  LOT_SURCHARGES_TYPE_AUTOBID_VAT_EU,

  ORDER_DOC_TYPE_INVOICE,
  ORDER_DOC_TYPE_RECEIPT,
  ORDER_CURRENCY_PLN,
  ORDER_CURRENCY_EUR,
  ORDER_PAYMENT_PROVIDER_MANUAL,
  ORDER_PAYMENT_PROVIDER_ING_PL,
  ORDER_PAYMENT_PROVIDER_PAYNOW,
  ORDER_PRODUCT_TYPE_TARIFF,
  ORDER_PRODUCT_TYPE_LOT,
  ORDER_STATUS_NEW,
  ORDER_STATUS_SUCCESSFUL,
  ORDER_STATUS_FAILED,
  ORDER_STATUS_RETRY,
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_SUCCESSFUL_MANUAL,

  PROBLEM_ID_0,
  PROBLEM_ID_1,
  PROBLEM_ID_2,
  PROBLEM_ID_3,
  PROBLEM_ID_4,
  PROBLEM_ID_5,
  PROBLEM_ID_6,
  PROBLEM_ID_7,

  LOT_CATEGORY_CAR,
  LOT_CATEGORY_STUFF,

  getSURCHARGES_GROSS,
  getSURCHARGES_NET,

  LOT_FEATURE_VALUE_TYPE_NUMBER,
  LOT_FEATURE_VALUE_TYPE_STRING,
  LOT_FEATURE_VALUE_TYPE_BOOLEAN,
  LOT_FEATURE_VALUE_TYPE_DATE,
  LOT_FEATURE_VALUE_TYPE_ENUM,
  LOT_FEATURE_VALUE_TYPE_SET,

  LOT_FEATURE_CATEGORY_GROUP_CAR_OTHER,
  LOT_FEATURE_CATEGORY_GROUP_CAR_MAIN_DATA,
  LOT_FEATURE_CATEGORY_GROUP_CAR_DOCUMENTATION,
  LOT_FEATURE_CATEGORY_GROUP_CAR_OPTIONS,
  LOT_FEATURE_CATEGORY_GROUP_CAR_SEATS,
  LOT_FEATURE_CATEGORY_GROUP_CAR_TIRES,
  LOT_FEATURE_CATEGORY_GROUP_CAR_BODY_DEFECTS,
  LOT_FEATURE_CATEGORY_GROUP_CAR_BODY_SALON,
  LOT_FEATURE_CATEGORY_GROUP_CAR_BODY_SELLER_INFO,

  FILTER_OPTION_MANUFACTURER,
  FILTER_OPTION_YEAR,
  FILTER_OPTION_FUEL,
  FILTER_OPTION_TRANSMISSION,
  FILTER_OPTION_DAMAGE,
  FILTER_OPTION_MILES,
  FILTER_OPTION_POWER_KW,
  FILTER_OPTION_POWER_HP,
  FILTER_OPTION_ENGINE_SIZE,
  FILTER_OPTION_COLOR,
  FILTER_OPTION_DOORS,

  DATA_SOURCE_TYPE_OWN,
  DATA_SOURCE_TYPE_AUTOBID2,
  DATA_SOURCE_TYPE_AUTOBID3,

  LOT_FEATURE_SYNC_STATUS_WAITING_ADMIN,
  LOT_FEATURE_SYNC_STATUS_WAITING_SYNC,
  LOT_FEATURE_SYNC_STATUS_READY,
  LOT_FEATURE_SYNC_STATUS_ERROR,
};

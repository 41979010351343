import {
  REGEX_EMAIL,
  REGEX_NICK_NAME,
  REGEX_PASSWORD,
} from '@helpers/regex';

export const inputsData = [
  {
    id: 'nickname',
    type: 'text',
    pattern: REGEX_NICK_NAME,
  },
  {
    id: 'email',
    type: 'email',
    pattern: REGEX_EMAIL,
  },
  {
    id: 'password',
    type: 'password',
    pattern: REGEX_PASSWORD,
  },
  {
    id: 'repeatpassword',
    type: 'password',
  },
];

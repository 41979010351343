import GenerateLotInvoice from './GenerateLotInvoice';
import ProductIdInOrder from './ProductIdInOrder';
import InsertTable from './InsertTable';
import OrdersLink from './OrdersLink';
import HtmlText from './HtmlText';
import TagField from './TagField';
import UserLink from './UserLink';
import LotLink from './LotLink';
import Price from './Price';
import Date from './Date';
import SyncFilter from './SyncFilter';
import PaymentDocs from './PaymentDocs';

function buildBody(props) {
  const {
    config,
    field,
    synchronizeValueStructure,
    selectedProduct,
    middlewareSetColumns,
  } = props;
  const col = config.fields[field];

  switch (col?.type) {
    case 'date':
      return Date(field);
    case 'syncFilter':
      return SyncFilter({
        config: col,
        field,
        synchronizeValueStructure,
        selectedProduct,
        middlewareSetColumns,
      });
    case 'table':
      return InsertTable({
        config: col,
        field,
        synchronizeValueStructure,
        selectedProduct,
        middlewareSetColumns,
      });
    case 'lotLink':
      return LotLink(field);
    case 'productIdInOrder':
      return ProductIdInOrder(field);
    case 'userLink':
      return UserLink(field);
    case 'htmlText':
      return HtmlText(field);
    case 'ordersLink':
      return OrdersLink(field);
    case 'price':
      return Price(field);
    case 'list':
    case 'listStatus':
    case 'paymentStatus':
      return TagField(col, field);
    case 'generateLotInvoice':
      return GenerateLotInvoice(config);
    case 'paymentDocs':
      return PaymentDocs(config);
    default:
      //Do nothing
      break;
  }
}
export default buildBody;

import {DropDownList, Input} from '@ui';
import {LOT_PRICE_TYPE_GROSS, LOT_PRICE_TYPE_NET} from '@helpers/models';

function Description({data, setData}) {
  const priceTypeOptions = [
    {
      title: 'Нетто',
      type: LOT_PRICE_TYPE_NET,
    },
    {
      title: 'Брутто',
      type: LOT_PRICE_TYPE_GROSS,
    },
  ];
  return (
    <div className='Description'>
      <Input
        headerTitle={'Назва лота'}
        value={data['name']}
        onChange={e =>
          setData(prev => ({
            ...prev,
            name: e.target.value,
          }))
        }
      />
      <Input
        headerTitle={'VIN'}
        value={data['VIN']}
        onChange={e =>
          setData(prev => ({
            ...prev,
            VIN: e.target.value,
          }))
        }
      />
      <DropDownList
        headerTitle={'Виберіть тип ціни'}
        value={
          typeof data.prices['priceType'] === 'number'
            ? priceTypeOptions.find(el => el.type === data.prices['priceType'])
            : null
        }
        onChange={e => {
          setData(prev => ({
            ...prev,
            prices: {
              ...prev.prices,
              priceType: e.value.type,
            },
          }));
        }}
        options={priceTypeOptions}
      />
      {[
        {
          headerTitle: 'Ціна від власника',
          code: 'ownerPrice',
        },
        {
          headerTitle: 'Початкова ціна',
          code: 'minimalPrice',
        },
      ].map(({headerTitle, code}) => (
        <Input
          type='number'
          headerTitle={headerTitle}
          value={data.prices[code]}
          onChange={e =>
            setData(prev => ({
              ...prev,
              prices: {...prev.prices, [code]: parseFloat(e.target.value)},
            }))
          }
        />
      ))}
    </div>
  );
}

export default Description;

import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom';

import {useDispatchAsync} from '@hooks';
import {ProgressBar} from '@ui';

import {CLEAN_ALL_REGISTRATION_DATA} from '../../store/registration/types';

import './Registration.scss';

function Registration() {
  const dispatch = useDispatchAsync();
  const location = useLocation();
  const navigate = useNavigate();
  const [t] = useTranslation('pages/registration', {
    keyPrefix: 'registration',
  });
  const allSteps = t('allSteps', {returnObjects: true});
  const [activePage, setActivePage] = useState(1);
  const {phoneNumber} = useSelector(state => state.registration);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  useEffect(() => {
    dispatch({type: CLEAN_ALL_REGISTRATION_DATA});
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      return navigate('/', {replace: true});
    }
    if (!phoneNumber && location.pathname !== '/registration') {
      return navigate('/registration/Step_1', {replace: true});
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/registration') {
      return navigate('/registration/Step_1', {replace: true});
    }
    const page = Number(location.pathname.split('_')[1]) - 1;
    setActivePage(page);
  }, [location]);

  return (
    <div className='Registration container'>
      <div className='navBar'>
        <div className='header'>
          <span className='mainTitle'>{t('registration')}</span>
          <span>
            {t('haveAccount')} <Link to='/auth'>{t('signIn')}</Link>
          </span>
        </div>
        <ProgressBar steps={allSteps} activeStep={activePage} gap={80} />
      </div>
      <div className='containerRegistration'>
        <Outlet />
      </div>
    </div>
  );
}
export default Registration;

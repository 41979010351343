import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {ShadowContainer} from '@containers';
import {CheckBox} from '@ui';
import {Toast} from '@helpers';
import {ACCOUNT_STATUS_VERIFIED} from '@models';

import VerificationDocuments from './VerificationDocuments';
import VerificationContract from './VerificationContract';
import VerificationTariff from './VerificationTariff';

import './Verification.scss';

function Verification() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const [page, setPage] = useState(
    location.pathname.split('/profile/verification/')[1] || 'documents'
  );
  const [t] = useTranslation('pages/profile', {
    keyPrefix: 'verification',
  });

  useEffect(() => {
    if (
      user.verificationRights.document &&
      user.verificationRights.contract &&
      user.verificationRights.tariff &&
      user.status === ACCOUNT_STATUS_VERIFIED &&
      !user.bidAllowed
    ) {
      Toast.info(t('wait_when_admin_give_bid_allowed'), 10000);
    }
  }, []);

  useEffect(() => {
    return navigate('/profile/verification/' + page, {replace: true});
  }, [page]);

  return (
    <div className='Verification'>
      <div className={'header'}>
        <section onClick={() => setPage('documents')}>
          <CheckBox state={user.verificationRights.document} />
          <div className={page === 'documents' ? 'active' : ''}>
            {t('documents')}
          </div>
        </section>
        <section onClick={() => setPage('contract')}>
          <CheckBox state={user.verificationRights.contract} />
          <div className={page === 'contract' ? 'active' : ''}>
            {t('contract')}
          </div>
        </section>
        <section onClick={() => setPage('tariff')}>
          <CheckBox state={user.verificationRights.tariff} />
          <div className={page === 'tariff' ? 'active' : ''}>{t('tariff')}</div>
        </section>
      </div>

      <ShadowContainer className={'body'}>
        {page === 'documents' ? <VerificationDocuments /> : null}
        {page === 'contract' ? <VerificationContract /> : null}
        {page === 'tariff' ? <VerificationTariff /> : null}
      </ShadowContainer>
    </div>
  );
}

export default Verification;

import {useTranslation} from 'react-i18next';
import './InfoCompany.scss';

function InfoCompany() {
  const [t] = useTranslation('pages/introduction/info-company');
  return (
    <div className='InfoCompany'>
      <h3>{t('registration_information')}</h3>
      <div>
        <h4>{t('name_register')}:</h4>
        <p>Krajowy Rejestr Sadowy</p>
      </div>
      <div>
        <h4>{t('recorder')}:</h4>
        <p>
          SAD REJONOWY W LUBLIN-WSCHOD W LUBLINIE Z SIEDZIBA W SWIDNIKU,
          <br />
          VI WYDZIAL GOSPODARCZY KRAJOWEGO REJESTRU SADOWEGO
        </p>
      </div>
      <div>
        <h4>{t('registers')}:</h4>
        <p>
          NIP: 5252617553
          <br />
          REGON: 361454709
          <br />
          KRS: 0000555273
        </p>
      </div>
      <div>
        <h4>{t('size_authorized_capital')}:</h4>
        <p>50 0000 PLN</p>
      </div>
    </div>
  );
}

export default InfoCompany;

import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Divider} from 'primereact/divider';

import {LotMiniGalleria} from '@components/index';

import ProgressBar from '../progress-bar/ProgressBar';
import CurrentBid from '../current-bid/CurrentBid';

import './ActiveLot.scss';

function ActiveLot() {
  const navigate = useNavigate();
  const [t] = useTranslation('pages/lot');

  const lot = useSelector(state => state.lot);
  const activeLot = useSelector(state => state.activeLot);

  return (
    <div
      className='ActiveLot'
      onClick={() => {
        navigate(`/lot/${lot.currentLotId}`);
      }}>
      <div className='title'>
        {lot.index > lot.currentLotIndex
          ? t('bidding_will_begin', {count: lot.index - lot.currentLotIndex})
          : t('bidding_ended')}
      </div>
      <Divider />
      <div className='context'>
        <LotMiniGalleria
          turnOffStyle={true}
          lot={activeLot}
          isShowLotStatus={false}
          className='ActiveLotGalary'
        />
        <div className='div_active_lot'>
          <div className='title_active_lot'>{t('active_lot')}</div>
          <ProgressBar lot={activeLot} />
          <CurrentBid lot={activeLot} isShowVat={false} />
        </div>
      </div>
    </div>
  );
}

export default ActiveLot;

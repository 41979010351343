import {
  REGEX_ADDRESS,
  REGEX_DEFAULT,
  REGEX_GENERAL_NAME,
  REGEX_NO,
  REGEX_NUMBERS,
  REGEX_USER_NAME,
  REGEX_WORDS_NUMBERS,
} from '@helpers/regex';

export const firstInputs = [
  {
    id: 'address',
    pattern: REGEX_ADDRESS,
  },
  {
    id: 'ZIPCode',
    pattern: REGEX_NUMBERS,
  },
];

export const secondUkraineInputs = [
  {
    id: 'companyName',
    pattern: REGEX_GENERAL_NAME,
  },
  {
    id: 'IPNCompany',
    pattern: REGEX_WORDS_NUMBERS,
  },
  {
    id: 'CodeEDRPOYCompany',
    pattern: REGEX_WORDS_NUMBERS,
  },
];

export const secondEuropeInputs = [
  {
    id: 'companyName',
    pattern: REGEX_GENERAL_NAME,
  },
  {
    id: 'NIPCompany',
    pattern: REGEX_WORDS_NUMBERS,
  },
  {
    id: 'VATCompany',
    pattern: REGEX_WORDS_NUMBERS,
  },
];

export const personalInputs = [
  {
    id: 'last_name',
    type: 'text',
    pattern: REGEX_USER_NAME,
  },
  {
    id: 'first_name',
    type: 'text',
    pattern: REGEX_USER_NAME,
  },
  {
    id: 'birthday',
    type: 'date',
    pattern: REGEX_DEFAULT,
  },
  {
    id: 'passport_no',
    type: 'text',
    pattern: REGEX_NO,
  },
  {
    id: 'passport_issued_by',
    type: 'text',
    pattern: REGEX_NO,
  },
  {
    id: 'passport_date',
    type: 'date',
    pattern: REGEX_DEFAULT,
  },
  {
    id: 'identification_number',
    type: 'text',
    pattern: REGEX_NO,
  },
];

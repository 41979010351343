import {
  BID_ALLOWED_HELP_REQUEST,
  FEEDBACK_REQUEST,
  SEND_COMPLAINTS,
} from './types';

export const sendСomplaints = (userId, name, phone, problems) => ({
  event: SEND_COMPLAINTS,
  link: '/api/help/complaints',
  data: {
    userId: userId,
    name: name,
    phone: phone,
    problems: problems,
  },
});

export const bidAllowedHelpRequest = userId => ({
  event: BID_ALLOWED_HELP_REQUEST,
  link: '/api/help/bid_allowed_help_request',
  data: {
    userId: userId,
  },
});

export const feedbackRequest = (userId, name, phone) => ({
  event: FEEDBACK_REQUEST,
  link: '/api/help/feedback',
  data: {
    userId: userId,
    name: name,
    phone: phone,
  },
});

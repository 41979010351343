import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {NavBarContainer} from '@containers';

import './UserProfilePage.scss';

function UserProfilePage() {
  const [t] = useTranslation('pages/profile');
  const verificationRights = useSelector(
    state => state.user.verificationRights
  );
  let links = [
    {
      path: '/profile/verification',
      title: t('title.verification'),
    },
    {
      path: '/profile/personal-information',
      title: t('title.personal-information'),
    },
    {
      path: '/profile/documents',
      title: t('title.documents'),
    },
    {
      path: '/profile/contact-details',
      title: t('title.contact-details'),
    },
    {
      path: '/profile/password-change',
      title: t('title.password-change'),
    },
    {
      path: '/profile/payments',
      title: t('title.payments'),
    },
    {
      path: '/profile/tariffs',
      title: t('title.tariffs'),
    },
    {
      path: '/profile/messages',
      title: t('title.messages'),
    },
    /* {
      path: '/profile/settings',
      title: t('title.settings'),
    },*/
    {
      path: '/profile/go-out',
      title: t('title.go-out'),
    },
  ];
  if (verificationRights?.document !== true) {
    links = links.filter(i => i.path !== '/profile/personal-information');
  }

  return (
    <div className='UserProfilePage'>
      <NavBarContainer links={links} />
    </div>
  );
}
export default UserProfilePage;

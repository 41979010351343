import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Galleria} from 'primereact/galleria';

import {
  LOT_STATUS_ACTIVE,
  LOT_STATUS_FINISHED_SOLD,
  LOT_STATUS_FINISHED_NOT_SOLD,
  LOT_STATUS_FINISHED_RESERVED,
} from '@models';

import './LotMiniGalleria.scss';

function LotMiniGalleria({
  lot,
  isShowLotStatus = true,
  className = '',
  turnOffStyle = false,
}) {
  const galleriaRef = useRef();
  const [ta] = useTranslation('pages/auctions');

  useEffect(() => {
    if (!isShowLotStatus) return;
    const el = galleriaRef.current
      .getElement()
      .querySelector('.p-galleria-item');
    const ResultLine = document.createElement('div');
    ResultLine.setAttribute('class', 'ResultLineBloc');
    const div = document.createElement('div');
    div.setAttribute('class', 'ResultLine');
    const span = document.createElement('span');
    span.setAttribute('class', 'ResultLineSpan');
    div.appendChild(span);
    ResultLine.appendChild(div);
    el?.appendChild(ResultLine);
  }, []);

  useEffect(() => {
    if (!isShowLotStatus) return;
    const el = galleriaRef.current
      .getElement()
      .querySelector('.ResultLineSpan');
    el.innerHTML = '';
    switch (lot.lotStatus) {
      case LOT_STATUS_ACTIVE:
        el.innerHTML = ta('active_lot');
        break;
      case LOT_STATUS_FINISHED_SOLD:
        el.innerHTML = ta('sold');
        break;
      case LOT_STATUS_FINISHED_NOT_SOLD:
        el.innerHTML = ta('no_sold');
        break;
      case LOT_STATUS_FINISHED_RESERVED:
        el.innerHTML = ta('reserved');
        break;
      default:
        break;
    }
  }, [lot?.lotStatus]);

  function determineStatus() {
    if (!isShowLotStatus) return;
    switch (lot.lotStatus) {
      case LOT_STATUS_ACTIVE:
        return 'active_lot';
      case LOT_STATUS_FINISHED_SOLD:
        return 'sold';
      case LOT_STATUS_FINISHED_NOT_SOLD:
        return 'not-sold';
      case LOT_STATUS_FINISHED_RESERVED:
        return 'reserved';
      default:
        break;
    }
  }
  const items = lot.images?.slice(0, 5);
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <Galleria
      activeIndex={activeIndex}
      onItemChange={e => setActiveIndex(e.index)}
      onClick={e => {
        setActiveIndex(activeIndex + 1 < items?.length ? activeIndex + 1 : 0);
        e.preventDefault();
        e.stopPropagation();
      }}
      ref={galleriaRef}
      className={`LotMiniGalleria ${determineStatus()} ${className}`}
      value={items}
      style={turnOffStyle ? null : {maxWidth: '250px', minWidth: '250px'}}
      showThumbnails={false}
      showIndicators
      changeItemOnIndicatorHover
      item={item => <img src={item.src} style={{display: 'block'}} />}
    />
  );
}

export default LotMiniGalleria;

import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Button} from '@ui';
import {
  LOT_STATUS_FINISHED_NOT_SOLD,
  LOT_STATUS_FINISHED_RESERVED,
  LOT_STATUS_FINISHED_SOLD,
} from '@models';

import './ResultLot.scss';

function ResultLot() {
  const [t] = useTranslation('pages/lot');
  const lot = useSelector(state => state.lot);
  const userId = useSelector(state => state.user.id);

  function lost(title) {
    return (
      <div className='lost'>
        <h3>{t('not_win_car')}</h3>
        <h4>
          {t('auction_finished')}, {title}
        </h4>
        <Button text={t('find_another_car')} to={'/lots'} />
      </div>
    );
  }
  function render() {
    switch (lot.lotStatus) {
      case LOT_STATUS_FINISHED_SOLD:
        if (lot.isParticipant) {
          if (userId === lot.bids[0].userId) {
            return (
              <div className='win'>
                <h3>{t('you_win')}</h3>
                <h4>
                  {t('view_detailed_information')}{' '}
                  <Link to={'/my-cars/won-cars'}>{t('my_car')}</Link>
                </h4>
                <Button text={t('move')} to={'/my-cars/won-cars'} />
              </div>
            );
          } else {
            return lost(t('car_sold'));
          }
        } else {
          return (
            <div className='auction_ended'>
              <h3>{t('cannot_bid')}</h3>
              <h4>
                {t('auction_finished')}, {t('car_sold')}
              </h4>
              <Button text={t('find_another_car')} to={'/lots'} />
            </div>
          );
        }

      case LOT_STATUS_FINISHED_NOT_SOLD:
        if (lot.isParticipant) {
          return lost(t('car_no_sold'));
        } else {
          return (
            <div>
              <h3>{t('cannot_bid')}</h3>
              <h4>
                {t('auction_finished')} {t('car_no_sold')}
              </h4>
              <Button text={t('find_another_car')} to={'/lots'} />
            </div>
          );
        }

      case LOT_STATUS_FINISHED_RESERVED:
        if (lot.isParticipant) {
          if (userId === lot.bids[0].userId) {
            return (
              <div className='win_reservation'>
                <h3>{t('you_win_reservation')}</h3>
                <h4>
                  {t('track_status')}{' '}
                  <Link to={'/my-cars/won-with-caveat'}>{t('my_car')}</Link>.
                  {t('seller_have_72h')}
                </h4>
                <h5>
                  {t('more_about_reservation')}{' '}
                  <Link to={'/privacy-policy'}>{t('privacy_policy')}</Link>
                </h5>
                <Button text={t('my_car')} to={'/my-cars/won-with-caveat'} />
              </div>
            );
          } else {
            return lost(t('car_sold_reservation'));
          }
        } else {
          return (
            <div>
              <h3>{t('cannot_bid')}</h3>
              <h4>
                {t('auction_finished')},{t('car_sold_reservation')}
              </h4>
              <h5>
                {t('note_seller_have_72h')}{' '}
                <Link to={'/privacy-policy'}>{t('privacy_policy')}</Link>
              </h5>
              <Button text={t('find_another_car')} to={'/lots'} />
            </div>
          );
        }
      default:
        break;
    }
  }

  const res =
    lot.lotProcessing &&
    lot.lotProcessing.status !== undefined &&
    lot.lotProcessing.status !== null
      ? null
      : render();
  if (res) {
    return <div className='ResultLot'>{res}</div>;
  } else {
    return <></>;
  }
}
export default ResultLot;

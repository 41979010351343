import React from 'react';
import {useSelector} from 'react-redux';
import {Route, Routes} from 'react-router-dom';

import './fonts/index.scss';
// pages
import * as Sentry from '@sentry/react';
import {MainContainer} from '@containers';

import Cars from './pages/cars/LotsPage';
import HelpPage from './pages/help/HelpPage';
import HomePage from './pages/home/HomePage';
import Auction from './pages/auction/Auction';
import Auctions from './pages/auctions/Auctions';
import UserProfilePage from './pages/user/profile/UserProfilePage';
import Page404 from './pages/errors/404/Page404';
import Page500 from './pages/errors/500/Page500';
import LotPage from './pages/lot/LotPage';
//        Container
import OrgContainer from './org/containers/org/OrgContainer';
import AdminContainer from './org/containers/admin/AdminContainer';
//        Registration
import {
  Registration,
  Step_1,
  Step_2,
  Step_3,
  Step_4,
} from './pages/registration/index';
//        Authentication
import {
  AuthenticationPage,
  PasswordRecovery,
  PasswordRecoveryConfirm,
  SetNewPassword,
  SetNewPasswordConfirm,
} from './pages/authentication/index';
//        Profile
import {
  PersonalInformation,
  ContactDetails,
  GoOut,
  PasswordChange,
  Tariffs,
  Messages,
  Settings,
  Documents,
  Verification,
} from './pages/user/profile/index';
//        Admin
import {
  AuthenticationAdmin,
  Moderation,
  ModerationDocuments,
  Support,
  Events,
  Lots,
  Features,
  GoOutAdmin,
  PaymentsOrg,
  Debug,
  Filters,
} from './org/pages/admin/index';
//        Agent
import {
  AuthenticationAgent,
  Lots as LotsUnderlot,
  AddLots as AddLotsUnderlot,
  OwnFeatures as FeaturesUnderlot,
  GoOutAgent,
} from './org/pages/agent';
//        General
import {
  GeneralPages,
  InfoCompany,
  Rules,
  InfoAboutAuction,
  GeneralTermsUse,
  PrivacyPolicy,
  ConsiderationСlaims,
  Contacts,
} from './pages/general/index';
import {
  MyCars,
  Bids,
  NotWon,
  TrackedCars,
  WonCars,
  WonWithCaveat,
} from './pages/user/my-cars/index';
import SynchronizeFeatures from './org/pages/admin/features/synchronize-features/SynchronizeFeatures';
import ExportedFeatures from './org/pages/admin/features/exported-features/ExportedFeatures';
import OwnFeatures from './org/pages/admin/features/own-features/OwnFeatures';
import Payment from './pages/payments/Payment';
import PaymentResult from './pages/payments/payment-result/PaymentResult';
import Payments from './pages/user/profile/payments/Payments';
import AgentContainer from './org/containers/agent/AgentContainer';

/* eslint-disable react/jsx-pascal-case */
function App() {
  const verificationRights = useSelector(
    state => state.user.verificationRights
  );
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const isAuthenticatedAdmin = useSelector(
    state => state.admin.isAuthenticated
  );
  const isAuthenticatedAgent = useSelector(
    state => state.agent.isAuthenticated
  );
  const adminRights = useSelector(
    state => state.admin.rights,
    (prev, next) => {
      if (!prev || !next) return false;
      for (const right in next) {
        if (prev[right] !== next[right]) return false;
      }
      return true;
    }
  );
  const agentRights = useSelector(
    state => state.agent.rights,
    (prev, next) => {
      if (!prev || !next) return false;
      for (const right in next) {
        if (prev[right] !== next[right]) return false;
      }
      return true;
    }
  );

  return (
    <Routes>
      <Route element={<MainContainer />}>
        <Route path='/' index exact element={<HomePage />} />

        <Route path='/auctions' element={<Auctions />} />
        <Route path='/auction/:id' element={<Auction />} />
        <Route path='/lots' element={<Cars />} />
        <Route path='/lot/:id' element={<LotPage />} />
        <Route path='/help' element={<HelpPage />} />

        <Route path='/auth' element={<AuthenticationPage />} />

        <Route path='/registration' element={<Registration />}>
          <Route path='Step_1' element={<Step_1 />} />
          <Route path='Step_2' element={<Step_2 />} />
          <Route path='Step_3' element={<Step_3 />} />
          <Route path='Step_4' element={<Step_4 />} />
        </Route>

        <Route element={<GeneralPages />}>
          <Route path='/info-company' element={<InfoCompany />} />
          <Route path='/rules' element={<Rules />} />
          <Route path='/info-about-auction' element={<InfoAboutAuction />} />
          <Route path='/general-terms-of-use' element={<GeneralTermsUse />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route
            path='/consideration-claims'
            element={<ConsiderationСlaims />}
          />
          <Route path='/contacts' element={<Contacts />} />
        </Route>

        {isAuthenticated ? (
          <>
            <Route path='/payment' element={<Payment />} />
            <Route path='/payment-result' element={<PaymentResult />} />

            <Route path='/profile' element={<UserProfilePage />}>
              {verificationRights?.document ? (
                <Route
                  path='personal-information'
                  element={<PersonalInformation />}
                />
              ) : null}

              <Route path='verification' element={<Verification />} />
              <Route path='verification/:id' element={<Verification />} />
              <Route path='documents' element={<Documents />} />
              <Route path='contact-details' element={<ContactDetails />} />
              <Route
                path='contact-details/:token/:email/:prevEmail'
                element={<ContactDetails />}
              />
              <Route path='password-change' element={<PasswordChange />} />
              <Route path='tariffs' element={<Tariffs />} />
              <Route path='messages' element={<Messages />} />
              <Route path='payments' element={<Payments />} />
              <Route path='settings' element={<Settings />} />
              <Route path='go-out' element={<GoOut />} />
            </Route>

            <Route path='/my-cars' element={<MyCars />}>
              <Route path='tracked-cars' element={<TrackedCars />} />
              <Route path='bids' element={<Bids />} />
              <Route path='won-cars' element={<WonCars />} />
              <Route path='won-with-caveat' element={<WonWithCaveat />} />
              <Route path='not-won' element={<NotWon />} />
            </Route>
          </>
        ) : (
          <>
            <Route path='/password-recovery' element={<PasswordRecovery />} />
            <Route
              path='/password-recovery-confirm'
              element={<PasswordRecoveryConfirm />}
            />
            <Route
              path='/set-new-password/:token'
              element={<SetNewPassword />}
            />
            <Route
              path='/set-new-password-confirm'
              element={<SetNewPasswordConfirm />}
            />
          </>
        )}
        <Route path='/500' element={<Page500 />} />
        <Route path='*' element={<Page404 />} />
      </Route>
      {/*-----------------------------------------------------------------------*/}
      <Route path='/org' element={<OrgContainer />}>
        <Route path='admin/auth' element={<AuthenticationAdmin />} />
        <Route path='agent/auth' element={<AuthenticationAgent />} />
        {isAuthenticatedAdmin ? (
          <Route path='admin' element={<AdminContainer />}>
            {adminRights?.moderation ? (
              <Route path='moderation' element={<Moderation />} />
            ) : null}
            {adminRights?.moderation_of_documents ? (
              <Route
                path='moderation_of_documents'
                element={<ModerationDocuments />}
              />
            ) : null}
            {adminRights?.payments ? (
              <>
                <Route path='payments' element={<PaymentsOrg />} />
                <Route path='payments/:id' element={<PaymentsOrg />} />
              </>
            ) : null}
            {adminRights?.support ? (
              <Route path='support' element={<Support />} />
            ) : null}
            {adminRights?.events ? (
              <>
                <Route path='events' element={<Events />} />
                <Route path='events/:id' element={<Events />} />
              </>
            ) : null}
            {adminRights?.invoices ? (
              <>
                <Route path='lots' element={<Lots />} />
                <Route path='lots/:id' element={<Lots />} />
              </>
            ) : null}
            {adminRights?.features ? (
              <>
                <Route path='filters' element={<Filters />} />
                <Route path='features' element={<Features />}>
                  <Route path='own' element={<OwnFeatures />} />
                  <Route path='export' element={<ExportedFeatures />} />
                  <Route path='synchronize' element={<SynchronizeFeatures />} />
                  <Route
                    path='synchronize/:id'
                    element={<SynchronizeFeatures />}
                  />
                </Route>
              </>
            ) : null}
            {adminRights?.debug ? (
              <Route path='debug' element={<Debug />} />
            ) : null}
            <Route path='go-out' element={<GoOutAdmin />} />
            <Route path='500' element={<Page500 />} />
            <Route path='*' element={<Page404 />} />
          </Route>
        ) : null}
        {isAuthenticatedAgent ? (
          <Route path='agent' element={<AgentContainer />}>
            {agentRights?.add_lots_underlot ? (
              <>
                <Route path='add_lots_underlot' element={<AddLotsUnderlot />} />
                <Route
                  path='add_lots_underlot/:id'
                  element={<AddLotsUnderlot />}
                />
                <Route path='lots_underlot' element={<LotsUnderlot />} />
                <Route
                  path='features_underlot'
                  element={<FeaturesUnderlot />}
                />
              </>
            ) : null}
            <Route path='go-out' element={<GoOutAgent />} />
            <Route path='500' element={<Page500 />} />
            <Route path='*' element={<Page404 />} />
          </Route>
        ) : null}
        <Route path='500' element={<Page500 />} />
        <Route path='*' element={<Page404 />} />
      </Route>
    </Routes>
  );
}

export default Sentry.withProfiler(App);

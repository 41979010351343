import './ShadowContainer.scss';

function ShadowContainer({children, className = '', ...props}) {
  return (
    <div className={`ShadowContainer ${className}`} {...props}>
      {children}
    </div>
  );
}

export default ShadowContainer;

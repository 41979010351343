
import {CardLotProcessing} from '@components';

import Lot from './lot/Lot';
import './Lots.scss';

function Lots({lotsData, auction}) {
  return (
    <div className='Lots'>
      {lotsData &&
        lotsData.cars &&
        lotsData.cars.map((lot, key) =>
          lot.lotProcessing ? (
            <CardLotProcessing key={key} lot={lot} />
          ) : (
            <Lot auction={auction} key={key} lot={lot} />
          )
        )}
    </div>
  );
}
export default Lots;

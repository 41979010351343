import {useState} from 'react';
import {PhotoSlider} from 'react-photo-view';

import DescriptionFrame from './DescriptionFrame';
import './Description.scss';

function Description() {
  const [showPhoto, setShowPhoto] = useState();
  const [visible, setVisible] = useState();

  function openGallery(img) {
    setShowPhoto(img);
    setVisible(true);
  }

  return (
    <div className='Description'>
      <DescriptionFrame openGallery={openGallery} />
      <PhotoSlider
        toolbarRender={({scale, onScale, rotate, onRotate}) => {
          return (
            <div className='PhotoSlider_toolbar'>
              <span
                className='pi pi-replay'
                onClick={() => onRotate(rotate - 90)}
              />
              <span
                className='pi pi-minus'
                onClick={() => onScale(scale - 1)}
              />
              <span className='pi pi-plus' onClick={() => onScale(scale + 1)} />
            </div>
          );
        }}
        images={[{src: showPhoto, key: showPhoto}]}
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </div>
  );
}
export default Description;

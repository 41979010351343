import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {Button, Message, InputCode, Input, InputNumber} from '@ui';
import {REGEX_EMAIL, REGEX_NOT_SPACES} from '@helpers/regex';
import {useDispatchAsync, useValidation, useUpdatedState} from '@hooks';
import {ShadowContainer} from '@containers';
import {ReactComponent as ArrowGreen} from '@assets/icons/arrow-green.svg';

import {
  getUserInfo,
  requestChangeEmail,
  setNewPhone,
  sentCodeToPhone,
  setNewEmail,
} from '@actions/user';

import './ContactDetails.scss';

function ContactDetails() {
  const params = useParams();
  const dispatch = useDispatchAsync();
  const navigate = useNavigate();
  const isInputsValid = useValidation('ContactDetails_input', 'color');

  const [code, setCode] = useState('');
  const [activeMessage, setActiveMessage] = useState({});
  const [isShowInputForCode, setShowInputForCode] = useState(false);

  const phoneNumber = useSelector(state => state.user.phone);
  const [phone, setPhone] = useUpdatedState(phoneNumber || '');

  const emailAddress = useSelector(state => state.user.email);
  const [email, setEmail] = useUpdatedState(
    params.email || emailAddress || ' '
  );
  const phoneIdForNewPhone = useSelector(
    state => state.user.phoneIdForNewPhone
  );

  const [errors] = useTranslation('common', {keyPrefix: 'errors'});

  const [t] = useTranslation('pages/profile', {
    keyPrefix: 'contact-details',
  });

  async function clickPhoneCode() {
    if (isInputsValid && phone && phoneNumber && phone !== phoneNumber) {
      const res = await dispatch(sentCodeToPhone(phone, phoneNumber), false);
      if (res.ok) {
        setShowInputForCode(true);
        setActiveMessage(t('messages.numberInfo', {returnObjects: true}));
      } else {
        setActiveMessage({title: errors(res.code), type: 'error'});
      }
    }
  }

  async function clickSetNewPhone() {
    if (code.length === 4) {
      const res = await dispatch(
        setNewPhone(code, phone, phoneNumber, phoneIdForNewPhone),
        false
      );
      if (res.ok) {
        navigate('/profile/contact-details');
        setActiveMessage(t('messages.numberSuccess', {returnObjects: true}));

        setShowInputForCode(false);
        setCode('');
      } else if (res?.code === 'invalid_code') {
        setActiveMessage({title: errors(res.code), type: 'error'});
      }
    }
  }

  async function clickRequestChangeEmail() {
    if (isInputsValid && email && emailAddress && email !== emailAddress) {
      const res = await dispatch(
        requestChangeEmail(email, emailAddress),
        false
      );
      if (res.ok) {
        setActiveMessage(t('messages.emailInfo', {returnObjects: true}));
      } else {
        setActiveMessage({title: errors(res.code), type: 'error'});
      }
    }
  }

  useEffect(async () => {
    if (params.token && params.email && params.prevEmail) {
      const res = await dispatch(
        setNewEmail(params.token, params.email, params.prevEmail),
        false
      );
      if (res.ok) {
        setActiveMessage(t('messages.emailSuccess', {returnObjects: true}));
      } else {
        setActiveMessage({title: errors(res.code), type: 'error'});
      }
      return navigate('/profile/contact-details');
    }
    dispatch(getUserInfo());
  }, []);

  return (
    <div className='ContactDetails'>
      <Message type={activeMessage.type} title={activeMessage.title} />
      <ShadowContainer className='email'>
        <div className='title'>{t('email.mainTitle')}</div>
        <Input
          className='ContactDetails_input'
          type='text'
          pattern={REGEX_EMAIL}
          value={email}
          headerTitle={t('email.title')}
          placeholder={t('email.placeholder')}
          onChange={e => setEmail(e.target.value)}
          underTitle={t('email.under_input_text')}
        />
        <Button text={t('email.bt')} onClick={clickRequestChangeEmail} />
      </ShadowContainer>
      <ShadowContainer className='numberPhone'>
        {isShowInputForCode ? (
          <>
            <div className='title'>{t('numberPhone.mainTitleConfirm')}</div>
            <InputCode
              title={t('code.title')}
              value={code}
              onChangeHandler={cod => setCode(cod)}
              under_input_text={t('code.under_input_text')}
            />

            <div className='bts'>
              <Button
                onClick={clickSetNewPhone}
                text={t('numberPhone.btNext')}
              />
              <Button
                className='gray'
                to='/profile/contact-details'
                text={t('numberPhone.btCancel')}
                onClick={() => {
                  window.location.reload(true);
                }}
              />
            </div>
            <Button
              className='greenwhite'
              onClick={() => {
                setShowInputForCode(false);
                setActiveMessage({title: ''});
              }}
              text={t('numberPhone.btBack')}>
              <ArrowGreen />
            </Button>
          </>
        ) : (
          <>
            <div className='title'>{t('numberPhone.mainTitle')}</div>
            <InputNumber
              phone={phone}
              title={t('numberPhone.title')}
              placeholder={t('numberPhone.placeholder')}
              under_input_text={t('numberPhone.under_input_text')}
              pattern={REGEX_NOT_SPACES}
              onChangeHandler={number => setPhone(number)}
            />
            <Button text={t('email.bt')} onClick={clickPhoneCode} />
          </>
        )}
      </ShadowContainer>
    </div>
  );
}

export default ContactDetails;

import {Dropdown} from 'primereact/dropdown';
import {Tag} from 'primereact/tag';

function ListStatus(editorData) {
  const firstValue = {};
  return options => {
    const {value, editorCallback, rowData} = options;
    const tag = editorData.find(i => i.indexInDB === value);
    if (firstValue[rowData.id] === undefined) {
      firstValue[rowData.id] = value;
    }
    return (
      <Dropdown
        value={tag?.title || ''}
        placeholder='Select...'
        style={{height: '30px', alignItems: 'center'}}
        options={editorData
          .filter(i => i.indexInDB >= firstValue[rowData.id])
          .map(i => i.title)}
        onChange={e => {
          editorCallback(editorData.find(i => i.title === e.value).indexInDB);
        }}
        itemTemplate={option => {
          const tag = editorData.find(i => i.title === option);
          return <Tag value={tag.title} severity={tag.severity}></Tag>;
        }}
      />
    );
  };
}
export default ListStatus;

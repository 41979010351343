import {
  UPDATE_BIT_ALLOWED,
  UPDATE_LOT_COUNT_LIMIT,
  UPDATE_LOT_PRICE_LIMIT,
  UPDATE_LOT_PRICE_RESERVE,
  UPDATE_RIGHTS,
  UPDATE_STATUS,
} from './types';

export const updateStatus = (userId, status) => ({
  event: UPDATE_STATUS,
  link: '/api/org/update_status',
  data: {
    userId: userId,
    status: status,
  },
});

export const updateRights = (userId, field, right) => ({
  event: UPDATE_RIGHTS,
  link: '/api/org/update_admin_rights',
  data: {
    userId: userId,
    field: field,
    right: right,
  },
});

export const updateAgentRights = (userId, field, right) => ({
  event: UPDATE_RIGHTS,
  link: '/api/org/update_agent_rights',
  data: {
    userId: userId,
    field: field,
    right: right,
  },
});

export const updateVerificationRights = (userId, right, value) => ({
  event: UPDATE_RIGHTS,
  link: '/api/org/update_verification_rights',
  data: {
    userId: userId,
    right: right,
    value: value,
  },
});

export const updateBidAllowed = (userId, status) => ({
  event: UPDATE_BIT_ALLOWED,
  link: '/api/org/update_bid_allowed',
  data: {
    userId: userId,
    status: status,
  },
});

export const updateLotCountLimit = (userId, limit) => ({
  event: UPDATE_LOT_COUNT_LIMIT,
  link: '/api/org/update_lot_count_limit',
  data: {
    userId: userId,
    limit: limit,
  },
});

export const updateLotPriceLimit = (userId, limit) => ({
  event: UPDATE_LOT_PRICE_LIMIT,
  link: '/api/org/update_lot_price_limit',
  data: {
    userId: userId,
    limit: limit,
  },
});

export const updateLotPriceReserve = (userId, reserve) => ({
  event: UPDATE_LOT_PRICE_RESERVE,
  link: '/api/org/update_lot_price_reserve',
  data: {
    userId: userId,
    reserve: reserve,
  },
});

import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {formatPrice} from '@utils';
import {ReactComponent as PersonSVG} from '@assets/icons/person.svg';

import './LastBids.scss';

function LastBids() {
  const [t] = useTranslation('pages/lot');
  const lot = useSelector(state => state.lot);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  if (!isAuthenticated || !lot.bids) return <></>;
  return (
    <div className='LastBids'>
      <div className='title'>{t('lastBits')}</div>
      <div className='bits'>
        {lot.bids
          .sort((a, b) => b.placedAt - a.placedAt)
          .map(item => {
            let userName = item.userName;
            if (userName.length === 32 && userName.split(' ').length === 1) {
              ////eefc4d54052b5f169214d00736954881
              userName = t('auction_participant');
            }
            return (
              <div
                key={item.bidPrice}
                className={`bit ${
                  item.bidPrice > lot.prices.ownerPrice ? 'green' : 'red'
                }`}>
                <div className={'icon'}>
                  <PersonSVG />
                </div>
                <div className='price'>{formatPrice(item.bidPrice)}</div>
                <div className='userName'>{userName}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
export default LastBids;

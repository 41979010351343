import {
  ADMIN_LOGIN,
  ADMIN_LOGOUT,
  CHECK_NEW_ADMIN_EVENTS,
  GET_ADMIN_EVENTS,
  GET_ADMIN_RIGHTS,
  GET_USERS,
  SET_EVENT_STATUS_AS_PROCESSED,
} from './types';

export const adminLogin = (login, password) => ({
  event: ADMIN_LOGIN,
  link: '/api/org/login',
  data: {
    login: login,
    password: password,
  },
});

export const adminLogout = () => ({
  event: ADMIN_LOGOUT,
  link: '/api/org/logout',
  data: {},
});

export const getAdminRights = () => ({
  event: GET_ADMIN_RIGHTS,
  link: '/api/org/admin_rights',
});

export const getUsers = () => ({
  event: GET_USERS,
  link: '/api/org/users',
});

export const getEventsByTypes = (dateValue, ids) => ({
  event: GET_ADMIN_EVENTS,
  link: '/api/org/events',
  data: {date: dateValue, ids: ids},
});

export const checkNewAdminEvents = date => ({
  event: CHECK_NEW_ADMIN_EVENTS,
  link: '/api/org/check_new_events',
  data: {date: date},
});

export const setAsProcessedEvent = id => ({
  event: SET_EVENT_STATUS_AS_PROCESSED,
  link: '/api/org/set_status_as_processed',
  data: {
    id: id,
  },
});

import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Dialog} from 'primereact/dialog';
import {useNavigate, useParams} from 'react-router-dom';

import {Button, DropDownList, Input, SelectButton} from '@ui';
import {CLEAR_UNDER_DRAFT_LOT} from '@actions/agent/types';
import {LOT_CATEGORY_CAR, LOT_CATEGORY_STUFF} from '@helpers/models';
import {useDispatchAsync} from '@hooks';
import {Toast} from '@helpers';
import {
  getUnderFeaturesByCategory,
  createUnderDraftLot,
  getUnderDraftLotInfoById,
  setUnderDraftLotInfo,
  getSourceFeatureIdsForFilters,
} from '@actions/agent';

import OtherOptions from './OtherOptions';
import Photos from './Photos';
import FilterOptions from './FilterOptions';
import Description from './Description';

import './AddLotsUnderlot.scss';

const categoryOptions = [
  {
    title: 'Авто',
    type: LOT_CATEGORY_CAR,
  },
  {
    title: 'Речі',
    type: LOT_CATEGORY_STUFF,
  },
];
function AddLotsUnderlot() {
  const pagesTitles = ['Фотографії', 'Опис', 'Фільтер опції', 'Комплектація'];

  const navigate = useNavigate();
  const {id: lotId} = useParams();
  const dispatch = useDispatchAsync();

  const [visible, setVisible] = useState();
  const [selectPage, setSelectPage] = useState(pagesTitles[0]);
  const [data, setData] = useState({prices: {}, features: []});
  const [dataForm, setDataForm] = useState({name: '', category: 1});

  const lot = useSelector(state => state.agent.lot);
  const categoryFeatures = useSelector(state => state.agent.categoryFeatures);
  const filterSourceFeatureIds = useSelector(
    state => state.agent.filterSourceFeatureIds
  );

  useEffect(() => {
    if (lot) setData(lot);
  }, [lot]);

  useEffect(async () => {
    if (lotId) {
      const res = await dispatch(getUnderDraftLotInfoById(lotId));
      if (res.ok) {
        setData({...res.lot, lotId});
      }
    }
  }, [lotId]);

  useEffect(() => {
    if (
      typeof data.category === 'number' &&
      (!categoryFeatures || categoryFeatures !== data.category)
    ) {
      dispatch(getUnderFeaturesByCategory(data.category));
    }
    !filterSourceFeatureIds && dispatch(getSourceFeatureIdsForFilters());
  }, [data]);

  async function onCreate() {
    const res = await dispatch(
      createUnderDraftLot({
        name: dataForm.name,
        category: dataForm.category,
      })
    );
    if (res) {
      navigate(`/org/agent/add_lots_underlot/${res.lotId}`);
    }
  }

  async function setInfo() {
    const _data = {...data};
    delete _data.category;
    delete _data.images;
    const res = await dispatch(setUnderDraftLotInfo(_data));
    if (res.ok) {
      Toast.info('Інформація успішно оновлена!');
      navigate('/org/agent/lots_underlot');
      dispatch({type: CLEAR_UNDER_DRAFT_LOT});
    }
  }

  return (
    <div className='AddLotsUnderlot'>
      <Dialog
        header='Створення нового лота'
        visible={visible}
        className='create_new_lot_dialog'
        footer={() => (
          <div>
            <Button
              className={'create_bt'}
              onClick={async () => {
                if (dataForm['name'] && dataForm['category']) {
                  setData(prev => ({
                    ...prev,
                    name: dataForm['name'],
                    category: dataForm['category'],
                  }));
                  setDataForm({});
                  await onCreate();
                  setVisible(false);
                } else {
                  Toast.warning('Заповніть необхідні дані!');
                }
              }}>
              <p className='pi pi-check'></p>
              <p>Створити</p>
            </Button>
          </div>
        )}
        onHide={() => setVisible(false)}>
        <div className='create_new_lot_dialog_context'>
          <Input
            headerTitle={'Назва лота'}
            value={dataForm['name']}
            onChange={e =>
              setDataForm(prev => ({
                ...prev,
                name: e.target.value,
              }))
            }
          />
          <DropDownList
            headerTitle={'Виберіть групу'}
            value={categoryOptions.find(el => el.type === dataForm['category'])}
            onChange={e => {
              setDataForm(prev => ({
                ...prev,
                category: e.value.type,
              }));
            }}
            options={categoryOptions}
          />
        </div>
      </Dialog>

      <div>
        <h1>{data['name']}</h1>
      </div>
      <div className='preHeader'>
        {!lotId ? (
          <Button
            className={'plus'}
            text={'Додати лот'}
            onClick={() => setVisible(true)}
          />
        ) : null}
        {data['id'] ? (
          <Button
            className={'finishEdit'}
            text={'Зберегти зміни'}
            onClick={setInfo}
          />
        ) : null}
      </div>
      <div className='header'>
        {data['id'] ? (
          <SelectButton
            value={selectPage}
            onChange={e => {
              if (e.value !== null && e.value !== undefined) {
                setSelectPage(e.value);
              }
            }}
            options={pagesTitles}
          />
        ) : null}
      </div>
      {data['id'] ? (
        <div className='AddLotsUnderlot_container'>
          {(() => {
            switch (pagesTitles.findIndex(i => i === selectPage)) {
              case 0:
                return <Photos lotId={lotId} />;
              case 1:
                return <Description data={data} setData={setData} />;
              case 2:
                return <FilterOptions data={data} setData={setData} />;
              case 3:
                return <OtherOptions data={data} setData={setData} />;
              default:
                break;
            }
          })()}
        </div>
      ) : null}
    </div>
  );
}
export default AddLotsUnderlot;

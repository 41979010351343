import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {userLogout} from '@actions/user';
import {useDispatchAsync} from '@hooks';

function GoOut() {
  const dispatch = useDispatchAsync();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(userLogout());
    navigate('/');
  }, []);

  return 'GoOut';
}

export default GoOut;

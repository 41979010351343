import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Link, Outlet} from 'react-router-dom';

import './NavBarContainer.scss';

function NavBarContainer({links}) {
  const navigate = useNavigate();
  const [t] = useTranslation('pages/messages');
  const location = useLocation();
  const routeContainer = links[0].path.split('/')[1];

  useEffect(() => {
    if (location.pathname === '/' + routeContainer) {
      return navigate(links[0].path, {replace: true});
    }
  }, [location]);

  return (
    <div className='NavBarContainer'>
      <div className='container'>
        <ul className='navbar'>
          {links.map((it, index) => (
            <li
              key={index}
              className={location.pathname.includes(it.path) ? 'active' : ''}>
              <Link to={it.path}>{t(it.title)}</Link>
            </li>
          ))}
        </ul>
        <div className='bloc-info'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default NavBarContainer;

import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Dialog} from 'primereact/dialog';
import {InputTextarea} from 'primereact/inputtextarea';
import {File, FilePen} from 'lucide-react';

import {Button} from '@ui/index';
import {useDispatchAsync} from '@hooks';
import {setLotNote} from '@actions/lot';
import {Toast} from '@helpers';

import './Note.scss';

function Note({lot}) {
  const [t] = useTranslation('pages/cars');

  const dispatch = useDispatchAsync();
  const user = useSelector(state => state.user);

  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(lot.note || '');

  return (
    <div className='Note' onClick={e => e.stopPropagation()}>
      <Button
        className={`${value ? 'active' : 'gray'}`}
        onClick={() => setVisible(true)}>
        {value ? <File /> : <FilePen />}
      </Button>
      <Dialog
        className='NoteDialog'
        header={`${lot.name}`}
        visible={visible}
        style={{width: '700px'}}
        onHide={() => {
          setVisible(false);
        }}>
        <div className=''>
          <InputTextarea
            autoResize
            value={value}
            variant='filled'
            placeholder={t('add_note_placeholder')}
            onChange={e => setValue(e.target.value)}
            rows={5}
          />

          <div className='btns'>
            <Button
              onClick={async () => {
                const res = await dispatch(
                  setLotNote(lot.lotId, user.id, value)
                );
                if (res.ok) {
                  Toast.success(t('note_is_saved'));
                }
                setVisible(false);
              }}>
              {t('save')}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default Note;

import {useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useDispatchAsync} from '@hooks';
import {TopHomeContainer} from '@containers';
import {Button, Input} from '@ui';
import {ReactComponent as ArrowGreen} from '@assets/icons/arrow-green.svg';
import {sendEmailList} from '@actions/password-recovery';
import {useValidation} from '@hooks';
import {REGEX_EMAIL} from '@helpers/regex';

import './PasswordRecovery.scss';

function PasswordRecovery() {
  const navigate = useNavigate();
  const [t] = useTranslation('pages/authentication', {
    keyPrefix: 'password-recovery',
  });
  const dispatch = useDispatchAsync();
  const last_email = useSelector(state => state.passwordRecovery.email);
  const [email, setEmail] = useState(last_email);
  const isInputsValid = useValidation('PasswordRecovery_input', 'color');

  async function onClickHandler(e) {
    if (last_email === email) {
      return navigate('/password-recovery-confirm');
    }
    if (isInputsValid) {
      const res = await dispatch(sendEmailList(email));
      if (res.ok) return navigate('/password-recovery-confirm');
    }
    e.preventDefault();
  }

  return (
    <TopHomeContainer>
      <div className='PasswordRecovery'>
        <header>
          <div className='title'>{t('title')}</div>
          <div className='subTitle'>
            <span>{t('subTitle')}</span>
          </div>
        </header>

        <div className='form'>
          <Input
            className='PasswordRecovery_input'
            type='email'
            pattern={REGEX_EMAIL}
            value={email}
            headerTitle={t('email.headerTitle')}
            placeholder={t('email.placeholder')}
            onChange={e => setEmail(e.target.value)}
            underTitle={t('email.underTitle')}
          />
        </div>

        <div className='links'>
          <Button onClick={onClickHandler} text={t('btNext')} />
          <Link to='/auth' className='back'>
            <ArrowGreen />
            {t('btBack')}
          </Link>
        </div>
      </div>
    </TopHomeContainer>
  );
}

export default PasswordRecovery;

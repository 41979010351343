import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Logger} from '@helpers';
import {ORDER_DOC_TYPE_INVOICE, ORDER_DOC_TYPE_RECEIPT, ORDER_PRODUCT_TYPE_LOT, ORDER_PRODUCT_TYPE_TARIFF} from '@models';
import {downloadOrderDoc} from '@actions/user';
import {useDispatchAsync} from '@hooks';

function useDownloadOrderDocs(docType = ORDER_DOC_TYPE_INVOICE) {
  const dispatch = useDispatchAsync();
  const user = useSelector(state => state.user);

  return useCallback(async props => {
    const params = new URLSearchParams();
    params.set('docType', docType);
    if (props.orderId) {
      params.set('orderId', props.orderId);
    } else {
      params.set('userId', props.userId || user.id); // in order to admin can download

      if (props.lotId) {
        params.set('productId', props.lotId);
        params.set('productType', ORDER_PRODUCT_TYPE_LOT);
      } else if (props.tariffId !== null && props.tariffId !== undefined) {
        params.set('productId', props.tariffId);
        params.set('productType', ORDER_PRODUCT_TYPE_TARIFF);
      } else {
        Logger.error(
          `Attempt download invoice with un correct props : ${JSON.stringify(
            props
          )}`
        );
        return;
      }
    }
    const blob = await dispatch(downloadOrderDoc(params));
    if (blob.ok !== undefined) return;
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    switch (docType) {
      case ORDER_DOC_TYPE_INVOICE:
        a.download = 'Invoice.pdf';
        break;
      case ORDER_DOC_TYPE_RECEIPT:
        a.download = 'Receipt.pdf';
        break;
      default:
        break;
    }

    document.body.appendChild(a);
    a.click();
    a.remove();
  });
}
export default useDownloadOrderDocs;

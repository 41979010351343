import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {Toast, Logger} from '@helpers';
import {COMMONQUERY, FetchToRedux} from '@helpers/requests';
import {USER_LOGOUT} from '@actions/user/types';
import {ADMIN_LOGOUT} from '@actions/admin/types';
import {AGENT_LOGOUT} from '@actions/agent/types';

let role = null;
function useDispatchAsync(_role) {
  if (_role) {
    role = _role;
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errors] = useTranslation('common', {keyPrefix: 'errors'});
  const [t, i18n] = useTranslation();

  return useCallback((obj, isLogErrors = true) => {
    if (obj.type) {
      return dispatch({type: obj.type, payload: obj.payload});
    }
    return dispatch(async (dispatch, getState) => {
      let token = null;
      if (role) {
        switch (role) {
          case 'user':
            token = getState().user.token;
            break;
          case 'admin':
            token = getState().admin.token;
            break;
          case 'agent':
            token = getState().agent.token;
            break;
          default:
            Logger.error('Unknown type role');
            break;
        }
      }

      const res = await FetchToRedux(
        dispatch,
        obj.event,
        obj.link,
        COMMONQUERY(obj.data ? 'POST' : 'GET', obj.data, {
          'Accept-Language': i18n.currentLang,
          Authorization: token ? `Bearer ${token}` : undefined,
        }),
        obj.data,
        isLogErrors,
        obj.excludeAbort || false
      );
      if (isLogErrors && res.ok === false && res.code) {
        if (res.code === 'invalid_token') {
          Logger.error('invalid_token');
          switch (role) {
            case 'user':
              await dispatch({type: USER_LOGOUT});
              navigate('/auth');
              break;
            case 'admin':
              await dispatch({type: ADMIN_LOGOUT});
              navigate('/org/admin/auth');
              break;
            case 'agent':
              await dispatch({type: AGENT_LOGOUT});
              navigate('/org/agent/auth');
              break;
            default:
              await dispatch({type: USER_LOGOUT});
              await dispatch({type: ADMIN_LOGOUT});
              await dispatch({type: AGENT_LOGOUT});
              Logger.error('Unknown type role');
              navigate('/auth');
              break;
          }
        }
        Toast.error(errors([res.code, 'unknown']));
      }
      return res;
    });
  });
}
export default useDispatchAsync;

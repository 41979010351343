import {useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Button, Input} from '@ui';
import {REGEX_PASSWORD} from '@helpers/regex';

import {useDispatchAsync} from '@hooks';
import {ReactComponent as ArrowGreen} from '@assets/icons/arrow-green.svg';
import {TopHomeContainer} from '@containers';

import {setNewPassword} from '@actions/password-recovery';
import {useValidation} from '@hooks';

import './SetNewPassword.scss';

function SetNewPassword() {
  const {token} = useParams();
  const navigate = useNavigate();

  const [t] = useTranslation('pages/authentication', {
    keyPrefix: 'set-new-password',
  });
  const dispatch = useDispatchAsync();
  const [errorSMS, setErrorSMS] = useState(' ');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const isInputsValid = useValidation('SetNewPassword_input', 'color');

  function setPasswordHandlers(e) {
    document
      .querySelector('#repeatpassword')
      .setAttribute('pattern', e.target.value);
    setPassword(e.target.value);
  }

  async function onClickHandler() {
    if (isInputsValid && password && token && password === repeatPassword) {
      const res = await dispatch(setNewPassword(password, token), false);
      if (res.ok) {
        return navigate('/set-new-password-confirm');
      } else {
        if (res.status === 'error') {
          setErrorSMS(t('errors.time_out'));
        } else {
          setErrorSMS(' ');
        }
      }
    }
  }

  return (
    <TopHomeContainer>
      <div className='SetNewPassword'>
        <header>
          <div className='title'>{t('title')}</div>
          <div className='subTitle'>
            <span>
              {t('subTitle')}
              <Link to='/registration'>{t('subTitleLink')}</Link>
            </span>
          </div>
        </header>

        <div className='form'>
          <Input
            className='SetNewPassword_input'
            type={'password'}
            pattern={REGEX_PASSWORD}
            key={0}
            value={password}
            headerTitle={t('password.headerTitle')}
            placeholder={t('password.placeholder')}
            onChange={setPasswordHandlers}
            underTitle={t('password.underTitle')}
          />
          <Input
            className='SetNewPassword_input'
            type={'password'}
            pattern={password}
            key={1}
            id={'repeatpassword'}
            value={repeatPassword}
            headerTitle={t('repeatPassword.headerTitle')}
            placeholder={t('repeatPassword.placeholder')}
            onChange={e => setRepeatPassword(e.target.value)}
            underTitle={t('repeatPassword.underTitle')}
          />
        </div>
        <div className='errorSMS'>{errorSMS}</div>
        <div className='links'>
          <Button onClick={onClickHandler} text={t('btNext')} />
          <Link to='/' className='back'>
            <ArrowGreen />
            {t('btBack')}
          </Link>
        </div>
      </div>
    </TopHomeContainer>
  );
}

export default SetNewPassword;

import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {useState} from 'react';

function DeleteProductsDialog({idRow, config, selectedProduct}) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button
        label='Видалити'
        icon='pi pi-trash'
        severity='danger'
        onClick={() => setVisible(true)}
        disabled={!selectedProduct}
      />
      <Dialog
        visible={visible}
        style={{width: '32rem'}}
        breakpoints={{'960px': '75vw', '641px': '90vw'}}
        header='Підтвердження'
        modal
        footer={
          <>
            <Button
              label='Ні'
              icon='pi pi-times'
              outlined
              onClick={() => setVisible(false)}
            />
            <Button
              label='Так'
              icon='pi pi-check'
              severity='danger'
              onClick={async () => {
                await config.onDelete(selectedProduct, idRow);
                setVisible(false);
                await config.onLoad();
              }}
            />
          </>
        }
        onHide={() => setVisible(false)}>
        <div className='confirmation-content'>
          <i
            className='pi pi-exclamation-triangle mr-3'
            style={{fontSize: '2rem'}}
          />
          {selectedProduct && <span>Видалити даний запис ?</span>}
        </div>
      </Dialog>
    </>
  );
}
export default DeleteProductsDialog;

import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {ReactComponent as Dagger} from '@assets/icons/dagger.svg';
import {ReactComponent as WasteBasket} from '@assets/icons/waste-basket.svg';

import './SelectedFilterOptions.scss';

function SelectedFilterOptions() {
  const entitys = [
    ['manufacturers', 'manufacturers'],
    ['fuels', 'fuelTypes'],
    ['transmitions', 'transmitions'],
    ['damages', 'damages'],
  ];

  const filters = useSelector(state => state.lots.filters);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectFilters, setSelectFilters] = useState(getSelectFilters());

  function InnerJoin(keyParams, keyFilter) {
    if (!searchParams.get(keyParams) || !filters) return [];
    const paramsData = searchParams.get(keyParams).split(',');
    return filters[keyFilter]
      .filter(i => paramsData.find(l => +l === i.id))
      .map(i => i.name);
  }
  function getSelectFilters() {
    const res = [];
    for (const [key, value] of entitys) {
      res.push(...InnerJoin(key, value));
    }
    return res;
  }

  useEffect(async () => {
    setSelectFilters(getSelectFilters());
  }, [searchParams, filters]);

  function deleteSelect(title) {
    for (const [key, value] of entitys) {
      const filter = filters[value];
      const res = filter.find(i => i.name === title);
      if (res) {
        searchParams.set(
          key,
          searchParams
            .get(key)
            .split(',')
            .filter(i => +i !== res.id)
        );
        setSearchParams(searchParams, {replace: true});
        return;
      }
    }
  }

  return (
    <div className='SelectedFilterOptions'>
      {selectFilters.map(i => (
        <div className='option' key={i}>
          {i}
          <Dagger className='Dagger' onClick={() => deleteSelect(i)} />
        </div>
      ))}
      {selectFilters.length > 0 ? (
        <div className='WasteBasket' onClick={() => setSearchParams({})}>
          <WasteBasket />
        </div>
      ) : null}
    </div>
  );
}
export default SelectedFilterOptions;

import {useEffect, useState} from 'react';

function useUpdatedState(initialState) {
  const [value, setValue] = useState(initialState);
  useEffect(() => {
    setValue(initialState);
  }, [initialState]);
  return [value, setValue];
}
export default useUpdatedState;

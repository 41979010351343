import {useEffect} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';

function ScrollTopContainer(props) {
  const location = useLocation();
  const [params, setSearchParams] = useSearchParams(location.search);

  useEffect(() => {
    scrollToTop();
    return () => {
      if (location.search === '') {
        scrollToTop();
      }
    };
  }, [location]);

  const scrollToTop = () => {
    if (
      //so that there is no automatic scrolling when selecting filters
      location.pathname === '/lots' &&
      !params.has('page') &&
      !params.has('limit')
    ) {
      return;
    }

    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return props.children;
}
export default ScrollTopContainer;

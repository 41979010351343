import {useEffect, useRef} from 'react';
import {Dropdown} from 'primereact/dropdown';
import './Select.scss';

function Select({data, value, onChange}) {
  const ref = useRef();

  useEffect(() => {
    const el = ref.current.getElement().querySelector('.p-hidden-accessible');
    if (!el) return;
    el.remove();
  });

  return (
    <div className='Select'>
      <Dropdown
        ref={ref}
        value={value}
        onChange={onChange}
        options={data}
        optionLabel='title'
        placeholder={data[0].title}
      />
    </div>
  );
}
export default Select;
